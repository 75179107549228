import styled from "styled-components";
import cssVars from "../../../../styles/cssVariables.vars";

export const StyledClientInfo = styled.div`
    display: flex;
    width: max-content;
    height: 100%;
    gap: 0.5rem;

    >div:nth-child(1) {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        padding: 0.5rem;
        border-radius: 0.25rem;
        max-width: max-content;
        
        background-color: ${cssVars.colorGrey["7"]};
        color: ${cssVars.colorGrey[2]};
        position: relative;

        >img, svg{
            width: 3rem;
            height: 3rem;
            object-fit: cover;
            border-radius: 50%;
        }

        >img {
            outline: 2px solid ${cssVars.colorGrey["6h"]};
            transition: width 0.5s ease-in, border-radius 0ms ease-in, height 0.5s ease-in;
            border-radius: 0.125rem;
        }

        >div {
            display: flex;
            flex-direction: column;

            >h2 {
                font-size: 0.75rem;
                font-weight: 500;
                white-space: nowrap;
            }

            >h3 {
                font-size: 0.75rem;
                font-weight: 500;
                white-space: nowrap;
            }
            
            >span {
                font-size: 0.75rem;
                white-space: nowrap;
            }
        }
    }

    >div.info_box {
        box-sizing: border-box;
        height: 100%;
        width: max-content;

        display: flex;
        flex-direction: column;
        justify-content: center;

        padding: 0.5rem;
        background-color: ${cssVars.colorGrey["7"]};
        color: ${cssVars.colorGrey[2]};
        border-radius: 0.25rem;

        font-size: 0.75rem;

        b {
            font-weight: 500;
        }
    }
    
    >div.margin_select {
        &>div:nth-child(2) {
            margin-top: 0.25rem;
        }
    }
`;