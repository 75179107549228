import { downloadExcel } from 'react-export-table-to-excel';
import Input from '../../Input';
import cssVars from '../../../styles/cssVariables.vars';
import { FaDownload } from 'react-icons/fa6';
import Modal from '../../Modal';
import { MutableRefObject, ReactNode } from 'react';
import StyledExportTableDialog from './styles';
import { ButtonType2 } from '../../../styles/buttons.style';
import { CustomState, CustomStateHook } from '../../../Hooks/State.hook';
import { TableColumn } from '../types';

type ExportTableDialogProps<T> = {
    tableName: string;
    modalState: CustomStateHook<ReactNode>;
    tableRef?: MutableRefObject<null | HTMLTableElement>;
    columns: Array<TableColumn<T>>;
    rows: Array<T>;
}

function ExportTableDialog<T>({ tableName, modalState, columns, rows }: ExportTableDialogProps<T>) {
    const filenameState = CustomState<string>(`${new Date().toLocaleDateString().replace(/([/])+/g, "-")}_${tableName}`);

    const handleDownload = () => {
        downloadExcel({
            fileName: filenameState.value.replace(" ", "_"),
            sheet: tableName.replace(" ", "_"),
            tablePayload: {
                header: [
                    ...columns.map(c => c.header || String(c.key))
                ],
                body: [
                    ...rows.map(r => {
                        return columns.map(c => c.format ? c.format(r) as string : getRowKey(r, c.key));
                    })
                ]
            }
        });
    }

    const getRowKey = (row: T, key: keyof T) => String(row[key]);


    return (
        <Modal modalState={modalState} title={`Exportar dados`}>
            <StyledExportTableDialog>
                <Input
                    $color={cssVars.colorGrey[4]}
                    $focusColor={cssVars.colorGrey[2]}
                    $padding={[0.5, 0.5]}
                    $fontSize={0.875}
                    $width="24rem"
                    leftIcon={null}
                    rightIcon={<p style={{ padding: "0.5rem" }}>.xls</p>}
                    placeholder='Digite o nome do arquivo'
                    value={filenameState.value}
                    onChange={(e) => filenameState.set(e.target.value)}
                />

                <ButtonType2 onClick={handleDownload}>
                    <FaDownload /> Salvar
                </ButtonType2>
            </StyledExportTableDialog>
        </Modal>
    )
}

export default ExportTableDialog;