import styled from "styled-components";
import cssVars from "../../../styles/cssVariables.vars";

export const StyledMonitoria = styled.div`
    box-sizing: border-box;
    padding: 0.5rem;
    height: calc(100% - 9rem);
    width: 100%;
    background-color: ${cssVars.colorGrey[8]};

    // content
    >div {
        box-sizing: border-box;
        width: 100%;
    
        // Navigation menu
        >nav {
            display: flex;
            justify-content: space-between;

            >button {
                color: ${cssVars.colorGrey[3]};
            }
            >button>span {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 0.5rem;
                font-weight: 600;
                
                >svg {
                    font-size: 1.25rem;
                }
            }

            &.selected_0>button:nth-child(1) {
                pointer-events: none;
                cursor: default;
                background-color: var(--color-primary);
                color: ${cssVars.colorGrey[8]};  
            }

            &.selected_1>button:nth-child(2) {
                pointer-events: none;
                cursor: default;
                background-color: var(--color-primary);
                color: ${cssVars.colorGrey[8]};  
            }

            &.selected_2>button:nth-child(3) {
                pointer-events: none;
                cursor: default;
                background-color: var(--color-primary);
                color: ${cssVars.colorGrey[8]};  
            }

            &.selected_3>button:nth-child(4) {
                pointer-events: none;
                cursor: default;
                background-color: var(--color-primary);
                color: ${cssVars.colorGrey[8]}; 
            }

            >button {
                box-sizing: border-box;
                width: 100%;
                padding: 1rem;
                background-color: ${cssVars.colorGrey[7]};
                cursor: pointer;
                font-size: 0.875rem;
                font-weight: 500;

                :hover {
                    &>span{
                        color: var(--color-primary); 

                    }
                }
            }

            >button:nth-child(1) {
                border-top-left-radius: 0.25rem;
            }

            >button:nth-last-child(1) {
                border-top-right-radius: 0.25rem;
            }
        }

    }
`;