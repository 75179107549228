import styled from "styled-components";
import cssVars from "../../../../../../styles/cssVariables.vars";

export const StyledTableItem = styled.li`
        display: grid;
        grid-template-areas: "acoes id ativo pessoa razao fantasia cpf telefone";
        grid-template-columns: 4rem 6rem 6rem 10rem 2fr 2fr 1fr 6rem;
        padding-right: 0.75rem;

        &:nth-child(even) {
            background-color: ${cssVars.colorGrey["8"]};
        }

        &:nth-child(odd) {
            background-color: ${cssVars.colorGrey["7h"]};
        }

        >div {
            box-sizing: border-box;
            padding: 0.5rem;


            font-size: 0.75rem;
            color: ${cssVars.colorGrey[4]};

            display: flex;
            align-items: center;
            gap: 0.25rem;

            &:nth-child(1) {
                border-left: none;
            }

            >input {
                padding: 0.25rem;
                background-color: rgba(0,0,0, 5%);
                border: 1px solid rgba(0,0,0, 50%);
                border-radius: 0.25rem;
                width: 100%;
                font-size: 0.75rem;

                &[disabled] {
                    background-color: rgba(0,0,0, 1%);
                    border: 1px solid rgba(0,0,0, 10%);
                    border-radius: 0.25rem;
                    width: 100%;

                    &.id {
                        font-weight: 600;
                        border: 1px solid rgba(0,0,0, 2.5%);
                    }
                }
            }

            >svg {
                width: 1rem;
                height: 1rem;
                color: ${cssVars.colorGrey[2]};
                cursor: pointer;

                :hover {
                    transform: scale(1.1);
                    color: var(--color-primary);
                }
            }
        }

        >div:nth-child(8) {
            >:nth-child(1) {
                border: 0;
                padding: 0;
            }
        }

        div.select {
            width: 100%;
            padding: 0.25rem 0.75rem;
            border-radius: 0.25rem;
            position: relative;

            >select {
                appearance: none;
                cursor: pointer;
                font-size: 0.75rem;
            }

            >svg {
                pointer-events: none;
                position: absolute;
                font-size: 1rem;
                top: calc(50% - 0.5rem);
                right: 0.5rem;
                color: rgba(0, 0, 0, 50%)
            }

            &:disabled {
                border: 1px solid rgba(0,0,0, 10%);
                >svg {
                    color: rgba(0, 0, 0, 25%)
                }
            }
        }
`;