import styled from "styled-components";

export const StyledCadastros = styled.div`
    box-sizing: border-box;
    padding: 1rem;
    height: calc(100% - 4rem);

    display: flex;
    flex-direction: column;

    >nav {
        display: flex;
        gap: 0.125rem;
        margin-bottom: 1rem;
        padding-bottom: 0;
        grid-area: navbar;

        >button {
            border-radius: 0.25rem;
            padding: 0.5rem 1rem;
            font-size: 0.75rem;
            font-weight: 700;
            cursor: pointer;
            background-color: var(--color-primary-o);
            color: rgba(245, 245, 245);

            transition: 300ms ease-in-out;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            &:nth-child(1) {
                border-top-right-radius: 0;
            }

            &:nth-child(2) {
                border-top-left-radius: 0;
            }

            &.selected {
                background-color: var(--color-primary);
            }

        }
    }
`;