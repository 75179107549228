
import { useContext } from "react";
import { StyledDigitarMensagem } from "./style";
import { MMContext } from "../context";

export const DigitarMensagem = () => {
    const { typedMessage, selectedFile } = useContext(MMContext);

    return (
        <StyledDigitarMensagem>
            <div>
                <textarea
                    value={typedMessage.value}
                    onChange={(e) => typedMessage.set(e.target.value)}
                    placeholder="Digite sua mensagem aqui..."
                >
                </textarea>
                <input
                    type="file"
                    onChange={(e) => {
                        selectedFile.set(e.target.files ? e.target.files[0] : null);
                    }}
                />
            </div>
        </StyledDigitarMensagem>
    );
};