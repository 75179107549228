import React, { ReactNode } from "react";
import { Option } from "../../../components/CustomSelect";
import { StyledFerramentas } from "./style";
import { CustomState } from "../../../Hooks/State.hook";
import { EnvioDeMensagensEmMassa } from "./EnvioDeMensagensEmMassa";
import { MMProvider } from "./EnvioDeMensagensEmMassa/context";
import cssVars from "../../../styles/cssVariables.vars";
import Select from "../../../components/Select";

export const Ferramentas = () => {

    const selectedOption = CustomState<ReactNode>(null);
    const options: Array<Option<ReactNode>> = [
        { name: "Envio de mensagens em massa", value: (<MMProvider><EnvioDeMensagensEmMassa /></MMProvider>) },
    ];

    return (
        <StyledFerramentas>
            <div className="menu">
                <Select<ReactNode>
                    options={options}
                    onChange={selectedOption.set}
                    placeholder="Selecione uma ferramenta"
                    $fontSize={0.875}
                    $padding={[0.5, 1]}
                    $color={cssVars.colorGrey[3]}
                    $focusColor={cssVars.colorGrey[2]}
                    $borderColor={cssVars.colorGrey[6]}
                    $width="24rem"
                />
            </div>
            <div className="content">
                {selectedOption.value}
            </div>
        </StyledFerramentas>
    );
};