import styled from "styled-components";
import cssVars from "../../../../styles/cssVariables.vars";

export const StyledTableItem = styled.li`
    display: grid;
    grid-template-areas: "acoes codigo titulo arquivo setor ultima_mod";
    grid-template-columns: 6rem 4rem 4fr 3fr 20rem 15rem;

    border-radius: 0.25rem;
    margin: 2px;

    &:nth-child(even) {
        background-color: ${cssVars.colorGrey["7h"]};
    }

    &:nth-child(odd) {
        background-color: transparent;
    }

    >div {
        box-sizing: border-box;
        padding: 0.5rem;
        overflow: hidden;
        font-size: 0.875rem;
        font-weight: 500;

        >p {
            box-sizing: border-box;
            overflow: hidden;
            white-space: nowrap;
            padding-right: 1rem;
        }
    }

    >div:nth-child(1) {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;

        >button {
            font-size: 1rem;
            cursor: pointer;

            :hover {

                transform: scale(1.25);
            }
        }
    }

    :hover {
        outline: 2px solid ${cssVars.colorGrey[5]};
    }
`;