import styled from "styled-components";
import cssVars from "../../../../../styles/cssVariables.vars";

export const StyledContactList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 100%;

    >button:nth-child(1) {
        display: flex;
        align-items: center;
        gap: 1rem;
        font-size: 1rem;

        :hover {
            cursor: pointer;
            color: var(--color-primary);
        }
    }

    >ul {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        width: 100%;
        height: calc(100% - 6rem);
        min-height: 10rem;
        
        box-sizing: border-box;
        overflow-y: auto;
        border-radius: 0.25rem;

        >li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 0.25rem;
            padding: 0.5rem;

            &:nth-child(odd) {
                background-color: ${cssVars.colorGrey["7h"]};
            }

            &:nth-child(even) {
                background-color: ${cssVars.colorGrey["6h"]};
            }
            
            >div:nth-child(1) {
                >h3 {
                    font-size: 0.875rem;
                    font-weight: 500;
                }

                >span {
                    font-size: 0.75rem;
                    font-weight: 400;
                }
            }

            >button:nth-child(2) {
                cursor: pointer;
                margin-right: 1rem;
                font-size: 1rem;
                color: ${cssVars.colorGrey[4]};

                :hover {
                    transition: 250ms ease-in-out;
                    color: ${cssVars.colorGrey[2]};
                }
            }
        }
    }

    >div.register_contact {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        margin-top: auto;

        >label {
            font-size: 0.875rem;
        }

        >input {
            border: 2px solid rgba(0, 0, 0, 0.125);
            padding: 0.5rem;
            font-size: 0.875rem;
            width: 100%;
            box-sizing: border-box;
            border-radius: 0.25rem;
        }

        >.PhoneInput {
            border: 2px solid rgba(0, 0, 0, 25%);
            border-radius: 0.25rem;
            padding: 0.5rem;
        }
    }
`;