import { createContext, ReactNode, useContext, useEffect } from "react";
import { SocketContextProps, SocketProviderProps } from "../../interfaces/Sockets.interfaces";
import socketIOClient, { Socket } from "socket.io-client";
import { CustomState } from "../../Hooks/State.hook";
import { currentRoute, LoginContext } from "./LoginContext";
import { QrCode } from "../../components/QrCode";
import { toast } from "react-toastify";

export const SocketContext = createContext({} as SocketContextProps);

export const SocketProvider = ({ children }: SocketProviderProps) => {

    const socket = CustomState<Socket | null>(null);
    const qrModalState = CustomState<ReactNode>("");
    const isConnectedState = CustomState<boolean>(false);
    const oficialMode = CustomState<boolean>(true);
    const isConnected = CustomState<boolean>(false);
    const currentQrNumber = CustomState<string | null>(null);

    const { user } = useContext(LoginContext);
    const baseURL = process.env.REACT_APP_SOCKET_URL || "http://localhost:8000";

    useEffect(() => {
        const newSocket = socketIOClient(baseURL, {
            extraHeaders: {
                "ngrok-skip-browser-warning": "skip"
            }
        });

        socket.set(newSocket);

        newSocket.connect()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const handleToastError = (data: string) => toast.error(data, { autoClose: 3000 });
        const handleToastSuccess = (data: string) => toast.success(data, { autoClose: 1500 });

        const handleQr = (data: { qr: string, number: string }) => {
            if (!currentQrNumber.value) {
                currentQrNumber.set(data.number);
                isConnectedState.set(false);
                qrModalState.set(<QrCode {...data} />);
            } else if (currentQrNumber.value === data.number) {
                qrModalState.set(<QrCode {...data} />);
            }
        };

        const handleAuthenticated = () => {
            currentQrNumber.reset();
            isConnectedState.set(true);
            qrModalState.reset();
        };

        const handleOficialMode = (data: boolean) => {
            oficialMode.set(data);
        };

        const handleGroupMessage = (data: any) => {
            console.log("GROUP MESSAGE", data);
        };

        if (socket.value) {
            socket.value.on("toast", handleToastError);
            socket.value.on("toast-error", handleToastError);
            socket.value.on("toast-success", handleToastSuccess);
            socket.value.on("qr", handleQr);
            socket.value.on("authenticated", handleAuthenticated);
            socket.value.on("oficial-mode", handleOficialMode);
            socket.value.on("group_message", handleGroupMessage);
            socket.value.on("disconnect", () => {
                setTimeout(() => {
                    window.location.reload();
                }, 5000);
            })
        }



        return () => {
            if (socket.value) {
                socket.value.off("toast", handleToastError);
                socket.value.off("toast-error", handleToastError);
                socket.value.off("toast-success", handleToastSuccess);
                socket.value.off("qr", handleQr);
                socket.value.off("authenticated", handleAuthenticated);
                socket.value.off("oficial-mode", handleOficialMode);
                socket.value.off("group_message", handleGroupMessage);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket.value]);

    useEffect(() => {
        if (socket.value && user) {
            socket.value.emit("session-connect", { clientName: currentRoute, operatorId: user.CODIGO });
            socket.value.emit("join-room", { clientName: currentRoute, operatorId: user.CODIGO });

            isConnected.set(true);

            if (user.NIVEL === "ADMIN") {
                socket.value.emit("join-room", { clientName: currentRoute, operatorId: -1 });
            };

        } else if (socket.value && !user) {
            isConnected.set(false);

            socket.value.emit("session-disconnect");
            socket.value.emit("leave-room");
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket.value, user]);

    return (
        <SocketContext.Provider value={{
            qrModalState,
            isConnectedState,
            socket,
            oficialMode
        }}>
            {children}
        </SocketContext.Provider>
    );
};