import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import StyledPageController from "./styles";
import { CustomStateHook } from "../../Hooks/State.hook";
import { FilterWithPaginationQueryParameters } from "../../interfaces/generics.types";

export type PageState = {
    current: number;
    total: number;
    perPage: number;
}

type PageControllerProps<T> = {
    next: boolean;
    prev: boolean;
    appliedFiltersState: CustomStateHook<FilterWithPaginationQueryParameters<T>>;
}

function PageController<T>({ appliedFiltersState, next, prev }: PageControllerProps<T>) {

    const onClickPreviousPage = () => appliedFiltersState.set(prev => ({ ...prev, page: prev.page - 1 }));
    const onClickNextPage = () => appliedFiltersState.set(prev => ({ ...prev, page: prev.page + 1 }));

    return (
        <StyledPageController>
            <button
                disabled={!prev}
                onClick={onClickPreviousPage}
            >
                <FaAngleLeft />
            </button>
            <span>
                Página: {appliedFiltersState.value.page}
            </span>
            <button
                disabled={!next}
                onClick={onClickNextPage}
            >
                <FaAngleRight />
            </button>
        </StyledPageController>
    );
}

export default PageController;