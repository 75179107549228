import { StyledSupervisorMenu } from "./style";
import logo from "../../../logoh.png";
import { FaDesktop, FaUsersCog, FaWhatsapp, FaWhatsappSquare, FaChartLine, FaToolbox, FaEnvelopeOpenText } from "react-icons/fa";
import { SupervisorMenuButton } from "./SupervisorMenuButton";
import { useContext, useEffect } from "react";
import { Cadastros } from "../Cadastros";
import { SupervisorContext } from "../../../contexts/admin/Supervisor.context";
import { Monitoria } from "../Monitoria";
import { SocketContext } from "../../../contexts/global/SocketContext";
import { TemplateMessages } from "../TemplateMessages";
import { MeusAtendimentos } from "../MeusAtendimentos";
import { QuickMessages } from "../QuickMessages";
import Relatórios from "../Relatórios";
import { Ferramentas } from "../Ferramentas";
import { CustomTooltip } from "../../../components/CustomTooltip";
import { CustomMessageProvider } from "../../../contexts/admin/CustomMessages.context";

export const SupervisorMenu = () => {

    const {
        handleSelection,
        handleSelectionClass
    } = useContext(SupervisorContext);

    const { oficialMode } = useContext(SocketContext);

    // 
    useEffect(() => {
        handleSelection(1, <Monitoria />)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <StyledSupervisorMenu>
            <header>
                <img src={logo} alt="logo" />
            </header>
            <div>
                <CustomTooltip text="Monitoria" x="0" y="0">
                    <SupervisorMenuButton>
                        <button className={handleSelectionClass(1)} onClick={() => handleSelection(1, <Monitoria />)}>
                            <FaDesktop />
                        </button>
                    </SupervisorMenuButton>
                </CustomTooltip>
                <CustomTooltip text="Cadastros" x="0" y="0">
                    <SupervisorMenuButton>
                        <button className={handleSelectionClass(2)} onClick={() => handleSelection(2, <Cadastros />)}>
                            <FaUsersCog />
                        </button>
                    </SupervisorMenuButton>
                </CustomTooltip>
                <CustomTooltip text="Mensagens Prontas" x="0" y="0">
                    <SupervisorMenuButton>
                        <button className={handleSelectionClass(3)} onClick={() => handleSelection(3, <CustomMessageProvider><QuickMessages /></CustomMessageProvider>)}>
                            <FaEnvelopeOpenText />
                        </button>
                    </SupervisorMenuButton>
                </CustomTooltip>
                {
                    oficialMode.value &&
                    <CustomTooltip text="Templates WhatsApp" x="0" y="0">
                        <SupervisorMenuButton>
                            <button className={handleSelectionClass(4)} onClick={() => handleSelection(4, <TemplateMessages />)}>
                                <FaWhatsappSquare />
                            </button>
                        </SupervisorMenuButton>
                    </CustomTooltip>
                }
                <CustomTooltip text="Atendimentos" x="0" y="0">
                    <SupervisorMenuButton>
                        <button className={handleSelectionClass(5)} onClick={() => handleSelection(5, <MeusAtendimentos />)}>
                            <FaWhatsapp />
                        </button>
                    </SupervisorMenuButton>
                </CustomTooltip>
                <CustomTooltip text="Ferramentas" x="0" y="0">
                    <SupervisorMenuButton>
                        <button className={handleSelectionClass(6)} onClick={() => handleSelection(6, <Ferramentas />)}>
                            <FaToolbox />
                        </button>
                    </SupervisorMenuButton>
                </CustomTooltip>

                <CustomTooltip text="Relatórios" x="0" y="0">
                    <SupervisorMenuButton>
                        <button className={handleSelectionClass(7)} onClick={() => handleSelection(7, <Relatórios />)}>
                            <FaChartLine />
                        </button>
                    </SupervisorMenuButton>
                </CustomTooltip>

            </div>
        </StyledSupervisorMenu>
    );
};