import { IoIosPerson } from "react-icons/io";
import { ScheduledAttendanceDetails } from "../../../interfaces/Attendances.interfaces";
import { StyledScheduleItem } from "./style";
import { FaCalendar, FaUserCircle } from "react-icons/fa";
import { useEffect, useState } from "react";
import formatarCPForCNPJ from "../../../Hooks/FormatCPFCNPJ.hook";

interface ScheduleItemProps {
    attendance: ScheduledAttendanceDetails;
};

export const ScheduleItem: React.FC<ScheduleItemProps> = ({ attendance }) => {
    const date = new Date(attendance.DATA_AGENDAMENTO as unknown as string);

    const dateString = date.toLocaleDateString()
    const timeString = date.toLocaleTimeString().slice(0, 9);

    const [urgencia, setUrgencia] = useState("NORMAL");

    useEffect(() => {
        const { URGENCIA_SUPERVISOR, URGENCIA_AGENDAMENTO, URGENCIA_OPERADOR } = attendance;
        if (URGENCIA_SUPERVISOR) setUrgencia(URGENCIA_SUPERVISOR);
        else if (URGENCIA_AGENDAMENTO) setUrgencia(URGENCIA_AGENDAMENTO);
        else setUrgencia(URGENCIA_OPERADOR);
    }, [attendance]);

    return (
        <StyledScheduleItem>
            <FaUserCircle />
            <div className="dados1">
                <span>
                    <b> {attendance.CONTATO} </b>
                </span>
                <span>
                    {attendance.RAZAO?.slice(0, 40) || "CLIENTE NÃO CADASTRADO"}
                </span>
                <span>
                    CPF/CNPJ: {" "}
                    {
                        formatarCPForCNPJ(attendance.CPF_CNPJ || "n/d")
                    }
                </span>
            </div>
            <span className={`urgencia ${urgencia}`}>
                {urgencia.replace("_", " ")}
            </span>
            <div className="data_agendamento">
                <div>
                    <span> {dateString} </span>
                    <span> {timeString} </span>
                </div>
                <FaCalendar />
            </div>
        </StyledScheduleItem>
    );
};