import { ReactNode } from "react";
import { CustomState } from "../../../Hooks/State.hook";
import StyledReports from "./style";
import Select, { Option } from "../../../components/Select";
import cssVars from "../../../styles/cssVariables.vars";
import AttendancesWithoutResponse from "./AttendancesWithoutResponse";
import MessagesByUser from "./MessagesByUser";
import MessagesByContact from "./MessagesByContact";
import MessagesByHourAndDay from "./MessagesByHourAndDay";

function Reports() {
    const options: Array<Option<ReactNode>> = [
        { name: "Atendimentos sem resposta", value: <AttendancesWithoutResponse /> },
        { name: "Mensagens por contato", value: <MessagesByContact /> },
        { name: "Mensagens por usuário", value: <MessagesByUser /> },
        { name: "Mensagens por dia/hora", value: <MessagesByHourAndDay /> },
    ];

    const selectedOption = CustomState<ReactNode>(null);

    return (
        <StyledReports>
            <header>
                <Select<ReactNode>
                    $fontSize={1}
                    $padding={[0.5, 1]}
                    options={options}
                    onChange={(v) => selectedOption.set(v)}
                    placeholder="Selecione um relatório para exibir"
                    $color={cssVars.colorGrey[4]}
                    $focusColor={cssVars.colorGrey[2]}
                    $width="30rem"
                />
            </header>
            <main>
                {selectedOption.value}
            </main>
        </StyledReports>
    );
};

export default Reports;