import styled from "styled-components";
import cssVars from "../../../../styles/cssVariables.vars";

export const StyledMyAttendancesMenu = styled.div`
    grid-area: mbody;

    display: grid;
    grid-template-areas: 
    "isearch"
    "results"
    ;

    grid-template-columns: 100%;
    grid-template-rows: 3rem calc(100% - 3rem);
    height: 100%;

    >div {
        grid-area: isearch;
        box-sizing: border-box;
        padding: 0.625rem;
        height: 3rem;

        display: flex;
        flex-direction: column;
        gap: 1rem;

        >h2 {
            font-size: var(--font-body-1);
            font-weight: 500;
        }

    }

    >ul {
        grid-area: results;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        height: 100%;
        overflow-y: auto;


        padding: 0 0.5rem;

        >button {
            padding: 1rem 0;
            cursor: pointer;
            border-radius: 0.25rem;
            font-weight: 600;
            font-size: 1rem;
            position: relative;
            color: ${cssVars.colorGrey[2]};

            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1rem;


            >span {
                position: absolute;
                bottom: 0;
                left: calc(50% - 12.5%);
                display: block;
                margin: 0 auto;
                width: 25%;
                height: 1px;
                background-color: rgba(0,0,0, 0.125);
            }

            >svg {
                font-size: 1.25rem;
            }

            :hover {
                background-color: rgba(0,0,0, 0.05);

                >span {
                    background-color: transparent;
                }
            }
        }
    }

`;