import { createContext, useEffect } from "react";
import { Customer, CustomersContextProps, CustomersProviderProps } from "../../interfaces/Customers.interface";
import api from "../../services/api";
import { toast } from "react-toastify";
import { CustomState } from "../../Hooks/State.hook";

export const CustomerContext = createContext({} as CustomersContextProps);

export const CustomerProvider = ({ children }: CustomersProviderProps) => {
    const customersState = CustomState<Customer[]>([]);
    const previousPage = CustomState<string | null>(null);
    const nextPage = CustomState<string | null>(null);
    const currentPage = CustomState<number>(1);

    const loadingState = CustomState<boolean>(false);
    const searchState = CustomState<string>("");
    const fieldState = CustomState<string>("RAZAO");

    function getAllCustomers() {
        loadingState.set(true);

        api.get<{ data: Customer[], nextPage: string | null, previousPage: string | null }>(`/customers/custom/filter`, {
            params: {
                page: currentPage.value,
                perPage: 20,
                field: fieldState.value,
                value: searchState.value
            }
        }).then((res) => {
            customersState.set(res.data.data);
            nextPage.set(res.data.nextPage);
            previousPage.set(res.data.previousPage);
        }).catch((err) => {
            console.error(err);
            customersState.set([]);
        }).finally(() => {
            loadingState.set(false);
        });
    };

    useEffect(() => {
        getAllCustomers();
    }, [currentPage.value]);

    useEffect(() => {
        currentPage.set(1);
    }, [searchState.value]);

    const customersRequests = {
        deleteFunc(id: number) {
            api.delete(`/customers/${id}`)
                .then(res => getAllCustomers())
                .catch(err => toast.error(err))
        },

        recoverFunc(id: number) {
            api.put(`/customers/${id}/recover`)
                .then(res => getAllCustomers())
                .catch(err => toast.error(err))
        },

        updateFunc(id: number, data: any) {
            api.patch(`/customers/${id}`, data)
                .then(res => getAllCustomers())
                .catch(err => toast.error(err))
        }
    }

    return (
        <CustomerContext.Provider value={{
            getAllCustomers,
            customersRequests,
            customersState,
            currentPage,
            previousPage,
            nextPage,
            searchState,
            fieldState
        }}>
            {children}
        </CustomerContext.Provider>
    )

}