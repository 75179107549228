import { StyledMyAttendancesMenu } from "./style";
import { useContext, useState } from "react";
import { AttendancesContext } from "../../../../contexts/user/AttendancesContext";
import { AttendanceItem } from "./AttendanceItem";
import removeChars from "../../../../Hooks/removeChars.util";
import { FaComments, FaSearch } from "react-icons/fa";
import Input from "../../../../components/Input";
import { defaultInput100 } from "../../../../components-variants/defaultInputs";


export const MyAttendancesMenu = () => {
    const { detailedAttendances, attendancesMessages } = useContext(AttendancesContext);
    const { newAttendanceModalState } = useContext(AttendancesContext);
    const [filter, setFilter] = useState("");

    const sortedAttendances = detailedAttendances.value.sort((a, b) => {
        const allMessagesA = attendancesMessages.value.filter((m) => m.CODIGO_NUMERO === a.CODIGO_NUMERO).sort((a, b) => b.DATA_HORA.getTime() - a.DATA_HORA.getTime());
        const allMessagesB = attendancesMessages.value.filter((m) => m.CODIGO_NUMERO === b.CODIGO_NUMERO).sort((a, b) => b.DATA_HORA.getTime() - a.DATA_HORA.getTime());

        const lastMessageA = allMessagesA[0];
        const lastMessageB = allMessagesB[0];

        if (!lastMessageA) {
            return 1;
        } else if (!lastMessageB) {
            return -1;
        } else if (lastMessageA.DATA_HORA > lastMessageB.DATA_HORA) {
            return -1;
        } else if (lastMessageA.DATA_HORA < lastMessageB.DATA_HORA) {
            return 1;
        } else {
            return 0;
        };
    });

    const filteredSortedAttendances = sortedAttendances.filter((a) => {
        return String(a.CODIGO || "") === filter ||
            String(a.COD_ERP || "") === filter ||
            String(a.CODIGO_CLIENTE || "") === filter ||
            String(a.CODIGO_NUMERO || "") === filter ||
            a.CONTATO_NOME?.toUpperCase().includes(filter) ||
            a.CONTATO_NUMERO?.includes(removeChars(filter, "+_() ")) ||
            removeChars((a.RAZAO || "").toUpperCase(), " ").includes(removeChars(filter, " ")) ||
            a.CPF_CNPJ?.includes(removeChars(filter, "-. "))
    });

    return (
        <StyledMyAttendancesMenu>
            <div>
                <Input
                    placeholder="Filtrar atendimentos..."
                    value={filter}
                    onChange={(e) => setFilter(e.target.value.toUpperCase())}
                    {...defaultInput100}
                    $fontSize={0.875}
                    leftIcon={<FaSearch />}
                />
            </div>
            <ul>
                {
                    <button onClick={() => newAttendanceModalState.set(true)}>
                        <FaComments />
                        Novo atendimento
                        <span></span>
                    </button>
                }
                {
                    filteredSortedAttendances.map((a, i) => <AttendanceItem attendance={a} key={`a_${i}`} />)
                }
            </ul>
        </StyledMyAttendancesMenu>
    );
};