import { useEffect, useRef } from "react";
import { CustomState } from "../../Hooks/State.hook"
import { StyledCustomSelectProps, StyledCustomSelect } from "./style"
import { FaAngleDown } from "react-icons/fa6";

export interface Option<T> {
    name: string;
    value: T;
}

interface CustomSelectProps<T> extends StyledCustomSelectProps {
    options: Array<Option<T>>;
    defaultValue?: Option<T>;
    onChange: (value: T) => void;
    selectMessage?: string;
    disabled?: boolean;
}

export function CustomSelect<T>({ options, onChange, defaultValue, padding, fontSize, fontWeight, selectMessage, disabled, disableBorder }: CustomSelectProps<T>) {
    const optionState = CustomState<Option<T> | null>(defaultValue || null);
    const menuState = CustomState<boolean>(false);

    const toggleRef = useRef<HTMLDivElement | null>(null);
    const ulRef = useRef<HTMLUListElement | null>(null);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (ulRef.current && !ulRef.current.contains(event.target as Node) &&
                toggleRef.current && !toggleRef.current.contains(event.target as Node)) {
                menuState.set(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [menuState.value]);

    useEffect(() => {
        if (optionState.value) {
            onChange(optionState.value.value);
        }
    }, [optionState.value]);

    const handleChange = (option: Option<T>) => {
        return () => {
            optionState.set(option);
            menuState.reset()
        }
    };

    return (
        <StyledCustomSelect
            padding={padding}
            fontSize={fontSize}
            disabled={disabled}
            disableBorder={disableBorder}
            fontWeight={fontWeight}
        >
            <div
                onClick={() => menuState.set(prev => !prev)}
                ref={toggleRef}
            >
                <p> {String(optionState.value?.name || (selectMessage || "selecione uma opção"))} </p>
                {!disabled && <FaAngleDown />}
            </div>
            <ul
                ref={ulRef}
                className={!disabled && menuState.value ? "showing" : "hidden"}
            >
                {
                    options.map((option, index) => (
                        <li
                            className={optionState.value === option.value ? "selected" : ""}
                            onClick={handleChange(option)}
                            key={`option_${option.name}_${index}`}
                        >
                            <p> {option.name} </p>
                        </li>
                    ))
                }
            </ul>
        </StyledCustomSelect>
    )
}