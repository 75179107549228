import styled from "styled-components";

export const StyledCadastroAgente = styled.div`
    box-sizing: border-box;

    height: calc(100% - 2.5rem);
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 1rem;

    >button {
        margin-top: auto;
        grid-area: button;
    }

    >div.search {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        row-gap: 0.5rem;
        flex-wrap: wrap;

        >div:nth-child(1) {
            width: 32rem;
        }

        >div:nth-child(2) {
            width: 16rem;
        }

        >button {
            height: auto;
            font-size: 0.75rem;
            font-weight: 600;
            padding: 0.625rem 4rem;
            box-sizing: border-box;
        }
    }
`;