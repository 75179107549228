import { useContext, useEffect } from "react";
import { StyledFormulario } from "./style";
import { IoMdClose } from "react-icons/io";
import 'react-phone-number-input/style.css';
import { RegistrationButton } from "../../../../../styles/registrationButton";
import { CustomState } from "../../../../../Hooks/State.hook";
import { UsersRegistrationContext } from "../../../../../contexts/admin/UsersRegistration.context";
import { Option } from "../../../../../components/CustomSelect";
import api from "../../../../../services/api";
import { toast } from "react-toastify";
import Input from "../../../../../components/Input";
import { defaultInput100, defaultSelect100 } from "../../../../../components-variants/defaultInputs";
import Select from "../../../../../components/Select";
import cssVars from "../../../../../styles/cssVariables.vars";

interface FormProps {
    closeFunction: () => void;
};

interface RegisterUserForm {
    NOME: string;
    LOGIN: string;
    SENHA: string;
    EMAIL: string;
    NIVEL: null | "ATIVO" | "RECEP" | "AMBOS" | "ADMIN";
    SETOR: null | number;
}

export const Formulario = ({ closeFunction }: FormProps) => {
    const { getAllOperators } = useContext(UsersRegistrationContext);

    const sectorOptions = CustomState<Array<Option<number>>>([]);

    const levelOptions: Array<Option<"ATIVO" | "RECEP" | "AMBOS" | "ADMIN">> = [
        { name: "Atendente (Ambos)", value: "AMBOS" },
        { name: "Atendente (Ativo)", value: "ATIVO" },
        { name: "Atendente (Receptivo)", value: "RECEP" },
        { name: "Supervisor", value: "ADMIN" },
    ];

    useEffect(() => {
        api.get<{ CODIGO: number, NOME: string }[]>(`/sectors`)
            .then(res => {
                const options = res.data.map(s => ({ name: s.NOME, value: s.CODIGO }))
                sectorOptions.set(options);
            })
            .catch(err => console.error(err));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const formState = CustomState<RegisterUserForm>({
        NOME: "", LOGIN: "", SENHA: "", EMAIL: "", NIVEL: null, SETOR: null
    });

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!formState.value.NIVEL) toast.error("Selecione o nível do atendente/supervisor");
        else if (!formState.value.SETOR) toast.error("Selecione o setor do atendente/supervisor");
        else {
            api.post(`/operators`, formState.value)
                .then(() => getAllOperators())
                .catch(err => console.error(err));
        }
    }

    return (
        <StyledFormulario onSubmit={onSubmit}>
            <header>
                <h1> Cadastrar novo atendente </h1>
                <button
                    className="closeModal"
                    type="button"
                    onClick={closeFunction}
                >
                    <IoMdClose />
                </button>
            </header>

            <Input
                placeholder="Login"
                minLength={6}
                onChange={(e) => formState.set(prev => ({ ...prev, LOGIN: e.target.value.replace(" ", "") }))}
                value={formState.value.LOGIN}
                required
                {...defaultInput100}
                $padding={[0.5, 1]}
            />

            <Input
                placeholder="Nome"
                onChange={(e) => formState.set(prev => ({ ...prev, NOME: e.target.value }))}
                value={formState.value.NOME}
                required
                {...defaultInput100}
                $padding={[0.5, 1]}
            />

            <Input
                placeholder="Senha"
                minLength={6}
                onChange={(e) => formState.set(prev => ({ ...prev, SENHA: e.target.value }))}
                value={formState.value.SENHA}
                required
                {...defaultInput100}
                $padding={[0.5, 1]}
            />

            <Input
                placeholder="E-mail"
                onChange={(e) => formState.set(prev => ({ ...prev, EMAIL: e.target.value }))}
                value={formState.value.EMAIL}
                type="email"
                {...defaultInput100}
                $padding={[0.5, 1]}
            />

            <Select
                onChange={(v) => formState.set(prev => ({ ...prev, NIVEL: v as "ATIVO" | "RECEP" | "AMBOS" }))}
                options={levelOptions}
                placeholder="Nível"
                {...defaultSelect100}
                $padding={[0.5, 1]}
                $focusColor={cssVars.colorGrey[1]}
            />

            <Select
                onChange={(v) => formState.set(prev => ({ ...prev, SETOR: v }))}
                options={sectorOptions.value}
                placeholder="Setor"
                {...defaultSelect100}
                $padding={[0.5, 1]}
                $focusColor={cssVars.colorGrey[1]}
            />

            <RegistrationButton type="submit">
                Cadastrar
            </RegistrationButton>
        </StyledFormulario>
    );
};