import styled from "styled-components";
import cssVars from "../../../styles/cssVariables.vars";

export const StyledScheduleItem = styled.li`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem;

    &:nth-child(even) {
        background-color: ${cssVars.colorGrey["6h"]};
    }

    &:nth-child(odd) {
        background-color: ${cssVars.colorGrey["7h"]};
    }

    border-radius: 0.25rem;
    box-sizing: border-box;
    position: relative;

    >img,
    >svg {
        width: 3rem;
        height: 3rem;
    }

    >img {
        border-radius: 0.25rem;
    }

    >div.dados1 {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 0.25rem;

        >span {
            font-size: 0.75rem;
            font-weight: 500;
            >b {
                font-size: 0.875rem;
            }
        }
    }

    >span.urgencia {
        position: absolute;
        padding: 0.25rem;
        top: 1.5rem;
        right: 10rem;
        font-size: 0.75rem;
        font-weight: 500;
        border-radius: 0.25rem;
        color: white;

        &.NORMAL {
            background-color: rgb(55, 165, 105);
        }

        &.ALTA {
            background-color: rgb(225, 225, 55);
        }

        &.MUITO_ALTA {
            background-color: rgba(255, 100, 55);
        }

        &.URGENTE {
            background-color: rgb(225, 55, 55);
            font-weight: 600;
        }
    }
    
    >div.data_agendamento {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${cssVars.colorGrey["7"]};
        z-index: 2;
        gap: 0.25rem;
        padding: 0.75rem;
        border-radius: 0.25rem;
        position: absolute;
        bottom: 0.5rem;
        right: 0.5rem;
        font-size: 0.75rem;

        >svg {
            font-size: 1.5rem;
            color: ${cssVars.colorGrey[2]};
        }

        >div {
            display: flex;
            flex-direction: column;
            align-items: end;
            justify-content: center;
        }
    }
`;