import styled from "styled-components";
import cssVars from "../../../styles/cssVariables.vars";

export const StyledSupervisorMenu = styled.nav`
        width: 4rem;
        height: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;

        background-color: ${cssVars.colorGrey["7h"]};
        
        position: relative;
       
        // Menu header
        >header {
            box-sizing: border-box;
            width: 100%;
            padding: 0.25rem;
            height: 4rem;

            display: flex;
            align-items: center;
            justify-content: center ;
            gap: 3.75rem;
            z-index: 2;

            >img {
                width: 100%;
                align-self: center;
                justify-self: center;
                transform: translateY(-0.125rem);
                cursor: default;

                :hover {
                    transition: 300ms ease-in-out;
                    filter: brightness(1.25);
                }
            }
        }

        // Menu navbuttons
        >div {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            overflow: auto;
            max-height: calc(100% - 8rem);
            padding: 0.5rem;
            box-sizing: border-box;
            gap: 0.5rem;
        }
`;