import styled from "styled-components";
import cssVars from "../../../styles/cssVariables.vars";

export const StyledOperadorMenu = styled.nav`
        grid-area: menu;

        background-color: ${cssVars.colorGrey["7h"]};
        color: ${cssVars.colorGrey["2"]};

        display: grid;
        grid-template-areas: 
        "mheader"
        "mbody"
        ;
        overflow: hidden;

        max-height: 100vh;
        height: 100vh;
        grid-template-columns: 100%;
        grid-template-rows: 4rem calc(100% - 4rem);

        >header {
            grid-area: mheader;

            box-sizing: border-box;
            height: 100%;

            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1rem;

            >img {
                height: 3rem;
                align-self: center;
                justify-self: center;
            }

            color: var(--color-primary-1);
            font-weight: 700;
            font-size: 1.125rem;
            text-align: center;
        }
`;