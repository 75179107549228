import { ReactNode, useState } from "react";
import { ClientsRegistrationProvider } from "../../../contexts/admin/ClientsRegistration.context";
import { UsersRegistrationProvider } from "../../../contexts/admin/UsersRegistration.context";
import { CadastroAgente } from "./CadastroAgente";
import { CadastroCliente } from "./CadastroCliente";
import { StyledCadastros } from "./style";
import { CustomerProvider } from "../../../contexts/admin/Customers.context";

export const Cadastros = () => {
    const [tab, setTab] = useState(1);

    const handleSelect = (num: number) => {
        return tab === num ? "selected" : "";
    };

    const tabElements = [
        (
            <CadastroAgente />
        ),
        (
            <ClientsRegistrationProvider>
                <CustomerProvider>
                    <CadastroCliente />
                </CustomerProvider>
            </ClientsRegistrationProvider>
        )
    ] as ReactNode[];

    return (
        <StyledCadastros>
            <nav>
                <button className={handleSelect(1)} onClick={() => setTab(1)}>
                    Cadastro de atendentes
                </button>
                <button className={handleSelect(2)} onClick={() => setTab(2)}>
                    Cadastro de clientes
                </button>
            </nav>
            <UsersRegistrationProvider>
                {tabElements[tab - 1]}
            </UsersRegistrationProvider>
        </StyledCadastros>
    );
};