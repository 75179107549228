interface FilterParameters {
    remove?: Array<string | RegExp>;
    sensitiveCase?: boolean;
};

export default function filterValue(filter: string, value: string, params?: FilterParameters): boolean {
    params?.remove?.forEach(str => {
        filter = filter.replaceAll(str, "");
        value = value.replaceAll(str, "");
    });

    if (!params?.sensitiveCase) {
        value = value.toUpperCase();
        filter = filter.toUpperCase();
    };

    return value.includes(filter);
};