import styled from "styled-components";

export const StyledScheduleDialog = styled.div`
    padding: 0.5rem;
    border-radius: 0.25rem;
    background-color: white;

    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    height: 7.75rem;
    width: 20rem;
    box-sizing: border-box;
    
    >header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        >h2 {
            font-size: 0.875rem;
            font-weight: 500;
        }

        >svg {
            font-size: 1.25rem;

            :hover {
                transform: scale(1.25);
                cursor: pointer;
            }
        }
    }

    >button {
        padding: 0.5rem 1.5rem !important;
        font-size: 0.875rem;
    }

`;