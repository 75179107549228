import cssVars from "../../../styles/cssVariables.vars";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { TableColumn } from "../types";
import Input from "../../Input";
import { CustomStateHook } from "../../../Hooks/State.hook";
import { FilterWithPaginationQueryParameters } from "../../../interfaces/generics.types";

function filterInput<T>(
    column: TableColumn<T>,
    filterState: CustomStateHook<FilterWithPaginationQueryParameters<T>>
) {

    return (
        <Input
            $color={cssVars.colorGrey[3]}
            $focusColor={cssVars.colorGrey[0]}
            $padding={[0.5, 0.5]}
            $fontSize={1}
            $width={column.filter!.width}
            leftIcon={column.filter!.icon || <FaMagnifyingGlass />}
            onChange={(e) => filterState.set(prev => ({ ...prev, [column.key]: e.target.value || undefined }))}
            placeholder={column.header}
            defaultValue={filterState.value[column.key]}
        />
    );
}

export default filterInput;