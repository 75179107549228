import styled from "styled-components";

const StyledMessagesByHourAndDay = styled.div`
    box-sizing: border-box;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    gap: 1rem;

    overflow-y: auto;
`;

export default StyledMessagesByHourAndDay;