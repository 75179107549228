import styled from "styled-components";
import cssVars from "../../../styles/cssVariables.vars";

export const StyledAttendanceView = styled.div`
    box-sizing: border-box;
    width: 100%;
    height: 100%;

    // Atendimento Menu
    >div:nth-child(1) {
        padding: 0.5rem;
        display: flex;
        justify-content: space-between;
        gap: 0.25rem;
        height: 5rem;
        width: 100%;
        box-sizing: border-box;
        background-color: ${cssVars.colorGrey["6h"]};
        color: rgb(32, 32, 32);
    }

    >:nth-child(2) {
        height: calc(100% - 5rem);
        width: 100%;
    }
`;