import styled from "styled-components";
import cssVars from "../../../../../../../styles/cssVariables.vars";

export const StyledListaDeNumeros = styled.div`
    box-sizing: border-box;
    width: 100%;
    background-color: ${cssVars.colorGrey[8]};
    border-bottom: none;
    grid-area: lista;

    display: grid;

    grid-template-areas: "header"
    "list";

    grid-template-rows: auto 1fr;

    >header {
        display: grid;
        grid-template-areas: "acoes id nome numero";
        grid-template-columns: 4rem 4rem 12rem 12rem;
        border-bottom: none;
        background-color: rgba(0,0,0, 5%);
        padding-right: 0.75rem;
        grid-area: header;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
        
        >div {
            padding: 0.5rem;
            font-size: 0.75rem;
            display: flex;
            align-items: center;
        }
    }

    >ul {
        box-sizing: border-box;
        overflow: scroll;
        overflow-x: hidden;
        grid-area: list;

        height: 20rem;
        max-height: 60vh;

        >li:hover {
            background-color: rgba(0, 0, 0, 2.5%);
        }
    }
`;

export const TableContainer = styled.div`
    background-color: ${cssVars.colorGrey[8]};
    padding: 1rem;
    border-radius: 0.25rem;

    display: flex;
    flex-direction: column;
    gap: 1rem;

    width: max-content;

    >header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        >h2 {
            font-size: 1rem;
            font-weight: 500;
        }

        >button.closeModal {
            font-size: 1.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: 200ms;
            cursor: pointer;
            color: ${cssVars.colorGrey[2]};

            :hover {
                transform: scale(1.25);
            }
        }
    }


    >h3 {
        font-size: 1rem;
        font-weight: 500;
    }

    >div.inputs{
        display: flex;
        align-items: center;
        gap: 1rem;
        flex-wrap: wrap;
    }
`;
