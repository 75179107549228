import React, { ReactNode, createContext, useContext, useEffect } from "react";
import { CustomState, CustomStateHook } from "../../../../Hooks/State.hook";
import api from "../../../../services/api";
import { LoginContext } from "../../../../contexts/global/LoginContext";
import { toast } from "react-toastify";
import { UserData } from "../../../../interfaces/Users.interfaces";
import { Sector } from "../../../../interfaces/RunningAttendances";

interface IMMContext {
    selectedContacts: CustomStateHook<Array<string>>;
    typedMessage: CustomStateHook<string>;
    selectedFile: CustomStateHook<File | null>;
    selectedFilename: CustomStateHook<string | null>;
    messageMode: CustomStateHook<0 | 1>;
    sendMessage: () => void;
    sectors: CustomStateHook<Array<Sector>>;
};

interface IMMProvider {
    children: ReactNode;
};

export const MMContext = createContext({} as IMMContext);

export const MMProvider = ({ children }: IMMProvider) => {
    const messageMode = CustomState<0 | 1>(0);
    const selectedContacts = CustomState<Array<string>>([]);
    const typedMessage = CustomState<string>("");
    const selectedFile = CustomState<File | null>(null);
    const selectedFilename = CustomState<string | null>(null);
    const sectors = CustomState<Array<Sector>>([]);
    const { user } = useContext(LoginContext);

    function sendMessage() {
        const requestForm = new FormData();
        requestForm.append("contacts", selectedContacts.value.join(" "));
        requestForm.append("mode", `${messageMode.value}`);
        requestForm.append("sector", `${(user as UserData).SETOR}`);
        requestForm.append("text", typedMessage.value);

        if (messageMode.value === 0) {
            selectedFile.value && requestForm.append("file", selectedFile.value, encodeURIComponent(selectedFile.value.name));

            if (typedMessage.value) {
                api.post(`/custom-routes/send_mm`, requestForm)
                    .then(() => toast.success("Envio de mensagens em massa agendado com sucesso!"))
                    .catch(() => toast.error("Oops, algo deu errado..."));
            } else {
                toast.error("Digite uma mensagem primeiro!");
            }
        } else {
            selectedFilename.value && requestForm.append("filename", encodeURIComponent(selectedFilename.value));

            if (typedMessage.value) {
                api.post(`/custom-routes/send_mm`, requestForm)
                    .then(() => toast.success("Envio de mensagens em massa agendado com sucesso!"))
                    .catch(() => toast.error("Oops, algo deu errado..."));
            } else {
                toast.error("Selecione uma mensagem primeiro!");
            }
        }
    }

    useEffect(() => {
        typedMessage.reset()
        selectedFile.reset();
        selectedFilename.reset();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messageMode.value]);

    useEffect(() => {
        api.get<Array<Sector>>(`/sectors`)
            .then((res) => {
                sectors.set(res.data);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <MMContext.Provider value={{
            selectedContacts,
            typedMessage,
            selectedFile,
            selectedFilename,
            messageMode,
            sendMessage,
            sectors
        }}>
            {children}
        </MMContext.Provider>
    );
};