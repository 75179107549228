import styled from "styled-components";
import cssVars from "../../../../styles/cssVariables.vars";

export const StyledInProgressAttendances = styled.div`
    box-sizing: border-box;
    height: 100%;
    display: grid;
    grid-template-areas:
    "header"
    "body";

    grid-template-rows: 2rem 1fr;
    overflow-x: auto;

    >header {
        grid-area: header;

        display: grid;
        grid-template-areas: 
        "actions_header cod_attendance cod_erp priority_header attendance_sector_header operator_header client_name_header client_phone_header client_company_header start_date_header attendance_type_header";
        grid-template-columns: 8rem 6rem 6rem 9rem 9rem 12rem 12rem 10rem 1fr 10rem 12rem;
        padding-right: 0.75rem;
        background-color: ${cssVars.colorGrey["6h"]};
        color: ${cssVars.colorGrey[2]};

        >div {
            box-sizing: border-box;
            padding: 0.5rem;
            font-size: 0.75rem;
            position: relative;
            font-weight: 500;
            color: ${cssVars.colorGrey[2]};
        }

        >div.actions_header {
            border-left: none;
            grid-area: actions_header;
        }

        >div.attendance_sector_header {
            grid-area: attendance_sector_header;
        }
        
        >div.operator_header {
            grid-area: operator_header;
        }

        >div.client_name_header {
            grid-area: client_name_header;
        }

        >div.client_company_header {
            min-width: 20rem;
            grid-area: client_company_header;
        }

        >div.start_date_header {
            grid-area: start_date_header;
        }

        >div.last_msg_header {
            grid-area: last_msg_header;
        }

        >div.priority_header {
            grid-area: priority_header;
            border-right: none;
        }

        >div.search {
            padding: 0;
        }
    }

    >div:nth-child(2) {
        grid-area: body;
        overflow-y: scroll;
        overflow-x: hidden;
    }
`