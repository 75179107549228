import { ReactNode, useContext } from "react"
import { CustomState, CustomStateHook } from "../../../../Hooks/State.hook"
import { ModalBasis } from "../../../../components/ModalBasis"
import PhoneInputWithCountrySelect from "react-phone-number-input";
import { RegistrationButton } from "../../../../styles/registrationButton";
import api from "../../../../services/api";
import { LoginContext } from "../../../../contexts/global/LoginContext";
import { Contact, Customer } from "../../../../interfaces/Customers.interface";
import { toast } from "react-toastify";
import Input from "../../../../components/Input";
import { defaultInput100 } from "../../../../components-variants/defaultInputs";

interface Props {
    modalState: CustomStateHook<ReactNode>;
}
export const RegisterCustomerModal = ({ modalState }: Props) => {
    const { user } = useContext(LoginContext);

    const RAZAO = CustomState<string>("");
    const FANTASIA = CustomState<string>("");
    const CPF_CNPJ = CustomState<string>("");
    const CIDADE = CustomState<string>("");
    const NOME = CustomState<string>("");
    const NUMERO = CustomState<string>("");

    const onSubmitFunction = async (e: React.FormEvent) => {
        e.preventDefault();

        const customerData: any = {
            RAZAO: RAZAO.value,
            CPF_CNPJ: CPF_CNPJ.value,
            PESSOA: CPF_CNPJ.value.length === 11 ? "FIS" : "JUR",
            OPERADOR: user ? user.CODIGO : 0
        };

        if (FANTASIA.value) customerData.FANTASIA = FANTASIA.value;
        if (CIDADE.value) customerData.CIDADE = CIDADE.value;

        const CODIGO_CLIENTE = await api.post<Customer>(`/customers`, customerData)
            .then((res) => {
                toast.success(`Cliente cadastrado com sucesso!`);
                return res.data.CODIGO;
            })
            .catch((err) => {
                toast.error(err.response?.data ?
                    (
                        Array.isArray(err.response.data.message) ?
                            err.response.data.message.join("\n")
                            :
                            err.response.data.message
                    ) : "Falha ao conectar-se com o servidor...");
                return null;
            });


        if (NOME.value && NUMERO.value && CODIGO_CLIENTE) {
            const contactData = {
                NOME: NOME.value,
                NUMERO: NUMERO.value.replace(/\D/g, ''),
                CODIGO_CLIENTE
            };

            await api.post<Contact>(`/contacts`, contactData)
                .then(() => toast.success(`Contato cadastrado com sucesso!`))
                .catch((err) => {
                    toast.error(err.response?.data ?
                        (
                            Array.isArray(err.response.data.message) ?
                                err.response.data.message.join("\n")
                                :
                                err.response.data.message
                        ) : "Falha ao conectar-se com o servidor...");
                });
        }
    }

    return (
        <ModalBasis
            title="Cadastro de cliente"
            onClose={modalState.reset}
            fontSize={0.875}
            padding={{ h: 1, v: 1 }}
            size={{
                h: 27.75, w: 32
            }}
        >
            <form onSubmit={onSubmitFunction} >
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.5rem",
                    marginBottom: "0.5rem"
                }}>
                    <h3 style={{
                        fontSize: "1rem",
                        fontWeight: 500
                    }}>
                        Dados do cliente
                    </h3>
                    <Input
                        {...defaultInput100}
                        $fontSize={1}
                        $padding={[0.5, 0.5]}
                        placeholder="Razão social"
                        required
                        value={RAZAO.value}
                        onChange={(e) => RAZAO.set(e.target.value)}
                    />
                    <Input
                        {...defaultInput100}
                        $fontSize={1}
                        $padding={[0.5, 0.5]}
                        placeholder="Fantasia"
                        value={FANTASIA.value}
                        onChange={(e) => FANTASIA.set(e.target.value)}
                    />
                    <Input
                        {...defaultInput100}
                        $fontSize={1}
                        $padding={[0.5, 0.5]}
                        placeholder="CPF/CNPJ"
                        required
                        value={CPF_CNPJ.value}
                        onChange={(e) => CPF_CNPJ.set(e.target.value.replace(/\D/g, ""))}
                    />
                    <Input
                        {...defaultInput100}
                        $fontSize={1}
                        $padding={[0.5, 0.5]}
                        placeholder="Cidade"
                        value={CIDADE.value}
                        onChange={(e) => {
                            const inputValue = e.target.value;
                            const sanitizedValue = inputValue.replace(/[0-9]/g, '');
                            CIDADE.set(sanitizedValue);
                        }}
                    />


                </div>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.5rem",
                    marginBottom: "1rem"
                }}>
                    <h3 style={{
                        fontSize: "1rem",
                        fontWeight: 500
                    }}>
                        Dados do contato (opcional)
                    </h3>
                    <Input
                        {...defaultInput100}
                        $fontSize={1}
                        $padding={[0.5, 0.5]}
                        placeholder="Nome"
                        maxLength={60}
                        minLength={3}
                        value={NOME.value}
                        onChange={(e) => NOME.set(e.target.value)}
                        required={!!NOME.value}
                    />
                    <PhoneInputWithCountrySelect
                        style={{
                            border: "2px solid var(--color-grey-5)",
                            padding: "0.5rem 0.5rem",
                            borderRadius: "0.5rem"
                        }}
                        defaultCountry="BR"
                        value={NUMERO.value}
                        onChange={(v) => NUMERO.set(String(v))}
                        required={!!NUMERO.value}
                    />
                </div>
                <RegistrationButton style={{ padding: "0.75rem 4rem" }}>
                    Cadastrar
                </RegistrationButton>
            </form>
        </ModalBasis>
    )
}