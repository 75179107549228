import styled from "styled-components";
import cssVars from "../../../styles/cssVariables.vars";

export const StyledQuickMessages = styled.div`
    box-sizing: border-box;
    padding: 1rem;
    height: calc(100% - 5rem);

    display: grid;
    grid-template-areas: 
    "header"
    "ul"
    "button";
    grid-template-rows: auto 1fr auto;
    overflow-x: auto;

    >header {
        grid-area: header;
        display: grid;
        grid-template-areas: "acoes codigo titulo arquivo setor ultima_mod";
        grid-template-columns: 6rem 4rem 4fr 3fr 20rem 15rem;
        padding-right: 0.75rem;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
        background-color: ${cssVars.colorGrey["7h"]};
        color: ${cssVars.colorGrey[3]};
        font-size: 0.875rem;
        font-weight: 600;
        width: 100%;
        box-sizing: border-box;

        >div {
            padding: 0.5rem;
        }
    }

    >ul {
        grid-area: ul;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        width: 100%;
        box-sizing: border-box;
        gap: 0.5rem;
        padding: 0.5rem 0;
    }

    >button {
        margin-top: 1rem;
        grid-area: button;
        padding: 0.5rem 1rem;
        font-size: 1rem;
        font-weight: 700;
    }
`;