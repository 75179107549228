import styled from "styled-components";
import cssVars from "../../styles/cssVariables.vars";

export const StyledSupervisorPage = styled.div`
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;

    display: flex;
    position: relative;
    background-color: ${cssVars.colorGrey[8]};

    >main {
        width: calc(100% - 4rem);
        min-width: 70rem;
    }
`;