import styled from "styled-components";

export interface StyledCustomInputProps {
    padding: {
        horizontal: number;
        vertical: number;
    }
    fontSize: number;
    fontWeight?: number;
    disableBorder?: boolean;
    disableIcon?: boolean;
    whiteMode?: boolean;
    divWidth?: number | string;
    disabled?: boolean;
}

export const StyledCustomInput = styled.div<StyledCustomInputProps>`
    box-sizing: border-box;
    ${(props) => !!props.divWidth ?
        (typeof props.divWidth === "number" ? `width: ${props.divWidth}rem;` : `width: ${props.divWidth};`)
        : 'width: 100%;'
    }
    position: relative;

    >input {
        width: 100%;
        box-sizing: border-box;
        ${(props) => !props.disabled && `padding: ${props.padding.vertical}rem ${props.padding.horizontal}rem;`}
        ${(props) => !props.disabled && `padding-left: ${props.disableIcon ? (props.padding.horizontal) : ((props.padding.horizontal * 2) + (props.fontSize * 1.25))}rem;`}
        ${(props) => !props.disableBorder && "border: 2px solid rgba(0, 0, 0, 25%);"}
        border-radius: 0.25rem;
        font-weight: ${(props) => props.fontWeight ? props.fontWeight : 500};
        font-size: ${(props) => `${props.fontSize}rem`};
        ${(props) => !!props.whiteMode && 'color: white;'};
        ${(props) => props.disabled && 'color: rgba(0,0,0, 0.6);'}

        &::placeholder {
            ${(props) => props.whiteMode && 'color: white !important;'};

            font-weight: ${(props) => props.fontWeight ? props.fontWeight : 500};
        }

        :hover {
            background-color: rgba(255, 255, 255, 25%);
            transition: 300ms ease-in-out linear;
        }
    }

    >svg {
        font-size: ${(props) => `${props.fontSize}rem`};
        color: rgba(0,0,0,50%);
        ${(props) => props.whiteMode && 'color: white;'}
        position: absolute;
        top: ${(props) => `calc(50% - ${(props.fontSize) / 2}rem)`};
        left: ${(props) => `${props.padding.horizontal}rem`};
    }
`;