import { ReactNode, useContext, useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { CustomMessagesContext } from "../../contexts/admin/CustomMessages.context";
import { CustomMessage } from "../../interfaces/CustomMessages.interfaces";
import { StyledViewFullMessage } from "./style";
import { CustomState, CustomStateHook } from "../../Hooks/State.hook";
import api, { baseURL } from "../../services/api";
import { Sector } from "../../interfaces/RunningAttendances";
import { FaAngleDown } from "react-icons/fa";
import { toast } from "react-toastify";
import { Modal } from "../../styles/modal";
import { VariablesMenu } from "../../pages/Supervisor/QuickMessages/Variables";
import cssVars from "../../styles/cssVariables.vars";

interface ViewFullMessageProps {
    message: CustomMessage
    mode: "edit" | "view";
    modalState: CustomStateHook<ReactNode>;
};

export const ViewFullMessage = ({ message, mode, modalState }: ViewFullMessageProps) => {
    const { getAllMessages } = useContext(CustomMessagesContext);

    const messageTitle = CustomState<string>(message.TITULO);
    const messageText = CustomState<string>(message.TEXTO_MENSAGEM);
    const messageSector = CustomState<number>(message.SETOR);
    const sectors = CustomState<Array<Sector>>([]);
    const [file, setFile] = useState<File | null>(null);

    const variablesModalState = CustomState<ReactNode>(null);

    const title_verb: string = mode === "edit" ? "Editando" : "Visualizando";
    const Title = () => (<h1> {title_verb} mensagem: <b>{message.TITULO}</b>.</h1>);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            const selectedFile = files[0];
            setFile(selectedFile);
        } else {
            setFile(null);
        }
    };

    const SaveButton = () => {
        const saveChanges = () => {

            const requestForm = new FormData();

            requestForm.append("TITULO", messageTitle.value);
            requestForm.append("TEXTO_MENSAGEM", messageText.value);
            requestForm.append("SETOR", String(messageSector.value));

            if (file) {
                requestForm.append("file", file, encodeURIComponent(file.name));
            }

            api.patch(
                `/ready-messages/${message.CODIGO}`,
                requestForm
            )
                .then(() => {
                    getAllMessages();
                    toast.success("Mensagem atualizada com sucesso!")
                })
                .catch((err) => {
                    console.error(err);
                    toast.error("Falha ao atualizar mensagem...")
                })
                .finally(() => modalState?.reset())
        };

        return (
            <button onClick={() => saveChanges()} className="btn save">
                Salvar
            </button>
        )
    };

    const RevertChangesButton = () => {
        const undoChanges = () => {
            messageTitle.reset();
            messageText.reset();
            messageSector.reset();
        };
        return (
            <button onClick={() => undoChanges()} className="btn revert">
                Reverter
            </button>
        )
    };

    const onChangeText: React.ChangeEventHandler<HTMLTextAreaElement> = (element) => {
        if (element.target.value.split('').reverse()[0] === "@") {
            variablesModalState.set(
                <Modal>
                    <VariablesMenu modalState={variablesModalState} textState={messageText} />
                </Modal>
            );
        }

        messageText.set(element.target.value);
    };

    useEffect(() => {
        api.get<Array<Sector>>(`/sectors`)
            .then((res) => sectors.set(res.data))
            .catch((err) => console.error(err));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <StyledViewFullMessage>
            <header>
                <Title />
                <div>
                    <button onClick={modalState.reset}>
                        <IoMdClose color={cssVars.colorGrey[2]} />
                    </button>
                </div>
            </header>
            <div>
                <div>
                    <input
                        value={messageTitle.value}
                        onChange={(e) => messageTitle.set(e.target.value)}
                        disabled={mode === "view"}
                        style={{ fontSize: "1rem" }}
                    />
                </div>

                {
                    <div className="select">
                        <select
                            disabled={mode === "view"}
                            value={messageSector.value}
                            onChange={(e) => messageSector.set(Number(e.target.value))}
                        >
                            <option value={0}> Todos </option>
                            {sectors.value.map((s) => <option value={s.CODIGO} key={`vfmo_${s.CODIGO}`}> {s.NOME} </option>)}
                        </select>
                        <FaAngleDown />
                    </div>
                }
                <div>
                    <textarea
                        value={messageText.value}
                        onChange={onChangeText}
                        disabled={mode === "view"}
                    />
                </div>
                <div
                    style={{ marginTop: '0.25rem' }}
                >
                    <div style={{ padding: '0.5rem' }} className="files">
                        {
                            message.ARQUIVO &&
                            <div style={{ fontSize: "0.875rem" }}>
                                <span>Arquivo atual:&nbsp;</span>

                                <a
                                    style={{ appearance: 'none', gap: '1rem', alignItems: 'center', cursor: 'pointer' }}
                                    href={`${baseURL}/custom-routes/file/${message.ARQUIVO}`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <span>{message.ARQUIVO.slice(37)}</span>
                                </a>
                            </div>
                        }
                        {
                            mode === "edit" &&
                            <div style={{ fontSize: "0.875rem" }}>
                                <span>Novo arquivo:&nbsp;</span>
                                <input type="file" onChange={handleFileChange} />
                            </div>

                        }
                    </div>
                </div>
                <div className="bottom-btns">
                    {
                        mode === "edit" &&
                        <>
                            <SaveButton />
                            <RevertChangesButton />
                        </>
                    }
                </div>
            </div>
            {
                variablesModalState.value
            }
        </StyledViewFullMessage>
    );
};