import styled from "styled-components";
import cssVars from "../../../../../styles/cssVariables.vars";

export const StyledSelecionarContatos = styled.div`
    box-sizing: border-box;
    height: 100%;

    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0.5rem;

    >header {
        display: flex;
        border-radius: 0.25rem;
        background-color: ${cssVars.colorGrey["6h"]};

        >div {
            box-sizing: border-box;
            padding: 0.5rem;
            display: flex;
            align-items: center;
            
            &.check {
                width: 2rem;
            }
            
            &.contato_nome {
                width: 12rem;
            }

            &.contato_numero {
                width: 12rem;
            }

            &.cliente_razao {
                width: 20rem;
            }

            &.cliente_cnpj {
                width: 12rem;
            }

            &.cliente_operador {
                width: 12rem;
            }

            &.cliente_segmento {
                width: 12rem;
            }

            &.cliente_campanha {
                width: 12rem;
            }

            &.cliente_setor {
                width: 12rem;
            }
        }
    }

    >ul {
        display: flex;
        flex-direction: column;
        overflow: auto;
        box-sizing: border-box;
        height: calc(100% - 4.5rem);

        >div {
            box-sizing: border-box;
            display: flex;
            border-radius: 0.25rem;

            &:nth-child(odd) {
                background-color: ${cssVars.colorGrey["7h"]};
            }

            >div {
                box-sizing: border-box;
                padding: 0.5rem;
                font-size: 0.75rem;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;


                &.check {
                    width: 2rem;
                }

                &.contato_nome {
                    width: 12rem;
                }

                &.contato_numero {
                    width: 12rem;
                }

                &.cliente_razao {
                    width: 20rem;
                }

                &.cliente_cnpj {
                    width: 12rem;
                }

                &.cliente_operador {
                    width: 12rem;
                }

                &.cliente_segmento {
                    width: 12rem;
                }

                &.cliente_campanha {
                    width: 12rem;
                }

                &.cliente_setor {
                    width: 12rem;
                }
            }
        }
    }

    >.pages {
        box-sizing: border-box;
        padding: 0.5rem 0;
        
        display: flex;
        align-items: center;
        gap: 0.5rem;
        width: 100%;
        
        >button {
            background-color: ${cssVars.colorGrey["6h"]};
            color: ${cssVars.colorGrey["2"]};
            padding: 0.25rem 0.5rem;
            border-radius: 0.25rem;
            cursor: pointer;
            user-select: none;
        }

        >span {
            font-size: 0.875rem;
        }

        >:nth-last-child(3) {
            width: 4rem;
            >:nth-child(1) {
                appearance: inherit;
            }
        }
    }
`