import { useContext, useEffect } from "react";
import { IoMdChatbubbles, IoMdClose } from "react-icons/io";
import { StyledNewAttendance } from "./style"
import { CustomState } from "../../../../Hooks/State.hook";
import { CustomerWithContact } from "../../../../interfaces/Customers.interface";
import api from "../../../../services/api";
import { LoginContext, currentRoute } from "../../../../contexts/global/LoginContext";
import { FaSearch, FaUserAlt } from "react-icons/fa";
import { AttendancesContext } from "../../../../contexts/user/AttendancesContext";
import formatarCPForCNPJ from "../../../../Hooks/FormatCPFCNPJ.hook";
import { toast } from "react-toastify";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import { Modal } from "../../../../styles/modal";
import { SelectAttendanceTemplate } from "../../../../components/SelectAttendanceTemplate";
import { SocketContext } from "../../../../contexts/global/SocketContext";
import { Sector } from "../../../../interfaces/RunningAttendances";
import { UserData } from "../../../../interfaces/Users.interfaces";
import { formatNumber } from "../../../../Hooks/FormatNumber.hook";
import { RegistrationButton } from "../../../../styles/registrationButton";
import { PageNavigation } from "../../../../components/PageNavigation";
import MultiSelect from "../../../../components/MultiSelect";
import Input from "../../../../components/Input";
import Select, { Option } from "../../../../components/Select";
import cssVars from "../../../../styles/cssVariables.vars";

export const NewAttendance = () => {
    const customers = CustomState<Array<CustomerWithContact>>([]);
    const loading = CustomState<boolean>(false);
    const { oficialMode } = useContext(SocketContext);
    const { user } = useContext(LoginContext);
    const { newAttendanceModalState, selectedClient, startAttendance, modalState } = useContext(AttendancesContext);
    const searchInputState = CustomState<string | number[]>("");
    const fieldInputState = CustomState<string>("RAZAO");
    const currentPage = CustomState<number>(1);
    const nextPage = CustomState<string | null>(null);
    const previousPage = CustomState<string | null>(null);

    const searchState = CustomState<{ field: string, value: string | number[] }>({ field: "RAZAO", value: "" });

    const searchFieldOptions: Array<Option<string>> = [
        { name: "Razão Social", value: "RAZAO" },
        { name: "Código inPulse", value: "CODIGO" },
        { name: "Código ERP", value: "COD_ERP" },
        { name: "CPF/CNPJ", value: "CPF_CNPJ" },
        { name: "Fidelização", value: "FIDELIZADOS" },
        { name: "Número Whatsapp", value: "NUMERO" },
        { name: "Nome", value: "NOME" },
    ];

    const operatorsOptions = CustomState<Array<Option<number>>>([]);

    useEffect(() => {
        api.get<Array<UserData>>(`/operators`)
            .then((res) => {
                operatorsOptions.set([
                    { name: "Agenda pública", value: 0 },
                    ...res.data.map(o => ({ name: o.NOME, value: o.CODIGO }))
                ]);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        loading.set(true);

        async function loadContacts(field: string, value: string | Number[], page: number) {
            if (user) {
                try {
                    const setor = (await api.get<Sector>(`/sectors/${user?.SETOR}`)).data

                    if (["CPF_CNPJ", "CODIGO", "NUMERO"].includes(field) && typeof value === "string") {
                        value = value.replace(/\D/g, "");
                    }

                    if (setor.IS_SALES && currentRoute as string !== "vollo") {
                        await api.get<{
                            data: CustomerWithContact[], nextPage: string | null, previousPage: string | null, count: number
                        }>(`/contacts/findByOperator/${user.CODIGO}`, {
                            params: {
                                field: field,
                                value: value,
                                page: page,
                                perPage: 20,
                            }
                        })
                            .then(res => {
                                customers.set(res.data.data);
                                nextPage.set(res.data.nextPage);
                                previousPage.set(res.data.previousPage);

                                if (res.data.count === 0 && currentPage.value > 1) {
                                    currentPage.set(1);
                                }
                            })
                            .catch(err => toast.error(err.message));
                    } else {
                        await api.get<{
                            data: CustomerWithContact[], nextPage: string | null, previousPage: string | null, count: number,
                        }>(`/contacts/findNotRunning`, {
                            params: {
                                field: field,
                                value: value,
                                page: page,
                                perPage: 10,
                                sectorId: currentRoute === "natrielli" ? (user.NIVEL === "ADMIN" ? undefined : user.SETOR) : undefined
                            }
                        })
                            .then(res => {
                                customers.set(res.data.data);
                                nextPage.set(res.data.nextPage);
                                previousPage.set(res.data.previousPage);

                                if (res.data.count === 0 && currentPage.value > 1) {
                                    currentPage.set(1);
                                }
                            })
                            .catch(err => toast.error(err.message));
                    }
                } finally {
                    loading.reset();
                }
            }
        }

        loadContacts(searchState.value.field, searchState.value.value, currentPage.value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage.value, searchState.value]);

    return (
        <StyledNewAttendance>
            <header>
                <h2>Iniciar novo atendimento</h2>
                <button onClick={newAttendanceModalState.reset}>
                    <IoMdClose />
                </button>
            </header>

            <div className="search">
                {
                    fieldInputState.value === "FIDELIZADOS" ?
                        <MultiSelect<number>
                            $fontSize={0.875}
                            $fontWeight={500}
                            $padding={[0.5, 0.5]}
                            $width="calc(50% - 4rem)"
                            $color={cssVars.colorGrey[4]}
                            options={operatorsOptions.value}
                            onChange={(v) => searchInputState.set(v)}
                            placeholder="Selecione o(s) atendente(s)"
                            leftIcon={null}
                        />
                        :
                        <Input
                            $fontSize={0.875}
                            $fontWeight={500}
                            $padding={[0.5, 0.5]}
                            $width="calc(50% - 4rem)"
                            $color={cssVars.colorGrey[4]}
                            $focusColor={cssVars.colorGrey[2]}
                            leftIcon={<FaSearch />}
                            placeholder="Pesquisar contato(s)"
                            onChange={(e) => searchInputState.set(e.target.value)}
                            value={typeof searchInputState.value !== "object" ? searchInputState.value : ""}
                        />
                }
                <Select<string>
                    $fontSize={0.875}
                    $fontWeight={500}
                    $padding={[0.5, 0.5]}
                    $color={cssVars.colorGrey[4]}
                    $focusColor={cssVars.colorGrey[2]}
                    $width="calc(50% - 4rem)"
                    options={searchFieldOptions}
                    onChange={(v) => fieldInputState.set(v || "")}
                    placeholder="Selecione o campo"
                />
                <RegistrationButton onClick={() => {
                    searchState.set(({ field: fieldInputState.value, value: searchInputState.value }));
                }}
                    type="button"
                >
                    Buscar
                </RegistrationButton>
            </div>
            {


                loading.value ?
                    <LoadingSpinner />
                    :
                    <>
                        <ul>
                            {
                                customers.value.map(customer => {
                                    return (
                                        <li key={`contact_${customer.NUMERO}`}>
                                            <FaUserAlt className="avatar" />
                                            <div>
                                                <h2> [{customer.CODIGO}] {customer.RAZAO} - {customer.NOME} </h2>
                                                <h3> CNPJ/CPF: {customer?.CPF_CNPJ && formatarCPForCNPJ(customer?.CPF_CNPJ)} </h3>
                                                <h3> Numero WhatsApp: {formatNumber(customer.NUMERO)} </h3>
                                                <h3> Código ERP: {customer.COD_ERP || "N/D"} </h3>
                                                <h3> Fidelizado p/: {customer.FIDELIZADO} </h3>
                                            </div>
                                            {
                                                !customer.BEING_ATTENDED ?
                                                    <button onClick={() => {
                                                        selectedClient.set(customer);
                                                        newAttendanceModalState.reset();

                                                        if (oficialMode.value) {

                                                            modalState.set(
                                                                <Modal>
                                                                    <SelectAttendanceTemplate modalState={modalState} />
                                                                </Modal>
                                                            );
                                                        } else {
                                                            startAttendance(customer.CODIGO, customer.CODIGO_CONTATO, customer.NUMERO, null);
                                                        };
                                                    }}>
                                                        <IoMdChatbubbles />
                                                    </button>
                                                    :
                                                    <p>
                                                        {
                                                            typeof customer.BEING_ATTENDED === "string" ?
                                                                customer.BEING_ATTENDED
                                                                :
                                                                "Já está sendo atendido..."
                                                        }
                                                    </p>

                                            }

                                        </li>
                                    )
                                })
                            }
                        </ul>

                        <PageNavigation
                            className="page-nav"
                            currentPage={currentPage}
                            nextPage={nextPage}
                            previousPage={previousPage}
                        />
                    </>
            }
        </StyledNewAttendance>
    );
};