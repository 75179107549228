import { FaUserCircle } from "react-icons/fa";
import { StyledClientInfo } from "./style";
import { AttendancesContext } from "../../../../contexts/user/AttendancesContext";
import { useContext, useEffect } from "react";
import formatarCPForCNPJ from "../../../../Hooks/FormatCPFCNPJ.hook";
import { CustomState } from "../../../../Hooks/State.hook";
import { SocketContext } from "../../../../contexts/global/SocketContext";
import { OperatorUrgency, SupervisorUrgency } from "../../../../interfaces/Attendances.interfaces";
import { LoginContext, currentRoute } from "../../../../contexts/global/LoginContext";
import { formatNumber } from "../../../../Hooks/FormatNumber.hook";
import formatarNome from "../../../../Hooks/FormatName.hook";
import Select from "../../../../components/Select";
import { defaultSelect100 } from "../../../../components-variants/defaultInputs";
import cssVars from "../../../../styles/cssVariables.vars";

export const ClientInfo = () => {
    const { readingAttendance, detailedAttendances } = useContext(AttendancesContext);
    const { socket } = useContext(SocketContext);
    const { user } = useContext(LoginContext);

    const urgenciaState = CustomState("NORMAL");

    useEffect(() => {
        if (readingAttendance.value) {
            urgenciaState.set(readingAttendance.value.URGENCIA_OPERADOR);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [readingAttendance.value]);

    function updateUrgency(urgencia: OperatorUrgency | SupervisorUrgency) {
        if (socket.value && readingAttendance.value && user) {
            const data = {
                CLIENTE: currentRoute,
                CODIGO_ATENDIMENTO: readingAttendance.value.CODIGO,
                URGENCIA: urgencia,
                MODO: user.NIVEL === "ADMIN" ? "ADMIN" : "OPERATOR"
            };

            detailedAttendances.set((prev) => {
                const newArray = [...prev]
                const i = prev.findIndex((a) => a.CODIGO === data.CODIGO_ATENDIMENTO);

                if (~i && data.MODO === "OPERATOR") {
                    newArray[i] = { ...newArray[i], URGENCIA_OPERADOR: data.URGENCIA as OperatorUrgency }
                }
                if (~i && data.MODO === "ADMIN") {
                    prev[i].URGENCIA_SUPERVISOR = data.URGENCIA;
                    newArray[i] = { ...newArray[i], URGENCIA_SUPERVISOR: data.URGENCIA }
                }

                return newArray;
            });

            socket.value.emit("update-urgency", data);
        };
    };

    const operatorUrgencies: Array<OperatorUrgency> = ["NORMAL", "ALTA"];
    const supervisorUrgencies: Array<SupervisorUrgency> = ["NORMAL", "ALTA", "MUITO_ALTA", "URGENTE"];

    return (
        <>
            {
                readingAttendance.value &&
                <StyledClientInfo>
                    <div>
                        {
                            readingAttendance.value.AVATAR_URL ?
                                <img src={readingAttendance.value.AVATAR_URL} alt="" />
                                :
                                <FaUserCircle />
                        }
                        <div>
                            <h2> {readingAttendance.value?.CONTATO_NOME ? formatarNome(readingAttendance.value.CONTATO_NOME) : "Contato Excluído"} </h2>
                            <h3>
                                {
                                    formatarNome((readingAttendance.value.RAZAO || "").length >= 30 ?
                                        (readingAttendance.value.RAZAO || "").slice(0, 30) + "..."
                                        :
                                        readingAttendance.value.RAZAO)
                                }
                            </h3>
                            <span>  {readingAttendance.value?.CONTATO_NUMERO && formatNumber(readingAttendance.value?.CONTATO_NUMERO)} </span>

                        </div>
                    </div>

                    <div className="info_box">
                        <span>
                            <b>CPF/CNPJ:{" "}</b>
                            {formatarCPForCNPJ(readingAttendance.value?.CPF_CNPJ || "00000000000")}
                        </span>
                        <span>
                            <b>Código Cliente:{" "}</b>
                            {readingAttendance.value.CODIGO_CLIENTE}
                        </span>
                        <span>
                            <b>Código ERP:{" "}</b>
                            {readingAttendance.value.COD_ERP || "null"}
                        </span>
                    </div>
                    <div className="info_box margin_select">
                        {
                            readingAttendance.value &&
                            <>

                                <span>
                                    <b> Data de início: {" "}</b>
                                    {
                                        readingAttendance.value.DATA_INICIO ?
                                            new Date(readingAttendance.value.DATA_INICIO).toLocaleDateString()
                                            :
                                            "0/0/0000"
                                    }
                                </span>

                                {
                                    user &&
                                        user.NIVEL === "ADMIN" ?
                                        <Select
                                            options={supervisorUrgencies.filter(o => o!).map(o => ({ name: o![0].toUpperCase() + o!.slice(1).toLowerCase().replace("_", " "), value: o! }))}
                                            defaultValue={{
                                                name: readingAttendance.value!.URGENCIA_SUPERVISOR?.toLowerCase().replace("_", " ").split("").map((c, i) => i === 0 ? c.toUpperCase() : c).join("") || "Normal",
                                                value: readingAttendance.value!.URGENCIA_SUPERVISOR || "NORMAL"
                                            }}
                                            onChange={updateUrgency}
                                            {...defaultSelect100}
                                            $focusColor={cssVars.colorPrimary}
                                        />
                                        :
                                        <Select
                                            options={operatorUrgencies.map(o => ({ name: o[0].toUpperCase() + o.slice(1).toLowerCase(), value: o }))}
                                            defaultValue={{
                                                name: readingAttendance.value!.URGENCIA_OPERADOR.toLowerCase().replace("_", " ").split("").map((c, i) => i === 0 ? c.toUpperCase() : c).join("") || "Normal",
                                                value: readingAttendance.value!.URGENCIA_OPERADOR
                                            }}
                                            onChange={updateUrgency}
                                            {...defaultSelect100}
                                            $focusColor={cssVars.colorPrimary}
                                        />
                                }
                            </>
                        }
                    </div>

                </StyledClientInfo>
            }
        </>
    );
};