import { useContext } from "react";
import { FaSignOutAlt } from "react-icons/fa";
import { SupervisorContext } from "../../../contexts/admin/Supervisor.context";
import { LoginContext } from "../../../contexts/global/LoginContext";
import { StyledSupervisorHeader } from "./style"
import { FaMoon, FaSun } from "react-icons/fa6";


export const SupervisorHeader = () => {

    const { logout, user, darkMode, setDarkMode } = useContext(LoginContext);
    const { selectedPageTitle } = useContext(SupervisorContext);

    return (
        <StyledSupervisorHeader>
            <div>
                <h1> {selectedPageTitle} </h1>
            </div>
            <div>
                <div>
                    {
                        !darkMode ?
                            <FaSun className="avatar" onClick={() => setDarkMode(true)} />
                            :
                            <FaMoon className="avatar" onClick={() => setDarkMode(false)} />
                    }
                    <div>
                        <span> Olá, </span>
                        <h2> {user && user.NOME} </h2>
                    </div>
                </div>
                <FaSignOutAlt onClick={logout} />
            </div>
        </StyledSupervisorHeader>
    );
};