import styled from "styled-components";

export const StyledTableItem = styled.li`
        display: grid;
        grid-template-areas: "acoes id nome numero";
        grid-template-columns: 4rem 4rem 12rem 12rem;

        >div {
            box-sizing: border-box;
            padding: 0.5rem;

            display: flex;
            align-items: center;
            gap: 0.25rem;

            &:nth-child(1) {
                border-left: none;
            }

            >input {
                padding: 0.5rem;
                background-color: rgba(0,0,0, 5%);
                border: 1px solid rgba(0,0,0, 50%);
                border-radius: 0.25rem;
                width: 100%;
                font-size: 0.75rem;

                &[disabled] {
                    background-color: rgba(0,0,0, 1%);
                    border: 1px solid rgba(0,0,0, 10%);
                    border-radius: 0.25rem;
                    width: 100%;

                    &.id {
                        font-weight: 600;
                        border: 1px solid rgba(0,0,0, 2.5%);
                    }
                }
            }

            >svg {
                cursor: pointer;
                opacity: 70%;
                :hover {
                    transform: scale(1.25);
                    opacity: 100%;
                }
            }

            >h3 {
                font-size: 0.875rem;
                font-weight: 500;
                margin: 0 auto;
            }
        }
`;