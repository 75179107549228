import { createContext, FormEvent, ReactNode, useEffect, useState } from "react";
import { LoginContextProprieties, LoginProviderProprieties, UserData } from "../../interfaces/Users.interfaces";
import api from "../../services/api";
import { toast } from 'react-toastify';
import { LoginPage } from "../../pages/Login";
import { SupervisorProvider } from "../admin/Supervisor.context";
import { SupervisorPage } from "../../pages/Supervisor";
import { OperadorPage } from "../../pages/Operador";

export const LoginContext = createContext({} as LoginContextProprieties);
export const currentRoute = /* window.location.hostname.split('.')[0]; */ "develop" as string;

const title = currentRoute[0].toUpperCase() + currentRoute.slice(1);

document.title = `inPulse WhatsApp - ${title}`;

export const LoginProvider = ({ children }: LoginProviderProprieties) => {
    const [name, setName] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [user, setUser] = useState<UserData | false>(false);
    const [renderingPage, renderPage] = useState<ReactNode>("");
    const [darkMode, setDarkMode] = useState<boolean>(false);

    useEffect(() => { getUserStatus() }, []);
    useEffect(() => {
        if (user && user.NIVEL === "ADMIN") renderPage(<SupervisorProvider> <SupervisorPage /></SupervisorProvider>);
        if (user && user.NIVEL !== "ADMIN") renderPage(<OperadorPage />);
        if (!user) renderPage(<LoginPage />);
    }, [user]);

    function tryToLogin(e: FormEvent) {
        e.preventDefault();

        api.post(`/login`, { LOGIN: name, SENHA: password })
            .then(res => {
                localStorage.setItem('@inPulse-web/token', res.data)
            })
            .then(() => getUserStatus())
            .catch(err => {
                toast.error(err.response?.data ? err.response.data.message : "Falha ao conectar-se com servidor.")
            })
    };

    function getUserStatus() {
        const token = localStorage.getItem('@inPulse-web/token');

        if (token) {
            api.defaults.headers.authorization = `Bearer ${token}`;
            api.get(`/login`)
                .then(res => setUser(res.data))
                .catch(err => toast.error(err.response?.data ? err.response.data.message : "Falha ao conectar-se com servidor."));
        };
    };

    function logout() {
        localStorage.removeItem('@inPulse-web/token');
        window.location.reload();
    };

    return (
        <LoginContext.Provider value={{
            setName,
            setPassword,
            tryToLogin,
            getUserStatus,
            logout,
            user,
            renderingPage,
            darkMode,
            setDarkMode
        }}>
            {children}
        </LoginContext.Provider>
    );
};