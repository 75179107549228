import { ReactNode, useContext, useEffect } from "react";
import { CustomState } from "../../../../../Hooks/State.hook"
import { CustomMessage } from "../../../../../interfaces/CustomMessages.interfaces"
import { StyledSelecionarMensagemPronta } from "./style"
import api from "../../../../../services/api";
import { toast } from "react-toastify";
import { MMContext } from "../context";
import { FaEye } from "react-icons/fa6";
import { ViewFullMessage } from "../../../../../components/ViewFullMessage";
import { Modal } from "../../../../../styles/modal";

export const SelecionarMensagemPronta = () => {
    const mensagensProntas = CustomState<CustomMessage[]>([]);
    const { typedMessage, selectedFilename } = useContext(MMContext);
    const modalState = CustomState<ReactNode>(null);

    useEffect(() => {
        api.get<Array<CustomMessage>>(`/ready-messages/`)
            .then((res) => mensagensProntas.set(res.data))
            .catch((err) => {
                console.error(err);
                toast.error("Falha ao carregar mensagens prontas.");
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <StyledSelecionarMensagemPronta>
            {
                mensagensProntas.value.length ? mensagensProntas.value.map((m) => {
                    return (
                        <div className="ready-message_card">
                            <input
                                type="radio"
                                name="ready-message"
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        typedMessage.set(m.TEXTO_MENSAGEM);
                                        m.ARQUIVO && selectedFilename.set(m.ARQUIVO);
                                    }
                                }}
                            />
                            <h2> {m.TITULO} </h2>
                            <button onClick={() => modalState.set(<ViewFullMessage mode="view" message={m} modalState={modalState} />)}>
                                <FaEye />
                            </button>
                        </div>
                    )
                }) : "Sem mensagens cadastradas"
            }
            {
                modalState.value &&
                <Modal>{modalState.value}</Modal>
            }
        </StyledSelecionarMensagemPronta>
    )
}