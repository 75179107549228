import { useState } from "react";

export interface CustomStateHook<T> {
    defaultValue: T;
    value: T;
    set: React.Dispatch<React.SetStateAction<T>>;
    reset: () => void;
};

export function CustomState<T>(defaultValue: T): CustomStateHook<T> {

    const [ value, set ] = useState<T>(defaultValue);

    const reset = () => { set(defaultValue) };
    
    return { defaultValue, value, set, reset };
};