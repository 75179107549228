import { useEffect, useState } from "react";
import { TableItem } from "./TableItem";
import api from "../../../../services/api";
import { StyledInProgressAttendances } from "./style";
import { ScheduledAttendanceDetails } from "../../../../interfaces/Attendances.interfaces";
import Input from "../../../../components/Input";
import { defaultInput100, defaultSelect100 } from "../../../../components-variants/defaultInputs";
import MultiSelect from "../../../../components/MultiSelect";

export const AppointmentsSchedules = () => {
    const [attendances, setAttendances] = useState<ScheduledAttendanceDetails[]>([]);
    const operators = Array.from(new Set(attendances.map(a => a.OPERADOR)));
    const types = Array.from(new Set(attendances.map(a => a.TIPO)));

    const [updater, setUpdater] = useState(0);

    const update = () => setUpdater(prev => prev + 1);

    useEffect(() => {
        api.get<Array<ScheduledAttendanceDetails>>(`/custom-routes/scheduled-attendances`)
            .then((res) => {
                res.data.forEach(a => {
                    a.DATA_AGENDAMENTO = new Date(a.DATA_AGENDAMENTO as unknown as string)
                });

                setAttendances(res.data.sort((a, b) => b.DATA_AGENDAMENTO.getTime() - a.DATA_AGENDAMENTO.getTime()));
            });
    }, [updater]);

    const [filterOperator, setFilterOperator] = useState<string[]>([]);
    const [filterPriority, setFilterPriority] = useState<string[]>([]);
    const [filterType, setFilterType] = useState<string[]>([]);
    const [filterId, setFilterId] = useState<string>("");
    const [filterContact, setFilterContact] = useState<string>("");
    const [filterCustomer, setFilterCustomer] = useState<string>("");
    const [filterNumber, setFilterNumber] = useState<string>("");

    const operatorsOptions = operators.map(o => ({ name: o, value: o }));
    const typesOptions = types.map(t => ({ name: t, value: t }));
    const priorityOptions = [
        { name: "Urgente", value: "URGENTE" },
        { name: "Muito Alta", value: "MUITO_ALTA" },
        { name: "Alta", value: "ALTA" },
        { name: "Media", value: "MEDIA" },
        { name: "Normal", value: "NORMAL" },
    ];

    const filteredAttendances = attendances.filter((a) => {
        const { URGENCIA_SUPERVISOR, URGENCIA_AGENDAMENTO, URGENCIA_OPERADOR } = a;
        const urgencia: string = URGENCIA_SUPERVISOR || URGENCIA_AGENDAMENTO || URGENCIA_OPERADOR;

        return (filterOperator.length ? filterOperator.includes(a.OPERADOR) : true) &&
            (filterPriority.length ? filterPriority.includes(urgencia) : true) &&
            (filterType.length ? filterType.includes(a.TIPO) : true) &&
            (filterId ? String(a.CODIGO || "") === filterId : true) &&
            (filterContact ? a.CONTATO?.toUpperCase().includes(filterContact) : true) &&
            (filterCustomer ? a.RAZAO?.toUpperCase().includes(filterCustomer) : true) &&
            (filterNumber ? a.CONTATO_NUMERO.includes(filterCustomer.replace(/\D/g, "")) : true)
    });

    return (
        <StyledInProgressAttendances>
            <header>
                <div className="actionsh">
                    Ações
                </div>
                <div className="codah search">
                    <Input
                        placeholder="ID"
                        value={filterId}
                        onChange={(e) => setFilterId(e.target.value.toUpperCase())}
                        {...defaultInput100}
                        $disableBorder
                        $fontSize={0.75}
                    />
                </div>
                <div className="priority_header search">
                    <MultiSelect
                        options={priorityOptions}
                        onChange={(v) => setFilterPriority(v)}
                        placeholder="Prioridade"
                        $disableBorder
                        {...defaultSelect100}
                        $fontSize={0.75}
                    />
                </div>
                <div className="clientnameh search">
                    <Input
                        placeholder="Contato"
                        value={filterContact}
                        onChange={(e) => setFilterContact(e.target.value.toUpperCase())}
                        {...defaultInput100}
                        $disableBorder
                        $fontSize={0.75}
                    />
                </div>
                <div className="clientnumberh search">
                    <Input
                        placeholder="Numero"
                        value={filterNumber}
                        onChange={(e) => setFilterNumber(e.target.value)}
                        {...defaultInput100}
                        $disableBorder
                        $fontSize={0.75}
                    />
                </div>
                <div className="clientcompanyh search">
                    <Input
                        placeholder="Razão Social"
                        value={filterCustomer}
                        onChange={(e) => setFilterCustomer(e.target.value.toUpperCase())}
                        {...defaultInput100}
                        $disableBorder
                        $fontSize={0.75}
                    />
                </div>
                <div className="operatorh search">
                    <MultiSelect
                        options={operatorsOptions}
                        onChange={(v) => setFilterOperator(v)}
                        placeholder="Operador"
                        $disableBorder
                        {...defaultSelect100}
                        $fontSize={0.75}
                    />
                </div>
                <div className="scheduledateh">
                    Agendamento
                </div>
                <div className="typeh search">
                    <MultiSelect
                        options={typesOptions}
                        onChange={(v) => setFilterType(v)}
                        placeholder="Tipo"
                        $disableBorder
                        {...defaultSelect100}
                        $fontSize={0.75}
                    />
                </div>
            </header>
            <div>
                {
                    filteredAttendances.map((attendance, index) => <TableItem attendance={attendance} key={index} update={update} />)
                }
            </div>
        </StyledInProgressAttendances>
    );
};