import { useContext } from "react";
import { Option } from "../../../../components/CustomSelect";
import { StyledEnvioDeMensagensEmMassa } from "./style";
import { MMContext } from "./context";
import { DigitarMensagem } from "./DigitarMensagem";
import { SelecionarMensagemPronta } from "./SelecionarMensagemPronta";
import { SelecionarContatos } from "./SelecionarContatos";
import { RegistrationButton } from "../../../../styles/registrationButton";
import Select from "../../../../components/Select";
import cssVars from "../../../../styles/cssVariables.vars";
import { FaAngleDown } from "react-icons/fa6";

export const EnvioDeMensagensEmMassa = () => {
    const { messageMode, sendMessage, selectedContacts, typedMessage } = useContext(MMContext);

    const options: Array<Option<0 | 1>> = [
        { name: "Digitar uma mensagem", value: 0 },
        { name: "Selecionar mensagem pronta", value: 1 },
    ];

    return (
        <StyledEnvioDeMensagensEmMassa>
            <div className="w24">
                <Select
                    options={options}
                    defaultValue={{ name: "Digitar uma mensagem", value: 0 as 0 | 1 }}
                    onChange={(v) => messageMode.set(v as 0 | 1)}
                    $fontSize={0.875}
                    $padding={[0.5, 1]}
                    $color={cssVars.colorGrey[3]}
                    $focusColor={cssVars.colorGrey[2]}
                    $borderColor={cssVars.colorGrey[6]}
                    $width="24rem"
                />
            </div>
            {
                messageMode.value === 0 ?
                    <DigitarMensagem />
                    :
                    <SelecionarMensagemPronta />
            }
            <SelecionarContatos />
            <RegistrationButton
                disabled={!selectedContacts.value.length || !typedMessage.value.length}
                onClick={sendMessage}
            >
                Enviar mensagens para: {selectedContacts.value.length} contato(s)
            </RegistrationButton>
        </StyledEnvioDeMensagensEmMassa>
    );
};