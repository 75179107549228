import { createContext, ReactNode, useEffect } from "react";
import { NewUserFormData, UpdateUserRequestData, UserData, UsersRegistrationContextProps, UsersRegistrationProviderProps } from "../../interfaces/Users.interfaces";
import api from "../../services/api";
import { toast } from "react-toastify";
import { CustomState } from "../../Hooks/State.hook";
import { Option } from "../../components/CustomSelect";

export const UsersRegistrationContext = createContext({} as UsersRegistrationContextProps)

export const UsersRegistrationProvider = ({ children }: UsersRegistrationProviderProps) => {
    const operators = CustomState<UserData[]>([]);
    const modalState = CustomState<ReactNode>("");
    const searchState = CustomState<string | number[] | string[]>("");
    const fieldState = CustomState<string>("");
    const sectorOptions = CustomState<Array<Option<number>>>([]);

    const levelOptions: Array<Option<"ATIVO" | "RECEP" | "AMBOS" | "ADMIN">> = [
        { name: "Atendente (Ambos)", value: "AMBOS" },
        { name: "Atendente (Ativo)", value: "ATIVO" },
        { name: "Atendente (Receptivo)", value: "RECEP" },
        { name: "Supervisor", value: "ADMIN" },
    ];

    useEffect(() => {
        getAllOperators();
        getAllSectors();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function getAllOperators() {
        api.get<UserData[]>(`/operators/`, {
            params: {
                field: fieldState.value,
                value: searchState.value
            }
        })
            .then(res => operators.set(res.data))
            .catch(err => operators.set([]));
    };

    async function getAllSectors() {
        api.get<{ CODIGO: number, NOME: string }[]>(`/sectors`)
            .then(res => {
                const options = res.data.map(s => ({ name: s.NOME, value: s.CODIGO }))
                sectorOptions.set(options);
            })
            .catch(err => console.error(err));
    }

    async function insertNewOperator(formData: Omit<NewUserFormData, "CSENHA">) {
        api.post(`/operators`, formData)
            .then(() => getAllOperators())
            .catch(err => console.error(err));
    }

    function updateOperator(id: number, data: UpdateUserRequestData) {
        api.patch(`/operators/${id}`, data)
            .then(res => {
            })
            .catch(err => {
                toast.error(err)
                getAllOperators()
            });
    }

    function deleteOperator(id: number, name: string) {
        api.delete(`/users/${id}`)
            .then(_ => {
                toast.success(`${name} foi definido como inativo.`)
                getAllOperators()
            })
            .catch(err => console.error(err));
    }

    function recoverOperator(id: number, name: string) {
        api.put(`/users/${id}/recover`)
            .then(_ => {
                toast.success(`${name} foi reativado.`);
                getAllOperators();
            })
            .catch(err => console.error(err));
    }

    return (
        <UsersRegistrationContext.Provider value={{
            insertNewOperator,
            updateOperator,
            deleteOperator,
            recoverOperator,
            getAllOperators,
            modalState,
            operators,
            searchState,
            fieldState,
            sectorOptions,
            levelOptions
        }}>
            {children}
        </UsersRegistrationContext.Provider>
    );
};