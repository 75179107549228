import { /* FaBrush,  */FaFilter } from "react-icons/fa6";
import Modal from "../../Modal";
import { TableColumn } from "../types";
import { ButtonType2 } from "../../../styles/buttons.style";
import StyledFiltersModal from "./style";
import filterSelect from "./filterSelect";
import filterInput from "./filterInput";
import filterMultiSelect from "./filterMultiSelect";
import filterPhone from "./filterPhone";
import { ReactNode } from "react";
import filterDateRange from "./filterDateRange";
import { CustomState, CustomStateHook } from "../../../Hooks/State.hook";
import { FilterWithPaginationQueryParameters } from "../../../interfaces/generics.types";

type FiltersModalProps<T> = {
    tableName: string;
    filtersModalState: CustomStateHook<ReactNode>;
    appliedFiltersState: CustomStateHook<FilterWithPaginationQueryParameters<T>>;
    $modalFiltersWidth: number;
    columns: Array<TableColumn<T>>;
    rowsState: CustomStateHook<Array<T>>;
}

function FiltersModal<T>({ tableName, filtersModalState, $modalFiltersWidth, columns, rowsState, appliedFiltersState }: FiltersModalProps<T>) {
    const filterSections = Array.from(new Set(columns.filter(c => c.filter).map(c => c.filter!.section)));
    const filterState = CustomState<FilterWithPaginationQueryParameters<T>>(appliedFiltersState.value);

    return (
        <Modal title={`Filtrar ${tableName.toLowerCase()}`} modalState={filtersModalState}>
            <StyledFiltersModal $modalFiltersWidth={$modalFiltersWidth}>
                <div>
                    {
                        filterSections.map(s => (
                            <div>
                                {s && <h3> {s} </h3>}
                                <div>
                                    {
                                        columns.filter(c => c.filter && c.filter.section === s).map(c =>
                                            c.filter!.type === "input" ? filterInput(c, filterState) :
                                                c.filter!.type === "select" ? filterSelect<T>(c, rowsState, filterState) :
                                                    c.filter!.type === "multi-select" ? filterMultiSelect(c, filterState) :
                                                        c.filter!.type === "phone" ? filterPhone(c, filterState) :
                                                            c.filter!.type === "date-range" && filterDateRange(c, filterState))
                                    }
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div>
                    {/* <ButtonType2 onClick={() => {
                        filterState.reset();
                    }}>
                        <FaBrush /> Limpar filtros
                    </ButtonType2> */}
                    <ButtonType2 onClick={() => {
                        appliedFiltersState.set(prev => ({ ...filterState.value }));
                        filtersModalState.reset();
                    }}>
                        <FaFilter />
                        <p>
                            Filtrar
                        </p>
                    </ButtonType2>
                </div>
            </StyledFiltersModal>
        </Modal>
    );
}

export default FiltersModal;