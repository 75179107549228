import { Dispatch, ReactNode, SetStateAction, useContext, useEffect } from "react";
import { CustomState, CustomStateHook } from "../../Hooks/State.hook";
import { StyledScheduleDialog } from "./style";
import { RegistrationButton } from "../../styles/registrationButton";
import { IoMdClose } from "react-icons/io";
import { SocketContext } from "../../contexts/global/SocketContext";
import api from "../../services/api";
import { UserData } from "../../interfaces/Users.interfaces";
import { LoginContext, currentRoute } from "../../contexts/global/LoginContext";
import { RunningAttendanceDetails } from "../../interfaces/Attendances.interfaces";
import { AttendancesContext } from "../../contexts/user/AttendancesContext";
import { defaultSelect100 } from "../../components-variants/defaultInputs";
import Select from "../Select";
import cssVars from "../../styles/cssVariables.vars";

interface TransferDialogProps {
    modalState: CustomStateHook<ReactNode>;
    CODIGO_ATENDIMENTO: number;
    sectorMode?: boolean;
    setAttendances?: Dispatch<SetStateAction<RunningAttendanceDetails[]>>;
};

export const UpdateOperatorDialog = ({ modalState, CODIGO_ATENDIMENTO, sectorMode, setAttendances }: TransferDialogProps) => {

    const { socket } = useContext(SocketContext);
    const { user } = useContext(LoginContext)
    const { readingAttendance } = useContext(AttendancesContext);

    const operators = CustomState<UserData[]>([]);
    const operatorValue = CustomState<number | null>(null);

    function transferAttendance() {
        if (socket.value && operatorValue.value && user) {
            const data = {
                clientName: currentRoute,
                attendanceId: CODIGO_ATENDIMENTO,
                newOperatorId: operatorValue.value,
                oldOperatorId: user.CODIGO
            };

            setAttendances && setAttendances(prev => {
                const arr = [...prev];
                const i = arr.findIndex(v => v.CODIGO === CODIGO_ATENDIMENTO);
                const o = operators.value.find(v => v.CODIGO === operatorValue.value);

                if (o) {
                    arr[i].OPERADOR = o.NOME;
                }

                return arr;
            });

            socket.value.emit("update-operator", data);
            readingAttendance.reset();
            modalState.reset();
        };
    };

    useEffect(() => {
        api.get<UserData[]>(`/operators`)
            .then(res => {
                if (sectorMode) {
                    operators.set(res.data.filter(o => o.SETOR !== (user && user.SETOR) && o.CODIGO > 0 && o.ATIVO === "SIM"))
                } else {
                    operators.set(res.data.filter(o => o.CODIGO > 0 && o.ATIVO === "SIM"));
                };
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const options = operators.value.map(o => {
        return { name: o.NOME, value: o.CODIGO };
    });

    return (
        <StyledScheduleDialog>
            <header>
                <h2> Transferir: {readingAttendance.value?.CONTATO_NOME} </h2>
                <IoMdClose onClick={modalState.reset} />
            </header>

            <Select
                {...defaultSelect100}
                $fontSize={1}
                $padding={[0.5, 0.5]}
                $focusColor={cssVars.colorGrey[2]}
                $color={cssVars.colorGrey[3]}
                options={options}
                onChange={(v) => operatorValue.set(v)}
                placeholder="Selecione o atendente"
            />

            <RegistrationButton
                onClick={transferAttendance}
                disabled={!operatorValue.value}
                style={{ fontSize: '1rem' }}

            >
                Transferir
            </RegistrationButton>
        </StyledScheduleDialog>
    );
};