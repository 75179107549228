import styled from "styled-components";
import cssVars from "../../../styles/cssVariables.vars";

export const StyledMeusAtendimentos = styled.div`
    box-sizing: border-box;
    height: calc(100% - 4rem);

    display: flex;

    >.chat_box {
        box-sizing: border-box;
        height: 100%;
        width: calc(100% - 20rem);
        border-right: none;

        @media (min-width: 64rem) {
            width: calc(100% - 22rem);
        }

        @media (min-width: 80rem) {
            width: calc(100% - 24rem);
        }

        @media (min-width: 100rem) {
            width: calc(100% - 28rem);
        }

        >.no_attendances {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            gap: 1rem;

            >h1 {
                font-size: 1rem;
                font-weight: 500;
            }

            >img {
                width: 10rem;
                height: 10rem;
                filter: contrast(0.05) opacity(0.375);
            }
        }
    }

    >.attendances_menu {
        box-sizing: border-box;
        width: 20rem;
        height: 100%;
        background-color: ${cssVars.colorGrey["7"]};
        color: ${cssVars.colorGrey["2"]};

        @media (min-width: 64rem) {
            width: 22rem;
        }

        @media (min-width: 80rem) {
            width: 24rem;
        }

        @media (min-width: 100rem) {
            width: 28rem;
        }
    }
`;