import styled from "styled-components";
import cssVars from "../../../styles/cssVariables.vars";

export const StyledListItem = styled.li`
    border-radius: 0.25rem;
    padding: 0.375rem 0.5rem;
    border: 1px solid ${cssVars.colorGrey[4]};

    &:nth-child(odd) {
        background-color: ${cssVars.colorGrey["7"]};
    }
    &:nth-child(even) {
        background-color: ${cssVars.colorGrey["6h"]};
    }

    >header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        box-sizing: border-box;
        color: ${cssVars.colorGrey[2]};

        >div {
            display: flex;
            align-items: center;
            gap: 0.5rem;

            >h3 {
                font-size: 0.875rem;
                font-weight: 500;
                line-height: 100%;
            }
        }
    }

    >div {
        background-color: ${cssVars.colorGrey["8"]};
        border-radius: 0.375rem;
        
        font-size: 0.75rem;
        margin-top: 0.5rem;

        border: 1px solid ${cssVars.colorGrey[6]};

        >.text {
            display: flex;
            flex-direction: column;
            gap: 0.25rem;
            padding: 0.5rem;
        }

        >.file {
            padding: 0.25rem;
            border-top: 1px solid ${cssVars.colorGrey[5]};
            background-color: ${cssVars.colorGrey[7]};
            border-bottom-left-radius: 0.25rem;
            border-bottom-right-radius: 0.25rem;
            color: ${cssVars.colorGrey[2]};
            font-weight: 500;

            display: flex;
            align-items: center;
            gap: 0.25rem;
        }
    }
`;