import styled from "styled-components";
import cssVars from "../../styles/cssVariables.vars";

export const StyledTransferSectorDialog = styled.div`
    padding: 1rem;
    border-radius: 0.25rem;
    background-color: ${cssVars.colorGrey[8]};

    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    height: max-content;
    width: 22rem;
    box-sizing: border-box;

    >header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        >h2 {
            font-size: 1rem;
            font-weight: 500;
            width: max-content;
        }

        >svg {
            font-size: 1.5rem;
            cursor: pointer;

            :hover {
                transform: scale(1.25);
            }
        }
    }

    >button {
        font-size: 1rem;
        padding: 0.625rem 2rem;
        margin-top: 0.5rem;
    }
`;