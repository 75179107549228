import styled from "styled-components";
import cssVars from "../../../styles/cssVariables.vars";

export const StyledFileSendForm = styled.form`
    background-color: ${cssVars.colorGrey[8]};

    box-sizing: border-box;
    width: 100%;
    height: 100%;
    overflow: hidden;

    grid-area: chat;

    >header {
        box-sizing: border-box;
        padding: 1rem 1rem;

        background-color: ${cssVars.colorGrey["7h"]};

        display: flex;
        align-items: center;
        justify-content: center;

        position: relative;

        >button {
            position: absolute;
            left: 1rem;
            top: calc(50% - 1rem);
            cursor: pointer;
            font-size: 1.5rem;

            :hover {
                transform: scale(0.875);
            }
        }

        >h2 {
            font-weight: 500;
            font-size: 1rem;
        }
    }

    >main {
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
        height: calc(100% - 3.35rem);

        >.file_preview {
            display: flex;
            align-items: center;
            justify-content: center;

            box-sizing: border-box;
            height: calc(100% - 6rem);
            padding: 2rem 1rem;

            >:nth-child(1):not(svg) {
                max-height: 90%;
                max-width: 100%;
            }

            >svg {
                width: 4rem;
                height: 4rem;
            }

            >.no_preview{
                display: flex;
                flex-direction: column;
                gap: 2rem;
                align-items: center;
                border-radius: 1rem;
                padding: 4rem;

                >svg {
                    font-size: 4rem;
                }

                >span {
                    font-size: 1rem;
                }
            }
        }

        >.message_camp {
            position: relative;
            box-sizing: border-box;
            width: 50vw;
            height: 5.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1rem;

            >button.sendFile {
                box-sizing: border-box;
                border-radius: 50%;
                background-color: var(--color-primary);
                padding: 1rem;
                cursor: pointer;

                display: flex;
                align-items: center;
                justify-content: center;

                >:nth-child(1) {
                    color: ${cssVars.colorGrey[8]};
                    width: 1.5rem;
                    height: 1.5rem;
                    transform: translateX(-0.125rem);
                }

                :hover {
                    filter: brightness(1.25);
                }
            }
        }


    }
`;