import styled from "styled-components";
import cssVars from "../../styles/cssVariables.vars";

export const StyledMySchedulesDialog = styled.div`
    background-color: ${cssVars.colorGrey[8]};
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 50rem;
    height: 40rem;
    border-radius: 0.25rem;

    >header {
        box-sizing: border-box;
        padding: 0.5rem 1rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
        background-color: ${cssVars.colorPrimary};
        color: ${cssVars.colorGrey[8]};

        >h2 {
            font-size: 1rem;
            font-weight: 500;
        }

        >div {
            display: flex;
            align-items: center;
            gap: 0.5rem;

            >button {
                color: ${cssVars.colorGrey[8]};
                font-size: 1.25rem;

                :hover {
                    transform: scale(1.25);
                    cursor: pointer;
                }
            }
        }

    }

    >div {
        box-sizing: border-box;
        width: 100%;
        height: calc(100% - 3rem);
        padding: 1rem;

        >ul {
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            overflow-y: auto;

            width: 100%;
            height: 100%;
            gap: 0.5rem;
        }

        >.center {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            >h2 {
                font-size: 1rem;
                font-weight: 500;
            }
        }
    }
`;