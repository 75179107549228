import { StyledLoginPage } from "./style";
import logo from "../../logo.png";
import { InputWrapper, StyledInput } from "./InputWrapper";
import { FaUserCircle, FaLock } from "react-icons/fa";
import { useContext } from "react";
import { LoginContext } from "../../contexts/global/LoginContext";

export const LoginPage = () => {
    const {
        setName,
        setPassword,
        tryToLogin
    } = useContext(LoginContext);

    return (
        <StyledLoginPage>
            <form onSubmit={tryToLogin}>
                <img src={logo} alt="logo" />

                
                <InputWrapper>
                    <StyledInput 
                        placeholder="Usuário"
                        type="text"
                        onChange={(e) => setName(e.target.value)} 
                    />
                    <FaUserCircle />
                </InputWrapper>
                {/* <span className="invalid_field_alert"> Usuário inválido </span> */}
                
                <InputWrapper>
                    <StyledInput 
                        placeholder="Senha" 
                        type="password"
                        onChange={(e) => setPassword(e.target.value)}  
                    />
                    <FaLock />
                </InputWrapper>
                {/* <span className="invalid_field_alert"> Senha inválida </span> */}

                <button 
                    type="submit"
                > 
                    Acessar 
                </button>
            </form>
            <div></div>
        </StyledLoginPage>
    );
};