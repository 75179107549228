import { ReactNode, createContext } from "react";

interface ProviderProps {
    children: ReactNode;
}

interface ContextProps {
    variables: Array<Variable>;
    replaceVariables: (props: ReplaceVariablesOptions) => string;
}

interface ReplaceVariablesOptions {
    message: string;
    values: {
        currentSaudation: string;
        customerFullName: string;
        customerFirstName: string;
        customerRazao: string;
        customerCnpj: string;
        operatorNameExibition: string;
        operatorName: string;
    }
}

interface Variable {
    name: string;
    replaceFor: keyof ReplaceVariablesOptions["values"];
}

export const ReadyMessagesContext = createContext({} as ContextProps);

export const ReadyMessagesProvider = ({ children }: ProviderProps) => {
    const variables: Variable[] = [
        { name: "@saudação_tempo", replaceFor: "currentSaudation" },
        { name: "@cliente_razao", replaceFor: "customerRazao" },
        { name: "@cliente_cnpj", replaceFor: "customerCnpj" },
        { name: "@atendente_nome", replaceFor: "operatorName" },
        { name: "@atendente_nome_exibicao", replaceFor: "operatorNameExibition" },
        { name: "@contato_primeiro_nome", replaceFor: "customerFirstName" },
        { name: "@contato_nome_completo", replaceFor: "customerFullName" },
    ];

    function replaceVariables({
        message, values
    }: ReplaceVariablesOptions): string {
        variables.forEach((v) => {
            message = message.replaceAll(v.name, values[v.replaceFor])
        });

        return message;
    }

    return (
        <ReadyMessagesContext.Provider
            value={{
                variables,
                replaceVariables
            }}
        >
            {children}
        </ReadyMessagesContext.Provider>
    );
}