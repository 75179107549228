import { ReactNode, useContext, useEffect } from "react";
import { CustomState, CustomStateHook } from "../../Hooks/State.hook";
import { StyledTransferSectorDialog } from "./style";
import { RegistrationButton } from "../../styles/registrationButton";
import { IoMdClose } from "react-icons/io";
import { SocketContext } from "../../contexts/global/SocketContext";
import "react-calendar/dist/Calendar.css";
import "react-time-picker/dist/TimePicker.css";
import api from "../../services/api";
import { LoginContext, currentRoute } from "../../contexts/global/LoginContext";
import { SpecificAttendanceDetails } from "../../interfaces/Attendances.interfaces";
import { Sector } from "../../interfaces/RunningAttendances";
import Select from "../Select";
import { defaultSelect100 } from "../../components-variants/defaultInputs";
import cssVars from "../../styles/cssVariables.vars";

interface ScheduleDialogProps {
    modalState: CustomStateHook<ReactNode>;
    attendance: SpecificAttendanceDetails;
};

export const UpdateSectorDialog = ({ modalState, attendance }: ScheduleDialogProps) => {
    const { socket } = useContext(SocketContext);
    const { user } = useContext(LoginContext);

    const sectors = CustomState<Sector[]>([]);
    const selectedSector = CustomState<Sector | null>(null);

    function transferSector() {
        if (socket.value && user && selectedSector.value) {
            const data = {
                clientName: currentRoute,
                attendanceId: attendance.CODIGO,
                oldOperatorId: user.CODIGO,
                oldSectorId: attendance.CODIGO_SETOR,
                newSectorId: selectedSector.value.CODIGO,
            };

            socket.value.emit("update-sector", data);
            modalState.reset();
        };
    };

    useEffect(() => {
        api.get<Sector[]>(`/sectors/`)
            .then(res => sectors.set(res.data.filter(s => s.CODIGO !== attendance.CODIGO_SETOR)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <StyledTransferSectorDialog>
            <header>
                <h2> Transferir: {attendance.CONTATO_NOME} </h2>
                <IoMdClose onClick={modalState.reset} />
            </header>
            <div>
                <Select
                    {...defaultSelect100}
                    $fontSize={1}
                    $padding={[0.5, 0.5]}
                    options={sectors.value.map(s => { return { name: s.NOME, value: s } })}
                    placeholder="Selecione o setor"
                    onChange={(o) => selectedSector.set(o)}
                    $focusColor={cssVars.colorGrey[2]}
                    $color={cssVars.colorGrey[3]}
                />
            </div>
            <RegistrationButton
                onClick={transferSector}
                disabled={!selectedSector.value}
            >
                Transferir
            </RegistrationButton>
        </StyledTransferSectorDialog>
    );
};