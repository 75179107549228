import styled from "styled-components";
import cssVars from "../../styles/cssVariables.vars";

export const StyledQuickMessage = styled.div`
    border-radius: 0.25rem;
    width: 45rem;
    background-color: ${cssVars.colorGrey[8]};

    display: flex;
    flex-direction: column;

    >header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem 1rem;
        background: var(--color-primary);
        color: ${cssVars.colorGrey[8]};
        border-top-right-radius: 0.25rem;
        border-top-left-radius: 0.25rem;

        >button {
            color: ${cssVars.colorGrey[8]};
            font-size: 1.5rem;
            cursor: pointer;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            :hover {
                transform: scale(1.125);
            }
        }

        h2 {
            font-size: 1rem;
            font-weight: 600;
        }
    }

    >main {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 0.5rem;

        >ul {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            height: 30rem;
            overflow-y: auto;
        }

        >div.no-messages {
            display: flex;
            align-items: center;
            justify-content: center ;
            height: 30rem;
            padding: 0.5rem;
            font-size: 1.5rem;
        }
    }

    button.btn-send {
        width: 20rem;
        padding: 0.75rem;
    }
`; 