import { ReactNode, useContext, useEffect } from "react";
import { StyledFormulario } from "./style";
import { IoMdClose } from "react-icons/io";
import { CustomState } from "../../../../Hooks/State.hook";
import api from "../../../../services/api";
import { Sector } from "../../../../interfaces/RunningAttendances";
import { Modal } from "../../../../styles/modal";
import { VariablesMenu } from "../Variables";
import { CustomMessagesContext } from "../../../../contexts/admin/CustomMessages.context";
import { RegistrationButton } from "../../../../styles/registrationButton";
import 'react-phone-number-input/style.css';
import { CustomInput } from "../../../../components/CustomInput";
import { CustomSelect } from "../../../../components/CustomSelect";

interface FormProps {
    closeFunction: () => void;
};

export const Formulario = ({ closeFunction }: FormProps) => {
    const messageTitle = CustomState<string>("");
    const messageText = CustomState<string>("");
    const messageSector = CustomState<number>(0);
    const messageFile = CustomState<File | null>(null);
    const sectors = CustomState<Array<Sector>>([]);
    const modalState = CustomState<ReactNode>(null);

    const { createNewReadyMessage } = useContext(CustomMessagesContext);

    const isButtonEnabled: boolean = messageTitle.value.length >= 6 && messageText.value.length >= 10;

    async function handleForm() {
        createNewReadyMessage({
            SETOR: messageSector.value,
            TEXTO_MENSAGEM: messageText.value,
            TITULO: messageTitle.value,
            ARQUIVO: messageFile.value
        });

        closeFunction();
    };

    function handleFileSelect(e: React.ChangeEvent<HTMLInputElement>) {
        const file = e.target.files?.[0];

        if (file) {
            messageFile.set(file);
        } else {
            messageFile.set(null);
        };
    };

    const onChangeText: React.ChangeEventHandler<HTMLTextAreaElement> = (element) => {
        if (element.target.value.split('').reverse()[0] === "@") {
            modalState.set(
                <Modal>
                    <VariablesMenu modalState={modalState} textState={messageText} />
                </Modal>
            );
        }

        messageText.set(element.target.value);
    };

    useEffect(() => {
        api.get<Array<Sector>>(`/sectors`)
            .then((res) => sectors.set([{
                CODIGO: 0,
                IS_SALES: false,
                NOME: 'Todos',
                WHATSAPP_NUMERO: '',
                WHATSAPP_NUMERO_ID: ''
            }, ...res.data]))
            .catch((err) => console.error(err));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <StyledFormulario>
            <header>
                <h2> Cadastrar nova mensagem rápida </h2>
                <button
                    className="closeModal"
                    type="button"
                    onClick={closeFunction}
                >
                    <IoMdClose />
                </button>
            </header>

            <CustomInput
                fontSize={0.875}
                fontWeight={500}
                padding={{ horizontal: 0.5, vertical: 0.5 }}
                placeholder="Título da mensagem..."
                required
                onChange={(element) => messageTitle.set(element.target.value)}
                value={messageTitle.value}
                disableIcon
            />

            <CustomSelect
                fontSize={0.875}
                fontWeight={500}
                padding={{ horizontal: 0.5, vertical: 0.5 }}
                options={sectors.value.map(o => ({ name: o.NOME, value: o.CODIGO }))}
                onChange={(v) => messageSector.set(v)}
                selectMessage="Selecione o setor..."
            />

            <div>
                <textarea
                    id="register-ready-message"
                    placeholder="Texto da mensagem..."
                    required
                    onChange={onChangeText}
                    value={messageText.value}
                />
            </div>
            <div>
                <input
                    id="register-ready-message-file"
                    type="file"
                    onChange={handleFileSelect}
                />
            </div>
            <RegistrationButton
                className="enviar"
                onClick={() => handleForm()}
                disabled={!isButtonEnabled}
            >
                Cadastrar
            </RegistrationButton>
            {
                modalState.value
            }
        </StyledFormulario>
    );
};