import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { StyledPageNavigation } from "./styles";
import { CustomStateHook } from "../../Hooks/State.hook";

interface PageNavigationProps {
    currentPage: CustomStateHook<number>;
    previousPage: CustomStateHook<string | null>;
    nextPage: CustomStateHook<string | null>;
    className?: string;
};

export const PageNavigation = ({ currentPage, previousPage, nextPage, className }: PageNavigationProps) => {
    function handleChangeButton(str: "+" | "-") {
        const changeEvent = () => {
            if (str === "+" && nextPage.value) {
                currentPage.set((prev) => prev + 1);
            } else if (str === "-" && previousPage.value) {
                currentPage.set((prev) => prev - 1);
            };
        };

        const isDisabled = () => {
            if (str === "+" && nextPage.value) {
                return false
            } else if (str === "-" && previousPage.value) {
                return false
            };

            return true
        };

        return { changeEvent, isDisabled }
    };

    return (
        <StyledPageNavigation {...{ className }}>
            <button
                onClick={handleChangeButton("-").changeEvent}
                disabled={handleChangeButton("-").isDisabled()}
            >
                <FaAngleLeft />
            </button>
            <input
                type="number"
                value={currentPage.value}
                disabled
            />
            <button
                onClick={handleChangeButton("+").changeEvent}
                disabled={handleChangeButton("+").isDisabled()}
            >
                <FaAngleRight />
            </button>
        </StyledPageNavigation>
    );
};