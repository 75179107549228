import { FilterWithPaginationQueryParameters } from "../../interfaces/generics.types";
import { TableColumn } from "./types";

export function orderBy<T>(
    rows: T[],
    column: keyof T,
    ASC: boolean
): T[] {
    return [...rows].sort((a, b) => {
        const valueA = a[column];
        const valueB = b[column];

        switch (typeof valueA) {
            case 'string':
                return ASC ? (valueA as string).localeCompare(valueB as string) : (valueB as string).localeCompare(valueA as string);
            case 'number':
                return ASC ? (valueA as number) - (valueB as number) : (valueB as number) - (valueA as number);
            case 'object':
                return ASC ? (valueA as Date).getTime() - (valueB as Date).getTime() : (valueB as Date).getTime() - (valueA as Date).getTime();
            default:
                return ASC ? (valueA < valueB ? -1 : valueA > valueB ? 1 : 0) : (valueA > valueB ? -1 : valueA < valueB ? 1 : 0);
        }
    });
}

export function filter<T>(
    rows: T[],
    filters: FilterWithPaginationQueryParameters<T>,
    columns: TableColumn<T>[],
): T[] {
    const filteredRows = rows.filter(row => {
        return columns.every(column => {
            const value = filters[column.key];
            const columnValue = row[column.key];

            if (value === undefined || value === null || !column.filter) {
                return true;
            }

            const filterValue = value?.toLowerCase();

            switch (column.filter.type) {
                case "input":
                    return (columnValue as string)?.toLowerCase().includes(filterValue);
                case "select":
                    return value === (columnValue as string);
                case "multi-select":
                    return value?.split(";").includes(columnValue as string);
                case "date-range":
                    const [startStr, endStr] = value?.split("_");
                    const startDate = startStr ? Number(startStr) : 0;
                    const endDate = endStr ? Number(endStr) : Date.now();

                    return Number(columnValue) >= startDate && Number(columnValue) <= endDate;
                default:
                    return true;
            }
        });
    });

    return filteredRows;
}
