import { useEffect, useState } from "react";
import { StyledInProgressAttendances } from "./style";
import { TableItem } from "./TableItem";
import api from "../../../../services/api";
import { FinishedAttendanceDetails } from "../../../../interfaces/Attendances.interfaces";
import Input from "../../../../components/Input";
import MultiSelect from "../../../../components/MultiSelect";
import { defaultInput100, defaultSelect100 } from "../../../../components-variants/defaultInputs";

export const FinishedAttendances = () => {
    const [attendances, setAttendances] = useState<Array<FinishedAttendanceDetails>>([]);
    const operators = Array.from(new Set(attendances.map(a => a.OPERADOR)));
    const types = Array.from(new Set(attendances.map(a => a.TIPO)));

    const [filterOperator, setFilterOperator] = useState<string[]>([]);
    const [filterType, setFilterType] = useState<string[]>([]);
    const [filterId, setFilterId] = useState<string>("");
    const [filterContact, setFilterContact] = useState<string>("");
    const [filterCustomer, setFilterCustomer] = useState<string>("");
    const [filterNumber, setFilterNumber] = useState<string>("");

    const operatorsOptions = operators.map(o => ({ name: o, value: o }));
    const typesOptions = types.map(t => ({ name: t, value: t }));

    const filteredAttendances = attendances.filter((a) => {
        return (filterOperator.length ? filterOperator.includes(a.OPERADOR) : true) &&
            (filterType.length ? filterType.includes(a.TIPO) : true) &&
            (filterId ? String(a.CODIGO) === filterId : true) &&
            (filterContact ? a.CONTATO_NOME?.toUpperCase().includes(filterContact) : true) &&
            (filterCustomer ? (a.RAZAO || "").toUpperCase().includes(filterCustomer) : true)
    });

    useEffect(() => {
        api.get<Array<FinishedAttendanceDetails>>(`/custom-routes/finished-attendances`)
            .then((res) => {
                res.data.forEach(a => {
                    a.DATA_INICIO = new Date(a.DATA_INICIO);
                    a.DATA_FIM = new Date(a.DATA_FIM!);
                });
                setAttendances(res.data)
            });
    }, []);

    return (
        <StyledInProgressAttendances>
            <header>
                <div className="actionsh">
                    Ações
                </div>
                <div className="codah search">
                    <Input
                        placeholder="ID"
                        value={filterId}
                        onChange={(e) => setFilterId(e.target.value.toUpperCase())}
                        {...defaultInput100}
                        $disableBorder
                        $fontSize={0.75}
                    />
                </div>
                <div className="operatorh search">
                    <MultiSelect
                        options={operatorsOptions}
                        onChange={(v) => setFilterOperator(v)}
                        placeholder="Operador"
                        $disableBorder
                        {...defaultSelect100}
                        $fontSize={0.75}
                    />
                </div>
                <div className="clientnameh search">
                    <Input
                        placeholder="Contato"
                        value={filterContact}
                        onChange={(e) => setFilterContact(e.target.value.toUpperCase())}
                        {...defaultInput100}
                        $disableBorder
                        $fontSize={0.75}
                    />
                </div>
                <div className="numberh search">
                    <Input
                        placeholder="Numero"
                        value={filterNumber}
                        onChange={(e) => setFilterNumber(e.target.value.replace(/\D/g, ""),)}
                        {...defaultInput100}
                        $disableBorder
                        $fontSize={0.75}
                    />
                </div>
                <div className="clientcompanyh search">
                    <Input
                        placeholder="Razão Social"
                        value={filterCustomer}
                        onChange={(e) => setFilterCustomer(e.target.value.toUpperCase())}
                        {...defaultInput100}
                        $disableBorder
                        $fontSize={0.75}
                    />
                </div>
                <div className="startdateh">
                    Iniciado
                </div>
                <div className="enddateh">
                    Finalizado
                </div>
                <div className="atypeh search">
                    <MultiSelect
                        options={typesOptions}
                        onChange={(v) => setFilterType(v)}
                        placeholder="Tipo"
                        $disableBorder
                        {...defaultSelect100}
                        $fontSize={0.75}
                    />
                </div>
            </header>
            <div>
                {
                    filteredAttendances.map((attendance, index) => <TableItem attendance={attendance} key={index} />)
                }
            </div>
        </StyledInProgressAttendances>
    );
};