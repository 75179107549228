export default function messageDate(string: string, dateFormat?: boolean): string {

    if (!string) return "Nunca";

    const mdate = new Date(string);
    const today = new Date();

    const toStringDate = (date: Date) => `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`;
    const toStringDatePlusOneDay = (date: Date) => `${date.getDate() + 1}/${date.getMonth()}/${date.getFullYear()}`;
    const isMfromToday: boolean = toStringDate(mdate) === toStringDate(today);
    const isMfromYesterday: boolean = toStringDatePlusOneDay(mdate) === toStringDate(today);

    const hours = mdate.getHours() >= 10 ? mdate.getHours() : `0${mdate.getHours()}`;
    const minutes = mdate.getMinutes() >= 10 ? mdate.getMinutes() : `0${mdate.getMinutes()}`;
    const day = mdate.getDate() >= 10 ? mdate.getDate() : `0${mdate.getDate()}`;
    const month = mdate.getMonth() >= 9 ? mdate.getMonth() + 1 : `0${mdate.getMonth() + 1}`;
    const dateString = `${day}/${month}/${mdate.getFullYear()}`;
    const timeString = `${hours}:${minutes}`;

    if (dateFormat) return `${dateString} ${timeString}`;

    if (isMfromToday) {
        return timeString;
    } else if (isMfromYesterday) {
        return 'Ontem';
    } else {
        return dateString;
    };
};