import { ReactNode, useContext, useEffect, useRef } from "react";
import { FaFile, FaPaperPlane } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { ChatContext } from "../../../contexts/user/ChatContext";
import { StyledFileSendForm } from "./style";
import { CustomState } from "../../../Hooks/State.hook";
import { SpecificAttendanceDetails } from "../../../interfaces/Attendances.interfaces";
import Input from "../../Input";
import { defaultInput100 } from "../../../components-variants/defaultInputs";

interface Props {
    attendance: SpecificAttendanceDetails
}

export const FileSendForm = ({ attendance }: Props) => {
    const {
        closeFile,
        sendMessage,
        formData
    } = useContext(ChatContext);

    const filePreview = CustomState<ReactNode>("");
    const textInputRef = useRef<HTMLInputElement>(null);
    const filePreviewRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!formData.value.isAudio) {
            const file = formData.value.file;

            if (filePreviewRef.current) {
                filePreviewRef.current.innerHTML = "";
            };

            if (!file) {
                filePreview.set(<></>)
            } else if (file && filePreviewRef) {
                const isImage = file.type.includes("image");
                const isVideo = file.type.includes("video");
                const isAudio = file.type.includes("audio");

                if (isImage) {
                    const img = document.createElement("img");
                    img.src = URL.createObjectURL(file);
                    img.alt = file.name;

                    filePreviewRef.current?.appendChild(img);
                } else if (isVideo) {
                    const video = document.createElement("video");
                    const source = document.createElement("source");
                    source.src = URL.createObjectURL(file);
                    source.type = file.type;
                    video.appendChild(source);
                    video.controls = true;

                    filePreviewRef.current?.appendChild(video);
                } else if (isAudio) {
                    const audio = document.createElement("audio");
                    audio.controls = true;
                    audio.src = URL.createObjectURL(file);

                    filePreviewRef.current?.appendChild(audio);
                } else {
                    filePreview.set(
                        <div className="no_preview">
                            <FaFile />
                            <span> Sem prévia disponível...</span>
                        </div>
                    );
                };
            };
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData.value.file]);

    function onClickSend() {
        sendMessage(attendance);
        closeFile();
    };

    //const fileType = formData.value.file?.type

    return (
        <StyledFileSendForm onSubmit={(e) => {
            e.preventDefault();
            onClickSend();
        }}>
            <header>
                <button type="button" onClick={closeFile}>
                    <IoMdClose />
                </button>
                <h2> {formData.value.file?.name} </h2>
            </header>

            <main>
                <div
                    className="file_preview"
                    ref={filePreviewRef}
                >
                    {filePreview.value}
                </div>
                <div className="message_camp">
                    <Input
                        placeholder="Digite sua mensagem..."
                        value={formData.value.text}
                        onChange={(e) => formData.set((prev) => {
                            return { ...prev, text: e.target.value }
                        })}
                        onSubmit={(e) => e.preventDefault()}
                        ref={textInputRef}
                        {...defaultInput100}
                        $padding={[1, 1]}
                    />
                    <button
                        className="sendFile"
                        onClick={onClickSend}
                        type="button"
                    >
                        <FaPaperPlane />
                    </button>
                </div>
            </main>
        </StyledFileSendForm>
    );
};