import { ReactNode } from "react";
import { StyledModalBasis, StyledModalBasisProps } from "./style";
import { IoMdClose } from "react-icons/io";

interface ModalBasisProps extends StyledModalBasisProps {
    title: string;
    children: ReactNode;
    onClose: () => void;
}

export const ModalBasis = ({ title, children, onClose, ...p }: ModalBasisProps) => {
    return (
        <StyledModalBasis
            size={{ h: p.size?.h, w: p.size?.w }}
            padding={{ h: p.padding?.h, v: p.padding?.v }}
            borderRadius={p.borderRadius}
            fontSize={p.fontSize}
            coloredHeader={p.coloredHeader}
        >
            <header>
                <h2> {title} </h2>
                <IoMdClose onClick={onClose} />
            </header>
            <div className="content">
                {children}
            </div>
        </StyledModalBasis>
    );
}