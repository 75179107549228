import { createContext, ReactNode, useState } from "react";
import { SupervisorContextProprieties, SupervisorProviderProprieties } from "../../interfaces/Supervisor.interfaces";
import { CustomState } from "../../Hooks/State.hook";

export const SupervisorContext = createContext({} as SupervisorContextProprieties);

export const SupervisorProvider = ({ children }: SupervisorProviderProprieties) => {
    const [page, setPage] = useState<ReactNode>("");
    const [selectedMenu, setSelectedMenu] = useState<number>(0);
    const modalState = CustomState<ReactNode>("");

    function handleSelection(num: number, page: ReactNode) {
        setSelectedMenu(num);
        setPage(page);
    };

    const handleSelectionClass = (num: number) => num === selectedMenu ? "selected" : "";

    const selectedPageTitle = [
        "Painel do supervisor -> Início",
        "Painel do supervisor -> Monitoria",
        "Painel do supervisor -> Cadastros",
        "Painel do supervisor -> Mensagens Prontas",
        "Painel do supervisor -> Templates Whatsapp",
        "Painel do supervisor -> Atendimentos",
        "Painel do supervisor -> Ferramentas",
        "Painel do supervisor -> Relatórios",
    ][selectedMenu];

    return (
        <SupervisorContext.Provider value={{
            page,
            selectedMenu,
            handleSelection,
            handleSelectionClass,
            selectedPageTitle,
            modalState
        }}>
            {children}
        </SupervisorContext.Provider>
    );
};