import { StyledAttendanceView } from "./style"
import { ClientInfo } from "./ClientInfo";
import { AttendanceButtons } from "./AttendanceButtons";
import { AttendanceChat } from "../../../components/AttendanceChat";
import { useContext } from "react";
import { AttendancesContext } from "../../../contexts/user/AttendancesContext";

export const AttendanceView = () => {

    const { readingAttendance, pausedState } = useContext(AttendancesContext);

    return (
        <StyledAttendanceView>
            <div>
                <ClientInfo />
                <AttendanceButtons />
            </div>
            <AttendanceChat attendance={readingAttendance.value!} desactivateChat={pausedState.value} />
        </StyledAttendanceView>
    );
};