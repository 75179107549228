import React from 'react';
import Linkify from 'react-linkify';

export const renderMessageWithLinks = (text: string): JSX.Element => {
  const componentDecorator = (href: string, text: string, key: number) => (
    <a href={href} key={key} target="_blank" rel="noopener noreferrer">
      {text}
    </a>
  );

  return (
    <div>
      <Linkify componentDecorator={componentDecorator}>{text}</Linkify>
    </div>
  );
};
