import { useContext } from "react";
import { StyledListaDeClientes } from "./style";
import { TableItem } from "./TableItem";
import { CustomerContext } from "../../../../../contexts/admin/Customers.context";
import { PageNavigation } from "../../../../../components/PageNavigation";

export const ListaDeClientes = () => {
    const { customersState, currentPage, nextPage, previousPage } = useContext(CustomerContext);
    return (
        <StyledListaDeClientes>
            <header>
                <div>

                </div>
                <div>
                    Código
                </div>
                <div>
                    Ativo
                </div>
                <div>
                    Pessoa
                </div>
                <div>
                    Razão Social
                </div>
                <div>
                    CPF / CNPJ
                </div>
                <div>
                    Cidade
                </div>
                <div>
                    ERP
                </div>
            </header>
            <ul>
                {customersState.value.map((customer) => <TableItem customer={customer} />)}
            </ul>
            <PageNavigation
                currentPage={currentPage}
                nextPage={nextPage}
                previousPage={previousPage}
            />
        </StyledListaDeClientes>
    );
};