import styled from "styled-components";
import cssVars from "../../../../../styles/cssVariables.vars";

export const StyledFormulario = styled.form`
    background-color: ${cssVars.colorGrey[8]};
    padding: 1rem;
    border-radius: 0.25rem;
    width: 20rem;
    position: relative;

    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    >header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        >h1 {
            font-size: 0.875rem;
            font-weight: 500;
        }

        >button.closeModal {
            font-size: 1.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0.25rem;
            transition: 50ms;
            cursor: pointer;
            width: max-content;
            height: max-content;
            color: ${cssVars.colorGrey[1]};

            :hover {
                transform: scale(1.25);
            }
        }
    }   

    >button[type="submit"]{
        grid-area: button;
        box-sizing: border-box;
  

        border-radius: 0.25rem;
        color: white;
        font-size: 0.875rem;
        font-weight: 500;
        cursor: pointer;
        margin-top: 0.5rem;

        :hover {
            transform: scale(1.00675);
        }
    }
`;
