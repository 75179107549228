import { ReactNode, useContext } from "react";
import { CustomState, CustomStateHook } from "../../Hooks/State.hook";
import { StyledScheduleDialog } from "./style";
import { RegistrationButton } from "../../styles/registrationButton";
import { IoMdClose } from "react-icons/io";
import { SocketContext } from "../../contexts/global/SocketContext";
import { CustomSelect } from "../CustomSelect";
import formatarNome from "../../Hooks/FormatName.hook";
import { currentRoute } from "../../contexts/global/LoginContext";

interface ScheduleDialogProps {
    modalState: CustomStateHook<ReactNode>;
    CODIGO_ATENDIMENTO: number;
};

export const UpdatePriorityDialog = ({ modalState, CODIGO_ATENDIMENTO }: ScheduleDialogProps) => {

    const { socket } = useContext(SocketContext);
    const urgenciaState = CustomState("NORMAL");

    function updateUrgency() {
        if (socket.value) {
            const data = {
                CLIENTE: currentRoute,
                CODIGO_ATENDIMENTO,
                URGENCIA: urgenciaState.value,
                MODO: "ADMIN",
            };

            socket.value.emit("update-urgency", data);
            modalState.reset();
        };
    };

    const priorityArr = ["NORMAL", "ALTA", "MUITO_ALTA", "URGENTE"]
    const options = priorityArr.map((v) => ({
        value: v, name: formatarNome(v.replace("_", " "))
    }));

    return (
        <StyledScheduleDialog>
            <header>
                <h2> Alterar Prioridade </h2>
                <IoMdClose onClick={modalState.reset} />
            </header>

            <CustomSelect
                fontSize={0.875}
                padding={{ horizontal: 1, vertical: 0.5 }}
                onChange={(v) => urgenciaState.set(v)}
                selectMessage="Selecione a prioridade"
                options={options}
            />

            <RegistrationButton
                onClick={updateUrgency}
                style={{ fontSize: '1rem' }}
            >
                Atualizar
            </RegistrationButton>
        </StyledScheduleDialog>
    );
};