import { CustomStateHook } from "../../../../Hooks/State.hook";
import Table from "../../../../components/Table";
import { TableColumn } from "../../../../components/Table/types";
import { UserMessages } from "./types";

const columns: TableColumn<UserMessages>[] = [
    {
        key: "userId",
        header: "Código",
        primarykey: true,
        width: 6
    },
    {
        key: "userName",
        header: "Nome",
        width: 20,
    },
    {
        key: "userActive",
        header: "Ativo",
        width: 6,
        format: (r) => r.userActive === "SIM" ? "Sim" : "Não",
        filter: {
            type: "multi-select",
            width: "calc(50% - 0.5rem)",
            options: [
                { name: "Sim", value: "SIM" },
                { name: "Não", value: "NAO" },
            ],
        }
    },
    {
        key: "userType",
        header: "Nível",
        width: 10,
        format: (r) => {
            switch (r.userType) {
                case "ATIVO":
                    return "Ativo";
                case "RECEP":
                    return "Receptivo";
                case "AMBOS":
                    return "Ambos";
                case "ADMIN":
                    return "Supervisor";
                default:
                    return "N/D"
            }
        },
        filter: {
            type: "multi-select",
            width: "calc(50% - 0.5rem)",
            options: [
                { name: "Supervisor", value: "ADMIN" },
                { name: "Ativo", value: "ATIVO" },
                { name: "Receptivo", value: "RECEP" },
                { name: "Ambos", value: "AMBOS" },
            ],
        }
    },
    {
        key: "userSector",
        header: "Setor",
        width: 10,
        filter: {
            type: "select",
            width: "calc(50% - 0.5rem)"
        }
    },
    {
        key: "messagesCount",
        header: "Mensagens",
        width: 6,
    },
    {
        key: "sentMessagesCount",
        header: "Enviadas",
        width: 6,
    },
    {
        key: "receivedMessagesCount",
        header: "Recebidas",
        width: 6,
    }
];

type Props = {
    rowsState: CustomStateHook<UserMessages[]>;
    filteredRowsState: CustomStateHook<UserMessages[]>;
}

const UsersMessagesTable = ({ rowsState, filteredRowsState }: Props) => {
    return (
        <Table
            columns={columns}
            rowsState={rowsState}
            filteredRowsState={filteredRowsState}
            $fontSize={0.75}
            $modalFiltersWidth={40}
            $tableHeight="max-content"
            $tableWidth="calc(100% - 6px)"
            tableName="Mensagens por usuário"
        />
    );
}

export default UsersMessagesTable;