import { useState } from "react";
import { AppointmentsSchedules } from "./AtendimentosAgendados";
import { FinishedAttendances } from "./AtendimentosFinalizados";
import { InProgressAttendances } from "./AtendimentosEmAndamento";
import { StyledMonitoria } from "./style"
import { Operadores } from "./Operadores";
import { IoMdChatbubbles } from "react-icons/io";
import { RiChatCheckFill } from "react-icons/ri"
import { FaCalendar, FaUsers } from "react-icons/fa";

export const Monitoria = () => {
    const [tab, setTab] = useState(0);
    const handleSelected = `selected_${tab}`;

    const tabComponents = [
        <InProgressAttendances />,
        <FinishedAttendances />,
        <AppointmentsSchedules />,
        <Operadores />
    ];

    return (
        <StyledMonitoria>
            <div>
                <nav className={handleSelected}>
                    <button onClick={() => setTab(0)}>
                        <span> <IoMdChatbubbles /> Em andamento </span>
                    </button>
                    <button onClick={() => setTab(1)}>
                        <span> <RiChatCheckFill /> Finalizados </span>
                    </button>
                    <button onClick={() => setTab(2)}>
                        <span> <FaCalendar /> Agendados </span>
                    </button>
                    <button onClick={() => setTab(3)}>
                        <span> <FaUsers /> Operadores </span>
                    </button>
                </nav>
            </div>
            {tabComponents[tab]}
        </StyledMonitoria>
    );
};