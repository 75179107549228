import { createGlobalStyle, css } from "styled-components";

const darkMode = css`
    --color-primary: rgb(245, 90, 75);
    --color-primary-o: rgba(245, 90, 75, 0.5);
    --color-primary-so: rgba(245, 90, 75, 0.25);
    --color-grey-8: rgba(21,23,26,1.0);
    --color-grey-7-h: rgba(33,37,41,1.0);
    --color-grey-7: rgba(43,47,51,1.0);
    --color-grey-6-h: rgba(73,80,87,1.0);
    --color-grey-6: rgba(134,142,150,1.0);
    --color-grey-5: rgba(173,181,189,1.0);
    --color-grey-4: rgba(180,185,190,1.0);
    --color-grey-3: rgba(195,200,205,1.0);
    --color-grey-2: rgba(210,215,220,1.0);
    --color-grey-1: rgba(225,230,235,1.0);
    --color-grey-0: rgba(240,245,250,1.0);

    --color-grey-0-o: rgba(248, 249, 250, 0.125);

    --color-btn-schedules: rgb(55, 155, 255); 
    --color-btn-pause: rgb(55, 255, 105);
    --color-btn-resume: rgb(255, 105, 55);
    --color-btn-register-contacts: rgba(155, 75, 255);
    --color-btn-register-customers: rgb(255, 105, 155);
    --color-btn-mass-messages: rgb(55, 205, 205);

    * {
        color-scheme: dark;
    }

    tspan {
        fill: rgba(255, 255, 255, 1);
    }
`;

const lightMode = css`
    --color-primary: rgb(245, 90, 75);
    --color-primary-o: rgba(245, 90, 75, 0.5);
    --color-primary-so: rgba(245, 90, 75, 0.25);
    --color-grey-0: #15171A;
    --color-grey-1: #212529;
    --color-grey-2: #2B2F33;
    --color-grey-3: #495057;
    --color-grey-4: #868E96;
    --color-grey-5: #ADB5BD;
    --color-grey-6: #CED4DA;
    --color-grey-6-h: #DEE2E6;
    --color-grey-7: #F0F1F3;
    --color-grey-7-h: #F1F3F5;
    --color-grey-8: #F8F9FA;

    --color-grey-0-o: rgba(21, 23, 27, 0.125);

    --color-btn-schedules: rgb(33, 150, 243); 
    --color-btn-pause: rgb(76, 175, 80);
    --color-btn-resume: rgb(255, 92, 51);
    --color-btn-register-contacts: rgba(103, 58, 183);
    --color-btn-register-customers: rgb(170, 55, 175);
    --color-btn-mass-messages: rgb(25, 155, 175);
`;

export const GlobalStyles = createGlobalStyle<{ darkMode: boolean }>`
    :root {
        --color-grey: #464343;
        --color-primary-1: rgb(225, 81, 63);
        --color-primary-1-o: rgba(225, 81, 63, 0.75);
        --color-secondary-1: rgba(115, 81, 245);

        ${props => props.darkMode ? darkMode : lightMode}
    }

    body {
        font-family: 'Inter', sans-serif;
        font-family: 'Rethink Sans', sans-serif;
        color: var(--color-grey-2);
    }

    &::-webkit-scrollbar {
            width: 0.75rem;
            height: 0.75rem;
            background-color: transparent;
        }

    &::-webkit-scrollbar-thumb {
        background: var(--color-primary);
    }
`;
