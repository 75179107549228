import { IoMdClose } from "react-icons/io";
import { AttendancesContext } from "../../contexts/user/AttendancesContext";
import { StyledQuickMessage } from "./style";
import { useContext, useEffect, ReactNode } from "react";
import { CustomState } from "../../Hooks/State.hook";
import api from "../../services/api";
import { RegistrationButton } from "../../styles/registrationButton";
import { SocketContext } from "../../contexts/global/SocketContext";
import { toast } from "react-toastify";
import { OficialWhatsappMessageTemplate, RecoverTemplatesResponse } from "../../interfaces/MessageTemplates.interfaces";
import { FaEye } from "react-icons/fa";
import { ViewMessageTemplate } from "../ViewMessageTemplate";
import { Modal } from "../../styles/modal";
import { LoginContext, currentRoute } from "../../contexts/global/LoginContext";
import formatarCPForCNPJ from "../../Hooks/FormatCPFCNPJ.hook";
import formatarNome from "../../Hooks/FormatName.hook";
import { SpecificAttendanceDetails } from "../../interfaces/Attendances.interfaces";

interface Props {
    attendance: SpecificAttendanceDetails;
}

export const QuickTemplate = ({ attendance }: Props) => {
    const { readingAttendance, modalState } = useContext(AttendancesContext);
    const { socket } = useContext(SocketContext);
    const { user } = useContext(LoginContext);

    const templates = CustomState<Array<OficialWhatsappMessageTemplate>>([]);
    const selectedTemplate = CustomState<OficialWhatsappMessageTemplate | null>(null);
    const modal = CustomState<ReactNode>("");

    useEffect(() => {
        api.get<RecoverTemplatesResponse>(`/whatsapp-cloud-api/templates`)
            .then(res => {
                if (res.data.data) {
                    templates.set(res.data.data.filter(t => t.status.toUpperCase() === "APPROVED"));
                };
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const currentSaudation = () => {
        const currentTime = new Date();
        const hour = currentTime.getHours();

        if (hour >= 5 && hour < 12) {
            return "Bom dia";
        } else if (hour >= 12 && hour < 18) {
            return "Boa tarde";
        } else {
            return "Boa noite";
        }
    }

    function sendTemplate() {
        if (socket.value && selectedTemplate.value && user) {
            const contactName = readingAttendance.value!.CONTATO_NOME || "CONTATO EXCLUÍDO";

            socket.value.emit('send-template', {
                clientName: currentRoute,
                operatorId: user.CODIGO,
                attendanceId: attendance.CODIGO,
                contactId: attendance.CODIGO_NUMERO,
                customerId: attendance.CODIGO_CLIENTE,
                localId: null,
                template: selectedTemplate.value,
                variables: {
                    "saudação_tempo": currentSaudation(),
                    "cliente_cnpj": formatarCPForCNPJ(attendance.CPF_CNPJ),
                    "cliente_razao": attendance.RAZAO,
                    "contato_primeiro_nome": contactName.split(" ")[0],
                    "contato_nome_completo": contactName,
                    "atendente_nome": formatarNome(user.NOME),
                    "atendente_nome_exibição": formatarNome(user.NOME_EXIBICAO)
                }
            });

            modalState.reset();
            toast.success("Template enviada com sucesso!");
        } else {
            toast.error("fallha ao enviar template...");
        };

    };

    return (
        <StyledQuickMessage>
            <header>
                <h2>
                    Enviar template WhatsApp:
                </h2>
                <button onClick={modalState.reset}>
                    <IoMdClose />
                </button>
            </header>
            <main>
                <ul>
                    {
                        templates.value.map(t => {
                            return (
                                <li className="template_item">
                                    <div className="check">
                                        <input
                                            type="radio"
                                            checked={
                                                selectedTemplate.value?.id === t.id
                                            }
                                            onClick={() => selectedTemplate.set(t)}
                                        />
                                    </div>
                                    <div className="name">
                                        {t.name}
                                    </div>
                                    <div className="view">
                                        <button onClick={() => modal.set(<ViewMessageTemplate messageTemplate={t} modal={modal} />)}>
                                            <FaEye />
                                        </button>
                                    </div>
                                </li>
                            );
                        })
                    }
                </ul>
                <RegistrationButton
                    onClick={sendTemplate}
                    disabled={!selectedTemplate.value}
                    className="btn-send"
                >
                    Enviar
                </RegistrationButton>
            </main>
            {
                modal.value &&
                <Modal>
                    {modal.value}
                </Modal>
            }
        </StyledQuickMessage>
    );
};