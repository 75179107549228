import { StyledCustomInput, StyledCustomInputProps } from "./style";
import { FaSearch } from "react-icons/fa";

type CustomInputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & StyledCustomInputProps;

export const CustomInput = ({ fontSize, fontWeight, divWidth, padding, disableBorder, disableIcon, whiteMode, disabled, ...inputProps }: CustomInputProps) => {
    return (
        <StyledCustomInput
            fontSize={fontSize}
            padding={padding}
            disableBorder={disableBorder}
            whiteMode={whiteMode}
            divWidth={divWidth}
            disableIcon={disableIcon}
            disabled={disabled}
            fontWeight={fontWeight}
        >
            {!disableIcon && <FaSearch />}
            <input {...inputProps} />
        </StyledCustomInput>
    )
}