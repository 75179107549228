import { IoMdClose } from "react-icons/io";
import { OficialWhatsappMessageTemplate } from "../../interfaces/MessageTemplates.interfaces";
import { StyledViewMessageTemplate } from "./stye"
import { SupervisorContext } from "../../contexts/admin/Supervisor.context";
import react, { useContext } from "react";
import { CustomStateHook } from "../../Hooks/State.hook";
import { TableItem } from "./TableItem";

interface Props {
    messageTemplate: OficialWhatsappMessageTemplate;
    modal?: CustomStateHook<react.ReactNode>;
    disableVars?: boolean;
};

export const ViewMessageTemplate = ({ messageTemplate, modal, disableVars }: Props) => {
    const { modalState } = useContext(SupervisorContext);

    return (
        <StyledViewMessageTemplate>
            <header>
                <h2>
                    Visualizando template: <b> {messageTemplate.name} </b>
                </h2>
                <button onClick={() => {
                    if (modal) modal.reset();
                    else modalState.reset();
                }}>
                    <IoMdClose />
                </button>
            </header>
            <div>

                {
                    messageTemplate.components.map((c, i) => <TableItem key={`ti_${i}`} c={c} disableVars={disableVars} />)
                }
            </div>
        </StyledViewMessageTemplate>
    );
};