import { useContext } from "react";
import { UsersRegistrationContext } from "../../../../../contexts/admin/UsersRegistration.context";
import { StyledListaDeAgentes } from "./style";
import { TableItem } from "./TableItem";

export const ListaDeAgentes = () => {
    const { operators } = useContext(UsersRegistrationContext);

    return (
        <StyledListaDeAgentes>
            <header>
                <div></div>
                <div>
                    ID
                </div>
                <div>
                    Ativo
                </div>
                <div>
                    Nível
                </div>
                <div>
                    Nome
                </div>
                <div >
                    Login
                </div>
                <div >
                    Nome de Exibição
                </div>
                <div className="setor">
                    Setor
                </div>
            </header>
            <ul>
                {
                    operators.value.map(operator => <TableItem operador={operator} key={operator.CODIGO} />)
                }
            </ul>
        </StyledListaDeAgentes>
    );
};