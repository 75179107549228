import styled from "styled-components";

export interface StyledCustomSelectProps {
    padding: {
        horizontal: number;
        vertical: number;
    }
    fontSize: number;
    fontWeight?: number;
    disableBorder?: boolean;
    disabled?: boolean;
}

export const StyledCustomSelect = styled.div<StyledCustomSelectProps>`
    position: relative;
    box-sizing: border-box;
    width: 100%;
    ${(props) => !props.disabled && `padding: ${props.padding.vertical}rem ${props.padding.horizontal}rem;`}

    ${(props) => !props.disableBorder && "border: 2px solid rgba(0, 0, 0, 20%);"}
    border-radius: 0.25rem;
    user-select: none;

    ${(props) => props.disabled && 'color: rgba(0,0,0, 0.6);'}

    :hover {
        ${props => !props.disabled && 'background-color: rgba(0, 0, 0, 5%);'}
    }

    >div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        ${props => !props.disabled && 'cursor: pointer;'}

        >p {
            font-size: ${(props) => props.fontSize + "rem"};
            font-weight: ${(props) => props.fontWeight ? props.fontWeight : 500};
        }

        >svg {
            font-size: ${(props) => props.fontSize + "rem"};
        }
    }

    >ul {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: ${props => `${(props.padding.vertical * 2) + (((props.padding.vertical * 2) + props.fontSize) / 4) + props.fontSize}rem`};
        border-radius: 0.25rem;
        left: 0;
        background-color: white;
        width: 100%;
        max-height: calc(40vh - 2rem);
        overflow-y: auto;
        box-shadow: 0 0 1px 1px rgba(0, 0, 0, 20%);
        z-index: 2;

        >li {
            ${(props) => `padding: ${props.padding.vertical}rem ${props.padding.horizontal}rem;`}

            :hover {
                background-color: rgba(0, 0, 0, 5%);
                cursor: pointer;
            }

            &.selected {
                background-color: rgba(0, 0, 0, 10%);
            }

            &:nth-child(1) {
                border-top-left-radius: 0.25rem;
                border-top-right-radius: 0.25rem;
                padding-top: 0.125rem;
                ${(props) => `padding-top: ${props.padding.vertical}rem;`}
            }

            &:nth-last-child() {
                border-bottom-left-radius: 0.25rem;
                border-bottom-right-radius: 0.25rem;
                ${(props) => `padding-bottom: ${props.padding.vertical}rem;`}
            }

            >p {
                font-size: ${(props) => props.fontSize + "rem"};
                font-weight: ${(props) => props.fontWeight ? props.fontWeight : 500};
            }
        }

        &.hidden {
            visibility: hidden;
        }

        transition-property: visibility;
    }
`;