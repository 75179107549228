import styled from "styled-components";
import cssVars from "../../../styles/cssVariables.vars";

export const StyledTypeMessageForm = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0.5rem;
    box-sizing: border-box;
    background-color: ${cssVars.colorGrey[7]};
    position: relative;

    >div.main {
        display: flex;
        align-items: center;
        position: relative;

        >textarea {
            box-sizing: border-box;
            width: max-content;
            border-radius: 0.25rem;
            background-color: ${cssVars.colorGrey[8]};
            padding: 0.5rem 0.875rem;
            font-size: var(--font-body-2);
            resize: none;
            width: 100%;
            overflow: hidden;
            color: ${cssVars.colorGrey[2]};;
            
            &::placeholder {
                color: ${cssVars.colorGrey[4]};;
            }

            &:disabled {
                pointer-events: none;
                opacity: 0.5;
            }
        }

        >audio {
            box-sizing: border-box;
            height: 2rem;
            width: 100%;
            padding-right: 1rem;
        }

        >svg {
            font-size: 1.75rem;
            color: black;
             cursor: pointer;
            transition: 250ms;

            :hover {
                transform: scale(0.875);
                opacity: 1;
            }
        }

        >div:first-of-type {
            padding-right: 1rem;
            display: flex;
            gap: 0.75rem;
            align-items: center;

            >button {
                color: ${cssVars.colorGrey[2]};
                cursor: pointer;
                font-size: 1.125rem;

                transition: 300ms ease-in-out;
                :hover {
                    transform: scale(1.1);
                    filter: brightness(1.25);
                }

                &[disabled] {
                    cursor: default;
                    color: ${cssVars.colorGrey[6]}
                }
            }
        }
        >div:last-child {
            display: flex;
            padding: 0 0.5rem;
            align-items: center;
            gap: 1rem;
            
            >button {
                color: var(--color-primary);
                cursor: pointer;
                font-size: 1.125rem;

                transition: 300ms ease-in-out;
                :not([disabled]):hover {
                    transform: scale(1.1);
                }

                &[disabled] {
                    cursor: default;
                    color: ${cssVars.colorGrey[4]};
                }
            }
        }
    }

    >div.emoji_picker {
        position: absolute;
        bottom: 4rem;
        left: 0;
    }

    >div.replyingMessage {
        display: flex;
        align-items: center;
        padding: 0.25rem;
        padding-left: 1rem;
        padding-right: 0.5rem;
        padding-bottom: 1rem;

        >div {
            box-sizing: border-box;
            padding: 0.75rem 1.25rem;
            border-radius: 0.25rem;
            background-color: ${cssVars.colorGrey[8]};
            width: 100%;
            border-left: 4px solid var(--color-primary);

            >h2 {
                font-size: var(--font-body-2);
                font-weight: 500;
                color: var(--color-primary);
                margin-bottom: 0.5rem;
            }
            >span {
                font-size: var(--font-body-2);
            }
        }
        >svg {
            padding: 1rem;
            width: 1.75rem;
            height: 1.75rem;
            cursor: pointer;
            color: ${cssVars.colorGrey[2]};
        }
    }
`;