import styled from "styled-components";
import cssVars from "../../styles/cssVariables.vars";

export interface StyledModalBasisProps {
    size?: {
        h?: number;
        w?: number;
    }
    borderRadius?: number;
    fontSize?: number;
    padding?: {
        h?: number;
        v?: number;
    }
    coloredHeader?: boolean;
}

export const StyledModalBasis = styled.div<StyledModalBasisProps>`
    display: flex;
    flex-direction: column;
    
    border-radius: ${props => props.borderRadius ? props.borderRadius + "rem" : "0.25rem"};
    appearance: none;
    background-color: ${cssVars.colorGrey[8]};

    box-sizing: border-box;
    height: ${props => props.size?.h ? (props.size.h + "rem") : "max-content"};
    width: ${props => props.size?.w ? (props.size.w + "rem") : "max-content"};

    overflow: hidden;

    >header {
        padding-top: ${props => props.padding?.h ? props.padding.v + "rem" : "0.25rem"};
        padding-left: ${props => props.padding?.h ? props.padding.h + "rem" : "0.25rem"};
        padding-right: ${props => props.padding?.h ? props.padding.h + "rem" : "0.25rem"};
        
        display: flex;
        align-items: center;
        justify-content: space-between;


        background-color: ${props => props.coloredHeader ? "var(--color-primary-1)" : "transparent"};
        color: ${cssVars.colorGrey[2]};

        >h2 {
            font-size: 1rem;
            font-weight: 500;
        }

        >svg {
            font-size: 1.5rem;
            cursor: pointer;

            &:hover {
                transform: scale(1.25);
            }
        }
    }

    >.content {

        box-sizing: border-box;
        height: 100%;
        width: 100%;

        padding-top: ${props => props.padding?.v ? props.padding.v + "rem" : '0.25rem'};
        padding-bottom: ${props => props.padding?.v ? props.padding.v + "rem" : '0.25rem'};
        padding-left: ${props => props.padding?.h ? props.padding.h + "rem" : '0.25rem'};
        padding-right: ${props => props.padding?.h ? props.padding.h + "rem" : '0.25rem'};

        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

`;