import { IoMdClose } from "react-icons/io";
import { StyledFormulario } from "./style";
import api from "../../../../../services/api";
import { toast } from "react-toastify";
import { Customer } from "../../../../../interfaces/Customers.interface";
import { CustomState } from "../../../../../Hooks/State.hook";
import Input from "../../../../../components/Input";
import cssVars from "../../../../../styles/cssVariables.vars";
import { RegistrationButton } from "../../../../../styles/registrationButton";

interface FormProps {
    closeFunction: () => void;
};

interface RegisterCustomerForm {
    RAZAO: string;
    FANTASIA: string;
    CPF_CNPJ: string;
    NOME: string;
    NUMERO: string;
}

export const Formulario = ({ closeFunction }: FormProps) => {
    const formState = CustomState<RegisterCustomerForm>({
        RAZAO: "", FANTASIA: "", CPF_CNPJ: "", NOME: "", NUMERO: ""
    });

    function insertNewCostumer() {
        const jurfis = formState.value.CPF_CNPJ.length === 11 ? "FIS" : "JUR";
        const { RAZAO, CPF_CNPJ, FANTASIA } = formState.value;
        api.post<Customer>(`/customers`, {
            RAZAO, CPF_CNPJ, FANTASIA,
            PESSOA: jurfis,
            ATIVO: "SIM"
        })
            .then(res => {
                const NUMERO = formState.value.NUMERO.replace(/\D/g, "");
                const NOME = formState.value.NOME;
                const CODIGO_CLIENTE = res.data.CODIGO;

                api.post(`/contacts`, { CODIGO_CLIENTE, NOME, NUMERO })
                    .then(() => toast.success('Número inserido com sucesso!'))
                    .catch(() => toast.error('Falha ao salvar contato do cliente...'))
            })
            .catch((err) => {
                toast.error("Falha ao criar cliente, verifique se o CNPJ já existe.")
            })
        toast.success('Cliente inserido com sucesso!');
        closeFunction()
    };

    return (
        <StyledFormulario onSubmit={(e) => {
            e.preventDefault();
            insertNewCostumer();
        }}>
            <header>
                <h1> Cadastrar novo cliente </h1>

                <button
                    className="closeModal"
                    type="button"
                    onClick={closeFunction}
                >
                    <IoMdClose />
                </button>
            </header>

            <Input
                $fontSize={0.875}
                $padding={[0.625, 0.625]}
                $color={cssVars.colorGrey[3]}
                $focusColor={cssVars.colorGrey[2]}
                $width="100%"
                placeholder="Razão social"
                minLength={6}
                onChange={(e) => formState.set(prev => ({ ...prev, RAZAO: e.target.value }))}
                value={formState.value.RAZAO}
                required
            />

            <Input
                $fontSize={0.875}
                $padding={[0.625, 0.625]}
                $color={cssVars.colorGrey[3]}
                $focusColor={cssVars.colorGrey[2]}
                $width="100%"
                placeholder="Fantasia"
                minLength={6}
                onChange={(e) => formState.set(prev => ({ ...prev, FANTASIA: e.target.value }))}
                value={formState.value.FANTASIA}
            />

            <Input
                $fontSize={0.875}
                $padding={[0.625, 0.625]}
                $color={cssVars.colorGrey[3]}
                $focusColor={cssVars.colorGrey[2]}
                $width="100%"
                placeholder="CPF/CNPJ"
                minLength={6}
                onChange={(e) => formState.set(prev => ({ ...prev, CPF_CNPJ: e.target.value }))}
                value={formState.value.CPF_CNPJ}
                required
            />

            <Input
                $fontSize={0.875}
                $padding={[0.625, 0.625]}
                $color={cssVars.colorGrey[3]}
                $focusColor={cssVars.colorGrey[2]}
                $width="100%"
                placeholder="Nome do contato"
                minLength={6}
                onChange={(e) => formState.set(prev => ({ ...prev, NOME: e.target.value }))}
                value={formState.value.NOME}
            />

            <Input
                $fontSize={0.875}
                $padding={[0.625, 0.625]}
                $color={cssVars.colorGrey[3]}
                $focusColor={cssVars.colorGrey[2]}
                $width="100%"
                placeholder="Número Whatsapp ex: 551198765432"
                minLength={6}
                value={formState.value.NUMERO}
                onChange={(e) => formState.set(prev => ({ ...prev, NUMERO: String(e.target.value.replace(/\D/g, "")) }))}
            />

            <RegistrationButton type="submit">
                Cadastrar
            </RegistrationButton>
        </StyledFormulario>
    );
};