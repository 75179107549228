import { IoMdClose } from "react-icons/io";
import { FaLocationDot, FaTableList } from "react-icons/fa6";
import { StyledRegisterContactsModal } from "./style";
import { useContext, useEffect } from "react";
import { CustomState } from "../../../../Hooks/State.hook";
import { Customer } from "../../../../interfaces/Customers.interface";
import { AttendancesContext } from "../../../../contexts/user/AttendancesContext";
import api from "../../../../services/api";
import { LoginContext, currentRoute } from "../../../../contexts/global/LoginContext";
import formatarCPForCNPJ from "../../../../Hooks/FormatCPFCNPJ.hook";
import { PageNavigation } from "../../../../components/PageNavigation";
import { ContactList } from "./ContactList";
import { RegistrationButton } from "../../../../styles/registrationButton";
import { Option } from "../../../../components/CustomSelect";
import Input from "../../../../components/Input";
import { defaultInput100, defaultSelect100 } from "../../../../components-variants/defaultInputs";
import { FaSearch } from "react-icons/fa";
import Select from "../../../../components/Select";
import cssVars from "../../../../styles/cssVariables.vars";

export const RegisterContactsModal = () => {
    const loadedCustomers = CustomState<Array<Customer>>([]);
    const { modalState } = useContext(AttendancesContext);
    const { user } = useContext(LoginContext);
    const currentPage = CustomState<number>(1);
    const nextPage = CustomState<string | null>(null);
    const previousPage = CustomState<string | null>(null);
    const loadingState = CustomState<boolean>(false);
    const selectedCustomer = CustomState<number | null>(null);

    const searchState = CustomState<string>("");
    const fieldState = CustomState<string>("RAZAO");

    const searchFieldOptions: Array<Option<string>> = [
        { name: "Razão social", value: "RAZAO" },
        { name: "Código inPulse", value: "CODIGO" },
        { name: "Código ERP", value: "COD_ERP" },
        { name: "CPF/CNPJ", value: "CPF_CNPJ" },
        { name: "Nome do contato", value: "NOME" },
        { name: "Número do WhatsApp", value: "NUMERO" },
    ];

    function handleSearch() {
        loadingState.set(true);

        api.get<{ data: Customer[], nextPage: string | null, previousPage: string | null, count: number }>(`/customers/custom/filter`, {
            params: {
                page: currentPage.value,
                perPage: 20,
                field: fieldState.value,
                value: searchState.value,
                sector: currentRoute === "natrielli" ? (user && user.NIVEL !== "ADMIN" ? user.SETOR : undefined) : undefined
            }
        }).then((res) => {
            loadedCustomers.set(res.data.data);
            nextPage.set(res.data.nextPage);
            previousPage.set(res.data.previousPage);

            console.log(res.data)

            if (!res.data.count) {
                currentPage.set(1);
            }
        }).catch((err) => {
            console.error(err);
            loadedCustomers.set([]);
        }).finally(() => {
            loadingState.set(false);
        });

    };

    useEffect(() => {
        handleSearch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage.value]);

    return (
        <StyledRegisterContactsModal>
            <header>
                <h2> Cadastro de contatos </h2>
                <button onClick={modalState.reset}>
                    <IoMdClose />
                </button>
            </header>
            <main>
                {
                    selectedCustomer.value !== null ?
                        <ContactList selectedCustomer={selectedCustomer} />
                        :
                        (
                            <>
                                <h3>
                                    Selecione um cliente:
                                </h3>
                                <div className="search">
                                    <Input
                                        {...defaultInput100}
                                        $fontSize={0.875}
                                        $fontWeight={500}
                                        $padding={[0.5, 0.5]}
                                        placeholder="Pesquisar cliente"
                                        onChange={(e) => searchState.set(e.target.value)}
                                        value={searchState.value}
                                        leftIcon={<FaSearch />}
                                    />
                                    <Select
                                        {...defaultSelect100}
                                        $fontSize={1}
                                        $padding={[0.5, 0.5]}
                                        $focusColor={cssVars.colorGrey[2]}
                                        $color={cssVars.colorGrey[3]}
                                        onChange={(v) => fieldState.set(v || "")}
                                        options={searchFieldOptions}
                                        defaultValue={searchFieldOptions[0]}
                                    />
                                    <RegistrationButton onClick={handleSearch}>
                                        Buscar
                                    </RegistrationButton>
                                </div>
                                <ul>
                                    {
                                        loadedCustomers.value.map((customer) => {
                                            return (
                                                <li
                                                    key={`customer_${customer.CODIGO}`}
                                                    onClick={() => selectedCustomer.set(customer.CODIGO)}
                                                >
                                                    <div>
                                                        <h4> [{customer.CODIGO}] {customer.RAZAO} </h4>
                                                        <h5> {customer.COD_ERP} </h5>
                                                        <h5>
                                                            {customer.CPF_CNPJ ? formatarCPForCNPJ(customer.CPF_CNPJ) : ""}
                                                        </h5>
                                                        <h5>
                                                            <FaLocationDot size={"1rem"} color={"rgba(185, 35, 35)"} />
                                                            {customer.CIDADE && customer.CIDADE}{customer.ESTADO && ` - ${customer.ESTADO}`}
                                                            {!customer.CIDADE && !customer.ESTADO && "N/D"}
                                                        </h5>
                                                    </div>
                                                    <FaTableList
                                                        size={"1rem"}
                                                        color={"rgba(185, 35, 35)"}
                                                    />
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                                <PageNavigation
                                    currentPage={currentPage}
                                    nextPage={nextPage}
                                    previousPage={previousPage}
                                />
                            </>
                        )
                }
            </main>
        </StyledRegisterContactsModal>
    )
};