import styled from "styled-components";
import cssVars from "../../../styles/cssVariables.vars";

export const StyledTemplateMessages = styled.div`
    box-sizing: border-box;
    padding: 1rem;
    height: calc(100% - 5rem);

    display: flex;
    flex-direction: column;
    gap: 1rem;

    >.templates_table {
        height: 100%;
        display: grid;
        grid-template-areas: "header" "body";
        grid-template-rows: auto 1fr;

        >header{
            grid-area: header;
            display: grid;
 
            grid-template-areas:  "actions id status lang category name";
            grid-template-columns: 5rem 2fr 1fr 1fr 2fr 4fr;
            grid-template-rows: 1fr;
            padding-right: 1rem;

            border-radius: 0.25rem 0.25rem 0 0;
            
            border-bottom: none;
            background-color: ${cssVars.colorGrey["7h"]};

            >div {
                padding: 0.5rem;
                font-size: 0.875rem;
            }
        }

        >ul {
            grid-area: body;
            overflow-y: auto;
            border-radius: 0 0 0.5rem 0.5rem;

            >li {
                display: grid;
 
                grid-template-areas:  "actions id status lang category name";
                grid-template-columns: 5rem 2fr 1fr 1fr 2fr 4fr;
                grid-template-rows: 1fr;

                padding: 1rem 0;

                >div {
                    padding: 0.5rem;
                    font-size: 0.875rem;
                    
                    >button {
                        cursor: pointer;
                        :hover {
                            transform: scale(1.25);
                        }
                        >svg {
                            font-size: 1rem;
                        }
                    }
                }
            }
        }
    }

`;