import { AttendancesContext } from "../../../contexts/user/AttendancesContext";
import { AttendanceView } from "../../Operador/AttendanceView";
import { MyAttendancesMenu } from "../../Operador/OperadorMenu/MyAttendancesMenu";
import { StyledMeusAtendimentos } from "./style";
import { useContext } from "react";
import logo from "../../../logo.png";

export const MeusAtendimentos = () => {

    const { readingAttendance } = useContext(AttendancesContext);

    return (
        <StyledMeusAtendimentos>
            <div className="chat_box">
                {
                    readingAttendance.value ?
                        <AttendanceView />
                        :
                        <div className="no_attendances">
                            <img src={logo} alt="logo" />
                        </div>
                }
            </div>
            <div className="attendances_menu">
                <MyAttendancesMenu />
            </div>
        </StyledMeusAtendimentos>
    );
};