import { useEffect } from "react";
import { CustomState } from "../../../../Hooks/State.hook";
import StyledMessagesByContact from "./style";
import { ContactMessages, MessagesPerContactResponse } from "./types";
import { customRequest } from "../../../../services/api";
import ContactsMessagesTable from "./table";

function MessagesByContact() {
    const data = CustomState<ContactMessages[]>([]);
    const filteredData = CustomState<ContactMessages[]>([]);

    useEffect(() => {
        customRequest<MessagesPerContactResponse, null>({
            endpoint: "/dashboard/messages-per-contact",
            method: "get",
            onSuccess: (res) => {
                data.set(res.messagesPerContact);
                filteredData.set(res.messagesPerContact);
            },
            timeoutSeconds: 40
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <StyledMessagesByContact>
            <ContactsMessagesTable
                rowsState={data}
                filteredRowsState={filteredData}
            />
        </StyledMessagesByContact>
    );
}

export default MessagesByContact;