import formatarCPForCNPJ from "../../../Hooks/FormatCPFCNPJ.hook";
import formatarNome from "../../../Hooks/FormatName.hook";
import { CustomState } from "../../../Hooks/State.hook";
import { LoginContext } from "../../../contexts/global/LoginContext";
import { AttendancesContext } from "../../../contexts/user/AttendancesContext";
import { TemplateComponents } from "../../../interfaces/MessageTemplates.interfaces";
import { useContext, useEffect } from "react";

interface Props {
    c: TemplateComponents,
    disableVars?: boolean
};

const currentSaudation = () => {
    const currentTime = new Date();
    const hour = currentTime.getHours();

    if (hour >= 5 && hour < 12) {
        return "Bom dia";
    } else if (hour >= 12 && hour < 18) {
        return "Boa tarde";
    } else {
        return "Boa noite";
    }
}

export const TableItem = ({ c, disableVars }: Props) => {
    const text = CustomState<string>(c.text);
    const { readingAttendance } = useContext(AttendancesContext);
    const { user } = useContext(LoginContext);



    useEffect(() => {
        if (readingAttendance.value && user && !disableVars) {

            const contactName = readingAttendance.value!.CONTATO_NOME || "CONTATO EXCLUÍDO";
            const values: any = {
                "saudação_tempo": currentSaudation(),
                "cliente_cnpj": formatarCPForCNPJ(readingAttendance.value.CPF_CNPJ),
                "cliente_razao": readingAttendance.value.RAZAO,
                "contato_primeiro_nome": contactName?.split(" ")[0],
                "contato_nome_completo": contactName,
                "atendente_nome": formatarNome(user.NOME),
                "atendente_nome_exibição": formatarNome(user.NOME_EXIBICAO)
            }
            if (c.type === "HEADER" && c.example?.header_text) {
                const variables = c.example?.header_text

                variables.forEach((v, i) => {
                    text.set(prev => prev.replaceAll(`{{${i + 1}}}`, values[v] || "UNDEFINED VAR"));
                });
            } else if (c.type === "BODY" && c.example?.body_text) {
                const variables = c.example?.body_text[0]

                variables.forEach((v, i) => {
                    text.set(prev => prev.replaceAll(`{{${i + 1}}}`, values[v] || "UNDEFINED VAR"));
                });
            };
        }
    }, [c, text.value])

    return (
        <div className="component">
            <h4>
                {c.type === "BODY" ? "Corpo" : c.type === "HEADER" ? "Cabeçalho" : c.type === "FOOTER" ? "Rodapé" : String((c as any).type!)}
            </h4>
            <div className="text-area">
                {text.value?.split("\n").map((p, i) => <p key={`p_${i}`}> {p} </p>)}
            </div>
        </div>
    );
}