import styled from "styled-components";
import cssVars from "../../../../../styles/cssVariables.vars";

export const StyledListaDeAgentes = styled.div`
    box-sizing: border-box;
    border-bottom: none;
    grid-area: list;

    height: calc(100vh - 16rem);
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    width: 100%;

    >header {
        display: grid;
        grid-template-areas: "acoes id ativo nivel nome login nome_e setor";
        grid-template-columns: 4rem 4rem 6rem 14rem 14rem 14rem 14rem 1fr;
        border-bottom: none;
        padding-right: 0.75rem;
        grid-area: header;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
        background-color: ${cssVars.colorGrey["6h"]};
        color: ${cssVars.colorGrey[2]};
        min-width: max-content;
        width: 100%;
        box-sizing: border-box;
        
        >div {
            padding: 0.5rem;
            font-size: 0.875rem;
            font-weight: 500;
            user-select: none;
        }

        >div.setor {
            grid-area: setor;
            min-width: 12rem;
            max-width: 24rem;
        }
    }

    >ul {
        box-sizing: border-box;
        overflow-y: auto;
        overflow-x: hidden;
        grid-area: alist;
        padding-bottom: 0.125rem;
        height: 100%;
        min-width: max-content;
        width: 100%;

        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }
`;