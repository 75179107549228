import { toast } from "react-toastify";
import api from "../services/api";

export async function validateNumber(number: string) {
    try {
        const response = await api.get<{ validNumber: false | string }>(`/wwebjs/validate-number/${number}`);

        if (!response.data) {
            toast.error("Este número não contem Whatsapp.")
            return response.data;
        }

        return String(response.data);
    } catch {
        toast.error("Falha ao tentar validar contato...")
        return false;
    }
}