import { Dispatch, SetStateAction, useContext } from "react";
import { FaCalendarAlt, FaExchangeAlt, FaRegEye } from "react-icons/fa";
import { StyledTableItem } from "./style";
import { SupervisorContext } from "../../../../../contexts/admin/Supervisor.context";
import { ViewChat } from "../../../../../components/ViewChat";
import { ScheduleDialog } from "../../../../../components/ScheduleDialog";
import { UpdateOperatorDialog } from "../../../../../components/UpdateOperatorDialog";
import { UpdatePriorityDialog } from "../../../../../components/UpdatePriorityDialog";
import { IoMdAlert } from "react-icons/io";
import { RunningAttendanceDetails } from "../../../../../interfaces/Attendances.interfaces";
import { formatNumber } from "../../../../../Hooks/FormatNumber.hook";

interface Props {
    attendance: RunningAttendanceDetails;
    setAttendances: Dispatch<SetStateAction<RunningAttendanceDetails[]>>;
};

export const TableItem = ({ attendance, setAttendances }: Props) => {
    const { URGENCIA_SUPERVISOR, URGENCIA_AGENDAMENTO, URGENCIA_OPERADOR } = attendance;
    const urgencia: string = URGENCIA_SUPERVISOR || URGENCIA_AGENDAMENTO || URGENCIA_OPERADOR;

    const { modalState } = useContext(SupervisorContext);

    return (
        <StyledTableItem className={`${urgencia} ${attendance.CONTATO_NUMERO === "CONTATO EXCLUÍDO" ? "deleted_contact" : ""}`}>
            <div className="actions">
                {
                    attendance.CODIGO_NUMERO &&
                    <FaRegEye
                        onClick={() => modalState.set(
                            <ViewChat
                                attendance={attendance}
                                contactId={attendance.CODIGO_NUMERO}
                                modalState={modalState}
                            />
                        )}
                        title="Abrir conversa"
                    />
                }
                <FaExchangeAlt
                    title="Abrir transferencia de agente"
                    onClick={() => modalState.set(<UpdateOperatorDialog CODIGO_ATENDIMENTO={attendance.CODIGO} setAttendances={setAttendances} modalState={modalState} />)}
                />
                <FaCalendarAlt title="Abrir agenda de retorno" onClick={() => modalState.set(<ScheduleDialog CODIGO_ATENDIMENTO={attendance.CODIGO} modalState={modalState} />)} />
                <IoMdAlert title="Editar Prioridade" onClick={() => modalState.set(<UpdatePriorityDialog CODIGO_ATENDIMENTO={attendance.CODIGO} modalState={modalState} />)} />
            </div>

            <div className="cod_attendance">
                <p> {attendance.CODIGO} </p>
            </div>

            <div className="cod_erp">
                <p> {attendance.COD_ERP} </p>
            </div>

            <div className="priority">
                {urgencia.replace("_", " ")}
            </div>

            <div className="attendance_sector">
                <p> {attendance.SETOR_NOME} </p>
            </div>

            <div className="operator">
                <p> {attendance.OPERADOR || "n/d"} </p>
            </div>

            <div className="client_name">
                <p> {attendance.CONTATO_NOME || "n/d"} </p>
            </div>

            <div className="client_phone">
                <p>{attendance.CONTATO_NUMERO ? formatNumber(attendance.CONTATO_NUMERO) : "N/D"}</p>
            </div>

            <div className="client_company">
                <p> {attendance.RAZAO || "n/d"} </p>
            </div>

            <div className="start_date">
                <p> {attendance.DATA_INICIO.toLocaleString()} </p>
            </div>

            <div className="attendance_type">
                <p> {attendance.TIPO} </p>
            </div>
        </StyledTableItem>
    );
}