import styled from "styled-components";
import cssVars from "../../../../styles/cssVariables.vars";

export const StyledAttendanceButtons = styled.div`
    box-sizing: border-box;
    gap: 0.75rem;
    row-gap: 0.25rem;
    width: 100%;
    position: relative;

    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 1rem;

    >button {
        font-size: 1.5rem;
        cursor: pointer;
        color: ${cssVars.colorGrey[4]};

        >svg {
            pointer-events: none;
        }

        &:hover {
            color: ${cssVars.colorGrey[2]};
        }
    }

    >ul {
        position: absolute;
        top: 2.75rem;
        right: 1rem;
        background-color: ${cssVars.colorGrey["6h"]};
        border-radius: 0.25rem;
        z-index: 2;
        box-shadow: 1px 1px 3px 1px rgba(0,0,0, 0.125);
        width: 12rem;

        >li {
            padding: 0.5rem;
            font-size: 0.875rem;
            font-weight: 600;
            display: flex;
            align-items: center;
            gap: 0.5rem;
            color: ${cssVars.colorGrey[2]};

            &:hover {
                background-color: ${cssVars.colorGrey[7]};
                cursor: pointer;
            } 
        }
    }

   
`