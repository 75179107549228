import styled from "styled-components";

export const InputWrapper = styled.fieldset`
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;

    >:nth-child(2){
        position: absolute;
        right: 2rem;
        top: calc(1.8125rem - 0.75rem);

        height: 1.5rem;
        width: 1.5rem;

        color: rgba(255,255,255, 75%);
    }

    :focus-within {
        >:nth-child(2) {
            color: white;
        }
    }
`;


export const StyledInput = styled.input`
    box-sizing: border-box;
    width: 100%;
    border: 2px solid rgba(255,255,255, 50%);
    border-radius: 0.25rem;
    padding: 1rem 2rem;
    background-color: rgba(0,0,0, 35%);

    font-size: var(--font-body-1);
    color: white;

    transition: 200ms ease-in-out;

    ::placeholder {
        color: rgba(255,255,255, 50%);
    }

    :focus {
        border: 2px solid white;
    }
`;