import styled from "styled-components";
import cssVars from "../../styles/cssVariables.vars";

export const StyledOperadorPage = styled.div`
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;

    display: grid;
    grid-template-areas:
    "menu header"
    "menu body"
    ;

    grid-template-columns: 18rem calc(100% - 18rem);
    grid-template-rows: 4rem calc(100vh - 4rem);
    overflow: hidden;

    @media (min-width: 64rem) {
        grid-template-columns: 22rem calc(100% - 22rem); 
    }

    @media (min-width: 80rem) {
        grid-template-columns: 26rem calc(100% - 26rem); 
    }

    @media (min-width: 100rem) {
        grid-template-columns: 30rem calc(100% - 30rem);
    }

    >div:nth-child(1) {
        grid-area: header;
        
    }

    >div:nth-child(2) {
        grid-area: body;
    }
`;

export const StyledNoAttendances = styled.div`
    background-color: ${cssVars.colorGrey[8]};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    gap: 1rem;


    >h1 {
        font-size: 1.25rem;
        font-weight: 700;
    }

    >img {
        width: 10rem;
        height: 10rem;
        filter: contrast(0.05) opacity(0.375);
    }
`;