import styled from "styled-components";
import cssVars from "../../../../../styles/cssVariables.vars";

export const StyledListaDeClientes = styled.div`
    box-sizing: border-box;
    border-bottom: none;
    grid-area: lista;
    height: calc(100vh - 16rem);

    display: grid;

    grid-template-areas: "header"
    "list";

    grid-template-rows: max-content 1fr;

    >header {
        display: grid;
        grid-template-areas: "acoes id ativo pessoa razao fantasia cpf telefone";
        grid-template-columns: 4rem 6rem 6rem 10rem 2fr 2fr 1fr 6rem;
        padding-right: 0.75rem;
        grid-area: header;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
        background-color: ${cssVars.colorGrey["6h"]};
        color: ${cssVars.colorGrey[2]};
        font-size: 0.875rem;
        font-weight: 600;
        
        >div {
            padding: 0.5rem;
        }
    }

    >ul {
        box-sizing: border-box;
        overflow-y: auto;
        overflow-x: hidden;
        grid-area: list;
    }
`;

