import { ReactNode } from "react";
import StyledInput, { StyledInputProps } from "./styles";

type InputOptions = {
    rightIcon?: ReactNode;
    leftIcon?: ReactNode;
    error?: string;
}

type InputProps =
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> &
    InputOptions & Omit<StyledInputProps, "$icons">;

const Input = ({
    $padding,
    $width,
    $fontSize,
    $fontWeight,
    $color,
    $borderColor,
    $focusColor,
    $disableBorder,
    leftIcon,
    rightIcon,
    error,
    ...styleProps
}: InputProps) => {

    return (
        <StyledInput {...{
            $padding, $width, $fontSize, $fontWeight, $color, $borderColor, $focusColor, $disableBorder
        }}>
            <div>
                {leftIcon || ""}
                <input {...styleProps} />
                {rightIcon || ""}
            </div>
            {error && <span className="error"> {error} </span>}
        </StyledInput>
    );
}

export default Input;