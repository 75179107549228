import { FaEdit, FaSave, FaTrash } from "react-icons/fa";
import { TiCancel } from "react-icons/ti";
import { StyledTableItem } from "./style"
import api from "../../../../../../../../services/api";
import { Contact } from "../../../../../../../../interfaces/Customers.interface";
import { CustomState } from "../../../../../../../../Hooks/State.hook";
import Input from "../../../../../../../../components/Input";
import { defaultInput100 } from "../../../../../../../../components-variants/defaultInputs";

interface TableItemProps {
    contact: Contact;
    closeFunction: () => void;
};

export const TableItem = ({ contact, closeFunction }: TableItemProps) => {
    const disabled = CustomState<boolean>(true);
    const name = CustomState<string>(contact.NOME);
    const number = CustomState<string>(contact.NUMERO);

    function cancelEdit() {
        disabled.reset()
        name.reset();
        number.reset();
    };

    function updateContact() {
        api.patch(`/contacts/${contact.CODIGO}`, { NOME: name.value, NUMERO: number.value })
            .then((res) => {
                contact = res.data;
                disabled.reset();
            })
            .catch(() => {
                cancelEdit();
            });
    };

    function deleteContact() {
        api.delete(`/contacts/${contact.CODIGO}`)
            .then(() => closeFunction())
            .catch((err) => console.error(err));
    };

    return (
        <StyledTableItem>
            <div>
                {
                    disabled.value ?
                        <FaEdit onClick={() => { disabled.set(false) }} />
                        :
                        <>
                            <FaSave onClick={updateContact} color="rgba(15, 215, 50, 80%)" />
                            <TiCancel onClick={cancelEdit} size="1.25rem" color="red" />
                        </>
                }
                <FaTrash onClick={() => deleteContact()} color="red" />
            </div>
            <div>
                <h3> {contact.CODIGO} </h3>
            </div>
            <div>
                <Input
                    disabled={disabled.value}
                    placeholder="Insira o nome"
                    value={name.value} onChange={(e) => name.set(e.target.value)}
                    {...defaultInput100}
                    $padding={[0.375, 0.375]}
                    $fontSize={0.75}
                />
            </div>
            <div>
                <Input
                    disabled={disabled.value}
                    placeholder="Insira o número de Telefone."
                    value={number.value} onChange={(e) => number.set(e.target.value)}
                    {...defaultInput100}
                    $padding={[0.375, 0.375]}
                    $fontSize={0.75}
                />
            </div>
        </StyledTableItem>
    );
};