import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { CustomMessage } from "../../../interfaces/CustomMessages.interfaces";
import { StyledListItem } from "./style"
import { CustomState, CustomStateHook } from "../../../Hooks/State.hook";
import { ReadyMessagesContext } from "../../../contexts/global/ReadyMessagesContext";
import { useContext } from "react";
import { LoginContext } from "../../../contexts/global/LoginContext";
import formatarCPForCNPJ from "../../../Hooks/FormatCPFCNPJ.hook";
import formatarNome from "../../../Hooks/FormatName.hook";
import { SpecificAttendanceDetails } from "../../../interfaces/Attendances.interfaces";
import { FaFile } from "react-icons/fa6";

interface ListItemProps {
    message: CustomMessage;
    state: CustomStateHook<CustomMessage | null>;
    attendance: SpecificAttendanceDetails;
};

export const ListItem = ({ message, state, attendance }: ListItemProps) => {

    const showing = CustomState<boolean>(false);
    const { replaceVariables } = useContext(ReadyMessagesContext);
    const { user } = useContext(LoginContext);

    const currentSaudation = () => {
        const currentTime = new Date();
        const hour = currentTime.getHours();

        if (hour >= 5 && hour < 12) {
            return "Bom dia";
        } else if (hour >= 12 && hour < 18) {
            return "Boa tarde";
        } else {
            return "Boa noite";
        }
    }


    const contactName = attendance.CONTATO_NOME || "CONTATO EXCLUÍDO";
    const replacedVarsMessage = replaceVariables({
        message: message.TEXTO_MENSAGEM,
        values: {
            currentSaudation: currentSaudation(),
            customerCnpj: formatarCPForCNPJ(attendance.CPF_CNPJ || ""),
            customerFirstName: contactName.split(" ")[0],
            customerFullName: contactName,
            customerRazao: attendance.RAZAO,
            operatorName: (user && formatarNome(user.NOME)) || "",
            operatorNameExibition: user && user.NOME_EXIBICAO ? formatarNome(user.NOME_EXIBICAO) : ""
        }
    })

    const content = replacedVarsMessage.split(/\n/g);

    return (
        <StyledListItem>
            <header>
                <div>
                    <input
                        type="radio"
                        onClick={() => {
                            if (state.value === message) {
                                state.reset();
                            } else {
                                state.set(message);
                            };
                        }}
                        checked={state.value === message}
                        readOnly
                    />
                    <h3> {message.TITULO} </h3>
                </div>
                {
                    showing.value ?
                        <FaAngleUp size={'1rem'} cursor={'pointer'} onClick={() => showing.set(false)} />
                        :
                        <FaAngleDown size={'1rem'} cursor={'pointer'} onClick={() => showing.set(true)} />
                }
            </header>
            {
                showing.value &&
                <div>
                    <div className="text">
                        {content.map((line, i) => <p key={`p_${i}`}> {line} </p>)}
                    </div>
                    {
                        message.ARQUIVO &&
                        <div className="file">
                            <FaFile />
                            <p>{message.ARQUIVO.slice(37)}</p>
                        </div>
                    }
                </div>
            }
        </StyledListItem>
    );
};