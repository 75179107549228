import styled from "styled-components";

export const StyledLoginPage = styled.div`
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;

    background: var(--color-secondary-1);
    background-image: url('./reunion.jpg');
    background-repeat: no-repeat;
    background-size: cover;

    @media (max-width: 500px) {
        padding: 0 5%;
    }

    >form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 25.5rem;
        padding: 1.5rem;
        border: 2px solid rgba(255,255,255, 50%);
        border-radius: 0.25rem;
        background-color: rgba(255,255,255, 0.1);
        backdrop-filter: blur(4px);
        
        > img {
            width: 12rem;
            margin-bottom: 4rem;

            filter: drop-shadow(0 0 2px black);
        }

        >.invalid_field_alert {
            color: rgb(255, 12, 12);
            align-self: flex-start;
            margin: 0.25rem;
        }

        >fieldset {
            margin-top: 1rem;
        }

        >button {
            margin-top: 1rem;
            background-color: var(--color-primary-1);
            box-sizing: border-box;
            width: 100%;
            padding: 1.25rem 0;
            border-radius: 0.25rem;
            color: white;
            font-size: var(--font-body-1);

            cursor: pointer;

            :hover {
                filter: brightness(1.1);
            }

            :active {
                transform: scale(0.99);
            }
        }
        z-index: 2;
    }

    >div {
        width: 100vw;
        height: 100vh;
        z-index: 1;
        position: absolute;
        background-color: rgba(0,0,0, 35%);
    }
`;