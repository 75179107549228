import styled from "styled-components";
import cssVars from "../../styles/cssVariables.vars";


export const StyledViewChat = styled.div`
    box-sizing: border-box;
    height: 80vh;
    width: 85vw;
    border-radius: 0.25rem;
    background-color: ${cssVars.colorGrey[8]};

    display: flex;
    flex-direction: column;

    >header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: ${cssVars.colorPrimary};
        padding: 0.5rem 1rem;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;

        >h2 {
            font-size: 1rem;
            color: ${cssVars.colorGrey[8]}
        }

        >button {
            font-size: 1.5rem;
            color: ${cssVars.colorGrey[8]};
            cursor: pointer;
            
            &:hover {
                transform: scale(1.25);
            }
        }
    }

    >:nth-child(2) {
        height: calc(100% - 2.85rem);
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;

        >:nth-last-child(1) {
            border-bottom-left-radius: 0.25rem;
            border-bottom-right-radius: 0.25rem;
        }
    }
`;