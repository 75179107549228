import styled from "styled-components";

export const Modal = styled.aside`
    background-color: rgba(0, 0, 0, 50%);
    
    position: absolute;
    top: 0;
    left: 0;
    z-index: 12;

    width: 100vw;
    height: 100vh;
    
    display: flex;
    align-items: center;
    justify-content: center;
`;