import styled from "styled-components";

const StyledRelatórios = styled.div`
    box-sizing: border-box;
    height: calc(100% - 5rem);

    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;

    >header {
        max-width: 50rem;
        height: 2rem;

    }

    >main {
        box-sizing: border-box;
        height: calc(100% - 2rem);
    }
`;

export default StyledRelatórios;