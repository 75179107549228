import { StyledMessage, StyledViewFileButton } from "./style";
import { ReactNode, useContext, useEffect } from "react";
import { FaReply } from "react-icons/fa";
import { ChatContext } from "../../../contexts/user/ChatContext";
import { AttendanceMessage } from "../../../interfaces/Attendances.interfaces";
import { CustomState } from "../../../Hooks/State.hook";
import { baseURL } from "../../../services/api";
import { FaRegClock } from "react-icons/fa6";
import { BsCheck, BsCheckAll, BsExclamationTriangle, BsFillSpeakerFill } from "react-icons/bs";
import { AttendancesContext } from "../../../contexts/user/AttendancesContext";
import { decodeSafeURI } from "../../../Hooks/decodeSafeUri.hook";
import parseVCard from "../../../Hooks/parseVCard.util";
import { renderMessageWithLinks } from "./LinkMessage/index";
import { FileIcon, defaultStyles } from 'react-file-icon';

interface MessageProps {
    message: AttendanceMessage;
};

export const Message = ({ message }: MessageProps) => {
    const { findReferenceMessage, referenceId } = useContext(ChatContext);
    const { readingAttendance } = useContext(AttendancesContext);


    if (message.MENSAGEM) {
        message.MENSAGEM = decodeSafeURI(message.MENSAGEM);
    }

    if (message.MENSAGEM.startsWith("BEGIN:VCARD")) {
        message.MENSAGEM = parseVCard(message.MENSAGEM);
    }

    const DATE = new Date(Number(message.TIMESTAMP)).toLocaleString('pt-BR').slice(0, 17);
    const arr = message.MENSAGEM && message.MENSAGEM.split(/\n/g);
    const referenceMessage = message.ID_REFERENCIA && findReferenceMessage(message.ID_REFERENCIA);
    const decodedReferenceMessage = referenceMessage && decodeSafeURI(referenceMessage.MENSAGEM);
    const fileComponent = CustomState<ReactNode>("");
    const viewComponent = CustomState<boolean>(false);

    const fileType = message.ARQUIVO && message.ARQUIVO.TIPO.toUpperCase().includes("IMAGE") ? "imagem" :
        message.ARQUIVO && message.ARQUIVO.TIPO.toUpperCase().includes("VIDEO") ? "video" :
            message.ARQUIVO && message.ARQUIVO.TIPO.toUpperCase().includes("PTT") ? "audio" :
                message.ARQUIVO && message.ARQUIVO.TIPO.toUpperCase().includes("AUDIO") ? "audio" :
                    "documento";

    function clickHandler() {
        if (message.ID_REFERENCIA) {
            let target = document.getElementById(message.ID_REFERENCIA);
            target?.scrollIntoView();
        };
    };

    useEffect(() => {
        if (message.ARQUIVO) {
            const type = message.ARQUIVO.TIPO.toUpperCase();
            const fileURL = `${baseURL}/custom-routes/file/${decodeSafeURI(message.ARQUIVO.NOME_ARQUIVO)}` || "";

            if (type.includes("IMAGE")) {
                fileComponent.set(<img src={fileURL} alt="" />)
            } else if (type.includes("VIDEO")) {
                fileComponent.set(<video controls>
                    <source src={fileURL} type={message.ARQUIVO.TIPO} />
                </video>);
            } else if (type.includes("PTT") || type.includes("AUDIO")) {
                fileComponent.set(<audio controls>
                    <source src={fileURL} type={message.ARQUIVO.TIPO} />
                </audio>);
            } else {
                const ext = message.ARQUIVO?.NOME_ARQUIVO.split(".").reverse()[0];

                fileComponent.set(<a href={fileURL} target="_blank" rel="noreferrer">
                    <FileIcon extension={ext} {...(defaultStyles as any)[ext]} />
                    <div>
                        <p> {decodeSafeURI(message.ARQUIVO.NOME_ORIGINAL || message.ARQUIVO.NOME_ARQUIVO)} </p>
                        <p> tipo: {message.ARQUIVO.TIPO} </p>
                    </div>
                </a>);
            };
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <StyledMessage sentByMe={!!message.FROM_ME} id={`${message.ID}`} >
            <div className="buttons">
                <FaReply onClick={() => {
                    referenceId.set(message.ID);
                }} />
            </div>
            {
                referenceMessage &&
                <div className={referenceMessage.FROM_ME ? "responding byMe" : "responding byOther"} onClick={clickHandler}>
                    <h4 >
                        {referenceMessage.FROM_ME ? "Você" : readingAttendance.value?.CONTATO_NOME}
                    </h4>
                    {decodedReferenceMessage?.split(/\n/g).map((p, i) => <p key={`p_${i}`}> {p}</p>)}
                </div>
            }
            <div className="message">
                {
                    fileComponent.value &&
                        ["imagem", "video", "audio"].includes(fileType) ?
                        (
                            viewComponent.value ?
                                fileComponent.value
                                :
                                <StyledViewFileButton onClick={() => viewComponent.set(prev => !prev)} className="view_file">
                                    Carregar {fileType}
                                </StyledViewFileButton>
                        )
                        :
                        (
                            fileComponent.value
                        )
                }
                {
                    arr &&
                    arr.map((p, i) => <p key={`p_${i}`}> {renderMessageWithLinks(p)}</p>)
                }
            </div>
            <span>
                {<div style={{ fontSize: '10px' }}>{DATE}</div>}
                <div className="status">
                    {
                        message.STATUS === "PENDING" ?
                            <FaRegClock size="0.875rem" />
                            :
                            message.STATUS === "SENT" ?
                                <BsCheck fontSize="1.125rem" />
                                :
                                message.STATUS === "RECEIVED" ?
                                    <BsCheckAll fontSize="1.125rem" />
                                    :
                                    message.STATUS === "READ" ?
                                        <BsCheckAll color="rgb(95, 185, 255)" fontSize="1.125rem" />
                                        :
                                        message.STATUS === "PLAYED" ?
                                            <BsFillSpeakerFill color="green" fontSize="1.125rem" />
                                            :
                                            <BsExclamationTriangle color="red" fontSize="1.125rem" />

                    }
                </div>
            </span>
        </StyledMessage>
    );
};