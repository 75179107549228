import styled from "styled-components";
import cssVars from "../../../../styles/cssVariables.vars";

export const StyledInProgressAttendances = styled.div`
    box-sizing: border-box;
    height: 100%;
    display: grid;
    grid-template-areas: 
    "header"
    "body";

    grid-template-rows: 2rem 1fr;
    overflow-x: auto;

    >header {
        grid-area: header;

        display: grid;
        grid-template-areas:
                            "actionsh codah priorityh operatorh clientnameh clientnumberh clientcompanyh scheduledateh typeh";
        grid-template-columns: 8rem   6rem  9rem     12rem     12rem       10rem         1fr         10rem         12rem;
        padding-right: 0.75rem;
        background-color: ${cssVars.colorGrey["6h"]};
        color: ${cssVars.colorGrey[2]};

        >div {
            box-sizing: border-box;
            padding: 0.5rem;
            font-size: 0.75rem;
            position: relative;
            font-weight: 500;
            color: ${cssVars.colorGrey[2]};
        }
        
        >div.actionsh {
            border-left: none;
            grid-area: actionsh;
        }

        >div.priorityh {
            grid-area: priorityh;
        }

        >div.clientnameh {
            grid-area: clientnameh;
        }

        >div.clientcompanyh {
            grid-area: clientcompanyh;
            min-width: 20rem;
        }

        
        >div.clientnumberh {
            grid-area: clientnumberh;
        }

        >div.operatorh {
            grid-area: operatorh;
        }

        >div.scheduledateh{
            grid-area: scheduledateh;
        }

        >div.typeh {
            grid-area: typeh;
        }

        >div.search {
            padding: 0;
        }
    }

    >div:nth-child(2) {
        grid-area: body;
        overflow: scroll;
        overflow-x: hidden;
    }

`