import { useContext, useEffect } from "react";
import { CustomState } from "../../../../../Hooks/State.hook";
import { CustomInput } from "../../../../../components/CustomInput";
import { StyledSelecionarContatos } from "./style";
import api from "../../../../../services/api";
import { LoginContext, currentRoute } from "../../../../../contexts/global/LoginContext";
import { toast } from "react-toastify";
import formatarCPForCNPJ from "../../../../../Hooks/FormatCPFCNPJ.hook";
import { MMContext } from "../context";
import { Option } from "../../../../../components/CustomSelect";
import Input from "../../../../../components/Input";
import { defaultInput100, defaultSelect100 } from "../../../../../components-variants/defaultInputs";
import MultiSelect from "../../../../../components/MultiSelect";

interface Contato {
    CODIGO_NUMERO: number;
    CODIGO_CLIENTE: number;
    CODIGO_OPERADOR: number;
    CONTATO_NOME: string;
    CONTATO_NUMERO: string;
    RAZAO: string;
    CPF_CNPJ: string;
    NOME_OPERADOR: string;
    SELECIONADO: boolean;
    SEGMENTO?: string;
    CAMPANHA?: string;
    SETOR?: number;
}

export const SelecionarContatos = () => {
    const { selectedContacts, sectors } = useContext(MMContext);
    const { user } = useContext(LoginContext);

    const contatos = CustomState<Array<Contato>>([]);
    const filtroNome = CustomState<string>("");
    const filtroNumero = CustomState<string>("");
    const filtroRazao = CustomState<string>("");
    const filtroCNPJ = CustomState<string>("");
    const filtroOperador = CustomState<string[]>([]);
    const filtroSegmentos = CustomState<string[]>([]);
    const filtroCampanhas = CustomState<string[]>([]);
    const filtroSetores = CustomState<number[]>([]);

    const filteredContatos = contatos.value.filter(c => {
        return ((filtroNome.value ? c.CONTATO_NOME.toUpperCase().includes(filtroNome.value.toUpperCase()) : true) &&
            (filtroNumero.value ? c.CONTATO_NUMERO.startsWith(filtroNumero.value.replace(/[^0-9]/g, '')) : true) &&
            (filtroRazao.value ? c.RAZAO.toUpperCase().includes(filtroRazao.value.toUpperCase()) : true) &&
            (filtroCNPJ.value ? c.CPF_CNPJ.includes(filtroCNPJ.value.replace(/[^0-9]/g, '')) : true) &&
            (filtroOperador.value.length ? filtroOperador.value.includes(c.NOME_OPERADOR) : true) &&
            (filtroSegmentos.value.length ? filtroSegmentos.value.includes(String(c.SEGMENTO)) : true) &&
            (filtroCampanhas.value.length ? filtroCampanhas.value.includes(String(c.CAMPANHA)) : true) &&
            (filtroSetores.value.length ? filtroSetores.value.includes(c.SETOR || 0) : true)) || c.SELECIONADO
    });


    const currentPage = CustomState<number>(1);
    const itemsPerPage = CustomState<number>(10);
    const startIndex = (currentPage.value - 1) * itemsPerPage.value;
    const endIndex = startIndex + itemsPerPage.value;
    const totalPages = Math.ceil(filteredContatos.length / itemsPerPage.value);

    const visibleContatos = filteredContatos.slice(startIndex, endIndex);
    const haveSegmentos = contatos.value[0] && "SEGMENTO" in contatos.value[0] && "CAMPANHA" in contatos.value[0];

    const operadores = Array.from(new Set(contatos.value.map(c => c.NOME_OPERADOR)));
    const operadoresOptions = operadores.map(o => ({ name: o, value: o }));
    const segmentos = haveSegmentos && Array.from(new Set(contatos.value.map(c => String(c.SEGMENTO))));
    const segmentosOptions = segmentos && segmentos.map(s => ({ name: s, value: s }));
    const campanhas = haveSegmentos && Array.from(new Set(contatos.value.map(c => String(c.CAMPANHA))));
    const campanhasOptions = campanhas && campanhas.map(c => ({ name: c, value: c }));
    const sectorsOptions = sectors.value && [...sectors.value.map(s => ({ name: s.NOME, value: s.CODIGO })), { name: "Não definido", value: 0 }];

    useEffect(() => {
        api.get<Array<Omit<Contato, 'SELECIONADO'>>>(`/custom-routes/mm_contacts`)
            .then((res) => {
                let cts = res.data.map(c => {
                    c.SEGMENTO = c.SEGMENTO?.trim() || "N/D";
                    c.CAMPANHA = c.CAMPANHA?.trim() || "N/D";
                    c.NOME_OPERADOR = c.NOME_OPERADOR?.trim() || "N/D";

                    if (!c.SEGMENTO) c.SEGMENTO = "N/D";
                    if (!c.CAMPANHA) c.CAMPANHA = "N/D";
                    if (!c.NOME_OPERADOR) c.NOME_OPERADOR = "N/D";

                    return ({ ...c, SELECIONADO: false })
                });

                if (currentRoute === "natrielli" && user && user.NIVEL !== "ADMIN") {
                    contatos.set(cts.filter(c => c.SETOR === user.SETOR));
                } else {
                    contatos.set(cts);
                }

            })
            .catch((err) => {
                toast.error(err.response ? err.response.data : err.message);
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        selectedContacts.set(contatos.value.filter(c => c.SELECIONADO).map(c => c.CONTATO_NUMERO));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contatos.value]);

    useEffect(() => {
        if (currentPage.value > totalPages) {
            currentPage.set(1);
        }
    }, [totalPages, currentPage])

    return (
        <StyledSelecionarContatos>
            <header>
                <div className="check">
                    <input
                        type="checkbox"
                        onClick={(e) => {
                            contatos.set(prev => prev.map(c => {
                                if (filteredContatos.some(f => f.CODIGO_NUMERO === c.CODIGO_NUMERO)) {
                                    return { ...c, SELECIONADO: filteredContatos.some(c => !c.SELECIONADO) }
                                } else {
                                    return c;
                                }
                            }))
                        }}
                        checked={!filteredContatos.some(c => !c.SELECIONADO)}
                    />
                </div>
                <div className="contato_nome">
                    <Input
                        placeholder="Nome"
                        value={filtroNome.value}
                        onChange={(e) => filtroNome.set(e.target.value.toUpperCase())}
                        {...defaultInput100}
                        $disableBorder
                        $fontSize={0.75}
                    />
                </div>

                <div className="cliente_razao">
                    <Input
                        placeholder="Razão"
                        value={filtroRazao.value}
                        onChange={(e) => filtroRazao.set(e.target.value.toUpperCase())}
                        {...defaultInput100}
                        $disableBorder
                        $fontSize={0.75}
                    />
                </div>
                <div className="cliente_cnpj">
                    <Input
                        placeholder="CPF/CNPJ"
                        value={filtroCNPJ.value}
                        onChange={(e) => filtroCNPJ.set(e.target.value.toUpperCase())}
                        {...defaultInput100}
                        $disableBorder
                        $fontSize={0.75}
                    />
                </div>
                <div className="cliente_operador">
                    <MultiSelect
                        onChange={(v) => { filtroOperador.set(v) }}
                        options={operadoresOptions}
                        placeholder={`Operador`}
                        {...defaultSelect100}
                        $disableBorder
                        $fontSize={0.75}
                    />
                </div>
                {
                    haveSegmentos &&
                    <>
                        <div className="cliente_segmento">
                            <MultiSelect
                                onChange={(v) => { filtroSegmentos.set(v) }}
                                options={segmentosOptions as Option<string>[]}
                                placeholder={`Segmento`}
                                {...defaultSelect100}
                                $disableBorder
                                $fontSize={0.75}
                            />
                        </div>
                        <div className="cliente_campanha">
                            <MultiSelect
                                onChange={(v) => { filtroCampanhas.set(v) }}
                                options={campanhasOptions as Option<string>[]}
                                placeholder={`Campanha`}
                                {...defaultSelect100}
                                $disableBorder
                                $fontSize={0.75}
                            />
                        </div>
                    </>
                }
                {
                    currentRoute === "natrielli" && user && user.NIVEL === "ADMIN" &&
                    <div className="cliente_setor">
                        <MultiSelect
                            onChange={(v) => { filtroSetores.set(v) }}
                            options={sectorsOptions}
                            placeholder={`Setor`}
                            {...defaultSelect100}
                            $disableBorder
                            $fontSize={0.75}
                        />
                    </div>
                }
            </header>
            <ul>
                {
                    visibleContatos.map(c => (
                        <div>
                            <div className="check">
                                <input
                                    type="checkbox"
                                    checked={c.SELECIONADO}
                                    onChange={() => {
                                        contatos.set(prev => prev.map(p => {
                                            if (p.CODIGO_NUMERO === c.CODIGO_NUMERO) {
                                                return { ...p, SELECIONADO: !p.SELECIONADO }
                                            } else {
                                                return p
                                            }
                                        }))
                                    }}
                                />
                            </div>
                            <div className="contato_nome">
                                <span title={c.CONTATO_NOME}>{c.CONTATO_NOME}</span>
                            </div>

                            <div className="cliente_razao">
                                <span title={c.RAZAO}>{c.RAZAO} </span>
                            </div>
                            <div className="cliente_cnpj">
                                <span title={c.CPF_CNPJ && formatarCPForCNPJ(c.CPF_CNPJ)}>{c.CPF_CNPJ && formatarCPForCNPJ(c.CPF_CNPJ)}</span>
                            </div>
                            <div className="cliente_operador">
                                <span title={c.NOME_OPERADOR}>{c.NOME_OPERADOR}</span>
                            </div>
                            {
                                haveSegmentos &&
                                <>
                                    <div className="cliente_segmento">
                                        <span title={c.SEGMENTO}>{c.SEGMENTO || "Não definido"}</span>
                                    </div>
                                    <div className="cliente_campanha">
                                        <span title={c.CAMPANHA}>{c.CAMPANHA || "Não definido"}</span>
                                    </div>
                                </>
                            }
                            {
                                currentRoute === "natrielli" && user && user.NIVEL === "ADMIN" &&
                                <div className="cliente_setor">
                                    <span title={sectors.value.find(s => s.CODIGO === c.SETOR)?.NOME || "Não definido"}>
                                        {sectors.value.find(s => s.CODIGO === c.SETOR)?.NOME || "Não definido"}
                                    </span>
                                </div>
                            }
                        </div>
                    ))
                }
            </ul>
            <div className="pages">
                <button
                    onClick={() => {
                        if (currentPage.value > 1) {
                            currentPage.set(currentPage.value - 1);
                        }
                    }}
                    disabled={currentPage.value === 1}
                >
                    Anterior
                </button>
                <span >Página {currentPage.value} / {totalPages} </span>
                <button
                    onClick={() => {
                        if (currentPage.value < Math.ceil(filteredContatos.length / itemsPerPage.value)) {
                            currentPage.set(currentPage.value + 1);
                        }
                    }}
                    disabled={currentPage.value === totalPages}
                >
                    Próxima
                </button>

                <span> Qtd. por página: </span>
                <CustomInput
                    fontSize={0.75}
                    padding={{ horizontal: 0.25, vertical: 0.25 }}
                    disableIcon
                    type="number"
                    value={itemsPerPage.value}
                    onChange={(e) => itemsPerPage.set(+e.target.value)}
                />
                <span> Total de items: {filteredContatos.length}</span>
                <span> Items selecionados: {selectedContacts.value.length} </span>
            </div>
        </StyledSelecionarContatos>
    );
};