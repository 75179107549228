import { StyledOperadorHeader, StyledUserDiv } from "./style";
import { FaCalendarAlt, FaPause, FaPhoneAlt, FaPlay, FaSignOutAlt, FaUserAlt } from "react-icons/fa";
import { ReactNode, useContext } from "react";
import { LoginContext, currentRoute } from "../../../contexts/global/LoginContext";
import { SocketContext } from "../../../contexts/global/SocketContext";
import { AttendancesContext } from "../../../contexts/user/AttendancesContext";
import { Modal } from "../../../styles/modal";
import { MySchedulesDialog } from "../../../components/MySchedulesDialog";
import { RegisterContactsModal } from "./RegisterContactsModal";
import { RegisterCustomerModal } from "./RegisterCustomersModal";
import { ModalBasis } from "../../../components/ModalBasis";
import { MMProvider } from "../../Supervisor/Ferramentas/EnvioDeMensagensEmMassa/context";
import { EnvioDeMensagensEmMassa } from "../../Supervisor/Ferramentas/EnvioDeMensagensEmMassa";
import { FaMoon, FaSun } from "react-icons/fa6";

export const OperadorHeader = () => {
    const { logout, user, darkMode, setDarkMode } = useContext(LoginContext);
    const { socket } = useContext(SocketContext);
    const { modalState, pausedState, switchPauseAttendances } = useContext(AttendancesContext);

    const openModal = (children: ReactNode) => () => modalState.set(<Modal>{children}</Modal>);

    return (
        <StyledOperadorHeader pause={pausedState.value} >
            <div>
                {
                    socket.value &&
                    <>
                        <div
                            className="clients_queue"
                            onClick={openModal(<MySchedulesDialog />)}
                            title="Meus agendamentos"
                        >
                            <FaCalendarAlt />
                            <h4> Meus Agendamentos </h4>
                        </div>
                        <div
                            className="pause_attendances"
                            onClick={switchPauseAttendances}
                            title={!pausedState.value ? "Pausar atendimentos" : "Retomar atendimentos"}
                        >
                            {
                                !pausedState.value ?
                                    <><FaPause /><h4>Pausar Atendimentos</h4></>
                                    :
                                    <><FaPlay /><h4> Retomar Atendimentos </h4></>
                            }
                        </div>

                        <div
                            className="register_contacts"
                            onClick={openModal(<RegisterContactsModal />)}
                            title="Cadastro de contatos"
                        >
                            <FaPhoneAlt />
                            <h4> Cadastro de Contatos </h4>
                        </div>
                        <div
                            className="register_customers"
                            onClick={openModal(<RegisterCustomerModal modalState={modalState} />)}
                            title="Cadastro de clientes"
                        >
                            <FaUserAlt />
                            <h4> Cadastro de Clientes </h4>
                        </div>
                        {
                            currentRoute === "natrielli" &&
                            <div
                                className="send_mm"
                                onClick={openModal(
                                    <ModalBasis
                                        onClose={modalState.reset}
                                        title="Envio de mensagens em massa"
                                        padding={{ v: 1, h: 1 }}
                                    >
                                        <MMProvider>
                                            <div style={{
                                                maxWidth: "90vw",
                                                maxHeight: "90vh",
                                                overflowY: "scroll",
                                            }}>
                                                <EnvioDeMensagensEmMassa />
                                            </div>
                                        </MMProvider>
                                    </ModalBasis>
                                )}
                                title="Mensagens em massa"
                            >
                                <FaUserAlt />
                                <h4> Mensagens em massa </h4>
                            </div>
                        }
                    </>
                }
            </div>
            <StyledUserDiv>
                <div>
                    {
                        !darkMode ?
                            <FaSun className="avatar" onClick={() => setDarkMode(true)} />
                            :
                            <FaMoon className="avatar" onClick={() => setDarkMode(false)} />
                    }
                    <div>
                        <span> Olá, </span>
                        <h2> {user && user.NOME} </h2>
                    </div>
                </div>
                <FaSignOutAlt onClick={logout} />
            </StyledUserDiv>
        </StyledOperadorHeader>
    );
}
