import styled from "styled-components";
import cssVars from "../../styles/cssVariables.vars";


export const StyledAttendanceChat = styled.div`
    box-sizing: border-box;
    height: 100%;
    background: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    display: grid;
    grid-template-areas: 
    "chat"
    "emojiPicker";

    grid-template-columns: 100%;
    grid-template-rows: 1fr max-content;
    position: relative;
    
    ul {
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        padding: 0.5rem 1.5rem;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        overflow-y: auto;
        overflow-x: hidden;
        grid-area: chat;
        background-image: url('./bg_chat.png');
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        background-color: ${cssVars.colorGrey[8]};
    }


    >.EmojiPickerReact.epr-main {
        grid-area: emojiPicker;
    }
`;