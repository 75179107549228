import { TableColumn } from "../types";
import InputDateRange from "../../InputDateRange";
import { defaultInput50 } from "../../../components-variants/defaultInputs";
import { FilterWithPaginationQueryParameters, RangeDate } from "../../../interfaces/generics.types";
import { CustomStateHook } from "../../../Hooks/State.hook";


function filterDateRange<T>(
    column: TableColumn<T>,
    filterState: CustomStateHook<FilterWithPaginationQueryParameters<T>>,
) {

    const defaultValue = () => {
        const [start, end] = filterState.value[column.key]?.split("_").map(s => s ? new Date(s) : null) || [null, null];

        console.log("SS EE", start, end)

        return { min: start, max: end } as RangeDate
    }
    const onChange = (v: RangeDate) => {
        filterState.set(prev => {
            const { min, max } = v;
            const format = (date: Date | null) => {
                if (date) {
                    const [YYYY, month, day] = [String(date.getUTCFullYear()), date.getUTCMonth() + 1, date.getUTCDate()];

                    const MM = month >= 10 ? String(month) : `0${month}`;
                    const DD = day >= 10 ? String(day) : `0${day}`;

                    return `${YYYY}-${MM}-${DD}`;
                }

                return "";
            }

            if (min || max) {
                prev[column.key] = `${format(min)}_${format(max)}` as any;
            } else {
                delete prev[column.key];
            }

            return prev;
        });
    }

    return (
        <InputDateRange
            {...defaultInput50}
            title={(column.header || column.key) as string}
            onChange={onChange}
            defaultValue={defaultValue()}
        />
    );
}

export default filterDateRange;