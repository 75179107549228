import Table from "../../../../components/Table";
import { CustomStateHook } from "../../../../Hooks/State.hook";
import { TableColumn } from "../../../../components/Table/types";
import { ContactMessages } from "./types";

const columns: TableColumn<ContactMessages>[] = [
    {
        key: "contactId",
        header: "Código",
        width: 4,
    },
    {
        key: "contactName",
        header: "Contato",
        width: 12,
        filter: {
            type: "input",
            width: "calc(50% - 0.5rem)",
            placeholder: "Contato"
        },
    },
    {
        key: "customerName",
        header: "Razão social",
        width: 24,
        filter: {
            type: "input",
            width: "calc(50% - 0.5rem)",
            placeholder: "Razão"
        },
    },
    {
        key: "customerCnpj",
        header: "CPF/CNPJ",
        width: 12,
        filter: {
            type: "input",
            width: "calc(50% - 0.5rem)",
            placeholder: "CPF/CNPJ"
        },
    },
];

if (window.location.hostname.split('.')[0] === "natrielli") {
    columns.push({
        key: "customerSector",
        header: "Setor",
        width: 12,
        filter: {
            type: "select",
            width: "calc(50% - 0.5rem)",
        },
    })
}

columns.push(...[
    {
        key: "messagesCount" as keyof ContactMessages,
        header: "Mensagens",
        format: (r: ContactMessages) => r.messagesCount.toLocaleString(),
    },
    {
        key: "receivedMessagesCount" as keyof ContactMessages,
        header: "Recebidas",
        format: (r: ContactMessages) => r.receivedMessagesCount.toLocaleString(),
    },
    {
        key: "sentMessagesCount" as keyof ContactMessages,
        header: "Enviadas",
        format: (r: ContactMessages) => r.sentMessagesCount.toLocaleString(),
    },
]);

type Props = {
    rowsState: CustomStateHook<ContactMessages[]>;
    filteredRowsState: CustomStateHook<ContactMessages[]>;
}

const ContactsMessagesTable = ({ rowsState, filteredRowsState }: Props) => {
    return (
        <Table
            columns={columns}
            $fontSize={0.75}
            $modalFiltersWidth={40}
            $tableHeight="max-content"
            $tableWidth="calc(100% - 6px)"
            rowsState={rowsState}
            tableName="Mensagens por contato"
        />
    );
}

export default ContactsMessagesTable;