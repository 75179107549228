import styled from "styled-components";
import cssVars from "../../../../../styles/cssVariables.vars";

export const StyledDigitarMensagem = styled.div`
    height: 100%;
    display: flex;
    gap: 1rem;

    >div:nth-child(1) {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        >textarea {
            appearance: none;
            border: 2px solid ${cssVars.colorGrey[6]};
            color: ${cssVars.colorGrey[2]};
            height: 14rem;
            width: 40rem;
            resize: none;
            padding: 0.5rem;
            border-radius: 0.25rem;
            font-size: 0.875rem;
        }

        >input {
            width: 40rem;
            border: 2px solid ${cssVars.colorGrey[6]};
            border-radius: 0.25rem;
            padding: 0.5rem;
        }
    }
`;