import styled from "styled-components";
import cssVars from "../../../../../styles/cssVariables.vars";

export const StyledFormulario = styled.form`
    background-color: ${cssVars.colorGrey[8]};
    padding: 1rem;
    border-radius: 0.25rem;

    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    width: 35rem;

    >header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        >h1 {
            font-size: 0.875rem;
            font-weight: 500;
        }

        >button.closeModal {
            font-size: 1.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0.25rem;
            transition: 50ms;
            cursor: pointer;
            width: max-content;
            height: max-content;
            color: ${cssVars.colorGrey[2]};

            :hover {
                transform: scale(1.25);
            }
        }
    }

    >button[type="submit"]{
        grid-area: button;
        box-sizing: border-box;
        height: 2.5rem;

        width: 12rem;
        border-radius: 0.25rem;
        font-size: 0.875rem;
        font-weight: 500;
        cursor: pointer;
    }
`;

export const StyledInputWrapper = styled.div`
    box-sizing: border-box;
    position: relative;

    :focus-within {
        >label {
            color: rgba(0,0,0, 100%);
        }

        >input {
            border: 2px solid rgba(0, 0, 0, 100%);
        }
    }

    >label {
        position: absolute;
        top: -0.375rem;
        left: 0.5rem;
        color: rgba(0, 0, 0, 80%);

        font-size: 0.75rem;
        font-weight: 500;
        background-color: white;
        padding: 0 0.25rem;
    }

    >input:not(.phone){
        box-sizing: border-box;
        width: 100%;
        border: 2px solid rgba(0, 0, 0, 20%);
        border-radius: 0.25rem;
        font-size: 0.875rem;
        padding: 0.625rem;

        &.error {
            border-color: red;
        }
    }

    &.phone {
        box-sizing: border-box;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0.625rem;
        border: 2px solid rgba(0, 0, 0, 20%);
        border-radius: 0.25rem;
        height: 3rem;

        &.error {
            border-color: red;
        }
    }
`;