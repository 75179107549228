import styled from "styled-components";
import cssVars from "../../../../../../styles/cssVariables.vars";

export const StyledTableItem = styled.li`
        display: grid;
        grid-template-areas: "acoes id ativo nivel nome login nome_e setor";
        grid-template-columns: 4rem 4rem 6rem 14rem 14rem 14rem 14rem 1fr;
        padding-right: 0.75rem;

        &:nth-child(even) {
            background-color: ${cssVars.colorGrey["8"]};
        }

        &:nth-child(odd) {
            background-color: ${cssVars.colorGrey["7h"]};
        }


        >div {
            box-sizing: border-box;
            padding: 0.5rem;

            display: flex;
            align-items: center;
            gap: 0.25rem;
            color: ${cssVars.colorGrey[4]};
            font-size: 0.75rem;

            >svg {
                width: 1.25rem;
                height: 1.25rem;
                color: ${cssVars.colorGrey[2]};
                cursor: pointer;

                :hover {
                    transform: scale(1.1);
                    color: var(--color-primary);
                }
            }
        }

        >div.setor {
            grid-area: setor;
            min-width: 12rem;
            max-width: 16rem;
        }
`;