import styled from "styled-components";
import cssVars from "../../../../styles/cssVariables.vars";

export const StyledFormulario = styled.div`
    background-color: white;
    padding: 1rem;
    border-radius: 0.25rem;
    min-width: 35rem;

    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    >header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        padding-bottom: 0.5rem;
        
        >h2 {
            font-size: 1rem;
            font-weight: 600;
        }

        >button.closeModal {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.5rem;
            border-radius: 0.25rem;
            transition: 50ms;
            cursor: pointer;

            :hover {
                transform: scale(1.25);
            }
        }
    }
    >div {
        >textarea {
            box-sizing: border-box;
            width: 100%;
            border: 2px solid ${cssVars.colorGrey[6]};
            border-radius: 0.25rem;
            font-size: var(--font-body-1);
            padding: 0.5rem;
            height: 12rem;
            resize: none;
        }
    }

    >button.enviar{
        box-sizing: border-box;
        width: 100%;
        border-radius: 0.25rem;
        color: ${cssVars.colorGrey[8]};
        font-size: 1rem;
        font-weight: 600;
        cursor: pointer;
        transition: 300ms ease-in-out;

    }
`;
