import filterValue from "../../../../Hooks/Filter.hook";
import { CustomState } from "../../../../Hooks/State.hook";
import { OperatorSessions } from "../../../../interfaces/Users.interfaces";
import { TableItem } from "./TableItem";
import { StyledOperadores } from "./style";
import { useEffect, useContext } from "react";
import { SocketContext } from "../../../../contexts/global/SocketContext";
import api from "../../../../services/api";
import Input from "../../../../components/Input";
import { defaultInput100 } from "../../../../components-variants/defaultInputs";

export const Operadores = () => {
    const search = CustomState<string>("");
    const allUsers = CustomState<Array<OperatorSessions>>([]);
    const unduplicatedSessions = allUsers.value.reduce<Array<OperatorSessions>>((acc, user) => {
        const exists = acc.some((u) => u.operatorId === user.operatorId);

        if (!exists) {
            acc.push(user);
        }

        return acc;
    }, []);

    const filteredUsers = unduplicatedSessions.filter(s => filterValue(search.value, s.operatorData.NOME, { remove: [" "] }));

    const { socket } = useContext(SocketContext);

    useEffect(() => {
        if (socket.value) {
            socket.value!.on("update-sessions", getSessions);
        };

        return () => {
            if (socket.value) {
                socket.value.off("update-sessions", getSessions);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket.value])

    useEffect(() => {
        getSessions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function getSessions() {
        api.get(`/sessions/`)
            .then((res) => {
                allUsers.set(res.data.operatorsSessions);
            })
            .catch(() => allUsers.set([]));
    };

    return (
        <StyledOperadores>
            <header>
                <div className="idh">
                    ID
                </div>
                <div className="statush">
                    Status
                </div>
                <div className="nameh search">
                    <Input
                        placeholder="Nome"
                        value={search.value}
                        onChange={(e) => search.set(e.target.value)}
                        {...defaultInput100}
                        $disableBorder
                        $fontSize={0.75}
                    />
                </div>
                <div className="emailh">
                    Email
                </div>
                <div className="sectorh">
                    Setor
                </div>
            </header>
            <div>
                {filteredUsers.map((s, index) => <TableItem user={s} key={index} />)}
            </div>
        </StyledOperadores>
    );
};