import styled from "styled-components";

export const RegistrationButton = styled.button`
    padding: 0.5rem 2rem;
    background-color: var(--color-primary);
    border-radius: 0.25rem;
    color: white;
    font-size: 0.875rem;
    font-weight: 400;
    cursor: pointer;
    width: max-content;

    :hover {
        filter: brightness(1.25);
    }

    &:disabled {
        pointer-events: none;
        opacity: 0.75;
    }
`
