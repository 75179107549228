import { createContext, ReactNode, useEffect } from "react";
import { CustomState } from "../../Hooks/State.hook";
import { CreateReadyMessageForm, CustomMessage, CustomMessagesContextProps, CustomMessagesProviderProps } from "../../interfaces/CustomMessages.interfaces";
import api from "../../services/api";
import { toast } from "react-toastify";
import FormData from 'form-data';

export const CustomMessagesContext = createContext({} as CustomMessagesContextProps);

export const CustomMessageProvider = ({ children }: CustomMessagesProviderProps) => {
    const modalState = CustomState<ReactNode>("");
    const readyMessages = CustomState<Array<CustomMessage>>([]);

    function getAllMessages() {
        api.get<Array<CustomMessage>>(`/ready-messages/`)
            .then(res => readyMessages.set(res.data))
            .catch(err => console.error(err));
    }

    function createNewReadyMessage(data: CreateReadyMessageForm) {
        const requestForm = new FormData();

        requestForm.append("SETOR", data.SETOR);
        requestForm.append("TEXTO_MENSAGEM", data.TEXTO_MENSAGEM);
        requestForm.append("TITULO", data.TITULO);

        if (data.ARQUIVO) {
            requestForm.append("file", data.ARQUIVO, encodeURIComponent(data.ARQUIVO.name));
        }

        api.post(`/ready-messages`, requestForm)
            .then(() => {
                getAllMessages()
                toast.success("Mensagem cadastrada com sucesso!");
            })
            .catch((err) => {
                console.error(err);
                toast.error("Falha ao cadastrar mensagem...");
            });
    };

    useEffect(() => {
        getAllMessages();
    }, []);

    return (
        <CustomMessagesContext.Provider value={{
            modalState,
            readyMessages,
            getAllMessages,
            createNewReadyMessage
        }}>
            {children}
        </CustomMessagesContext.Provider>
    );
};