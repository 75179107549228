import { AttendancesContext } from "../../contexts/user/AttendancesContext";
import { ChatProvider } from "../../contexts/user/ChatContext";
import { AttendanceView } from "./AttendanceView";
import { OperadorHeader } from "./OperadorHeader";
import { OperadorMenu } from "./OperadorMenu";
import { StyledNoAttendances, StyledOperadorPage } from "./style";
import { useContext } from "react";
import logo from "../../logo.png";

export const OperadorPage = () => {

    const { readingAttendance, modalState } = useContext(AttendancesContext);

    return (
        <>
            <StyledOperadorPage>
                <ChatProvider>
                    <OperadorMenu />
                    <OperadorHeader />
                    {
                        readingAttendance.value ?
                            <AttendanceView />
                            :
                            <StyledNoAttendances>
                                <img src={logo} alt="logo" />
                            </StyledNoAttendances>
                    }
                </ChatProvider>
            </StyledOperadorPage>
            {modalState.value}
        </>
    );
};