import ListaDeTelefone from "./ListaDeTelefone";
import { ReactNode, useContext, useEffect, useState } from "react";
import { FaEdit, FaEye, FaSave } from "react-icons/fa";
import { TiCancel } from "react-icons/ti";
import { StyledTableItem } from "./style"
import { Modal } from "../../../../../../styles/modal";
import { CustomerContext } from "../../../../../../contexts/admin/Customers.context";
import { Customer } from "../../../../../../interfaces/Customers.interface";
import { CustomState } from "../../../../../../Hooks/State.hook";
import Select from "../../../../../../components/Select";
import { defaultInput100, defaultSelect100 } from "../../../../../../components-variants/defaultInputs";
import cssVars from "../../../../../../styles/cssVariables.vars";
import Input from "../../../../../../components/Input";

interface TableItemProps {
    customer: Customer;
};

export const TableItem = ({ customer }: TableItemProps) => {
    const { customersRequests } = useContext(CustomerContext);

    const [modal, setModal] = useState<ReactNode>("");
    const [disabled, setDisabled] = useState<boolean>(true);

    const closeModal = () => setModal("");
    const openModal = () => setModal(<ListaDeTelefone customer={customer} closeFunction={closeModal} />);

    const ativo = CustomState<"SIM" | "NAO">(customer.ATIVO);
    const pessoa = CustomState<"FIS" | "JUR">(customer.PESSOA);
    const razao = CustomState<string>(customer.RAZAO);
    const cnpj = CustomState<string>(customer.CPF_CNPJ);
    const city = CustomState<string>(customer.CIDADE);

    function cancelEdit() {
        ativo.reset();
        pessoa.reset();
        razao.reset();
        city.reset();
        cnpj.reset();
        setDisabled(true);
    };

    useEffect(() => {
        cancelEdit()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customer])

    function saveEdit() {
        const data: Partial<Customer> = {
            ATIVO: ativo.value,
            RAZAO: razao.value,
            CIDADE: city.value,
            PESSOA: pessoa.value,
            CPF_CNPJ: cnpj.value,

        };

        setDisabled(true);
        customersRequests.updateFunc(customer.CODIGO, data);
    };

    return (
        <StyledTableItem>
            <div>
                {
                    disabled ?
                        <FaEdit onClick={() => { setDisabled(false) }} />
                        :
                        <>
                            <FaSave onClick={() => saveEdit()} color="rgba(15, 215, 50, 80%)" />
                            <TiCancel onClick={cancelEdit} size="1.25rem" color="red" />
                        </>
                }
                <FaEye onClick={() => openModal()} />
            </div>
            <div>
                {customer.CODIGO}
            </div>
            <div>
                <Select
                    defaultValue={{ name: ativo.value, value: ativo.value }}
                    options={[{ name: "SIM", value: "SIM" }, { name: "NAO", value: "NAO" }]}
                    onChange={(v) => ativo.set(v as "SIM" | "NAO")}
                    {...defaultSelect100}
                    $fontSize={0.75}
                    $disableBorder
                    $focusColor={cssVars.colorGrey[2]}
                />
            </div>
            <div>
                <Select
                    defaultValue={{ name: pessoa.value === "FIS" ? "Física" : "Jurídica", value: pessoa.value }}
                    options={[{ name: "Física", value: "FIS" }, { name: "Jurídica", value: "FIS" }]}
                    onChange={(v) => pessoa.set(v as "FIS" | "JUR")}
                    {...defaultSelect100}
                    $fontSize={0.75}
                    $disableBorder
                    $focusColor={cssVars.colorGrey[2]}
                />
            </div>
            <div>
                <Input
                    disabled={disabled}
                    placeholder="Insira a razão social"
                    value={razao.value}
                    onChange={(e) => razao.set(e.target.value)}
                    {...defaultInput100}
                    $fontSize={0.75}
                    $disableBorder
                />
            </div>

            <div>
                <Input
                    disabled={disabled}
                    placeholder="Insira o CPF ou CNPJ."
                    value={cnpj.value}
                    onChange={(e) => {
                        const inputValue = e.target.value;
                        const numericValue = inputValue.replace(/\D/g, '');
                        cnpj.set(numericValue);
                    }}
                    {...defaultInput100}
                    $fontSize={0.75}
                    $disableBorder
                />
            </div>
            <div>
                <Input
                    disabled={disabled}
                    placeholder="Insira a CIDADE"
                    value={city.value}
                    onChange={(e) => {
                        const inputValue = e.target.value;
                        const sanitizedValue = inputValue.replace(/[^a-zA-Z\s]/g, '');
                        city.set(sanitizedValue);
                    }}
                    {...defaultInput100}
                    $fontSize={0.75}
                    $disableBorder
                />
            </div>
            <div>
                {customer?.COD_ERP || "N/D"}
            </div>
            {
                modal &&
                <Modal>
                    {modal}
                </Modal>
            }
        </StyledTableItem>
    );
};