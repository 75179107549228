import { EmojiClickData } from "emoji-picker-react";
import React, { createContext, useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { ChatContextProps, ChatProviderProps, FormData as IFormData } from "../../interfaces/Chat.interfaces";
import { SocketContext } from "../global/SocketContext";
import { AttendancesContext } from "./AttendancesContext";
import { CustomState } from "../../Hooks/State.hook";
import api from "../../services/api";
import { AttendanceMessage, SpecificAttendanceDetails } from "../../interfaces/Attendances.interfaces";
import { LoginContext, currentRoute } from "../global/LoginContext";
import { UserData } from "../../interfaces/Users.interfaces";
import FormData from 'form-data';
import uuid from 'react-uuid';

export const ChatContext = createContext({} as ChatContextProps);

export const ChatProvider = ({ children }: ChatProviderProps) => {

    const { user } = useContext(LoginContext);
    const { socket } = useContext(SocketContext);
    const { readingAttendance, attendancesMessages } = useContext(AttendancesContext);

    const [messagesArr, setMessagesArr] = useState<any[]>([]);
    const [isEmotePickerActive, changeEmotePickerState] = useState<boolean>(false);

    const referenceId = CustomState<string>("");
    const replyingMessage = CustomState<AttendanceMessage | undefined>(undefined);
    const formData = CustomState<IFormData>({ text: "", referenceId: null, file: null, isAudio: false });

    const closeFile = () => formData.set((prev) => {
        return { ...prev, file: null };
    });

    useEffect(() => {
        formData.reset();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [readingAttendance.value]);

    useEffect(() => {
        if (referenceId.value) {
            replyingMessage.set(findReferenceMessage(referenceId.value));

            formData.set((prev) => {
                return { ...prev, referenceId: referenceId.value }
            })
        } else {
            replyingMessage.reset();

            formData.set((prev) => {
                return { ...prev, referenceId: null }
            });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [referenceId.value, readingAttendance.value]);

    useEffect(() => {
        referenceId.reset();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [readingAttendance.value]);

    async function getBase64(id: number) {
        try {
            const res = await api.get<{ base64: string }>(`/files/messages/base64/${id}`, {
                baseURL: `${process.env.REACT_APP_URL}`
            });
            return res.data.base64;
        } catch {
            return "";
        };
    };

    async function deleteMessage(id: string) {
        if (socket.value) {
            socket.value.emit("delete-message", id);
        };
    };

    function sendMessage(attendance: SpecificAttendanceDetails) {
        if (socket.value) {
            const userData = user as UserData;

            const requestForm = new FormData();

            const localId = uuid();

            requestForm.append("clientName", currentRoute);
            requestForm.append("operatorId", userData.CODIGO);
            requestForm.append("attendanceId", attendance.CODIGO);
            requestForm.append("contactId", attendance.CODIGO_NUMERO);
            requestForm.append("customerId", attendance.CODIGO_CLIENTE);
            requestForm.append("text", formData.value.text);
            requestForm.append("isAudio", formData.value.isAudio);
            requestForm.append("localId", localId);
            formData.value.referenceId && requestForm.append("referenceId", formData.value.referenceId);
            formData.value.file && requestForm.append("file", formData.value.file);
            formData.value.file && requestForm.append("encoded_filename", encodeURI(formData.value.file.name));

            const newMessage: AttendanceMessage = {
                CODIGO: Date.now(),
                CODIGO_ATENDIMENTO: attendance.CODIGO,
                CODIGO_NUMERO: attendance.CODIGO_NUMERO,
                CODIGO_OPERADOR: userData.CODIGO,
                DATA_HORA: new Date(),
                FROM_ME: 1,
                ID: localId,
                ID_REFERENCIA: formData.value.referenceId || null,
                MENSAGEM: `*${userData.NOME}*:\n${formData.value.text}`,
                STATUS: "PENDING",
                TIMESTAMP: Date.now(),
                TIPO: "",
                LOCAL_ID: localId,
            }

            const oldMessages = [...attendancesMessages.value, newMessage];

            attendancesMessages.set(oldMessages);

            api.post(`/custom-routes/send-message`, requestForm)
                .catch((err) => {
                    console.error(err);
                    toast.error("Falha ao enviar mensagem");
                })

            formData.reset();
        } else {
            toast.error("Socket isnt connected.");
        };
    };

    function findReferenceMessage(id_ref: string) {
        if (readingAttendance.value) {
            const attendanceMessages = attendancesMessages.value.filter((m) => m.CODIGO_NUMERO === readingAttendance.value!.CODIGO_NUMERO);
            const findMessage = attendanceMessages.find(m => m.ID.includes(id_ref) || id_ref.includes(m.ID));

            return findMessage;
        };
    };

    function onEmojiClick(emojiData: EmojiClickData, event: MouseEvent) {
        formData.set((prev) => {
            return { ...prev, text: prev.text + emojiData.emoji }
        });
        changeEmotePickerState(false);
    };

    function handlePaste(event: React.ClipboardEvent<HTMLInputElement>) {
        const clipboardData = event.clipboardData || (window as any).clipboardData;
        if (clipboardData) {
            const items = Array.from(clipboardData.items);
            const fileItems = items.filter((item) => item.kind === "file");

            if (fileItems.length > 0) {
                const file = fileItems[0].getAsFile();

                formData.set((prev) => {
                    return { ...prev, file: file, isAudio: false }
                });
            };
        };
    };

    function handleDrop(e: React.DragEvent<HTMLDivElement>) {
        e.preventDefault();
        const files = e.dataTransfer.files;

        formData.set((prev) => {
            return { text: prev.text, referenceId: prev.referenceId, file: files[0], isAudio: false };
        });
    };

    function handleFileSelect(e: React.ChangeEvent<HTMLInputElement>) {
        const file = e.target.files?.[0];

        if (file) {
            formData.set((prev) => {
                return { ...prev, file: file, isAudio: false };
            });
        };
    };

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => { e.preventDefault() };

    return (
        <ChatContext.Provider value={{
            isEmotePickerActive,
            changeEmotePickerState,
            messagesArr,
            setMessagesArr,
            closeFile,
            handleDrop,
            handleFileSelect,
            handleDragOver,
            handlePaste,
            onEmojiClick,
            sendMessage,
            findReferenceMessage,
            referenceId,
            replyingMessage,
            getBase64,
            deleteMessage,
            formData
        }}>
            {children}
        </ChatContext.Provider>
    );
};