import { IoMdClose } from "react-icons/io";
import { FaLocationDot, FaTableList } from "react-icons/fa6";
import { PiIdentificationCardBold } from "react-icons/pi";
import { StyledUpdateClientDialog } from "./style";
import { ReactNode, useEffect } from "react";
import { CustomState, CustomStateHook } from "../../Hooks/State.hook";
import { Customer } from "../../interfaces/Customers.interface";
import api from "../../services/api";
import formatarCPForCNPJ from "../../Hooks/FormatCPFCNPJ.hook";
import { PageNavigation } from "../PageNavigation";
import { RegistrationButton } from "../../styles/registrationButton";
import { toast } from "react-toastify";
import { Option } from "../CustomSelect";
import Input from "../Input";
import { defaultInput100, defaultSelect100 } from "../../components-variants/defaultInputs";
import cssVars from "../../styles/cssVariables.vars";
import Select from "../Select";
import { FaSearch } from "react-icons/fa";

interface Props {
    modalState: CustomStateHook<ReactNode>;
    contactId: number;
};

export const UpdateClientDialog = ({ contactId, modalState }: Props) => {
    const loadedCustomers = CustomState<Array<Customer>>([]);
    const currentPage = CustomState<number>(1);
    const nextPage = CustomState<string | null>(null);
    const previousPage = CustomState<string | null>(null);
    const loadingState = CustomState<boolean>(false);

    const searchState = CustomState<string>("");
    const fieldState = CustomState<string>("RAZAO");

    const searchFieldOptions: Array<Option<string>> = [
        { name: "Razão Social", value: "RAZAO" },
        { name: "Código inPulse", value: "CODIGO" },
        { name: "Código ERP", value: "COD_ERP" },
        { name: "CPF/CNPJ", value: "CPF_CNPJ" },
        { name: "Número Whatsapp", value: "NUMERO" },
    ];

    function handleSearch() {
        loadingState.set(true);
        api.get<{ data: Customer[], nextPage: string | null, previousPage: string | null }>(`/customers/custom/filter`, {
            params: {
                page: currentPage.value,
                perPage: 20,
                field: fieldState.value,
                value: searchState.value
            }
        }).then((res) => {
            loadedCustomers.set(res.data.data);
            nextPage.set(res.data.nextPage);
            previousPage.set(res.data.previousPage);
        }).catch((err) => {
            console.error(err);
            loadedCustomers.set([]);
        }).finally(() => {
            loadingState.set(false);
        });
    };

    useEffect(() => {
        handleSearch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage.value])

    async function handleSelect(clientId: number) {
        await api.patch(`/contacts/${contactId}`, { CODIGO_CLIENTE: clientId })
            .then(() => {
                toast.success("Cliente atribuído com sucesso!");
                modalState.reset();
            })
    };

    return (
        <StyledUpdateClientDialog>
            <header>
                <h2> Atribuir contato a um cliente </h2>
                <button onClick={modalState.reset}>
                    <IoMdClose />
                </button>
            </header>
            <main>
                <h3>
                    Selecione o cliente:
                </h3>
                <div className="search">
                    <Input
                        {...defaultInput100}
                        $fontSize={1}
                        $padding={[0.5, 0.5]}
                        placeholder="Pesquisar cliente"
                        onChange={(e) => searchState.set(e.target.value)}
                        value={searchState.value}
                        leftIcon={<FaSearch />}
                    />
                    <Select
                        {...defaultSelect100}
                        $fontSize={1}
                        $padding={[0.5, 0.5]}
                        onChange={(v) => fieldState.set(v || "")}
                        options={searchFieldOptions}
                        defaultValue={searchFieldOptions[0]}
                        $focusColor={cssVars.colorGrey[2]}
                        $color={cssVars.colorGrey[3]}
                    />
                    <RegistrationButton onClick={handleSearch}>
                        Buscar
                    </RegistrationButton>
                </div>
                <ul>
                    {
                        loadedCustomers.value.map((customer) => {
                            return (
                                <li
                                    key={`customer_${customer.CODIGO}`}
                                    onClick={() => handleSelect(customer.CODIGO)}
                                >
                                    <div>
                                        <h4> {customer.RAZAO} </h4>
                                        <h5>
                                            <PiIdentificationCardBold
                                                size={"1.25rem"}
                                                color={"rgba(65, 15, 155)"}
                                            />
                                            {formatarCPForCNPJ(customer.CPF_CNPJ)}
                                        </h5>
                                        <h5>
                                            <FaLocationDot
                                                size={"1.25rem"}
                                                color={"rgba(185, 35, 35)"}
                                            />
                                            {customer.ESTADO} - {customer.CIDADE}
                                        </h5>
                                    </div>
                                    <FaTableList
                                        size={"1rem"}
                                        color={"rgba(185, 35, 35)"}
                                    />
                                </li>
                            )
                        })
                    }
                </ul>
                <PageNavigation
                    currentPage={currentPage}
                    nextPage={nextPage}
                    previousPage={previousPage}
                />
            </main>
        </StyledUpdateClientDialog>
    )
};