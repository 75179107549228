import EmojiPicker from "emoji-picker-react";
import { useContext, useRef, useEffect } from "react";
import { ChatContext } from "../../contexts/user/ChatContext";
import { FileSendForm } from "./FileSendForm";
import { StyledAttendanceChat } from "./style";
import { TypeMessageForm } from "./TypeMessageForm";
import { Message } from "./Message";
import { AttendancesContext } from "../../contexts/user/AttendancesContext";
import { AttendanceMessage, SpecificAttendanceDetails } from "../../interfaces/Attendances.interfaces";
import { CustomState } from "../../Hooks/State.hook";
import { SocketContext } from "../../contexts/global/SocketContext";
import { currentRoute } from "../../contexts/global/LoginContext";

interface Props {
    attendance: SpecificAttendanceDetails;
    desactivateChat?: boolean;
};

export const AttendanceChat = ({ attendance, desactivateChat }: Props) => {

    const isChatDesactivated: boolean = desactivateChat || false;

    const {
        isEmotePickerActive,
        handleDrop,
        handleDragOver,
        onEmojiClick,
        handlePaste,
        formData
    } = useContext(ChatContext);

    const { attendancesMessages } = useContext(AttendancesContext);
    const { socket } = useContext(SocketContext);
    const readingAttendanceMessages = CustomState<Array<AttendanceMessage>>([]);

    useEffect(() => {

        const messages = attendancesMessages.value.filter((m) => m.CODIGO_NUMERO === attendance.CODIGO_NUMERO && m.TIPO !== "e2e_notification");
        const uniqueCodigos = new Set();
        const filteredByCodigo = messages.filter((message) => {
            if (!uniqueCodigos.has(message.CODIGO)) {
                uniqueCodigos.add(message.CODIGO);
                return true;
            }
            return false;
        });

        const sortedMessages = filteredByCodigo.sort((a, b) => new Date(a.TIMESTAMP).getTime() - new Date(b.TIMESTAMP).getTime())
        readingAttendanceMessages.set(sortedMessages);


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attendance, attendancesMessages.value]);

    const ulRef = useRef<HTMLUListElement>(null);

    useEffect(() => {
        if (ulRef.current) {
            ulRef.current.scrollTop = ulRef.current.scrollHeight;
        }

        if (socket.value) {
            socket.value.emit("read-attendance-messages", {
                attendanceId: attendance.CODIGO_NUMERO,
                clientName: currentRoute
            });

            const oldMessages = [...attendancesMessages.value];
            const notReadMessages = oldMessages.filter(n => !n.FROM_ME && n.STATUS !== "READ" && readingAttendanceMessages.value.some(m => m.CODIGO === n.CODIGO));

            if (notReadMessages.length) {
                for (const i in oldMessages) {
                    if (
                        readingAttendanceMessages.value.some(m => m.CODIGO === oldMessages[i].CODIGO) &&
                        !oldMessages[i].FROM_ME &&
                        oldMessages[i].STATUS !== "READ"
                    ) {
                        oldMessages[i].STATUS = "READ";
                    }
                }
                attendancesMessages.set(oldMessages);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [readingAttendanceMessages.value, socket.value]);

    return (
        <>
            <StyledAttendanceChat
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onPaste={handlePaste}
            >
                {
                    !isChatDesactivated &&
                        (formData.value.file && !formData.value.isAudio) ?
                        <FileSendForm attendance={attendance} />
                        :
                        <>
                            <ul ref={ulRef} >
                                {
                                    attendance &&
                                    readingAttendanceMessages.value.map((m, i) => <Message message={m} key={i + m.ID} />)
                                }
                            </ul>
                            {
                                !isChatDesactivated &&
                                isEmotePickerActive &&
                                <EmojiPicker
                                    width="calc(100% - 0.25rem)"
                                    height="70vh"
                                    previewConfig={{
                                        showPreview: false
                                    }}
                                    onEmojiClick={onEmojiClick}
                                />
                            }
                            {
                                !isChatDesactivated && attendance.CONTATO_NUMERO &&
                                <TypeMessageForm attendance={attendance} />
                            }
                        </>
                }
            </StyledAttendanceChat>
        </>
    );
};