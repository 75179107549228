import styled from "styled-components";
import cssVars from "../../styles/cssVariables.vars";

export const StyledQrCode = styled.div`
    background-color: ${cssVars.colorGrey[8]};
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    border-radius: 0.25rem;
`;