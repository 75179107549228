import StyledTable from "./style";
import { TableColumn, TableProps, TablePropsWithEndpoint, TablePropsWithRows } from "./types";
import { ReactNode, useEffect, useRef } from "react";
import { PaginatedResponse, customRequest } from "../../services/api";
import LoadingSpinner from "../LoadingSpinner";
import TableHeader from "./TableHeader";
import { CustomState } from "../../Hooks/State.hook";
import { FilterWithPaginationQueryParameters } from "../../interfaces/generics.types";
import { filter, orderBy } from "./utils";

function Table<T>(props: TableProps<T>) {
    const {
        columns,
        actions,
        tableName,
        enableAutoUpdate,
        $showTitle,
        ...rest
    } = props;

    const propsRowsState = (props as TablePropsWithRows<T>).rowsState;
    const propsFilterState = (props as TablePropsWithRows<T>).appliedFiltersState;
    const propsFlteredRowsState = (props as TablePropsWithRows<T>).filteredRowsState;

    const tableRef = useRef<null | HTMLTableElement>(null);
    const requestEndpoint = (props as TablePropsWithEndpoint<T>).requestEndpoint;
    const rowsState = propsRowsState || CustomState<Array<T>>([]);
    const filteredRowsState = propsFlteredRowsState || CustomState<Array<T>>(rowsState.value);
    const loadingState = CustomState<boolean>(false);
    const displayColumnsState = CustomState<Array<TableColumn<T>>>([...columns]);
    const displayActionsState = CustomState<boolean>(true);
    const modalState = CustomState<ReactNode>("");
    const isMaximizedState = CustomState<boolean>(false);
    const autoUpdateState = CustomState<boolean>(false);
    const autoUpdateIntervalState = CustomState<number>(5);
    const appliedFiltersState = propsFilterState || CustomState({ page: 1, perPage: 10 } as FilterWithPaginationQueryParameters<T>);
    const nextPageState = CustomState<boolean>(false);
    const prevPageState = CustomState<boolean>(false);

    const styleProps = { ...rest, requestEndpoint: undefined, rowsState: undefined, filteredRowsState: undefined };

    const { page, perPage } = appliedFiltersState.value;
    const totalRows = filteredRowsState.value.length;
    const startIndex = perPage * (page - 1);
    const endIndex = startIndex + perPage;

    const controlled = !!rowsState;

    useEffect(() => {
        if (controlled) {
            const nextPage = endIndex < totalRows;
            const prevPage = page > 1;

            nextPageState.set(nextPage);
            prevPageState.set(prevPage);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controlled, totalRows, endIndex])

    useEffect(() => {
        if (requestEndpoint) {
            customRequest<PaginatedResponse<T>, undefined>({
                method: "get",
                endpoint: requestEndpoint,
                params: appliedFiltersState.value,
                onSuccess: (res) => {
                    rowsState.set(res.data);
                    prevPageState.set(res.page.previous);
                    nextPageState.set(res.page.next);
                },
                loadingState
            });
        }

        appliedFiltersState.value.ORDENAR_POR ?
            filteredRowsState.set(
                orderBy(
                    filter(rowsState.value, appliedFiltersState.value, columns),
                    appliedFiltersState.value.ORDENAR_POR,
                    !!appliedFiltersState.value.ORDEM_ASC
                )
            )
            :
            filteredRowsState.set(
                filter(rowsState.value, appliedFiltersState.value, columns)
            );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appliedFiltersState.value, rowsState.value]);

    useEffect(() => {
        let intervalId: NodeJS.Timeout | null = null;

        if (autoUpdateState.value && requestEndpoint) {
            intervalId = setInterval(() => {
                customRequest<PaginatedResponse<T>, undefined>({
                    method: "get",
                    endpoint: requestEndpoint,
                    params: appliedFiltersState.value,
                    onSuccess: (res) => {
                        rowsState.set(res.data);
                        prevPageState.set(res.page.previous);
                        nextPageState.set(res.page.next);
                    },
                    loadingState
                });
            }, autoUpdateIntervalState.value * 1000);
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestEndpoint, appliedFiltersState.value, autoUpdateState.value, autoUpdateIntervalState.value]);

    const getRowKey = (row: T, key: keyof T) => String(row[key]);

    return (
        <StyledTable
            $columns={displayColumnsState.value}
            $isMaximized={isMaximizedState.value}
            $haveActions={!!actions}
            $showTitle={!!$showTitle}
            {...styleProps}
        >
            <TableHeader<T>
                $fontSize={styleProps.$fontSize}
                $modalFiltersWidth={styleProps.$modalFiltersWidth}
                $tableHeight={styleProps.$tableHeight}
                $tableWidth={styleProps.$tableWidth}
                columns={columns}
                displayActionsState={displayActionsState}
                displayColumnsState={displayColumnsState}
                loadingState={loadingState}
                modalState={modalState}
                isMaximizedState={isMaximizedState}
                currentPage={appliedFiltersState.value.page}
                rowsCount={filteredRowsState.value.length}
                rowsState={rowsState}
                haveActions={!!actions}
                tableName={tableName}
                tableRef={tableRef}
                enableAutoUpdate={enableAutoUpdate || false}
                autoUpdateState={autoUpdateState}
                autoUpdateIntervalState={autoUpdateIntervalState}
                appliedFiltersState={appliedFiltersState}
                nextPage={nextPageState.value}
                prevPage={prevPageState.value}
            />
            {
                !loadingState.value &&
                <table ref={tableRef}>
                    <thead>
                        <tr>
                            {
                                actions && displayActionsState.value &&
                                <th>
                                    Ações
                                </th>
                            }
                            {
                                displayColumnsState.value.map((c, i) => (
                                    <th key={`column_${i}`}>
                                        {c.header || String(c.key)}
                                    </th>
                                ))
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            filteredRowsState.value.slice(startIndex, endIndex).map((row, index) => (
                                <tr key={`row_${index}`}>
                                    {
                                        displayActionsState.value && actions &&
                                        (<td> {actions(row)} </td>)
                                    }
                                    {
                                        displayColumnsState.value.map((c, i) => (
                                            <td key={`row_${index}_column_${i}`}>
                                                {
                                                    c.format ?
                                                        c.format(row)
                                                        :
                                                        getRowKey(row, c.key)
                                                }
                                            </td>
                                        ))
                                    }
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            }
            {loadingState.value && <LoadingSpinner />}
            {modalState.value}
            {
                $showTitle &&
                <span className="title">
                    {tableName}
                </span>
            }
        </StyledTable >
    );
}

export default Table;