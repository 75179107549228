import { StyledOperadorMenu } from "./style";
import logo from "../../../logoh.png";
import { MyAttendancesMenu } from "./MyAttendancesMenu";

export const OperadorMenu = () => {
    return (
        <StyledOperadorMenu>
            <header> <img src={logo} alt="logo" /> inPulse CRM </header>
            <MyAttendancesMenu />
        </StyledOperadorMenu>
    );
};