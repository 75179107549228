import Chart from "react-apexcharts";
import { useContext, useEffect } from "react";
import { ApexOptions } from "apexcharts";
import { CustomState } from "../../../../Hooks/State.hook";
import { MessagesPerHourAndDayResponse } from "./types";
import { customRequest } from "../../../../services/api";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import { LoginContext } from "../../../../contexts/global/LoginContext";
import StyledMessagesByHourAndDay from "./style";

const generateDataForDay = (day: string) => {
    const hours = [
        '00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00',
        '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00',
        '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'
    ];

    return {
        name: day,
        data: hours.map((hour) => ({ x: hour, y: 0 }))
    };
}

function MessagesByHourAndDay() {
    const days = ["Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado", "Domingo"];
    const generatedSeries = [...days.map(day => generateDataForDay(day))];

    const { darkMode } = useContext(LoginContext);

    const options: ApexOptions = {
        chart: {
            type: 'heatmap',
        },
        dataLabels: {
            enabled: true,
            style: {
                colors: ["var(--color-grey-1)"]
            },
        },
        legend: {
            show: false,
        },
        theme: {
            mode: darkMode ? "dark" : "light"
        },
        noData: {
            text: "Sem dados"
        },
        tooltip: {
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                const day = w.globals.seriesNames[seriesIndex];
                const hour = w.globals.labels[dataPointIndex];
                const value = series[seriesIndex][dataPointIndex];

                return `
                <div style="padding: 5px; color: white; background-color: #333;">
                    ${day} ${hour}<br>
                    ${Number(value)}
                </div>`;
            }
        }
    };

    const series = CustomState<ApexAxisChartSeries>(generatedSeries);
    const isLoading = CustomState<boolean>(false);
    const loaded = CustomState<boolean>(false);

    useEffect(() => {
        customRequest<MessagesPerHourAndDayResponse, null>({
            method: "get",
            endpoint: "/dashboard/messages-per-hour-day",
            onSuccess: (data) => {
                console.log(data);
                const prev = generatedSeries;
                console.log("prev =>", prev)

                data.messagesPerHourDay.forEach(h => {
                    (prev[h.weekDay - 1].data[h.hour])["y"] = Number(h.messagesCount);
                });

                series.set(prev);
                loaded.set(true);
            },
            loadingState: isLoading,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <StyledMessagesByHourAndDay>
            {
                (loaded.value && series.value) ?
                    <Chart
                        options={options}
                        series={series.value}
                        type="heatmap"
                        width="100%"
                        height="96%"
                    />
                    :
                    <> </>
            }
            {
                isLoading.value && <LoadingSpinner />
            }
        </StyledMessagesByHourAndDay>
    );
};

export default MessagesByHourAndDay;
