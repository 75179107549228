import cssVars from "../../../styles/cssVariables.vars";
import Select, { Option } from "../../Select";
import { TableColumn } from "../types";
import { CustomStateHook } from "../../../Hooks/State.hook";
import { FilterWithPaginationQueryParameters } from "../../../interfaces/generics.types";

function filterSelect<T>(
    column: TableColumn<T>,
    rowsState: CustomStateHook<Array<T>>,
    filterState: CustomStateHook<FilterWithPaginationQueryParameters<T>>,
) {
    const uniqueColumnValues = Array.from(new Set(rowsState.value.map(r => r[column.key])))
    const options = (column.filter!.options as Array<Option<string>>) || uniqueColumnValues.map((ucv) => ({
        name: column.format ? column.format({ [column.key]: ucv } as T) as string : ucv as string,
        value: ucv
    }));

    const placeholder = column.filter!.placeholder || `Selecione um(a) ${column.header?.toLowerCase()}`;

    const defaultValue = options.find(o => o.value === filterState.value[column.key]) || undefined;

    const onChange = (v: string | null) => {
        filterState.set(prev => {
            if (v) {
                prev[column.key] = v as any;
            } else {
                delete prev[column.key];
            }

            return prev;
        });
    }

    return (
        <Select<string>
            $color={cssVars.colorGrey[3]}
            $borderColor={cssVars.colorGrey[2]}
            $focusColor={cssVars.colorGrey[0]}
            $padding={[0.5, 0.5]}
            $fontSize={1}
            $width={column.filter!.width}
            placeholder={placeholder}
            onChange={onChange}
            options={options}
            defaultValue={defaultValue}
        />
    );
}

export default filterSelect;