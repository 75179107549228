import { FaEye } from "react-icons/fa";
import { OficialWhatsappMessageTemplate } from "../../../../interfaces/MessageTemplates.interfaces";
import { SupervisorContext } from "../../../../contexts/admin/Supervisor.context";
import { useContext } from "react";
import { ViewMessageTemplate } from "../../../../components/ViewMessageTemplate";
import cssVars from "../../../../styles/cssVariables.vars";

interface Props {
    messageTemplate: OficialWhatsappMessageTemplate;
};

export const TableItem = ({ messageTemplate }: Props) => {
    const { modalState } = useContext(SupervisorContext);

    return (
        <li>
            <div style={{ gridArea: "actions", display: "flex", alignItems: "center", justifyContent: "center", gap: "0.5rem" }}>
                <button
                    title="Visualizar conteúdo da mensagem"
                    onClick={() => modalState.set(<ViewMessageTemplate messageTemplate={messageTemplate} disableVars />)}
                >
                    <FaEye color={cssVars.colorGrey[3]} />
                </button>
            </div>
            <div style={{ gridArea: "id" }}>
                {messageTemplate.id}
            </div>
            <div style={{ gridArea: "status" }}>
                {messageTemplate.status}
            </div>
            <div style={{ gridArea: "lang" }}>
                {messageTemplate.language}
            </div>
            <div style={{ gridArea: "category" }}>
                {messageTemplate.category}
            </div>
            <div style={{ gridArea: "name" }}>
                {messageTemplate.name}
            </div>
        </li>
    );
};