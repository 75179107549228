import { IoMdClose, IoMdRefresh } from "react-icons/io";
import { StyledMySchedulesDialog } from "./style";
import { AttendancesContext } from "../../contexts/user/AttendancesContext";
import { useContext, useEffect } from "react";
import { CustomState } from "../../Hooks/State.hook";
import { ScheduledAttendanceDetails } from "../../interfaces/Attendances.interfaces";
import api from "../../services/api";
import { LoginContext } from "../../contexts/global/LoginContext";
import LoadingSpinner from "../LoadingSpinner";
import { ScheduleItem } from "./ScheduleItem";

export const MySchedulesDialog = () => {
    const { user } = useContext(LoginContext);
    const { modalState } = useContext(AttendancesContext);
    const scheduledAttendances = CustomState<Array<ScheduledAttendanceDetails>>([]);
    const loading = CustomState<boolean>(false);

    useEffect(() => { refresh() }, []);

    function refresh() {
        if (user) {
            loading.set(true);
            api.get<Array<ScheduledAttendanceDetails>>(`/custom-routes/scheduled-attendances/${user.CODIGO}`)
                .then(res => {
                    scheduledAttendances.set(res.data);
                }).catch(err => console.error(err))
                .finally(() => loading.reset());
        };
    };

    return (
        <StyledMySchedulesDialog>
            <header>
                <h2> Meus agendamentos </h2>
                <div>
                    <button onClick={refresh}>
                        <IoMdRefresh />
                    </button>
                    <button onClick={modalState.reset}>
                        <IoMdClose />
                    </button>
                </div>

            </header>
            <div>
                {
                    loading.value ?
                        <div className="center">
                            <LoadingSpinner />
                        </div>
                        :
                        scheduledAttendances.value.length ?
                            <ul>
                                {scheduledAttendances.value.map(s => <ScheduleItem key={`attendance_${s.CODIGO}`} attendance={s} />)}
                            </ul>
                            :
                            <div className="center">
                                <h2> Você não tem agendamentos no momento. </h2>
                            </div>
                }
            </div>
        </StyledMySchedulesDialog>
    );
};