import { useContext, useEffect, useState } from "react";
import { FaBan, FaCalendarAlt, FaExchangeAlt } from "react-icons/fa";
import { StyledTableItem } from "./style";
import api from "../../../../../services/api";
import { SupervisorContext } from "../../../../../contexts/admin/Supervisor.context";
import { UpdatePriorityDialog } from "../../../../../components/UpdatePriorityDialog";
import { UpdateOperatorDialog } from "../../../../../components/UpdateOperatorDialog";
import { ScheduleDialog } from "../../../../../components/ScheduleDialog";
import { IoMdAlert } from "react-icons/io";
import { toast } from "react-toastify";
import { ScheduledAttendanceDetails } from "../../../../../interfaces/Attendances.interfaces";
import { formatNumber } from "../../../../../Hooks/FormatNumber.hook";

interface iTableProps {
    attendance: ScheduledAttendanceDetails;
    update: () => void;
}

export const TableItem = ({ attendance, update }: iTableProps) => {
    const [urgencia, setUrgencia] = useState("NORMAL");

    useEffect(() => {
        const { URGENCIA_SUPERVISOR, URGENCIA_AGENDAMENTO, URGENCIA_OPERADOR } = attendance;
        if (URGENCIA_SUPERVISOR) setUrgencia(URGENCIA_SUPERVISOR);
        else if (URGENCIA_AGENDAMENTO) setUrgencia(URGENCIA_AGENDAMENTO);
        else setUrgencia(URGENCIA_OPERADOR);
    }, [attendance]);

    const { modalState } = useContext(SupervisorContext);

    useEffect(() => {
        update();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalState.value]);


    function cancelSchedule() {
        api.put(`/attendances/${attendance.CODIGO}/cancelSchedule`)
            .then(res => {
                toast.success(res.data.message);
                update();
            })
            .catch(res => {
                toast.error(res.data.message);
            });
    };

    return (
        <StyledTableItem className={attendance.CONTATO === "CONTATO EXCLUÍDO" ? "deleted_contact" : ""}>
            <div className="actions">
                <IoMdAlert title="Editar Prioridade" onClick={() => modalState.set(<UpdatePriorityDialog CODIGO_ATENDIMENTO={attendance.CODIGO} modalState={modalState} />)} />
                <FaExchangeAlt title="Abrir transferencia de agente" onClick={() => modalState.set(<UpdateOperatorDialog CODIGO_ATENDIMENTO={attendance.CODIGO} modalState={modalState} />)} />
                <FaCalendarAlt title="Abrir agenda de retorno" onClick={() => modalState.set(<ScheduleDialog CODIGO_ATENDIMENTO={attendance.CODIGO} modalState={modalState} />)} />
                <FaBan title="Cancelar agendamento" color="red" onClick={cancelSchedule} />
            </div>
            <div className="coda">
                <p>{attendance.CODIGO}</p>
            </div>
            <div className="priority">
                {urgencia.replace("_", " ")}
            </div>
            <div className="operator">
                <p>{attendance.OPERADOR || "n/d"}</p>
            </div>
            <div className="clientname">
                <p>{attendance.CONTATO || "n/d"}</p>
            </div>
            <div className="clientcompany">
                <p>{attendance.RAZAO || "n/d"}</p>
            </div>
            <div className="clientnumber">
                <p>{attendance.CONTATO_NUMERO ? formatNumber(attendance.CONTATO_NUMERO) : "N/D"}</p>
            </div>
            <div className="scheduledate">
                <p>{attendance.DATA_AGENDAMENTO.toLocaleString()}</p>
            </div>
            <div className="type">
                <p>{attendance.TIPO}</p>
            </div>
        </StyledTableItem>
    );
};