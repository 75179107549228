import styled from "styled-components";

export const StyledTableItem = styled.div`
    display: grid;
    grid-template-areas:
    "idb statusb nameb emailb sectorb";
    grid-template-columns: 5rem 8rem 24rem 24rem 1fr;

    &:hover {
        background-color: rgba(0,0,0, 0.1%);
    }
    

    &:nth-child(even) {
        background-color: rgba(0, 0, 0, 0.05);
    }

    &:nth-child(odd) {
        background-color: transparent;
    }

    &.online {
        background-color: rgba(55, 225, 55, 0.15);
    }

    &.paused {
        background-color: rgba(225, 225, 55, 0.15);
    }

    &.offline {
        background-color: rgba(225, 55, 55, 0.15);
    }

    >div {
        box-sizing: border-box;
        padding: 0.5rem;
        font-size: 0.75rem;
        font-weight: 500;

        display: flex;
        align-items: center;

        overflow-x: hidden;
        text-align: start;

        >p {
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            display: inline-block;
        }
    }

    >div.idb {
        grid-area: idb;
    }

    >div.statusb {
        grid-area: statusb;
    }

    >div.nameb {
        grid-area: nameb;
    }

    >div.emailb {
        grid-area: emailb;
    }
    
    >div.sectorb {
        grid-area: sectorb;
        min-width: 8rem;
    }
`