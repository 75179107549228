/* eslint-disable @typescript-eslint/no-unused-vars */
import { FaCalendarCheck, FaRegCalendarAlt, FaRegCheckCircle, FaUserEdit } from "react-icons/fa";
import { FcCallTransfer } from "react-icons/fc";
import { ImTab } from "react-icons/im";
import { StyledAttendanceButtons } from "./style";
import { AttendancesContext } from "../../../../contexts/user/AttendancesContext";
import { useContext, useEffect, useRef } from "react";
import { Modal } from "../../../../styles/modal";
import { ScheduleDialog } from "../../../../components/ScheduleDialog";
import { FinishAttendance } from "../../../../components/FinishAttendance";
import { UpdateOperatorDialog } from "../../../../components/UpdateOperatorDialog";
import { UpdateSectorDialog } from "../../../../components/UpdateSectorDialog";
import { LoginContext, currentRoute } from "../../../../contexts/global/LoginContext";
import { UpdateContactDialog } from "../../../../components/UpdateContactDialog";
import { FaBars, FaBriefcase, FaXmark } from "react-icons/fa6";
import { UpdateClientDialog } from "../../../../components/UpdateClientDialog";
import { validateNumber } from "../../../../Hooks/validateNumber.util";
import { toast } from "react-toastify";
import { CustomState } from "../../../../Hooks/State.hook";

export const AttendanceButtons = () => {

    const { readingAttendance, modalState } = useContext(AttendancesContext);
    const { user } = useContext(LoginContext);

    function openSchedule() {
        readingAttendance.value &&
            modalState.set(
                <Modal>
                    <ScheduleDialog CODIGO_ATENDIMENTO={readingAttendance.value.CODIGO} modalState={modalState} />
                </Modal>
            );
    };

    function openFinish() {
        readingAttendance.value &&
            modalState.set(
                <Modal>
                    <FinishAttendance />
                </Modal>
            );
    };

    function openTransfer() {
        readingAttendance.value &&
            modalState.set(
                <Modal>
                    <UpdateOperatorDialog CODIGO_ATENDIMENTO={readingAttendance.value.CODIGO} modalState={modalState} />
                </Modal>
            );
    };

    function openSectorTransfer() {
        readingAttendance.value &&
            modalState.set(
                <Modal>
                    <UpdateSectorDialog attendance={readingAttendance.value} modalState={modalState} />
                </Modal>
            );
    }

    function openEditContact() {
        readingAttendance.value &&
            modalState.set(
                <Modal>
                    <UpdateContactDialog
                        CODIGO_NUMERO={readingAttendance.value.CODIGO_NUMERO}
                        CONTATO_NOME={readingAttendance.value.CONTATO_NOME}
                        CONTATO_NUMERO={readingAttendance.value.CONTATO_NUMERO}
                        modalState={modalState}
                    />
                </Modal>
            )
    }

    function openSetClient() {
        readingAttendance.value &&
            modalState.set(
                <Modal>
                    <UpdateClientDialog contactId={readingAttendance.value.CODIGO_NUMERO} modalState={modalState} />
                </Modal>
            )
    }

    function onClickValidateNumber() {
        validateNumber(readingAttendance.value!.CONTATO_NUMERO || "")
            .then((v) => {
                if (v !== readingAttendance.value!.CONTATO_NUMERO) {
                    toast.error(`Numero inválido... Atualize o contato ou exclua.`)
                } else {
                    toast.success("Numero válido!")
                }
            })
    }

    const displayListState = CustomState<boolean>(false);
    const listRef = useRef<any>(null);
    const buttonRef = useRef<any>(null);

    useEffect(() => {
        const handleClickOutside = (e: any) => {
            if (
                listRef.current &&
                !listRef.current?.contains(e.target) &&
                buttonRef.current &&
                !buttonRef.current.contains(e.target)
            ) {
                displayListState.set(false);
            }
        };

        if (displayListState) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [displayListState]);

    return (
        <StyledAttendanceButtons >
            <button onClick={() => displayListState.set(prev => !prev)} ref={buttonRef}>
                {
                    displayListState.value ?
                        <FaXmark />
                        :
                        <FaBars />
                }
            </button>
            {
                displayListState.value &&
                <ul ref={listRef}>
                    <li onClick={onClickValidateNumber}>
                        <FaRegCheckCircle color="rgba(255, 105, 45)" />
                        <p>Validar WhatsApp</p>
                    </li>
                    <li onClick={openSchedule}>
                        <FaRegCalendarAlt color="rgba(25, 225, 25)" />
                        <p>Agendar Retorno</p>
                    </li>
                    <li onClick={openFinish}>
                        <FaCalendarCheck color="rgba(225, 25, 25)" />
                        <p>Finalizar Atendimento</p>
                    </li>
                    {
                        !["odim"].includes(currentRoute) &&
                        <li onClick={openTransfer}>
                            <FcCallTransfer color="rgb(25, 55, 225)" />
                            <p>Transferir Atendente</p>
                        </li>
                    }
                    {
                        (
                            ["odim"].includes(currentRoute)
                            &&
                            user && user.NIVEL === "ADMIN"
                        )
                        &&
                        <li onClick={openTransfer}>
                            <FcCallTransfer color="rgb(25, 55, 225, 80%)" />
                            <p>Transferir Atendente</p>
                        </li>
                    }
                    <li onClick={openSectorTransfer}>
                        <ImTab color="rgb(25, 255, 155, 80%)" />
                        <p>Transferir Setor</p>
                    </li>
                    {
                        readingAttendance.value?.CONTATO_NUMERO &&
                        <li onClick={openEditContact}>
                            <FaUserEdit color="rgba(53, 125, 250, 80%)" />
                            <p>Editar Contato</p>
                        </li>
                    }
                    {
                        readingAttendance.value?.CODIGO_CLIENTE === -1 &&
                        <li onClick={openSetClient}>
                            <FaBriefcase color="rgba(170, 55, 175, 80%)" />
                            <p>Atribuir Cliente</p>
                        </li>
                    }
                </ul>
            }
        </StyledAttendanceButtons>
    );
};