import styled, { css } from "styled-components";
import cssVars from "../../../styles/cssVariables.vars";

interface iHeaderProps {
    pause: boolean;
}

export const StyledOperadorHeader = styled.header<iHeaderProps>`
    box-sizing: border-box;
    width: 100%;
    height: 100%;

    background-color: ${cssVars.colorGrey["7"]};

    display: grid;

    grid-template-areas: "buttons user";
    grid-template-columns: 1fr 18rem;
    grid-template-rows: 100%;

    // Botões da esquerda
    >div:nth-child(1) {
        grid-area: buttons;
        box-sizing: border-box;
        height: 100%;
        padding: 0.25rem;

        display: flex;
        gap: 0.25rem;
        
        >div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 0.5rem;
            padding: 0 0.5rem;
            width: 4rem;
            border-radius: 0.25rem; 
            background-color: ${cssVars.colorGrey["7h"]};
            border-bottom: 2px solid; 
            border-right: 1px solid; 
            border-left: 1px solid; 

            >h4 {
                font-size: 0.675rem;
                font-weight: 600;
                display: none;
            }

            >:nth-child(1) {
                width: 1.125rem;
                height: 1.125rem;
            }

            :hover {
                opacity: 1;

                transition-property: display;
                width: 8rem;

                &>h4 {
                    display: block;

                }
            }

            transition: ease-in-out 500ms;
            cursor: pointer;


            @media(min-width: 90rem){
                &{
                    width: 8rem;

                    >h4 {
                        display: block;
                    }
                }
            }
        }

        >div.clients_queue {
            border-color: var(--color-btn-schedules); 
            color: var(--color-btn-schedules); 
        }

        >div.pause_attendances {
            
            ${props => props.pause ?
        css`
            border-color: var(--color-btn-resume); 
            color: var(--color-btn-resume); 
         `
        :
        css`
            border-color: var(--color-btn-pause);
            color: var(--color-btn-pause);
        `
    }; 
        }

        >div.register_contacts {
            border-color: var(--color-btn-register-contacts); 
            color: var(--color-btn-register-contacts); 
        }

        >div.register_customers {
            border-color: var(--color-btn-register-customers);
            color: var(--color-btn-register-customers);
        }

        >div.send_mm {
            border-color: var(--color-btn-mass-messages);
            color: var(--color-btn-mass-messages);
        }
    }
`;

export const StyledUserDiv = styled.div`
    grid-area: user;

    padding: 0.5rem;
    color: var(--color-grey-2);

    cursor: default;

    display: flex;
    align-items: center;
    justify-content: flex-end;

    :hover {
        filter: brightness(1.1);
    }

    >div:nth-child(1) {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        width: calc(100% - 3rem);
        overflow: hidden;

        >.avatar {
            box-sizing: border-box;
            width: 2.5rem;
            height: 2.5rem;
            object-fit: cover;
            border-radius: 50%;
            padding: 0.5rem;
            color: ${cssVars.colorGrey["3"]};
            border: 2px solid ${cssVars.colorGrey["3"]};
            cursor: pointer;
        }

        >div {
            width: calc(100% - 3rem);
            display: flex;
            flex-direction: column;
            gap: 0.125rem;

            >span {
                font-size: 0.875rem;
            }
            
            >h2 {
                font-size: 0.875rem;
                white-space: nowrap;
            }
        }
    }

    // Botão logout
    >:nth-child(2) {
        padding: 0.5625rem;
        padding-right: 1rem;
        width: 1.25rem;
        height: 1.25rem;
        margin-right: 0.25rem;
        cursor: pointer;

        filter: drop-shadow(1px 1px 0 rgba(0,0,0, 0.5));
    }
`;
