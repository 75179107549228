import styled, { css } from "styled-components";
import cssVars from "../../../styles/cssVariables.vars";

interface iMessageProps {
    sentByMe: boolean;
    /* deleted: boolean; */
}

const options = {
    byMe: css`
        align-self: flex-end;
        background-color: ${cssVars.colorGrey["6h"]};
        border-bottom-right-radius: 0;
        transform: translateX(1rem);
    `,
    byOther: css`
        align-self: flex-start;
        background-color: ${cssVars.colorGrey[8]};
        border-bottom-left-radius: 0;
        transform: translateX(-1rem);
    `
};

export const StyledMessage = styled.li<iMessageProps>`
    padding: 0.75rem;
    width: max-content;
    border-radius: 0.25rem;
    max-width: 100%;
    position: relative;

    -webkit-box-shadow: 1px 1px 1px 1px rgba(82,82,82, 50%);
    -moz-box-shadow: 1px 1px 1px 1px rgba(82,82,82, 50%);
    box-shadow: 1px 1px 1px 1px rgba(82,82,82, 50%);

    ${props => props.sentByMe ? options.byMe : options.byOther};

    :hover {
        >div.buttons {
            display: flex;
        }
    }

    >div.buttons {
        display: none;
        flex-direction: column;
        gap: 0.5rem;
        width: max-content;
        position: absolute;
        border-radius: 0.25rem;
        padding: 0.25rem;
        top: 0.25rem;
        padding-right: 0.75rem;

        ${props => props.sentByMe ? `
            left: -2rem;
        ` : `
            right: -2rem;
        `};

        >svg {
            width: 1.25rem;
            height: 1.25rem;
            color: ${cssVars.colorGrey[4]};
            cursor: pointer;

            :hover {
                transform: scale(1.1);
                color: ${cssVars.colorGrey[2]};
            }
        }
    }

    >div.responding {
        background-color: ${cssVars.colorGrey[7]};
        padding: 0.5rem;
        border-radius: 0.25rem;
        margin-bottom: 0.5rem;
        cursor: pointer;

        >h4 {
            font-size: 0.875rem;
            font-weight: 500;
            margin-bottom: 0.5rem;
        }

        &.byMe {
            border-left: 4px solid rgba(252,64,0);
            >h4 {
                color: rgba(252,64,0);
            }
        }

        &.byOther {
            border-left: 4px solid rgba(205,64,188);
            >h4 {
                color: rgba(205,64,188);
            }
        }
    }

    >div.message{
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        
        >a {
            background-color: ${cssVars.colorGrey["7"]};
            width: 100%;
            display: flex;
            flex-direction: row;
            gap: 0.5rem;
            padding: 0.5rem;
            box-sizing: border-box;
            text-decoration: none;

            svg {
                font-size: 2rem !important;
                width: 2rem !important;
                height: 2rem !important;
                text-shadow: 1px 1px 1px black;
            }

            >div {
                >p {
                    color: ${cssVars.colorGrey[4]};
                    font-size: 0.75rem;
                }
            }
        }

        >img {

        }

        >p {
            font-size: 0.875rem;
        }
    }

    >span {
        font-size: 0.75rem;
        display: flex;
        align-items: center;
        gap: 0.25rem;

        &>.status {
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    animation: slidein 0.5s ease-in-out;

    @keyframes slidein {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }
`;

export const StyledViewFileButton = styled.button`
    padding: 0.5rem;
    color: white;
    font-weight: 500;
    background-color: var(--color-primary-o);
    border-radius: 0.25rem;
    cursor: pointer;
    margin-bottom: 0.5rem;

    :hover {
        background-color: var(--color-primary);
    }
`;