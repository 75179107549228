import styled from "styled-components";
import cssVars from "../../../../styles/cssVariables.vars";

export const StyledNewAttendance = styled.div`
        background-color: ${cssVars.colorGrey[8]};
        border-radius: 0.25rem;

        width: 50rem;
        height: 100%;
        max-height: 80vh;
        position: relative;

        >header {
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0.5rem 1rem;
            background: var(--color-primary);
            color: white;
            border-top-left-radius: 0.25rem;
            border-top-right-radius: 0.25rem;
            gap: 1rem;

            >h2 {
                font-size: 1rem;
                font-weight: 500;
            }

            >button {
                color: white;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;

                >svg {
                    font-size: 1.5rem;
                }

                :hover {
                    transform: scale(1.25);
                }
            }
        }

        >div.search {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            row-gap: 0.5rem;
            flex-wrap: wrap;
            padding: 0.5rem 1rem;
            border-bottom: 1px solid ${cssVars.colorGrey[6]};

            >button {
                height: auto;
                font-size: 0.75rem;
                font-weight: 600;
                padding: 0.625rem 2rem;
                box-sizing: border-box;
            }
        }

        >ul {
            box-sizing: border-box;
            height: calc(100% - 10rem);
            overflow-y: auto;
            overflow-x: hidden;
            padding: 0 1rem;
    
            display: flex;
            flex-direction: column;
            gap: 0.5rem;

            >li {
                box-sizing: border-box;
                height: 7rem;
                display: flex;
                gap: 1rem;
                position: relative;
                border-radius: 0.25rem;
                padding: 0.5rem;
                margin-right: 0.25rem;
                transition: 100ms ease-in-out;

                &:nth-child(even) {
                    background-color: ${cssVars.colorGrey["7"]};
                }

                &:nth-child(odd) {
                    background-color: ${cssVars.colorGrey["6h"]};
                }

                >img, svg.avatar{
                    margin: auto;
                    height: 4.5rem;
                    width: 4.5rem;
                    object-fit: cover;
                    color: ${cssVars.colorGrey[2]};
                }

                svg.avatar {
                    box-sizing: border-box;
                    padding: 0.75rem;
                    color: ${cssVars.colorGrey[2]};
                }

                >div {
                    display: flex;
                    flex-direction: column;
                    gap: 0.125rem;
                    width: calc(100% - 4.5rem);

                    >h2 {
                        font-size: 0.875rem;
                        font-weight: 500;

                    }

                    >h3 {
                        font-size: 0.75rem;
                        font-weight: 400;
                    }
                }

                >button {
                    position: absolute;
                    right: 1rem;

                    width: 2rem;
                    height: 2rem;

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    top: 1rem;
                    background-color: var(--color-primary);
                    color: ${cssVars.colorGrey[8]};
                    padding: 0.5rem;
                    border-radius: 50%;
                    cursor: pointer;

                    >:nth-child(1) {
                        width: 1.5rem;
                        height: 1.5rem;
                    }

                    :hover {
                        transform: scale(1.1);
                        filter: brightness(1.25);
                    }
                }

                >p {
                    position: absolute;
                    right: 2rem;
                    font-size: 0.875rem;
                    font-weight: 600;
                    padding: 0;
                    line-height: 100%;

                    top: calc(50% - 0.5rem);
                    color: var(--color-primary);

                    cursor: default;
            }
        }
        }

        >.loading-spinner {
            width: 100%;
            height: calc(100% - 8rem);
            display: flex;
            align-items: center;
            justify-content: center;
        }
        >.page-nav:nth-last-child(1) {
            margin-left: 1rem;
        }
`;

