import { useEffect, useContext } from "react";
import { CustomState } from "../../../../../Hooks/State.hook";
import { AttendanceMessage, SpecificAttendanceDetails, SupervisorUrgency } from "../../../../../interfaces/Attendances.interfaces";
import { StyledAttendanceItem } from "./style";
import messageDate from "../../../../../Hooks/MessageDate.hook";
import { AttendancesContext } from "../../../../../contexts/user/AttendancesContext";
import { FaRegClock } from "react-icons/fa6";
import { BsCheck, BsCheckAll } from "react-icons/bs";
import { FaUserCircle } from "react-icons/fa";
import { decodeSafeURI } from "../../../../../Hooks/decodeSafeUri.hook";

interface AttendanceItemProps {
    attendance: SpecificAttendanceDetails;
};

export const AttendanceItem = ({ attendance }: AttendanceItemProps) => {
    const lastMessage = CustomState<AttendanceMessage | null>(null);
    const notReadMessages = CustomState<number>(0);
    const className = CustomState<string>("");
    const urgencia = CustomState<SupervisorUrgency | "EXPIRADO">("NORMAL");
    const disabled = CustomState<boolean>(false);

    const { readingAttendance, attendancesMessages, readingGroup } = useContext(AttendancesContext);

    useEffect(() => {
        const { URGENCIA_SUPERVISOR, URGENCIA_AGENDAMENTO, URGENCIA_OPERADOR } = attendance;

        if (URGENCIA_SUPERVISOR) urgencia.set(URGENCIA_SUPERVISOR);
        else if (URGENCIA_AGENDAMENTO) urgencia.set(URGENCIA_AGENDAMENTO);
        else urgencia.set(URGENCIA_OPERADOR);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attendance]);

    useEffect(() => {
        const allMessages = attendancesMessages.value
            .filter((m) => m.CODIGO_NUMERO === attendance.CODIGO_NUMERO)
            .sort((a, b) => b.DATA_HORA.getTime() - a.DATA_HORA.getTime());

        if (allMessages.length) {
            lastMessage.set(allMessages[0])
        };

        const filterNotRead = allMessages.filter(m => !m.FROM_ME && m.STATUS !== "READ");

        notReadMessages.set(filterNotRead.length);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attendancesMessages.value, attendance]);

    useEffect(() => {
        if (readingAttendance.value && readingAttendance.value.CODIGO === attendance.CODIGO) {
            className.set("selected");
        } else {
            className.reset();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [readingAttendance.value, attendance]);

    const handleSelection = () => {
        readingAttendance.set(attendance);
        readingGroup.set(null);
    }


    return (
        <StyledAttendanceItem
            onClick={handleSelection}
            className={`${className.value} ${disabled.value ? 'disabled' : ''} ${urgencia.value}`}
        >
            {
                attendance.AVATAR_URL ?
                    <img src={attendance.AVATAR_URL} alt="profile_picture" />
                    :
                    <FaUserCircle />
            }
            <div className="box_1">
                <h2 title={attendance.CONTATO_NOME || ""}> {attendance.CONTATO_NOME || "CONTATO EXCLUÍDO"} </h2>
                <h3 title={attendance.RAZAO}> {attendance.RAZAO || "NÃO CADASTRADO"} </h3>
                {
                    lastMessage.value &&
                    <span className="lastmsg">
                        <>
                            {
                                !!lastMessage.value.FROM_ME &&
                                <div className="status">
                                    {
                                        lastMessage.value.STATUS === "PENDING" ?
                                            <FaRegClock size="0.875rem" />
                                            :
                                            lastMessage.value.STATUS === "SENT" ?
                                                <BsCheck fontSize="1.25rem" />
                                                :
                                                lastMessage.value.STATUS === "RECEIVED" ?
                                                    <BsCheckAll fontSize="1.25rem" />
                                                    :
                                                    lastMessage.value.STATUS === "READ" &&
                                                    <BsCheckAll color="rgb(95, 185, 255)" fontSize="1.25rem" />
                                    }
                                </div>
                            }
                            {
                                lastMessage.value.MENSAGEM ?
                                    (
                                        decodeSafeURI(lastMessage.value.MENSAGEM)
                                    )
                                    :
                                    lastMessage.value.ARQUIVO &&
                                    (
                                        `Arquivo: ${lastMessage.value.ARQUIVO.TIPO.split("/")[0]}`
                                    )
                            }

                        </>
                    </span>
                }
            </div>
            <div className="box_2">
                <span className="lastmsgtime">
                    {messageDate(String(lastMessage.value?.DATA_HORA || ""))}
                </span>
                {
                    notReadMessages.value > 0 &&
                    <span className="not_read_label">
                        {notReadMessages.value > 99 ? "99+" : notReadMessages.value}
                    </span>
                }
            </div>
        </StyledAttendanceItem >
    );
};