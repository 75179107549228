import styled from "styled-components";
import cssVars from "../../styles/cssVariables.vars";

export const StyledViewMessageTemplate = styled.div`
    background-color: ${cssVars.colorGrey[8]};
    border-radius: 0.25rem;
    display: flex;
    flex-direction: column;
    width: 50rem;

    >header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: var(--color-primary);
        color: white;
        padding: 0.5rem 1rem;
        border-radius: 0.25rem 0.25rem 0 0;

        >h2 {
            font-weight: 400;
            font-size: 1rem;

            >b {
                font-weight: 600;
            }
        }

        >button {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            :hover {
                transform: scale(1.25);
            }

            >svg {
                font-size: 1.5rem;
                color: white;
            }
        }
    }

    >div{
        max-height: 50vh;
        margin: 1rem;
        padding-right: 0.5rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        overflow-y: auto;

        ::-webkit-scrollbar {
            border: 1px solid black;
            border-radius: 0.25rem;
        }

        ::-webkit-scrollbar-thumb {
            border-radius: 0.25rem;
        } 
        .component{
            >h4 {
                font-size: 0.875rem;
                font-weight: 500;
                padding: 0.25rem;
                border-radius: 0.25rem 0.25rem 0 0;
                background-color: ${cssVars.colorGrey["6h"]};
            }
            
            >div.text-area {
                font-size: 0.875rem;
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
                padding: 0.5rem;
                border: 1px solid rgba(0,0,0, 0.25);
                border-top: none;
                border-radius: 0 0 0.25rem 0.25rem;
                background-color: ${cssVars.colorGrey["7h"]};

                >p {
                    color: ${cssVars.colorGrey[2]};
                }
            }
        }
    }
`;