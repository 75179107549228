import styled from "styled-components";

export const StyledSelectAttendanceTemplate = styled.div`
    background-color: white;
    border-radius: 0.25rem;
    display: flex;
    flex-direction: column;
    width: 60rem;
    height: 30rem;

    >header {
        box-sizing: border-box;
        width: 100%;
        padding: 0.5rem 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: var(--color-primary-1);
        color: white;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;

        >h2 {
            font-size: 1.25rem;
            font-weight: 400;
            >b {
                font-weight: 600;
            }
        }

        >button {
            font-size: 1.5rem;
            font-weight: 500;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;

            :hover {
                transform: scale(1.25);
            }
        }
    }

    >ul {
        margin: 1rem;
        height: 22rem;
        overflow-y: auto;

        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        >.template_item {
            display: flex;
            box-sizing: border-box;
            width: 100%;
            border: 1px solid rgba(0,0,0, 0.5);
            border-radius: 0.25rem;
            padding: 0.25rem;
            
            >div.check {
                width: 2rem;
                display: flex;
                justify-content: center;
            }

            >div.name {
                width: calc(100% - 4rem);
                font-size: 0.875rem;
                padding-left: 1rem;
            }

            >div.view {
                width: 2rem;
                border-right: none;
                display: flex;
                justify-content: center;
                >button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    font-size: 1.25rem;

                    :hover {
                        transform: scale(1.1);
                    }
                }
            }
        }
    }
   
    &>button:nth-child(3) {
        width: 10rem;
    }
`;