import { createContext, useContext, useEffect } from "react";
import { AttendanceContextProps, AttendanceMessage, AttendanceProviderProps, Group, MessageStatus, OperatorUrgency, SpecificAttendanceDetails, SupervisorUrgency } from "../../interfaces/Attendances.interfaces";
import { CustomState } from "../../Hooks/State.hook";
import { SocketContext } from "../global/SocketContext";
import { ReactNode } from "react";
import { OficialWhatsappMessageTemplate } from "../../interfaces/MessageTemplates.interfaces";
import api from "../../services/api";
import { LoginContext, currentRoute } from "../global/LoginContext";
import { CustomerWithContact } from "../../interfaces/Customers.interface";
import { Modal } from "../../styles/modal";
import { NewAttendance } from "../../pages/Operador/OperadorHeader/NewAttendanceModal";
import formatarCPForCNPJ from "../../Hooks/FormatCPFCNPJ.hook";
import formatarNome from "../../Hooks/FormatName.hook";

export const AttendancesContext = createContext({} as AttendanceContextProps);

export const AttendancesProvider = ({ children }: AttendanceProviderProps) => {
    const detailedAttendances = CustomState<Array<SpecificAttendanceDetails>>([]);
    const groups = CustomState<Array<Group>>([]);
    const attendancesMessages = CustomState<Array<AttendanceMessage>>([]);
    const readingAttendance = CustomState<SpecificAttendanceDetails | null>(null);
    const readingGroup = CustomState<Group | null>(null);
    const modalState = CustomState<ReactNode>("");
    const pausedState = CustomState<boolean>(false);
    const selectedTemplate = CustomState<OficialWhatsappMessageTemplate | null>(null);
    const selectedClient = CustomState<CustomerWithContact | null>(null);
    const loadedUser = CustomState<number | null>(null);
    const newAttendanceModalState = CustomState<boolean>(false);

    const { socket } = useContext(SocketContext);
    const { user } = useContext(LoginContext);

    useEffect(() => {
        if (user && loadedUser.value !== user.CODIGO) {
            loadedUser.set(user.CODIGO);
            api.get<SpecificAttendanceDetails[]>(`/custom-routes/operator-attendances/${user.CODIGO}`)
                .then(async (res) => {
                    await loadAllOperatorAttendancesMessages(user.CODIGO);
                    detailedAttendances.set(res.data);
                })

            user.NIVEL === "ADMIN" && api.get<SpecificAttendanceDetails[]>(`/custom-routes/operator-attendances/-1`)
                .then(async (res) => {
                    await loadAllOperatorAttendancesMessages(-1);
                    detailedAttendances.set(prev => [...prev, ...res.data]);
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, loadedUser.value]);

    async function loadAllOperatorAttendancesMessages(operatorId: number) {
        try {
            if (user) {
                const { data } = await api.get<Array<AttendanceMessage>>(`/messages/operator/${user.CODIGO}`);
                const fixDateMessages = data.map((m) => ({ ...m, DATA_HORA: new Date(m.DATA_HORA) }));

                attendancesMessages.set((prev) => {
                    const arr = [...prev];

                    fixDateMessages.forEach((m) => {
                        if (!arr.some(n => n.ID === m.ID)) {
                            arr.push(m);
                        };
                    });

                    return arr;
                });
            }
        } catch (err) {
            console.error(err);
        };
    }
    async function loadMessages(numberId: number) {
        try {
            const { data } = await api.get<Array<AttendanceMessage>>(`/messages/${numberId}`);
            const fixDateMessages = data.map((m) => {
                m.DATA_HORA = new Date(m.DATA_HORA);

                return m
            });

            attendancesMessages.set((prev) => {
                const arr = [...prev];

                fixDateMessages.forEach((m) => {
                    if (!arr.some(n => n.ID === m.ID)) {
                        arr.push(m);
                    };
                });

                return arr;
            });

        } catch (err) {
            console.error(err);
        };
    };

    function finishAttendance(resultId: number, scheduleDate?: Date) {
        socket.value && user && socket.value.emit("finish-attendance", {
            clientName: currentRoute,
            operatorId: user.CODIGO,
            sectorId: user.SETOR,
            attendanceId: readingAttendance.value!.CODIGO,
            customerId: readingAttendance.value!.CODIGO_CLIENTE,
            contactId: readingAttendance.value!.CODIGO_NUMERO,
            resultId, scheduleDate
        });

        readingAttendance.reset();
    };

    function startAttendance(
        cliente: number,
        numero: number,
        wpp: string,
        pfp: string | null,
        template?: OficialWhatsappMessageTemplate,
        variables?: { cnpj: string, razao: string, nome: string }
    ) {
        if (user) {
            const data: any = {
                clientName: currentRoute,
                operatorId: user.CODIGO,
                customerId: cliente,
                contactId: numero,
                sectorId: user.SETOR,
                whatsappNumber: wpp
            };

            if (template && variables) {
                const currentSaudation = () => {
                    const currentTime = new Date();
                    const hour = currentTime.getHours();

                    if (hour >= 5 && hour < 12) {
                        return "Bom dia";
                    } else if (hour >= 12 && hour < 18) {
                        return "Boa tarde";
                    } else {
                        return "Boa noite";
                    }
                }

                const vars = {
                    "saudação_tempo": currentSaudation(),
                    "cliente_cnpj": formatarCPForCNPJ(variables!.cnpj),
                    "cliente_razao": variables.razao.trim(),
                    "contato_primeiro_nome": variables.nome.split(" ")[0],
                    "contato_nome_completo": variables.nome,
                    "atendente_nome": formatarNome(user.NOME),
                    "atendente_nome_exibição": formatarNome(user.NOME_EXIBICAO)
                }
                data["template"] = { ...template, variables: { ...vars } };

                console.log(data.template);
            };

            socket.value && socket.value.emit("start-attendance", data);
        };
    };

    function switchPauseAttendances() {
        if (socket.value && user) {
            pausedState.value ?
                socket.value.emit("resume-attendances", { clientName: currentRoute, operatorId: user.CODIGO })
                :
                socket.value.emit("pause-attendances", { clientName: currentRoute, operatorId: user.CODIGO })
        };
    };

    useEffect(() => {
        const audio = new Audio('./web_whatsapp.mp3');

        const handleNewAttendance = async (attendanceId: number) => {
            if (!detailedAttendances.value.some(a => a.CODIGO === attendanceId)) {
                const attendance = await api.get<SpecificAttendanceDetails>(`/custom-routes/specific-attendance/${attendanceId}`).then((res) => res.data);
                await loadMessages(attendance.CODIGO_NUMERO);
                detailedAttendances.set((prev) => [...prev, attendance]);
            }
        };

        const handleRemoveAttendance = (id: number) => {
            detailedAttendances.set((prev) => prev.filter(a => a.CODIGO !== id));
        };

        const handleUpdateAttendanceCustomer = (data: { attendanceId: number, CODIGO: number, RAZAO: string, CPF_CNPJ: string }) => {
            detailedAttendances.set((prev) => {
                const newArr = [...prev];
                const i = newArr.findIndex(a => a.CODIGO === data.attendanceId);
                if (~i) {
                    newArr[i] = { ...newArr[i], CODIGO_CLIENTE: data.CODIGO, RAZAO: data.RAZAO, CPF_CNPJ: data.CPF_CNPJ }
                }
                return newArr;
            });

            if (readingAttendance.value && readingAttendance.value.CODIGO === data.attendanceId) {
                readingAttendance.set(prev => ({ ...prev!, CODIGO_CLIENTE: data.CODIGO, RAZAO: data.RAZAO, CPF_CNPJ: data.CPF_CNPJ }))
            }
        };

        const handleNewMessage = (data: AttendanceMessage) => {
            console.log(data);

            data.DATA_HORA = new Date(data.DATA_HORA);

            attendancesMessages.set(prev => {
                if (!prev.some(m => m.CODIGO === data.CODIGO || (data.LOCAL_ID && m.LOCAL_ID === data.LOCAL_ID))) {
                    if (!data?.FROM_ME) audio.play();
                    return [...prev, data];
                } else {
                    return prev.map(m => ((m.CODIGO === data.CODIGO || (data.LOCAL_ID && m.LOCAL_ID === data.LOCAL_ID)) ? data : m));
                }
            });
        };

        const handleMessageStatus = ({ LOCAL_ID, WAM_ID, STATUS }: MessageStatus) => {
            attendancesMessages.set(prev =>
                prev.map((msg) => ((msg.ID === WAM_ID) || (msg.LOCAL_ID === LOCAL_ID)) ? { ...msg, STATUS, ID: WAM_ID } : msg)
            );
        };

        const handlePaused = () => {
            pausedState.set(true);
        };

        const handleResumed = () => {
            pausedState.set(false);
        };

        const handleUpdateUrgency = (data: { URGENCIA_OPERADOR?: OperatorUrgency, URGENCIA_SUPERVISOR?: SupervisorUrgency | null, CODIGO: number }) => {
            detailedAttendances.set(prev => prev.map(a => {
                const { CODIGO, ...updated } = data;

                if (CODIGO === a.CODIGO) {
                    return { ...a, ...updated };
                }

                return a;
            }));
        }

        if (socket.value) {
            socket.value.on("new-attendance", handleNewAttendance);
            socket.value.on("remove-attendance", handleRemoveAttendance);
            socket.value.on("update-attendance-customer", handleUpdateAttendanceCustomer);
            socket.value.on("new-message", handleNewMessage);
            socket.value.on("message_status", handleMessageStatus);
            socket.value.on("paused", handlePaused);
            socket.value.on("resumed", handleResumed);
            socket.value.on("update-urgency", handleUpdateUrgency);
        }

        // Retorno de chamada de limpeza para desinscrição dos eventos
        return () => {
            if (socket.value) {
                socket.value.off("new-attendance", handleNewAttendance);
                socket.value.off("remove-attendance", handleRemoveAttendance);
                socket.value.off("update-attendance-customer", handleUpdateAttendanceCustomer);
                socket.value.off("new-message", handleNewMessage);
                socket.value.off("message_status", handleMessageStatus);
                socket.value.off("paused", handlePaused);
                socket.value.off("resumed", handleResumed);
                socket.value.off("update-urgency", handleUpdateUrgency);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket.value]);

    return (
        <AttendancesContext.Provider value={{
            detailedAttendances,
            attendancesMessages,
            readingAttendance,
            finishAttendance,
            modalState,
            startAttendance,
            pausedState,
            switchPauseAttendances,
            selectedTemplate,
            selectedClient,
            groups,
            readingGroup,
            newAttendanceModalState
        }}>
            {children}
            {
                newAttendanceModalState.value &&
                <Modal>
                    <NewAttendance />
                </Modal>

            }
        </AttendancesContext.Provider>
    );
};