import styled from "styled-components";

export const StyledSelecionarMensagemPronta = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    >div.ready-message_card {
        display: flex;
        padding: 0.5rem;
        background-color: rgba(25, 25, 102, 0.1);
        border-radius: 0.25rem;
        gap: 1rem;
        width: 40rem;

        >h2 {
            font-size: 0.875rem;
            font-weight: 600;
            color: rgba(0, 8, 12, 0.75);
        }

        >button {
            margin-left: auto;
            cursor: pointer;

            &:hover {
                color:var(--color-primary-1);
                transform: scale(1.1);
                transition: 300ms ease-in-out;
            }
        }
    }
`;