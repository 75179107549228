import { useContext } from "react";
import { UsersRegistrationContext } from "../../../../contexts/admin/UsersRegistration.context";
import { RegistrationButton } from "../../../../styles/registrationButton";
import { Formulario } from "./Formulario";
import { ListaDeAgentes } from "./ListaDeAgentes";
import { StyledCadastroAgente } from "./style";
import { UserData } from "../../../../interfaces/Users.interfaces";
import { SupervisorContext } from "../../../../contexts/admin/Supervisor.context";
import MultiSelect from "../../../../components/MultiSelect";
import Input from "../../../../components/Input";
import Select, { Option } from "../../../../components/Select";
import { defaultInput100, defaultSelect100 } from "../../../../components-variants/defaultInputs";
import cssVars from "../../../../styles/cssVariables.vars";
import { FaSearch } from "react-icons/fa";

export const CadastroAgente = () => {
    const { searchState, fieldState, getAllOperators, sectorOptions, levelOptions } = useContext(UsersRegistrationContext);
    const { modalState } = useContext(SupervisorContext);

    const openRegister = () => modalState.set(<Formulario closeFunction={modalState.reset} />);

    const searchFieldOptions: Array<Option<keyof UserData>> = [
        { name: "Login", value: "LOGIN" },
        { name: "Nome", value: "NOME" },
        { name: "Nível", value: "NIVEL" },
        { name: "Setor", value: "SETOR" },
        { name: "Código inPulse", value: "CODIGO" },
        { name: "Código ERP", value: "CODIGO_ERP" },
    ];

    return (
        <StyledCadastroAgente>
            <div className="search">
                {
                    fieldState.value === "NIVEL" ?
                        <MultiSelect
                            onChange={(v) => searchState.set(v)}
                            options={levelOptions}
                            placeholder="Selecione os níveis"
                            {...defaultSelect100}
                            $fontSize={0.875}
                        />
                        :
                        fieldState.value === "SETOR" ?
                            <MultiSelect
                                onChange={(v) => searchState.set(v)}
                                options={sectorOptions.value}
                                placeholder="Selecione os setores"
                                {...defaultSelect100}
                                $fontSize={0.875}
                            />
                            :
                            <Input
                                placeholder="Pesquisar operador"
                                onChange={(e) => searchState.set(e.target.value)}
                                {...defaultInput100}
                                $fontSize={0.875}
                                leftIcon={<FaSearch />}
                            />
                }
                <Select
                    onChange={(v) => fieldState.set(v || "")}
                    options={searchFieldOptions}
                    defaultValue={searchFieldOptions[0]}
                    {...defaultSelect100}
                    $fontSize={0.875}
                    $focusColor={cssVars.colorGrey[2]}
                />
                <RegistrationButton onClick={getAllOperators}>
                    Buscar
                </RegistrationButton>
            </div>
            <ListaDeAgentes />
            <RegistrationButton onClick={openRegister} >
                Cadastrar novo atendente
            </RegistrationButton>
        </StyledCadastroAgente>
    );
};