import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { LoginProvider } from './contexts/global/LoginContext';
import { SocketProvider } from './contexts/global/SocketContext';
import { AttendancesProvider } from './contexts/user/AttendancesContext';
import { ReadyMessagesProvider } from './contexts/global/ReadyMessagesContext';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

root.render(
	<React.StrictMode>
		<LoginProvider>
			<SocketProvider>
				<AttendancesProvider>
					<ReadyMessagesProvider>
						<App />
					</ReadyMessagesProvider>
				</AttendancesProvider>
			</SocketProvider>
		</LoginProvider>
	</React.StrictMode>
);
