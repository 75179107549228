import styled from "styled-components";
import cssVars from "../../styles/cssVariables.vars";

export const StyledQuickMessage = styled.div`
    border-radius: 0.25rem;
    width: 45rem;
    background-color: ${cssVars.colorGrey[8]};

    display: flex;
    flex-direction: column;

    >header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem 1rem;
        background: var(--color-primary);
        color: ${cssVars.colorGrey[8]};
        border-top-right-radius: 0.25rem;
        border-top-left-radius: 0.25rem;

        >button {
            color: ${cssVars.colorGrey[8]};
            font-size: 1.5rem;
            cursor: pointer;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            :hover {
                transform: scale(1.125);
            }
        }

        h2 {
            font-size: 1rem;
            font-weight: 600;
        }
    }

    >main {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 0.5rem;

        >ul {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            height: 20rem;
            overflow-y: auto;

            >.template_item {
                display: flex;
                box-sizing: border-box;
                width: 100%;
                
                border-radius: 0.25rem;
                padding: 0.375rem 0.5rem;
                border: 1px solid ${cssVars.colorGrey[4]};

                &:nth-child(odd) {
                    background-color: ${cssVars.colorGrey["7"]};
                }
                &:nth-child(even) {
                    background-color: ${cssVars.colorGrey["6h"]};
                }

                >div.check {
                    width: 2rem;
                    display: flex;
                    justify-content: center;
                }

                >div.name {
                    width: calc(100% - 4rem);
                    font-size: 0.875rem;
                }

                >div.view {
                    width: 2rem;
                    border-right: none;
                    display: flex;
                    justify-content: center;

                    >button {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        font-size: 1.25rem;
                        color: ${cssVars.colorGrey[4]};

                        :hover {
                            transform: scale(1.1);
                        }
                    }
                }
            }
        }
    }

    button.btn-send {
        width: 20rem;
        padding: 0.75rem;
    }
`; 