import { useEffect } from "react";
import { CustomState } from "../../../../Hooks/State.hook";
import { customRequest } from "../../../../services/api";
import { BarChart } from "@mui/x-charts";
import StyledMessagesByUser from "./style";
import UsersMessagesTable from "./table";
import { MessagesPerUserResponse, UserMessages } from "./types";

function MessagesByUser() {
    const data = CustomState<UserMessages[]>([]);
    const filteredData = CustomState<UserMessages[]>([]);

    useEffect(() => {
        customRequest<MessagesPerUserResponse, undefined>({
            endpoint: "/dashboard/messages-per-user",
            method: "get",
            onSuccess: (res) => {
                data.set(res.messagesPerUser);
                filteredData.set(res.messagesPerUser);
            },
            timeoutSeconds: 40
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const dataset = filteredData.value.map((o) => {
        return ({
            "messages": Number(o.messagesCount),
            "sent": Number(o.sentMessagesCount),
            "received": Number(o.receivedMessagesCount),
            "name": o.userName
        })
    });

    return (
        <StyledMessagesByUser>
            <UsersMessagesTable
                rowsState={data}
                filteredRowsState={filteredData}
            />
            <div>
                {
                    !!filteredData.value.length &&
                    <BarChart
                        xAxis={[
                            { dataKey: "name", scaleType: "band" },
                        ]}
                        series={[
                            { dataKey: "messages", label: "Mensagens" },
                            { dataKey: "sent", label: "Enviadas" },
                            { dataKey: "received", label: "Recebidas" },
                        ]}
                        height={600}
                        colors={[
                            "var(--color-btn-register-customers)",
                            "var(--color-btn-mass-messages)",
                            "var(--color-btn-register-contacts)",
                        ]}
                        dataset={dataset}
                    />
                }
            </div>
        </StyledMessagesByUser>
    );
}

export default MessagesByUser;