import { useContext } from "react";
import { FaRegEye } from "react-icons/fa";
import { SupervisorContext } from "../../../../../contexts/admin/Supervisor.context";
import { StyledTableItem } from "./style";
import { ViewChat } from "../../../../../components/ViewChat";
import { FinishedAttendanceDetails } from "../../../../../interfaces/Attendances.interfaces";
import { formatNumber } from "../../../../../Hooks/FormatNumber.hook";

interface iTableProps {
    attendance: FinishedAttendanceDetails;
};

export const TableItem = ({ attendance }: iTableProps) => {
    const { modalState } = useContext(SupervisorContext);

    return (
        <StyledTableItem className={attendance.CONTATO_NOME === "CONTATO EXCLUÍDO" ? "deleted_contact" : ""}>
            <div className="actions">
                {attendance.CODIGO_NUMERO &&
                    <FaRegEye
                        title="Abrir chat"
                        onClick={() => modalState.set(
                            <ViewChat
                                attendance={attendance}
                                contactId={attendance.CODIGO_NUMERO}
                                disableMessages
                                modalState={modalState}
                            />
                        )
                        }
                    />
                }
            </div>
            <div className="cod_attendance">
                <p>{attendance.CODIGO}</p>
            </div>
            <div className="operator">
                <p>{attendance.OPERADOR}</p>
            </div>
            <div className="clientname">
                <p>{attendance.CONTATO_NOME}</p>
            </div>
            <div className="clientnumber">
                <p>{attendance.CONTATO_NUMERO ? formatNumber(attendance.CONTATO_NUMERO) : "N/D"}</p>
            </div>
            <div className="clientcompany">
                <p>{attendance.RAZAO}</p>
            </div>
            <div className="startdate">
                <p>{attendance.DATA_INICIO.toLocaleString()}</p>
            </div>
            <div className="enddate">
                <p>{attendance.DATA_FIM.toLocaleString()}</p>
            </div>
            <div className="atype">
                <p>{attendance.TIPO}</p>
            </div>
        </StyledTableItem>
    );
}