import styled from "styled-components";
import cssVars from "../../../../styles/cssVariables.vars";

export const SupervisorMenuButton = styled.div`
    &>button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem;
        width: 100%;
        box-sizing: border-box;
        cursor: pointer;
        width: 100%;
        transition: 300ms ease-in-out;

        &.selected {
            background-color: ${cssVars.colorPrimary};
            border-radius: 0.25rem;

            >svg {
                color: white;
            }
        }

        >svg {
            width: 2rem;
            height: 2rem;
            color: ${cssVars.colorGrey[2]};
        }

        :hover {
            transform: scale(1.25);
            transition: 300ms ease-in-out;
        }
    }
`;