import { StyledViewChat } from "./style";
import { CustomState, CustomStateHook } from "../../Hooks/State.hook";
import { ReactNode, useContext, useEffect } from "react";
import { AttendanceMessage, RunningAttendanceDetails } from "../../interfaces/Attendances.interfaces";
import api from "../../services/api";
import { AttendanceChat } from "../AttendanceChat";
import { FaXmark } from "react-icons/fa6";
import { AttendancesContext } from "../../contexts/user/AttendancesContext";
import { SocketContext } from "../../contexts/global/SocketContext";
import { currentRoute } from "../../contexts/global/LoginContext";

interface Props {
    attendance: RunningAttendanceDetails;
    contactId: number;
    disableMessages?: boolean;
    modalState: CustomStateHook<ReactNode>;
};

export const ViewChat = ({ contactId, attendance, disableMessages, modalState }: Props) => {
    const alreadyFetched = CustomState<boolean>(false);
    const { attendancesMessages } = useContext(AttendancesContext)
    const { socket } = useContext(SocketContext);

    useEffect(() => {
        if (!alreadyFetched.value && socket.value) {
            alreadyFetched.set(true);

            socket.value!.emit("join-chat-room", {
                clientName: currentRoute,
                contactId: attendance.CODIGO_NUMERO
            });

            api.get<Array<AttendanceMessage>>(`/messages/${contactId}`)
                .then((res) => {
                    const fixDateMessages = res.data.map((m) => {
                        m.DATA_HORA = new Date(m.DATA_HORA);

                        return m;
                    });

                    const previousMessages = [...attendancesMessages.value];
                    const newMessages = fixDateMessages.filter((m) => !previousMessages.some((pv) => pv.CODIGO === m.CODIGO));
                    attendancesMessages.set([...previousMessages, ...newMessages]);
                })
                .catch(() => alreadyFetched.set(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contactId, alreadyFetched.value, socket.value]);

    function handleClose() {
        socket.value?.emit("leave-chat-room", {
            clientName: currentRoute,
            contactId: attendance.CODIGO_NUMERO
        });

        modalState.reset();
    }

    return (
        <StyledViewChat>
            <header>
                <h2> Visualizando conversa de "{attendance.CONTATO_NOME}"</h2>
                <button onClick={handleClose}>
                    <FaXmark />
                </button>
            </header>
            <AttendanceChat
                attendance={attendance}
                desactivateChat={disableMessages}
            />
        </StyledViewChat>
    );
};