import { ReactNode, useContext } from "react";
import { CustomStateHook } from "../../../../Hooks/State.hook";
import { StyledVariablesMenu } from "./style"
import { ReadyMessagesContext } from "../../../../contexts/global/ReadyMessagesContext";
import { FaXmark } from "react-icons/fa6";

interface VariablesMenuProps {
    textState: CustomStateHook<string>;
    modalState: CustomStateHook<ReactNode>;
}

export const VariablesMenu = ({ textState, modalState }: VariablesMenuProps) => {
    const { variables } = useContext(ReadyMessagesContext);

    const handleSelect = (name: string) => {
        textState.set(`${textState.value}${name}`);
        modalState.reset();
    }

    return (
        <StyledVariablesMenu>
            <header>
                <h2> Selecione a variavel </h2>
                <button onClick={modalState.reset}>
                    <FaXmark />
                </button>
            </header>
            <ul>
                {
                    variables.map((v) => {
                        return (
                            <li
                                onClick={() => handleSelect(v.name)}
                                key={`var_${v.name}`}
                            >
                                {v.name}
                            </li>
                        )
                    })
                }
            </ul>
        </StyledVariablesMenu>
    );
}