import styled from "styled-components";
import cssVars from "../../styles/cssVariables.vars";

export const StyledPageNavigation = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    margin: 0.75rem 0 0.75rem 0;
    border-radius: 0.25rem;
    width: max-content;
    padding: 0.375rem 0.25rem;
    background: ${cssVars.colorGrey["7"]};

    >button {
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        >svg {
            font-size: 1rem;
            border-radius: 50%;
            padding: 0.25rem;
            background-color: var(--color-primary);
            color: white;
        }
        
        &:disabled {
            opacity: 0.75;
        }

        cursor: pointer;

        &:not(:disabled):active {
            transform: scale(1.1);
            filter: brightness(1.1);
        }
    }

    >input {
        font-size: 0.875rem;
        line-height: 100%;
        border-radius: 0.25rem;
        padding: 0.125rem 0.25rem;
        width: 2.5rem;
        background: ${cssVars.colorGrey["8"]};
        color: ${cssVars.colorGrey["2"]};
        text-align: right;
    }
`;