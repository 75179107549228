import { useContext } from "react";
import { FaEdit, FaTrash } from "react-icons/fa"
import { CustomMessagesContext } from "../../../../contexts/admin/CustomMessages.context";
import { CustomMessage } from "../../../../interfaces/CustomMessages.interfaces";
import { StyledTableItem } from "./style"
import api from "../../../../services/api";
import { toast } from "react-toastify";
import { ViewFullMessage } from "../../../../components/ViewFullMessage";
import cssVars from "../../../../styles/cssVariables.vars";

interface TableItemProps {
    message: CustomMessage;
};

export const TableItem = ({ message }: TableItemProps) => {
    const { modalState, getAllMessages } = useContext(CustomMessagesContext);
    const { CODIGO, TITULO, SETOR_NOME, ARQUIVO } = message;

    const deleteMessage = () => api.delete(`/ready-messages/${message.CODIGO}`)
        .then(() => {
            getAllMessages();
            toast.success("Mensagem deletada com sucesso!")
        })
        .catch((err) => {
            toast.error("Falha ao deletar mensagem");
            console.error(err);
        });

    const editMessage = () => modalState.set(
        <ViewFullMessage
            message={message}
            mode="edit"
            modalState={modalState}
        />
    );

    return (
        <StyledTableItem>
            <div>
                <button onClick={deleteMessage} title="Deletar">
                    <FaTrash color="rgb(236, 64, 58)" />
                </button>
                <button onClick={editMessage} title="Editar">
                    <FaEdit color={cssVars.colorGrey[3]} />
                </button>
            </div>
            <div className="cod">
                {CODIGO}
            </div>
            <div>
                {TITULO}
            </div>
            <div>
                {ARQUIVO?.slice(37) || "Sem arquivo"}
            </div>
            <div>
                <p>
                    {message.SETOR === 0 ? "Todos" : SETOR_NOME}
                </p>
            </div>
            <div>
                {new Date(message.LAST_UPDATE).toLocaleString()}
            </div>
        </StyledTableItem>
    )
}