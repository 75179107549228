import styled from "styled-components";

const StyledPageController = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 0.25rem 0.5rem;

    background-color: var(--color-grey-4);
    color: var(--color-grey-7);
    font-weight: 500;
    font-size: 0.75rem;
    border-radius: 0.25rem;
    border: 1px solid var(--color-grey-4);
    transition: 0.3s ease-in-out;

    &:hover {
        background-color: var(--color-grey-2);

    }

    >button {
        font-size: 0.75rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--color-grey-8);
        transition: 0.3s ease-in-out;
        padding: 0.375rem 0.375rem;
        border-radius: 0.25rem;
        transition: 0.3s ease-in-out;
        cursor: pointer;

        >svg {
            pointer-events: none;
        }

        &:hover {
            background-color: var(--color-grey-3);
            color: var(--color-grey-8);
        }
    }

    >button:disabled {
        opacity: 0;
        pointer-events: none;
    }
`;

export default StyledPageController;