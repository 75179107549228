import { ReactNode } from "react";
import { StyledCustomTooltip } from "./style";

interface CustomTooltipProps {
    children: ReactNode;
    text: string;
    x: string;
    y: string;
}

export function CustomTooltip({ children, text, x, y }: CustomTooltipProps) {
    return (
        <StyledCustomTooltip x={x} y={y}>
            {children}
            <div className="tooltip">
                {text}
            </div>
        </StyledCustomTooltip>
    )
}