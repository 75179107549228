import { FaArrowLeft, FaSearch, FaTrash } from "react-icons/fa";
import { CustomState, CustomStateHook } from "../../../../../Hooks/State.hook";
import { Contact } from "../../../../../interfaces/Customers.interface";
import { StyledContactList } from "./style"
import { useEffect } from "react";
import api from "../../../../../services/api";
import { toast } from "react-toastify";
import { RegistrationButton } from "../../../../../styles/registrationButton";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import { formatNumber } from "../../../../../Hooks/FormatNumber.hook";
import Input from "../../../../../components/Input";
import { defaultInput100 } from "../../../../../components-variants/defaultInputs";

interface ContactListProps {
    selectedCustomer: CustomStateHook<number | null>;
};

export const ContactList = ({ selectedCustomer }: ContactListProps) => {
    const loadedContacts = CustomState<Array<Contact>>([]);
    const nameState = CustomState<string>("");
    const numberState = CustomState<string>("");

    const filtersState = CustomState<string>("");

    const filteredContacts = loadedContacts.value.filter((v) => {
        return (
            filtersState.value ?
                (
                    v.NOME.toUpperCase().includes(filtersState.value.toUpperCase()) ||
                    v.NUMERO.includes(filtersState.value.replace(/\D/g, ""))
                )
                : true
        );
    });

    function loadContacts() {
        api.get<Array<Contact>>(`/contacts/findByCustomer/${selectedCustomer.value}`)
            .then((res) => {
                loadedContacts.set(res.data);
            }).catch((err) => {
                console.error(err)
                toast.error("Falha ao carregar contatos...");
                selectedCustomer.reset();
            });
    };

    const validName = nameState.value.length >= 3 && nameState.value.length <= 30;
    const validNumber = numberState.value.length >= 12 && numberState.value.length <= 14;
    const disabled = !validName || !validNumber;

    useEffect(() => {
        loadContacts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function addContact() {
        api.post(`/contacts`, {
            CODIGO_CLIENTE: selectedCustomer.value,
            NOME: nameState.value,
            NUMERO: numberState.value.replace(/\D/g, "")
        })
            .then(() => {
                loadContacts();
                toast.success("Contato adicionado com sucesso!");
                numberState.reset();
                nameState.reset();
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.message) {
                    try {
                        if (error.response.data.message instanceof Array) {
                            toast.error(error.response.data.message.join('\n'));
                        } else {
                            toast.error(error.response.data.message);
                        }
                    } catch {
                        toast.error("Falha ao criar contato...");
                    }
                } else {
                    toast.error("Falha ao criar contato...");
                };
            });
    };

    function deleteContact(contactId: number) {
        api.delete(`/contacts/${contactId}`)
            .then(() => {
                loadContacts();
                toast.success("Contato removido com sucesso!");
            })
            .catch(() => {
                toast.error("Falha ao remover contato...");
            });
    }

    return (
        <StyledContactList>
            <button onClick={selectedCustomer.reset}>
                <FaArrowLeft /> Voltar
            </button>
            <Input
                {...defaultInput100}
                $fontSize={1}
                $padding={[0.5, 0.5]}
                $width="24rem"
                leftIcon={<FaSearch />}
                placeholder="Filtrar contatos"
                value={filtersState.value}
                onChange={(e) => filtersState.set(e.target.value)}
            />
            <ul>
                {
                    filteredContacts.map((contact) => {
                        return (
                            <li>
                                <div>
                                    <h3> {contact.NOME} </h3>
                                    <span> {formatNumber(contact.NUMERO)} </span>
                                </div>
                                <button onClick={() => deleteContact(contact.CODIGO)}>
                                    <FaTrash />
                                </button>
                            </li>
                        )
                    })
                }
            </ul>
            <div className="register_contact">
                <Input
                    {...defaultInput100}
                    $fontSize={1}
                    $padding={[0.5, 0.5]}
                    value={nameState.value}
                    onChange={(e) => nameState.set(e.target.value)}
                    placeholder="Nome"
                />
                <PhoneInputWithCountrySelect
                    id="register-client-phone"
                    placeholder="Numero"
                    defaultCountry="BR"
                    style={{
                        border: "2px solid var(--color-grey-2)",
                        padding: "0.5rem 0.5rem",
                        borderRadius: "0.5rem"
                    }}
                    onChange={(phone) => {
                        numberState.set(String(phone))
                    }}
                    value={numberState.value}
                />
                <RegistrationButton
                    disabled={disabled}
                    onClick={addContact}
                >
                    Adicionar
                </RegistrationButton>
            </div>
        </StyledContactList>
    );
};