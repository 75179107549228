import styled from "styled-components";

const StyledAttendancesWithoutResponse = styled.div`
    box-sizing: border-box;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    gap: 1rem;

    overflow-y: auto;

    .auto-complete {
        * {
            color: var(--color-grey-2) !important;
            border-color: var(--color-grey-2) !important;
            outline-color: var(--color-grey-2) !important;
            fill: var(--color-grey-2) !important;
        }
    }

    >.charts {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        margin: 4px;
        margin-bottom: 2rem;

        >div {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            border: 1px solid var(--color-grey-6);
            border-radius: 0.25rem;
            padding: 1rem;
            
            &:nth-child(odd) {
                background-color: var(--color-grey-7);
            }

            &:nth-child(even) {
                background-color: var(--color-grey-6);
            }

            >h2 {
                font-size: 1rem;
                font-weight: 500;
            }
        }
    }
`;

export default StyledAttendancesWithoutResponse;