import { IoMdClose } from "react-icons/io";
import { StyledFinishAttendance } from "./style";
import { CustomState } from "../../Hooks/State.hook";
import { useEffect, useContext } from "react";
import api from "../../services/api";
import { Result } from "../../interfaces/Attendances.interfaces";
import { RegistrationButton } from "../../styles/registrationButton";
import { AttendancesContext } from "../../contexts/user/AttendancesContext";
import { toast } from "react-toastify";
import "react-calendar/dist/Calendar.css";
import "react-time-picker/dist/TimePicker.css";
import Input from "../Input";
import Select from "../Select";
import { defaultInput100, defaultSelect100 } from "../../components-variants/defaultInputs";
import cssVars from "../../styles/cssVariables.vars";
import LoadingSpinner from "../LoadingSpinner";

export const FinishAttendance = () => {

    const { finishAttendance, modalState, readingAttendance } = useContext(AttendancesContext);
    const results = CustomState<Array<Result>>([]);
    const selectedResult = CustomState<Result | null>(null);
    const selectedDate = CustomState<Date>(new Date());
    const loadingState = CustomState<boolean>(false);

    const minDate = new Date();

    const needSchedule: boolean = selectedResult.value?.WHATS_ACAO === "AGENDAR";
    const disabled: boolean = !selectedResult.value || needSchedule ? !selectedDate.value : false;

    function clickFinish() {
        finishAttendance(selectedResult.value!.CODIGO, selectedDate.value);
        toast.success("Atendimento finalizado com sucesso!");
        modalState.reset();
    };

    useEffect(() => {
        loadingState.set(true);
        api.get<Array<Result>>(`/results/`)
            .then(res => {
                console.dirxml(res, { depth: null })
                results.set(res.data)
            })
            .catch(err => console.error(err))
            .finally(() => loadingState.set(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <StyledFinishAttendance>
            <header>
                <h2>
                    Finalizar Atendimento: {readingAttendance.value?.CONTATO_NOME}
                </h2>
                <IoMdClose onClick={modalState.reset} />
            </header>
            {
                loadingState.value ?
                    <LoadingSpinner />
                    :
                    <>
                        <Select
                            {...defaultSelect100}
                            $fontSize={1}
                            $padding={[0.5, 0.5]}
                            options={results.value.map(o => ({ name: o.NOME, value: o }))}
                            onChange={(v) => selectedResult.set(v)}
                            placeholder="Selecione o resultado"
                            $focusColor={cssVars.colorGrey[2]}
                            $color={cssVars.colorGrey[3]}
                        />
                        {
                            needSchedule &&
                            <Input
                                {...defaultInput100}
                                $fontSize={1}
                                $padding={[0.5, 0.5]}
                                type="datetime-local"
                                onChange={(e) => selectedDate.set(new Date(e.target.value))}
                                min={minDate.toISOString().slice(0, 16)}
                                leftIcon={null}
                            />
                        }
                    </>
            }
            <RegistrationButton
                onClick={clickFinish}
                disabled={disabled}
            >
                Finalizar
            </RegistrationButton>
        </StyledFinishAttendance>
    );
};