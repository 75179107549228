import cssVars from '../../../../../styles/cssVariables.vars';
import styled from "styled-components";

export const StyledAttendanceItem = styled.li`
    box-sizing: border-box;
    width: 100%;

    display: flex;
    align-items: center;
    gap: 0.25rem;
    padding: 0.5rem 0.5rem;
    border-radius: 0.25rem;
    height: 4.375rem;


    &.ALTA {
        background-color: rgba(225, 225, 55, 0.25);
    }

    &.MUITO_ALTA {
        background-color: rgba(255, 100, 55, 0.25);
    }

    &.URGENTE {
        background-color: rgba(225, 55, 55, 0.25);
    }   

    &.disabled {
        pointer-events: none;
        opacity: 0.75;
    }

    &.selected {
        outline: 1px solid ${cssVars.colorGrey["2"]};
    }
    
    color: ${cssVars.colorGrey[3]};

    >svg,
    img {
        width: 3rem;
        height: 3rem;
        object-fit: cover;
        margin-right: 0.5rem;
        
        

        @media (max-width: 80rem) {
            display: none;
        }
    }

    img {
        outline: 1px solid rgba(0,0,0, 0.125);
        border-radius: 50%;
    }

    >div.box_1 {
        display: flex;
        flex-direction: column;
        width: calc(100% - 7rem);
        overflow: hidden;

        @media (max-width: 80rem) {
            width: calc(100% - 3rem);
        }

        >h2 {
            font-size: 0.75rem;
            font-weight: 600;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        >h3 {
            font-size: 0.7rem;
            font-weight: 700;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        >.lastmsg {
            margin-top: 0.25rem;
            display: flex;
            align-items: center;
            gap: 0.5rem;
            font-size: 0.675rem;
            white-space: nowrap;
            overflow: hidden;
            font-weight: 500;

            &>:nth-child(1) {
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                
            }
        }
    }

    :hover:not(.selected) {
        cursor: pointer;
        background-color: rgba(0,0,0, 0.05);
    }

    >div.box_2{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.25rem;
        width: 3.5rem;
        
        >span.lastmsgtime {
            font-size: 0.625rem;
            font-weight: 700;
        }

        >.not_read_label {
            font-size: 0.625rem;
            padding: 0.125rem;
            width: 1rem;
            height: 1rem;
            font-weight: 700;
            background-color: var(--color-primary-1);
            color: white;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        >.urgencia {
            border-radius: 0.25rem;
            padding: 0.125rem 0.25rem;
            font-size: 0.625rem;
            font-weight: 500;
            color: white;
            width: max-content;
            font-weight: 700;

            &.NORMAL {
                background-color: rgb(185, 215, 185);
                color: black;
            }

            &.MEDIA {
                background-color: rgb(55, 225, 55);
            }
            
            &.ALTA {
                background-color: rgb(225, 225, 55);
            }

            &.MUITO_ALTA {
                background-color: rgb(255, 100, 55);
            }

            &.URGENTE {
                background-color: rgb(225, 55, 55);
                font-weight: 600;
            }

            &.EXPIRADO {
                background-color: rgba(0, 0, 0, 100%);
                font-weight: 600;
            }
        }
    }
`;