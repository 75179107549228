import { useContext } from "react";
import { CustomMessagesContext } from "../../../contexts/admin/CustomMessages.context";
import { Modal } from "../../../styles/modal";
import { RegistrationButton } from "../../../styles/registrationButton";
import { StyledQuickMessages } from "./style";
import { Formulario } from "./Formulario";
import { TableItem } from "./TableItem";

export const QuickMessages = () => {
    const {
        modalState,
        readyMessages
    } = useContext(CustomMessagesContext);

    const closeModal = () => modalState.set("");
    const openFormulario = () => modalState.set(<Formulario closeFunction={closeModal} />)

    return (
        <StyledQuickMessages>
            <header>
                <div>
                    Ações
                </div>
                <div>
                    ID
                </div>
                <div>
                    Título
                </div>
                <div>
                    Arquivo
                </div>
                <div>
                    Setor
                </div>
                <div>
                    Ultima modificação
                </div>
            </header>
            <ul>
                {
                    readyMessages.value
                        .map((rm) => <TableItem message={rm} key={`cmsg_${rm.CODIGO}`} />)
                }
            </ul>
            <RegistrationButton onClick={() => openFormulario()}>
                Cadastrar nova
            </RegistrationButton>
            {
                modalState.value &&
                <Modal>
                    {modalState.value}
                </Modal>
            }
        </StyledQuickMessages>
    );
};