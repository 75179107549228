import { useEffect } from 'react'
import { StyledListaDeNumeros, TableContainer } from './style'
import { IoMdClose } from 'react-icons/io';
import { RegistrationButton } from '../../../../../../../styles/registrationButton';
import { Contact, Customer } from '../../../../../../../interfaces/Customers.interface';
import { CustomState } from '../../../../../../../Hooks/State.hook';
import api from '../../../../../../../services/api';
import { TableItem } from './TableItem';
import Input from '../../../../../../../components/Input';
import { defaultInput100, defaultInput50 } from '../../../../../../../components-variants/defaultInputs';
import { FaSearch } from 'react-icons/fa';

interface TableProps {
    customer: Customer;
    closeFunction: () => void;
};

interface ContactFormState {
    NOME: string;
    NUMERO: string;
    CODIGO_CLIENTE: number;
}

interface FilterState {
    NOME: string;
    NUMERO: string;
}

const ListaDeTelefone = ({ customer, closeFunction }: TableProps) => {
    const contacts = CustomState<Array<Contact>>([]);
    const formState = CustomState<ContactFormState>({ NOME: "", NUMERO: "", CODIGO_CLIENTE: customer.CODIGO });
    const filtersState = CustomState<FilterState>({ NOME: "", NUMERO: "" });

    useEffect(() => {
        loadContacts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const filteredContacts = contacts.value.filter((v) => {
        return (
            (filtersState.value.NOME
                ? v.NOME.toUpperCase().includes(filtersState.value.NOME.toUpperCase())
                : true) &&
            (filtersState.value.NUMERO
                ? v.NUMERO.includes(filtersState.value.NUMERO.replace(/\D/g, ""))
                : true)
        );
    });

    function loadContacts() {
        api.get(`/contacts/findByCustomer/${customer.CODIGO}`)
            .then((res) => {
                console.log(res.data);
                contacts.set(res.data);
            });
    };

    const onSubmitFunction = () => {
        const sanitizedContactData = ({ ...formState.value, NUMERO: formState.value.NUMERO.replace(/\D/g, "") })
        api.post(`/contacts/`, sanitizedContactData)
            .then(() => loadContacts())
            .finally(() => closeFunction())
    };

    return (
        <TableContainer>
            <header>
                <h2> Contatos de "{customer.RAZAO}" </h2>
                <button className='closeModal' onClick={closeFunction}>
                    <IoMdClose />
                </button>
            </header>
            <StyledListaDeNumeros>
                <header>
                    <div>
                        Ações
                    </div>
                    <div>
                        Código
                    </div>
                    <div>
                        <Input
                            placeholder="Nome"
                            value={filtersState.value.NOME}
                            onChange={(e) => filtersState.set(prev => ({ ...prev, NOME: e.target.value }))}
                            {...defaultInput100}
                            $padding={[0.375, 0.375]}
                            $fontSize={0.75}
                            leftIcon={<FaSearch />}
                        />
                    </div>
                    <div>
                        <Input
                            placeholder="Número"
                            value={filtersState.value.NUMERO}
                            onChange={(e) => filtersState.set(prev => ({ ...prev, NUMERO: e.target.value }))}
                            {...defaultInput100}
                            $padding={[0.375, 0.375]}
                            $fontSize={0.75}
                            leftIcon={<FaSearch />}
                        />
                    </div>
                </header>
                <ul>
                    {filteredContacts.map((contact) => <TableItem
                        contact={contact}
                        key={contact.NUMERO}
                        closeFunction={loadContacts}
                    />)}
                </ul>
            </StyledListaDeNumeros>
            <h3> Novo contato </h3>
            <div className="inputs">
                <Input
                    placeholder="Nome do contato"
                    minLength={6}
                    onChange={(e) => formState.set(prev => ({ ...prev, NOME: e.target.value }))}
                    value={formState.value.NOME}
                    required
                    {...defaultInput50}
                    $padding={[0.5, 0.5]}
                />

                <Input
                    placeholder="Número Whatsapp ex: 551198765432"
                    minLength={6}
                    value={formState.value.NUMERO}
                    onChange={(e) => formState.set(prev => ({ ...prev, NUMERO: String(e.target.value.replace(/\D/g, "")) }))}
                    required
                    {...defaultInput50}
                    $padding={[0.5, 0.5]}
                />
            </div>

            <RegistrationButton onClick={onSubmitFunction}>
                Adicionar
            </RegistrationButton>
        </TableContainer>
    );
}

export default ListaDeTelefone;