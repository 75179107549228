import { IoMdClose } from "react-icons/io";
import { AttendancesContext } from "../../contexts/user/AttendancesContext";
import { StyledQuickMessage } from "./style";
import { useContext, useEffect } from "react";
import { CustomState } from "../../Hooks/State.hook";
import { CustomMessage } from "../../interfaces/CustomMessages.interfaces";
import api from "../../services/api";
import { ListItem } from "./ListItem";
import { RegistrationButton } from "../../styles/registrationButton";
import { SocketContext } from "../../contexts/global/SocketContext";
import { toast } from "react-toastify";
import { LoginContext, currentRoute } from "../../contexts/global/LoginContext";
import { ReadyMessagesContext } from "../../contexts/global/ReadyMessagesContext";
import formatarCPForCNPJ from "../../Hooks/FormatCPFCNPJ.hook";
import formatarNome from "../../Hooks/FormatName.hook";
import uuid from "react-uuid";
import { SpecificAttendanceDetails } from "../../interfaces/Attendances.interfaces";

interface Props {
    attendance: SpecificAttendanceDetails;
}

export const QuickMessage = ({ attendance }: Props) => {
    const { modalState } = useContext(AttendancesContext);
    const { socket } = useContext(SocketContext);
    const { user } = useContext(LoginContext);
    const { replaceVariables } = useContext(ReadyMessagesContext);

    const readyMessages = CustomState<Array<CustomMessage>>([]);
    const selectedMessage = CustomState<CustomMessage | null>(null);

    useEffect(() => {
        api.get<Array<CustomMessage>>(`/ready-messages/`)
            .then(res => {
                if (user) {
                    const filtered = res.data.filter(rm => rm.SETOR === user.SETOR || rm.SETOR === 0);
                    readyMessages.set(filtered);
                }

            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function sendMessage() {
        if (socket.value && user && selectedMessage.value) {
            const currentSaudation = () => {
                const currentTime = new Date();
                const hour = currentTime.getHours();

                if (hour >= 5 && hour < 12) {
                    return "Bom dia";
                } else if (hour >= 12 && hour < 18) {
                    return "Boa tarde";
                } else {
                    return "Boa noite";
                }
            }

            const contactName = attendance.CONTATO_NOME || "CONTATO EXCLUÍDO";
            const replacedVarsMessage = replaceVariables({
                message: selectedMessage.value.TEXTO_MENSAGEM,
                values: {
                    currentSaudation: currentSaudation(),
                    customerCnpj: formatarCPForCNPJ(attendance.CPF_CNPJ),
                    customerFirstName: contactName.split(" ")[0],
                    customerFullName: contactName,
                    customerRazao: attendance.RAZAO,
                    operatorName: formatarNome(user.NOME),
                    operatorNameExibition: formatarNome(user.NOME_EXIBICAO)
                }
            })

            const requestForm = new FormData();
            const localId = uuid();

            requestForm.append("clientName", currentRoute);
            requestForm.append("operatorId", String(user.CODIGO));
            requestForm.append("attendanceId", String(attendance.CODIGO));
            requestForm.append("contactId", String(attendance.CODIGO_NUMERO));
            requestForm.append("customerId", String(attendance.CODIGO_CLIENTE));
            requestForm.append("text", replacedVarsMessage);
            requestForm.append("localId", localId);

            if (selectedMessage.value.ARQUIVO) {
                requestForm.append("filename", selectedMessage.value.ARQUIVO);
            }

            api.post(`/custom-routes/send-message`, requestForm)
                .then(() => { })
                .catch((err) => {
                    toast.error("Falha ao enviar mensagem");
                })
            //
            modalState.reset();
            toast.success("Mensagem enviada com sucesso!");
        } else {
            toast.error("fallha ao enviar mensagem...");
        };

    };



    return (
        <StyledQuickMessage>
            <header>
                <h2>
                    Mensagens rápidas
                </h2>
                <button onClick={modalState.reset}>
                    <IoMdClose />
                </button>
            </header>
            <main>
                {
                    readyMessages.value.length ?
                        <ul>
                            {readyMessages.value.map(rm => <ListItem message={rm} state={selectedMessage} key={rm.CODIGO} attendance={attendance} />)}
                        </ul>
                        :
                        <div className="no-messages">
                            Nenhuma mensagem cadastrada, contate seu supervisor.
                        </div>
                }
                <RegistrationButton
                    onClick={sendMessage}
                    disabled={!selectedMessage.value}
                    className="btn-send"
                >
                    Enviar
                </RegistrationButton>
            </main>
        </StyledQuickMessage>
    );
};