import { createContext, useState, ReactNode } from "react";
import { ClientsRegistrationContextProps, ClientsRegistrationProviderProps, NewClientFormData } from "../../interfaces/Clients.interfaces";
import api from "../../services/api";

export const ClientsRegistrationContext = createContext({} as ClientsRegistrationContextProps);

export const ClientsRegistrationProvider = ({ children }: ClientsRegistrationProviderProps) => {
    const [modal, setModal] = useState<ReactNode>("");
    const modalState = { modal, setModal };

    function insertNewCostumer(data: NewClientFormData) {
        const { RAZAO, FANTASIA, CPF_CNPJ, NOME, NUMERO } = data;

        api.post('costumers', { RAZAO, FANTASIA, CPF_CNPJ, NUMERO })
            .then(res => {
                insertNewWhatsappNumber(res.data, NOME, NUMERO);
            })
            .catch(err => console.error(err));
    };

    function insertNewWhatsappNumber(CODIGO_CLIENTE: number, NOME: string, NUMERO: string) {
        api.post('/costumers/numbers', { CODIGO_CLIENTE, NOME, NUMERO })
            .catch(err => console.error(err));
    };

    return (
        <ClientsRegistrationContext.Provider value={{
            insertNewCostumer,
            modalState
        }}>
            {children}
        </ClientsRegistrationContext.Provider>
    );
};