import { useEffect } from "react";
import StyledAttendancesWithoutResponse from "./style";
import { customRequest } from "../../../../services/api";
import AttendancesWithoutResponseTable from "./table";
import { CustomState } from "../../../../Hooks/State.hook";
import { AttendanceWithoutResponse } from "./types";
import { BarChart } from '@mui/x-charts';
import cssVars from "../../../../styles/cssVariables.vars";
import MultiSelect from "../../../../components/MultiSelect";

function AttendancesWithoutResponse() {
    const rowsState = CustomState<AttendanceWithoutResponse[]>([]);
    const sectorRowsState = CustomState<AttendanceWithoutResponse[]>([]);

    useEffect(() => {
        customRequest<AttendanceWithoutResponse[], undefined>({
            endpoint: "/dashboard/contacts-awaiting-return",
            method: "get",
            onSuccess: (res) => {
                rowsState.set(res);
                sectorRowsState.set(res);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const operators = Array.from(new Set(sectorRowsState.value.map(r => r.OPERADOR))) || [];
    const attendanceNumbers = operators.map(o => sectorRowsState.value.filter(r => r.OPERADOR === o).length) || [];
    const sectors = Array.from(new Set(rowsState.value.map(r => r.SETOR)));
    const options = [...sectors.map(s => ({ name: s, value: s }))];

    return (
        <StyledAttendancesWithoutResponse>
            {
                sectors.length > 1 &&
                <MultiSelect<string>
                    $fontSize={1}
                    $padding={[0.5, 1]}
                    options={options}
                    onChange={(v) => sectorRowsState.set(rowsState.value.filter(r => v.includes(r.SETOR)))}
                    placeholder="Setor"
                    $color={cssVars.colorGrey[4]}
                    $width="30rem"
                />
            }
            <AttendancesWithoutResponseTable
                rowsState={sectorRowsState}
            />
            <div className="charts">
                <div>
                    <h2>
                        Atendimentos sem resposta por operador:
                    </h2>
                    {
                        operators?.length && attendanceNumbers?.length &&
                        <BarChart
                            xAxis={[
                                {
                                    id: 'barCategories',
                                    data: operators,
                                    scaleType: 'band',
                                },
                            ]}
                            colors={[
                                "var(--color-primary)",
                            ]}
                            series={[
                                {
                                    data: attendanceNumbers,
                                },
                            ]}
                            height={600}
                        />
                    }
                </div>
            </div>
        </StyledAttendancesWithoutResponse>
    )
}

export default AttendancesWithoutResponse;