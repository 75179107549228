import styled from "styled-components";
import cssVars from "../../../../styles/cssVariables.vars";

export const StyledOperadores = styled.div`
    box-sizing: border-box;
    height: 100%;
    display: grid;
    grid-template-areas: 
    "header"
    "body";

    grid-template-rows: 2rem 1fr;
    overflow-x: auto;

    >header {
        grid-area: header;

        display: grid;
        grid-template-areas:
        "idh statush nameh emailh sectorh";
        grid-template-columns: 5rem 8rem 24rem 24rem 1fr;
        padding-right: 0.75rem;
        background-color: ${cssVars.colorGrey["6h"]};
        color: ${cssVars.colorGrey[2]};

        >div {
            box-sizing: border-box;
            padding: 0.5rem;
            font-size: 0.75rem;
            font-weight: 500;
        }

        >div.idh {
            grid-area: idh;
        }

        >div.statush {
            grid-area: statush;
        }

        >div.nameh {
            grid-area: nameh;
        }

        >div.emailh {
            grid-area: emailh;
        }


        >div.sectorh {
            grid-area: sectorh;
            min-width: 8rem;
        }

        >div.search {
            padding: 0;
        }
    }

    >div:nth-child(2) {
        grid-area: body;
        overflow: auto;
        overflow-x: hidden;
    }

`