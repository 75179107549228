import { useContext } from "react";
import { ClientsRegistrationContext } from "../../../../contexts/admin/ClientsRegistration.context";
import { Modal } from "../../../../styles/modal";
import { RegistrationButton } from "../../../../styles/registrationButton";
import { Formulario } from "./Formulario";
import { ListaDeClientes } from "./ListaDeClientes";
import { StyledCadastroCliente } from "./style";
import { CustomerContext } from "../../../../contexts/admin/Customers.context";
import { Option } from "../../../../components/CustomSelect";
import Input from "../../../../components/Input";
import Select from "../../../../components/Select";
import { defaultInput100, defaultSelect100 } from "../../../../components-variants/defaultInputs";
import cssVars from "../../../../styles/cssVariables.vars";
import { FaSearch } from "react-icons/fa";

export const CadastroCliente = () => {

    const { modalState } = useContext(ClientsRegistrationContext);

    const { getAllCustomers, searchState, fieldState } = useContext(CustomerContext)

    const closeModal = () => modalState.setModal("");
    const openRegister = () => modalState.setModal(<Formulario closeFunction={closeModal} />);

    const searchFieldOptions: Array<Option<string>> = [
        { name: "Razão Social", value: "RAZAO" },
        { name: "Código inPulse", value: "CODIGO" },
        { name: "Código ERP", value: "COD_ERP" },
        { name: "CPF/CNPJ", value: "CPF_CNPJ" },
        { name: "Número Whatsapp", value: "NUMERO" },
    ];

    return (
        <StyledCadastroCliente>
            <div className="search">
                <Input
                    placeholder="Pesquisar cliente"
                    onChange={(e) => {
                        if (["CPF_CNPJ", "NUMERO"].some(v => v === fieldState.value)) {
                            searchState.set(e.target.value.replace(/\D/g, ''));
                        } else {
                            searchState.set(e.target.value);
                        }
                    }}
                    value={searchState.value}
                    {...defaultInput100}
                    $fontSize={0.875}
                    leftIcon={<FaSearch />}
                />
                <Select
                    onChange={(v) => fieldState.set(v || "")}
                    options={searchFieldOptions}
                    defaultValue={searchFieldOptions[0]}
                    {...defaultSelect100}
                    $fontSize={0.875}
                    $focusColor={cssVars.colorGrey[2]}
                />
                <RegistrationButton onClick={getAllCustomers}>
                    Buscar
                </RegistrationButton>
            </div>
            <ListaDeClientes />
            <RegistrationButton onClick={openRegister}>
                Cadastrar Novo Cliente
            </RegistrationButton>
            {modalState.modal && <Modal> {modalState.modal} </Modal>}
        </StyledCadastroCliente>
    );
};