import { ReactNode, useContext } from "react";
import { CustomState, CustomStateHook } from "../../Hooks/State.hook";
import { RegistrationButton } from "../../styles/registrationButton";
import api from "../../services/api";
import { Contact } from "../../interfaces/Customers.interface";
import { AttendancesContext } from "../../contexts/user/AttendancesContext";
import { toast } from "react-toastify";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import { ModalBasis } from "../ModalBasis";
import Input from "../Input";
import { defaultInput100 } from "../../components-variants/defaultInputs";


interface TransferDialogProps {
    modalState: CustomStateHook<ReactNode>;
    CODIGO_NUMERO: number;
    CONTATO_NOME: string | null;
    CONTATO_NUMERO: string | null;
};

export const UpdateContactDialog = ({ modalState, CODIGO_NUMERO, CONTATO_NOME, CONTATO_NUMERO }: TransferDialogProps) => {
    const name = CustomState<string>(CONTATO_NOME || "");
    const number = CustomState<string>(CONTATO_NUMERO ? "+" + CONTATO_NUMERO : "");
    const { detailedAttendances, readingAttendance } = useContext(AttendancesContext);

    async function updateContact() {
        api.patch<Contact>(`/contacts/${CODIGO_NUMERO}`, { NOME: name.value || null, NUMERO: number.value.replace(/\D/g, "") })
            .then((res) => {
                detailedAttendances.set((prev) => prev.map((a) => a.CODIGO_NUMERO === CODIGO_NUMERO ? { ...a, CONTATO_NOME: res.data.NOME, CONTATO_NUMERO: res.data.NUMERO } : a));
                readingAttendance.set((prev) => prev ? ({ ...prev, CONTATO_NOME: res.data.NOME, CONTATO_NUMERO: res.data.NUMERO }) : prev);
                modalState.reset();
            })
            .catch((err) => {
                toast.error(err.response.data ? err.response.data.message : "Falha ao atualizar contato, contate o suporte.");
            });
    };

    return (
        <ModalBasis
            title="Atualizar contato"
            size={{ w: 22, h: 13 }}
            padding={{ v: 1, h: 1 }}
            onClose={() => modalState.reset()}
        >
            <Input
                {...defaultInput100}
                $fontSize={1}
                $padding={[0.5, 0.5]}
                placeholder="Nome"
                value={name.value}
                onChange={(e) => name.set(e.target.value)}
            />

            <PhoneInputWithCountrySelect
                value={number.value}
                onChange={(v) => number.set(String(v))}
                defaultCountry="BR"
                style={{
                    border: "1px solid var(--color-grey-4)",
                    padding: "0.5rem 0.5rem",
                    borderRadius: "0.5rem"
                }}
            />

            <RegistrationButton
                disabled={name.value.length <= 2}
                type="button"
                onClick={updateContact}
                style={{ padding: "0.625rem 2rem", fontSize: '1rem', marginTop: "0.5rem" }}
            >
                Atualizar
            </RegistrationButton>
        </ModalBasis>
    );
};