import styled from "styled-components";

export const StyledFerramentas = styled.div`
    box-sizing: border-box;
    padding: 1rem;
    height: calc(100% - 4rem);
    width: 100%;
    overflow-y: auto;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    
    >.menu {
        width: 24rem;
    }
`;