import { StyledQrCode } from "./styled";
import QRCode from 'qrcode.react';

interface QrCodeProps {
    number: string;
    qr: string;
};

export const QrCode = ({ number, qr }: QrCodeProps) => {

    return (
        <StyledQrCode>
            <h1>
                {number}
            </h1>
            <h2>
                Leia o QrCode para conectar-se ao Whatsapp.
            </h2>
            <QRCode value={qr} size={400} />
        </StyledQrCode>
    );
};