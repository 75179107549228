import { ReactNode, useContext } from "react";
import { CustomState, CustomStateHook } from "../../Hooks/State.hook";
import { StyledScheduleDialog } from "./style";
import { RegistrationButton } from "../../styles/registrationButton";
import { IoMdClose } from "react-icons/io";
import { SocketContext } from "../../contexts/global/SocketContext";
import "react-calendar/dist/Calendar.css";
import "react-time-picker/dist/TimePicker.css";
import { LoginContext, currentRoute } from "../../contexts/global/LoginContext";
import Input from "../Input";
import { defaultInput100 } from "../../components-variants/defaultInputs";

interface ScheduleDialogProps {
    modalState: CustomStateHook<ReactNode>;
    CODIGO_ATENDIMENTO: number;
};

export const ScheduleDialog = ({ modalState, CODIGO_ATENDIMENTO }: ScheduleDialogProps) => {

    const selectedDate = CustomState<Date | null>(null);
    const minDate = new Date();
    const { socket } = useContext(SocketContext);
    const { user } = useContext(LoginContext);

    function scheduleAttendance() {
        if (socket.value && user) {
            socket.value.emit("reschedule-attendance", {
                clientName: currentRoute,
                attendanceId: CODIGO_ATENDIMENTO,
                operatorId: user.CODIGO,
                scheduleDate: selectedDate.value
            });

            modalState.reset();
        };
    };

    return (
        <StyledScheduleDialog>
            <header>
                <h2> Agendar retorno </h2>
                <IoMdClose onClick={modalState.reset} />
            </header>

            <Input
                {...defaultInput100}
                $fontSize={1}
                $padding={[0.5, 0.5]}
                type="datetime-local"
                onChange={(e) => selectedDate.set(new Date(e.target.value))}
                min={minDate.toISOString().slice(0, 16)}

            />
            <RegistrationButton
                disabled={!selectedDate.value || selectedDate.value <= minDate}
                onClick={scheduleAttendance}

            >
                Agendar
            </RegistrationButton>
        </StyledScheduleDialog>
    );
};