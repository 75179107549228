import styled, { css } from "styled-components";

interface Props {
    x: string;
    y: string;
}

export const StyledCustomTooltip = styled.div<Props>`
    width: max-content;
    height: max-content;
    
    &:hover {
        .tooltip {
            display: block;
            opacity: 1;
        }
    }

    >.tooltip{
        opacity: 0;
        display: none;
        position: absolute;
        width: 6rem;
        text-align: center;
        padding: 0.375rem;
        font-size: 0.875rem;
        font-weight: 800;
        color: white;
        background-color: var(--color-primary-1-o);
        z-index: 5;
        border-radius: 0.25rem;
        pointer-events: none;
        transition: opacity 500ms ease-in-out;
        ${({ x, y }) => `transform: translateY(${y}) translateX(${x});`}
    }

    @keyframes gradual {
        0% {
            opacity: 0;
        } 100% {
            opacity: 1;
        }
    }
`;