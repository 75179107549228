import { useContext, useEffect, useState } from "react";
import { StyledInProgressAttendances } from "./style";
import { TableItem } from "./TableItem";
import { OperatorUrgency, RunningAttendanceDetails, SupervisorUrgency } from "../../../../interfaces/Attendances.interfaces";
import api from "../../../../services/api";
import { SocketContext } from "../../../../contexts/global/SocketContext";
import Input from "../../../../components/Input";
import { defaultInput100, defaultSelect100 } from "../../../../components-variants/defaultInputs";
import MultiSelect from "../../../../components/MultiSelect";

export const InProgressAttendances = () => {
    const [attendances, setAttendances] = useState<RunningAttendanceDetails[]>([]);
    const operators = Array.from(new Set(attendances.map(a => a.OPERADOR)));
    const types = Array.from(new Set(attendances.map(a => a.TIPO)));
    const sectors = Array.from(new Set(attendances.map(a => a.SETOR_NOME)))

    const [filterOperator, setFilterOperator] = useState<string[]>([]);
    const [filterPriority, setFilterPriority] = useState<string[]>([]);
    const [filterType, setFilterType] = useState<string[]>([]);
    const [filterSector, setFilterSector] = useState<string[]>([]);
    const [filterId, setFilterId] = useState<string>("");
    const [filterErp, setFilterErp] = useState<string>("");
    const [filterContact, setFilterContact] = useState<string>("");
    const [filterCustomer, setFilterCustomer] = useState<string>("");
    const [filterNumber, setFilterNumber] = useState<string>("");

    const filteredAttendances = attendances.filter((a) => {
        const { URGENCIA_SUPERVISOR, URGENCIA_AGENDAMENTO, URGENCIA_OPERADOR } = a;
        const urgencia: string = URGENCIA_SUPERVISOR || URGENCIA_AGENDAMENTO || URGENCIA_OPERADOR;

        return (filterOperator.length ? filterOperator.includes(a.OPERADOR || "") : true) &&
            (filterPriority.length ? filterPriority.includes(urgencia || "") : true) &&
            (filterType.length ? filterType.includes(a.TIPO || "") : true) &&
            (filterSector.length ? filterSector.includes(a.SETOR_NOME) : true) &&
            (filterId ? String(a.CODIGO || "") === filterId : true) &&
            (filterContact ? a.CONTATO_NOME?.toUpperCase().includes(filterContact) : true) &&
            (filterCustomer ? a.RAZAO?.toUpperCase().includes(filterCustomer) : true) &&
            (filterErp ? String(a.COD_ERP || "") === filterErp : true) &&
            (filterNumber ? String(a.CONTATO_NUMERO || "").includes(filterNumber.replace(/\D/g, '')) : true)
    });

    const { socket } = useContext(SocketContext);

    useEffect(() => {
        api.get<Array<RunningAttendanceDetails>>(`/custom-routes/running-attendances`)
            .then(res => {
                res.data.forEach(a => {
                    a.DATA_INICIO = new Date(a.DATA_INICIO);
                });
                setAttendances(res.data.sort((a, b) => b.DATA_INICIO.getTime() - a.DATA_INICIO.getTime()));
            });
    }, []);

    useEffect(() => {
        const handleUpdateUrgency = (data: { URGENCIA_OPERADOR?: OperatorUrgency, URGENCIA_SUPERVISOR?: SupervisorUrgency | null, CODIGO: number }) => {
            setAttendances(prev => {
                const newArr = [...prev];
                const index = newArr.findIndex(ra => ra.CODIGO === data.CODIGO);

                const { CODIGO, ...updated } = data;
                if (~index) newArr[index] = { ...newArr[index], ...updated };

                return newArr;
            });
        };
        if (socket.value) {
            socket.value.on("update-urgency", handleUpdateUrgency);
        }

        return () => {
            if (socket.value) {
                socket.value.off("update-urgency", handleUpdateUrgency);
            }
        };
    }, [socket.value]);

    const operatorsOptions = operators.map(o => ({ name: o, value: o }));
    const typesOptions = types.map(t => ({ name: t, value: t }));
    const sectorOptions = sectors.map(s => ({ name: s, value: s }));
    const priorityOptions = [
        { name: "Urgente", value: "URGENTE" },
        { name: "Muito Alta", value: "MUITO_ALTA" },
        { name: "Alta", value: "ALTA" },
        { name: "Media", value: "MEDIA" },
        { name: "Normal", value: "NORMAL" },
    ];

    return (
        <StyledInProgressAttendances>
            <header>
                <div className="actions_header" >
                    Ações
                </div>
                <div className="cod_attendance search" >
                    <Input
                        placeholder="Código"
                        value={filterId}
                        onChange={(e) => setFilterId(e.target.value.toUpperCase())}
                        {...defaultInput100}
                        $disableBorder
                        $fontSize={0.75}
                    />
                </div>

                <div className="cod_erp search" >
                    <Input
                        placeholder="Códidgo ERP"
                        value={filterErp}
                        onChange={(e) => setFilterErp(e.target.value.toUpperCase())}
                        {...defaultInput100}
                        $disableBorder
                        $fontSize={0.75}
                    />
                </div>

                <div className="priority_header search">
                    <MultiSelect
                        options={priorityOptions}
                        onChange={(v) => setFilterPriority(v)}
                        placeholder="Prioridade"
                        $disableBorder
                        {...defaultSelect100}
                        $fontSize={0.75}
                    />
                </div>

                <div className="attendance_sector_header search">
                    <MultiSelect
                        options={sectorOptions}
                        onChange={(v) => setFilterSector(v)}
                        placeholder="Setor"
                        $disableBorder
                        {...defaultSelect100}
                        $fontSize={0.75}
                    />
                </div>

                <div className="operator_header search" >
                    <MultiSelect
                        options={operatorsOptions}
                        onChange={(v) => setFilterOperator(v)}
                        placeholder="Operador"
                        $disableBorder
                        {...defaultSelect100}
                        $fontSize={0.75}
                    />
                </div>

                <div className="client_name_header search">
                    <Input
                        placeholder="Contato"
                        value={filterContact}
                        onChange={(e) => setFilterContact(e.target.value.toUpperCase())}
                        {...defaultInput100}
                        $disableBorder
                        $fontSize={0.75}
                    />
                </div>

                <div className="client_phone_header search">
                    <Input
                        placeholder="Numero"
                        value={filterNumber}
                        onChange={(e) => setFilterNumber(e.target.value)}
                        {...defaultInput100}
                        $disableBorder
                        $fontSize={0.75}
                    />
                </div>

                <div className="client_company_header search">
                    <Input
                        placeholder="Razão Social"
                        value={filterCustomer}
                        onChange={(e) => setFilterCustomer(e.target.value.toUpperCase())}
                        {...defaultInput100}
                        $disableBorder
                        $fontSize={0.75}
                    />
                </div>

                <div className="start_date_header">
                    Data de início
                </div>

                <div className="attendance_type_header search">
                    <MultiSelect
                        options={typesOptions}
                        onChange={(v) => setFilterType(v)}
                        placeholder="Tipo"
                        {...defaultSelect100}
                        $disableBorder
                        $fontSize={0.75}
                    />
                </div>
            </header>
            <div>
                {filteredAttendances.map((attendance, index) => (
                    <TableItem
                        attendance={attendance}
                        key={index}
                        setAttendances={setAttendances}
                    />
                )
                )}
            </div>
        </StyledInProgressAttendances>
    );
};