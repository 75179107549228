import cssVars from "../../../styles/cssVariables.vars";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { TableColumn } from "../types";
import MultiSelect from "../../MultiSelect";
import { Option } from "../../Select";
import { CustomStateHook } from "../../../Hooks/State.hook";
import { FilterWithPaginationQueryParameters } from "../../../interfaces/generics.types";

function filterMultiSelect<T>(
    column: TableColumn<T>,
    filterState: CustomStateHook<FilterWithPaginationQueryParameters<T>>,
) {
    const options = column.filter!.options as Array<Option<T[keyof T]>>;
    const defaultValue = options.filter(o => {
        return filterState.value[column.key]?.split(";").includes(o.value as string);
    });

    const onChange = (v: T[keyof T][]) => {
        filterState.set(prev => {
            if (v.length) {
                prev[column.key] = v.join(";") as any;
            } else {
                delete prev[column.key];
            }

            return prev;
        })
    }

    return (
        <MultiSelect
            $color={cssVars.colorGrey[3]}
            $borderColor={cssVars.colorGrey[2]}
            $padding={[0.5, 0.5]}
            $fontSize={0.875}
            $width={column.filter!.width}
            leftIcon={column.filter?.icon || <FaMagnifyingGlass />}
            onChange={onChange}
            options={options}
            defaultValue={defaultValue}
            placeholder={column.filter!.placeholder || `${column.header || String(column.key)}s`}
        />
    );
}

export default filterMultiSelect;