/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext } from 'react';
import { ToastContainer } from 'react-toastify';
import { LoginContext } from './contexts/global/LoginContext';
import 'react-toastify/dist/ReactToastify.css';
import { GlobalStyles } from './styles/global';
import { ResetBrowserStyles } from './styles/reset';
import { CssBaseline, createTheme } from '@mui/material';
import { ThemeProvider } from 'styled-components';
import cssVars from './styles/cssVariables.vars';
const App = () => {

	const { renderingPage, darkMode } = useContext(LoginContext);

	const darkTheme = createTheme({
		palette: {
			mode: 'dark',
			background: {
				default: cssVars.colorGrey[8],
				paper: cssVars.colorGrey[8],
			},
			primary: {
				dark: "rgba(240,245,250,1.0)",
				light: "rgba(180,185,190,1.0)",
				main: "rgba(210,215,220,1.0)"
			}
		},
		components: {
			MuiAutocomplete: {
				styleOverrides: {
					input: {
						color: 'white',
					},
				},
			}
		}
	});

	const lightTheme = createTheme({
		palette: {
			mode: 'light',
		},
	});

	return (
		<div className="App" style={{ height: '100vh', width: '100vw' }}>
			<ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
				<ResetBrowserStyles />
				<GlobalStyles darkMode={darkMode} />
				<ToastContainer
					position="top-right"
					autoClose={1000}
					newestOnTop={true}
					rtl={false}
					pauseOnFocusLoss={true}
					draggable
					pauseOnHover={true}
					theme={darkMode ? "dark" : "light"}
				/>

				{renderingPage}

			</ThemeProvider>
		</div>
	);
};

export default App;
