import { ChangeEvent, FocusEvent, FormEvent, useContext, useEffect, useRef, useState } from "react";
import { FaPaperclip, FaPaperPlane, FaRegCommentAlt, FaSmileWink, FaWhatsappSquare } from "react-icons/fa";
import { StyledTypeMessageForm } from "./style";
import { ChatContext } from "../../../contexts/user/ChatContext";
import { IoMdClose } from "react-icons/io";
import { CustomState } from "../../../Hooks/State.hook";
import { useAudioRecorder, AudioRecorder } from "react-audio-voice-recorder";
import { AttendancesContext } from "../../../contexts/user/AttendancesContext";
import { QuickMessage } from "../../QuickMessage";
import { Modal } from "../../../styles/modal";
import { QuickTemplate } from "../../QuickTemplate";
import { SocketContext } from "../../../contexts/global/SocketContext";
import EmojiPicker, { Theme } from 'emoji-picker-react';
import { SpecificAttendanceDetails } from "../../../interfaces/Attendances.interfaces";
import { LoginContext } from "../../../contexts/global/LoginContext";

interface Props {
    attendance: SpecificAttendanceDetails;
}

export const TypeMessageForm = ({ attendance }: Props) => {
    const enabled = CustomState<boolean>(true);

    const [rows, setRows] = useState(1);
    const [isShiftPressed, setIsShiftPressed] = useState(false);

    const {
        sendMessage,
        referenceId,
        replyingMessage,
        handleFileSelect,
        formData
    } = useContext(ChatContext);

    const { modalState, attendancesMessages } = useContext(AttendancesContext);
    const { oficialMode } = useContext(SocketContext);
    const { darkMode } = useContext(LoginContext);

    const openQuickMessages = () => {
        if (oficialMode.value) {
            /* const allContactMessages = attendancesMessages.value.filter((m) => m.CODIGO_NUMERO === attendance.CODIGO_NUMERO);
            const lastContactMessage = allContactMessages.reduce((last, current) => {
                if (!current.FROM_ME && (!last || current.TIMESTAMP > last.TIMESTAMP)) {
                    return current;
                };

                return last;
            })

            if (lastContactMessage) {
                const messageTimestamp = new Date(lastContactMessage.DATA_HORA).getTime();
                const currentTimestamp = Date.now();
                const timeDifference = currentTimestamp - messageTimestamp;
                const twentyFourHoursInMilliseconds = 86400000;

                if (timeDifference > twentyFourHoursInMilliseconds) {
                    return toast.error("A janela de 24 horas da conversa já foi expirada, é necessário o envio de outro template para continuar.", { autoClose: 5000 });
                } else {
                    modalState.set(<Modal><QuickMessage attendance={attendance} /></Modal>);
                };
            } else {
                return toast.error("Não é possível enviar uma mensagem comum, antes que o cliente tenha respondido.", { autoClose: 5000 });
            }; */
            modalState.set(<Modal><QuickMessage attendance={attendance} /></Modal>);
        } else {
            modalState.set(<Modal><QuickMessage attendance={attendance} /></Modal>);
        };
    };
    const openQuickTemplate = () => modalState.set(<Modal><QuickTemplate attendance={attendance} /></Modal>)

    function onChangeFunction(e: ChangeEvent<HTMLTextAreaElement>) {
        formData.set((prev) => {
            return { ...prev, text: e.target.value };
        });

        setRows(e.target.value.split(/\n/g).length);
    };

    function onFocusFunction(e: FocusEvent<HTMLTextAreaElement>) {
        setRows(e.target.value.split(/\n/g).length);
    };

    function onBlurFunction(e: FocusEvent<HTMLTextAreaElement>) {
        let totalRows = e.target.value.split(/\n/g).length;
        setRows(1);
        setTimeout(() => {
            e.target.scroll(0, totalRows * 16)
        }, 50);
    };

    function onSubmitFunction(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();
        sendMessage(attendance);
        formData.reset();
    };

    const recorderControls = useAudioRecorder();

    const addAudioElement = (blob: Blob) => {
        const file = new File([blob], "audio.webm", { type: blob.type });

        formData.set((prev) => {
            return { text: "", isAudio: true, file, referenceId: prev.referenceId };
        });
    };

    const onAudioClose = () => {
        formData.set((prev) => {
            return { ...prev, file: null, isAudio: false };
        });
    };

    useEffect(() => {
        if (!recorderControls.isRecording) {
            enabled.set(false);
            setTimeout(() => {
                enabled.set(true);
            }, 1000);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recorderControls.isRecording]);

    const inputFileRef = useRef<HTMLInputElement>(null);

    const [isDisabled, setDisabled] = useState(false);

    function onKeyDownFunction(e: React.KeyboardEvent<HTMLTextAreaElement>) {
        if (e.key === 'Enter' && !isShiftPressed) {
            e.preventDefault();

            if (formData.value.text.trim() !== '') {
                sendMessage(attendance);
                formData.reset();
            }
        } else if (e.key === 'Shift') {
            setIsShiftPressed(true);
        }
    }

    function onKeyUpFunction(e: React.KeyboardEvent<HTMLTextAreaElement>) {
        if (e.key === 'Shift') {
            setIsShiftPressed(false);
        }
    }


    useEffect(() => {
        const contactId = attendance.CODIGO_NUMERO;
        const contactMessage = attendancesMessages.value.find(m => m.CODIGO_NUMERO === contactId && !m.FROM_ME);

        if (oficialMode.value && !contactMessage) {
            setDisabled(true);
        } else {
            setDisabled(false);
        };
    }, [attendance.CODIGO_NUMERO, oficialMode.value, attendancesMessages.value]);

    const [isEmojiActive, setEmojiActive] = useState(false);

    return (
        <StyledTypeMessageForm onSubmit={onSubmitFunction} >
            {
                replyingMessage.value &&
                <div className="replyingMessage">
                    <div>
                        <h2> {replyingMessage.value.FROM_ME ? "Você" : attendance.CONTATO_NOME} </h2>
                        <span> {decodeURI(replyingMessage.value.MENSAGEM).split(/\n/g).map(p => <p> {p}</p>)} </span>
                    </div>
                    <IoMdClose onClick={() => referenceId.reset()} />
                </div>
            }
            {
                isEmojiActive &&
                <div className="emoji_picker">
                    <EmojiPicker
                        onEmojiClick={(emoji, event) => {
                            formData.set(prev => ({ ...prev, text: prev.text + emoji.emoji }))
                        }}
                        theme={darkMode ? Theme.DARK : Theme.LIGHT}
                    />
                </div>
            }
            <div className="main">
                <div>
                    <button
                        type="button"
                        onClick={openQuickMessages}
                        disabled={isDisabled}
                        title="Mensagens prontas"
                    >
                        <FaRegCommentAlt />
                    </button>
                    {
                        oficialMode.value &&
                        <button
                            type="button"
                            onClick={openQuickTemplate}
                            title="Templates Whatsapp"
                        >
                            <FaWhatsappSquare />
                        </button>

                    }
                    <input
                        type="file"
                        onChange={handleFileSelect}
                        style={{ display: 'none' }}
                        ref={inputFileRef}
                    />
                    <button
                        onClick={() => inputFileRef.current?.click()}
                        type="button"
                        disabled={isDisabled}
                        title="Anexar arquivo"
                    >
                        <FaPaperclip />
                    </button>
                    <button
                        onClick={() => setEmojiActive(prev => !prev)}
                        type="button"
                    >
                        <FaSmileWink />
                    </button>
                </div>
                {
                    !!formData.value.file && formData.value.isAudio ?
                        <>
                            <audio controls src={URL.createObjectURL(formData.value.file)} />
                            <IoMdClose onClick={onAudioClose} />
                        </>
                        :
                        <textarea
                            placeholder="Mensagem"
                            onBlur={onBlurFunction}
                            onFocus={onFocusFunction}
                            onChange={onChangeFunction}
                            onKeyDown={onKeyDownFunction}
                            onKeyUp={onKeyUpFunction}
                            value={formData.value.text}
                            rows={rows}
                            disabled={isDisabled}
                        />
                }
                <div>
                    {
                        !isDisabled &&
                        <AudioRecorder
                            onRecordingComplete={(blob) => addAudioElement(blob)}
                            recorderControls={recorderControls}
                            audioTrackConstraints={{
                                noiseSuppression: true,
                                echoCancellation: true,
                            }}
                        />
                    }



                    <button
                        type="submit"
                        disabled={(recorderControls.isRecording || !enabled.value) || (formData.value.text.length === 0 && !formData.value.file) || isDisabled}
                        title="Enviar mensagem"
                    >
                        <FaPaperPlane />
                    </button>
                </div>
            </div>
        </StyledTypeMessageForm>
    );
};