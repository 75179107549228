import { StyledTableItem } from "./style";
import { OperatorSessions } from "../../../../../interfaces/Users.interfaces";
import { CustomState } from "../../../../../Hooks/State.hook";
import { useEffect } from "react";
import api from "../../../../../services/api";
import { Sector } from "../../../../../interfaces/RunningAttendances";

interface iTableProps {
    user: OperatorSessions;
};

export const TableItem = ({ user }: iTableProps) => {
    const sector = CustomState<string>("");

    useEffect(() => {
        api.get<Sector>(`/sectors/${user.operatorSector}`)
            .then((res) => {
                sector.set(res.data.NOME);
            }).catch(() => {
                sector.set("Indefinido")
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <StyledTableItem className={user.status}>
            <div className="idb">
                {user.operatorId}
            </div>
            <div className={`statusb`}>
                {
                    user.status === "paused" ?
                        "pausado"
                        :
                        user.status
                }
            </div>
            <div className="nameb">
                {user.operatorData.NOME}
            </div>
            <div className="emailb">
                {user.operatorData.EMAIL}
            </div>
            <div className="sectorb">
                {sector.value}
            </div>
        </StyledTableItem>
    );
};