import styled from "styled-components";
import cssVars from "../../../../../styles/cssVariables.vars";

export const StyledTableItem = styled.div`
   display: grid;
   grid-template-areas:
    "actions coda priority operator clientname clientnumber clientcompany scheduledate type ";
    grid-template-columns: 8rem 6rem 9rem 12rem 12rem 10rem 1fr 10rem 12rem;

    &:nth-child(even) {
        background-color: ${cssVars.colorGrey[7]};
    }

    &:nth-child(odd) {
        background-color: ${cssVars.colorGrey[8]};
    }

    &.ALTA {
        background-color: rgba(225, 225, 55, 0.15);
    }

    &.MUITO_ALTA {
        background-color: rgba(255, 100, 55, 0.15);
    }

    &.URGENTE {
        background-color: rgba(225, 55, 55, 0.15);
    } 

    >div {
        box-sizing: border-box;
        padding: 0.5rem 1rem;
        font-size: 0.75rem;
        font-weight: 500;

        display: flex;
        align-items: center;

        overflow-x: hidden;
        text-align: start;

        >p {
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            display: inline-block;
        }
    }

    >div.actions {
        border-left: none;
        grid-area: actions;
        display: flex;
        gap: 0.5rem;

        >svg {
            width: 1.25rem;
            height: 1.25rem;
            color: ${cssVars.colorGrey[3]};
            cursor: pointer;

            :hover {
                transform: scale(1.1);
                color: var(--color-primary);
            }
        }
    }

    >div.priority {
        grid-area: priority;
    }

    >div.operator {
        grid-area: operator;
    }

    >div.clientname {
        grid-area: clientname;
    }

    >div.clientcompany {
        grid-area: clientcompany;
        min-width: 20rem;
    }

    >div.scheduledate {
        grid-area: scheduledate;
    }

    >div.type {
        grid-area: type;
    }
`