import styled from "styled-components";

export const StyledEnvioDeMensagensEmMassa = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: max-content;
    width: 100%;

    overflow-y: auto;
    
    .w24 {
        width: 24rem;
    }
`;