import { useContext } from "react";
import { SupervisorContext } from "../../contexts/admin/Supervisor.context";
import { SocketContext } from "../../contexts/global/SocketContext";
import { Modal } from "../../styles/modal";
import { StyledSupervisorPage } from "./style"
import { SupervisorHeader } from "./SupervisorHeader";
import { SupervisorMenu } from "./SupervisorMenu";
import { ChatProvider } from "../../contexts/user/ChatContext";
import { AttendancesContext } from "../../contexts/user/AttendancesContext";

export const SupervisorPage = () => {
    const { page } = useContext(SupervisorContext);
    const { qrModalState } = useContext(SocketContext);
    const { modalState } = useContext(SupervisorContext);
    const { modalState: attendanceModalState } = useContext(AttendancesContext);

    return (
        <StyledSupervisorPage>
            <ChatProvider>
                <SupervisorMenu />
                <main>
                    <SupervisorHeader />
                    {page}
                </main>
                {
                    qrModalState.value &&
                    <Modal>
                        {qrModalState.value}
                    </Modal>
                }
                {
                    modalState.value &&
                    <Modal>
                        {modalState.value}
                    </Modal>
                }
                {
                    attendanceModalState.value
                }
            </ChatProvider>
        </StyledSupervisorPage>
    );
};