import { FadeLoader } from "react-spinners";
import styled from "styled-components";
import cssVars from "../../styles/cssVariables.vars";

const LoadingSpinnerStyles = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    >:nth-child(1) {
        transform: scale(0.625);
    }
`;


const LoadingSpinner = () => {
    return (
        <LoadingSpinnerStyles>
            <FadeLoader
                className="loading-spinner"
                color={cssVars.colorGrey[2]}

            />
        </LoadingSpinnerStyles>
    )
}

export default LoadingSpinner;