import { IoMdClose } from "react-icons/io";
import { RegistrationButton } from "../../styles/registrationButton";
import { StyledSelectAttendanceTemplate } from "./style";
import { AttendancesContext } from "../../contexts/user/AttendancesContext";
import { useContext } from "react";
import { CustomState, CustomStateHook } from "../../Hooks/State.hook";
import { OficialWhatsappMessageTemplate, RecoverTemplatesResponse } from "../../interfaces/MessageTemplates.interfaces";
import { ReactNode, useEffect } from "react";
import api from "../../services/api";
import { FaEye } from "react-icons/fa";
import { Modal } from "../../styles/modal";
import { ViewMessageTemplate } from "../ViewMessageTemplate";

interface Props {
    modalState: CustomStateHook<ReactNode>;
};

export const SelectAttendanceTemplate = ({ modalState }: Props) => {
    const { selectedTemplate, selectedClient, startAttendance } = useContext(AttendancesContext);

    const templates = CustomState<Array<OficialWhatsappMessageTemplate>>([]);
    const modal = CustomState<ReactNode>("");

    useEffect(() => {
        if (!selectedClient.value) modalState.reset();

        api.get<RecoverTemplatesResponse>(`/whatsapp-cloud-api/templates`)
            .then(res => {
                if (res.data.data) {
                    templates.set(res.data.data.filter(t => t.status.toUpperCase() === "APPROVED"));
                };
            });
    }, []);

    const { CODIGO, CODIGO_CONTATO, NUMERO } = selectedClient.value!;

    return (
        <StyledSelectAttendanceTemplate>
            <header>
                <h2>
                    Selecione o template para iniciar:
                </h2>
                <button
                    onClick={modalState.reset}
                >
                    <IoMdClose />
                </button>
            </header>
            <ul>
                {
                    templates.value.map(t => {
                        return (
                            <li className="template_item">
                                <div className="check">
                                    <input
                                        type="radio"
                                        checked={
                                            selectedTemplate.value?.id === t.id
                                        }
                                        onClick={() => selectedTemplate.set(t)}
                                    />
                                </div>
                                <div className="name">
                                    {t.name}
                                </div>
                                <div className="view">
                                    <button onClick={() => modal.set(<ViewMessageTemplate messageTemplate={t} modal={modal} />)}>
                                        <FaEye />
                                    </button>
                                </div>
                            </li>
                        );
                    })
                }
            </ul>
            <RegistrationButton
                disabled={!selectedTemplate.value}
                style={{ margin: "1rem" }}
                onClick={() => {
                    startAttendance(
                        CODIGO, CODIGO_CONTATO, NUMERO, null, selectedTemplate.value!,
                        {
                            cnpj: selectedClient.value!.CPF_CNPJ,
                            razao: selectedClient.value!.RAZAO,
                            nome: selectedClient.value!.NOME
                        }
                    );

                    console.log(selectedClient.value);

                    modalState.reset();
                }}
            >
                Iniciar
            </RegistrationButton>
            {
                modal.value && <Modal> {modal.value} </Modal>
            }
        </StyledSelectAttendanceTemplate>
    );
};