import styled from "styled-components";
import cssVars from "../../styles/cssVariables.vars";

export const StyledViewFullMessage = styled.div`
    background-color: ${cssVars.colorGrey[8]};
    padding: 1rem;
    min-width: 35rem;
    border-radius: 0.25rem;

    >header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 1rem;

        >h1 {
            font-size: 1rem;
            font-weight: 500;
        }
        >div{
            display: flex;
            gap: 1rem;
            align-items: center;

            >button {
                display: flex;
                align-items: center;
                font-size: 1.5rem;
                cursor: pointer;

                :hover {
                    transform: scale(1.1);
                }
            }
        }
    }

    >div {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        >div {
            position: relative;
            box-sizing: border-box;
            
            border-radius: 0.25rem;
            padding: 2px;
            border: 2px solid ${cssVars.colorGrey[5]};
            background-color: ${cssVars.colorGrey["7h"]};

            >input {
                padding: 0.5rem;
                font-size: 0.875rem !important;
                background-color: ${cssVars.colorGrey["7h"]};
                color: ${cssVars.colorGrey[2]};
                border-radius: 0.25rem;
                width: 100%;
                box-sizing: border-box;
            }

            >textarea{
                box-sizing: border-box;
                width: 100% !important;
                padding: 0.75rem 0.5rem;
                overflow-y: scroll;
                resize: none;
                font-size: 0.875rem;
                height: 15rem;
                background-color: ${cssVars.colorGrey["7h"]};
                color: ${cssVars.colorGrey[2]};
                border-radius: 0.25rem;
            }

            &:has(:disabled) {
                >svg {
                    display: none;
                }
            }
        }
        


        >span {
            display: block;
            padding-top: 1rem;
        }

        .select {
            position: relative;
            border-radius: 0.25rem;
            margin: 0.25rem 0;
            border: 2px solid ${cssVars.colorGrey[5]};

            >select {
                appearance: none;
                width: 100%;
                padding: 0.5rem;
                background-color: ${cssVars.colorGrey["7h"]};
                color: ${cssVars.colorGrey[2]};
                border-radius: 0.25rem;
                cursor: pointer;
                font-size: 0.875rem;
            }

            >svg {
                position: absolute;
                right: 1rem;
                top: calc(50% - 0.5rem);
                pointer-events: none;
                font-size: 1rem;
            }
        }
    }

    .files {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    .bottom-btns {
        border: none;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        gap: 0.5rem;
        background-color: ${cssVars.colorGrey["8"]};

        >button {
            padding: 0.5rem 2rem;
            border-radius: 0.25rem;
            cursor: pointer;
            
            &.revert {
                background-color: ${cssVars.colorGrey["6h"]};
                color: ${cssVars.colorGrey[2]};
            }

            &.save {
                background-color: ${cssVars.colorPrimary};
                color: white;
            }
        }
    }
`;