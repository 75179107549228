import axios, { AxiosInstance, RawAxiosRequestHeaders } from "axios";
import { toast } from "react-toastify";
import { CustomStateHook } from "../Hooks/State.hook";

const currentRoute = /* window.location.hostname.split('.')[0]; */ "develop";

export const baseURL = process.env.REACT_APP_API_URL ?
    `${process.env.REACT_APP_API_URL}/${currentRoute}`
    :
    `https://striking-multiply-eel.ngrok-free.app/api/develop`;

const api: AxiosInstance = axios.create({
    baseURL: baseURL,
    timeout: 20000,
    headers: {
        "ngrok-skip-browser-warning": "skip"
    }
});

type CustomRequestOptions<TResponseData, TRequestData> = {
    method: "get" | "post" | "patch" | "put" | "delete";
    endpoint: `/${string}`;
    params?: Record<string, any>;
    requestHeaders?: RawAxiosRequestHeaders;
    requestData?: TRequestData;
    onSuccess: (data: TResponseData) => void;
    onError?: (error: any) => void;
    loadingState?: CustomStateHook<boolean>;
    enableToast?: boolean;
    successToastMessage?: string;
    timeoutSeconds?: number
}

export type PaginatedResponse<T> = {
    data: Array<T>,
    page: {
        current: number;
        next: boolean;
        previous: boolean;
    }
}

async function customRequest<TResponseData, TRequestData>({
    method,
    endpoint,
    onSuccess,
    onError,
    loadingState,
    requestHeaders,
    params,
    requestData,
    enableToast,
    successToastMessage,
    timeoutSeconds = 20
}: CustomRequestOptions<TResponseData, TRequestData>): Promise<void> {
    try {
        loadingState && loadingState.set(true);

        let response: PaginatedResponse<TResponseData> | any;

        const localToken = localStorage.getItem("@inpulse/auth-token");
        const headers = { Authorization: localToken ? `Bearer ${localToken}` : undefined, ...requestHeaders };
        const timeout = timeoutSeconds * 1000;

        switch (method) {
            case "post":
                response = await api.post<PaginatedResponse<TResponseData>>(endpoint, requestData, { headers, params, timeout });
                break;
            case "patch":
                response = await api.patch<PaginatedResponse<TResponseData>>(endpoint, requestData, { headers, params, timeout });
                break;
            case "put":
                response = await api.put<PaginatedResponse<TResponseData>>(endpoint, requestData, { headers, params, timeout });
                break;
            case "delete":
                response = await api.delete<PaginatedResponse<TResponseData>>(endpoint, { headers, params, timeout });
                break;
            default:
                response = await api.get<PaginatedResponse<TResponseData>>(endpoint, { headers, params, timeout });
                break;
        }

        onSuccess(response.data);
        enableToast && successToastMessage && toast.success(successToastMessage);
    } catch (err: any) {
        console.error(err.response ? (err.response?.data || err.response) : err?.message);
        console.error(err);

        enableToast && toast.error(err.response ? err.response.data : err?.message);
        onError && onError(err);
    } finally {
        loadingState && loadingState.set(false);
    }
}

export default api;
export { customRequest }