import { CustomStateHook } from "../../../../Hooks/State.hook";
import Table from "../../../../components/Table";
import { TableColumn } from "../../../../components/Table/types";
import { FilterWithPaginationQueryParameters } from "../../../../interfaces/generics.types";
import { AttendanceWithoutResponse } from "./types";

const columns: Array<TableColumn<AttendanceWithoutResponse>> = [
    {
        key: "CODIGO_ATENDIMENTO",
        header: "Código",
        width: 4,
    },
    {
        key: "SETOR",
        header: "Setor",
        width: 8,
        filter: {
            type: "select",
            width: "calc(50% - 0.5rem)",
            icon: <></>,
            placeholder: "Setor"
        }
    },
    {
        key: "OPERADOR",
        header: "Operador",
        width: 10,
        filter: {
            type: "select",
            width: "calc(50% - 0.5rem)",
            icon: <></>,
            placeholder: "Operador"
        }
    },
    {
        key: "CLIENTE",
        header: "Cliente",
        width: 20,
        filter: {
            type: "input",
            width: "calc(50% - 0.5rem)",
        }
    },
    {
        key: "CONTATO",
        header: "Contato",
        width: 12,
        filter: {
            type: "input",
            width: "calc(50% - 0.5rem)",
        }
    },
    {
        key: "DATA_MENSAGEM_CLIENTE",
        header: "Data ult. mensagem",
        width: 8,
        format: (r) => {
            const date = new Date((r.DATA_MENSAGEM_CLIENTE)).toLocaleString()

            return date;
        },
        filter: {
            type: "date-range",
            width: "100%"
        }
    },
    {
        key: "DATA_MENSAGEM_CLIENTE",
        header: "Tempo de espera",
        width: 8,
        format: (r) => {
            const currentDate = new Date();
            const lastMsgDate = new Date((r.DATA_MENSAGEM_CLIENTE));

            const timeDifference = currentDate.getTime() - lastMsgDate.getTime();

            const seconds = Math.floor((timeDifference / 1000) % 60);
            const minutes = Math.floor((timeDifference / 1000 / 60) % 60);
            const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
            const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

            let formattedTime = "";

            if (days > 0) {
                formattedTime += `${days} dia${days > 1 ? 's' : ''}, `;
            }
            if (hours > 0) {
                formattedTime += `${hours} hora${hours > 1 ? 's' : ''}, `;
            }
            if (minutes > 0) {
                formattedTime += `${minutes} minuto${minutes > 1 ? 's' : ''}, `;
            }
            if (seconds > 0) {
                formattedTime += `${seconds} segundo${seconds > 1 ? 's' : ''}`;
            }

            formattedTime = formattedTime.replace(/,\s*$/, "");

            return formattedTime;
        }
    },

];

type Props = {
    filtersState?: CustomStateHook<FilterWithPaginationQueryParameters<AttendanceWithoutResponse>>;
    rowsState: CustomStateHook<Array<AttendanceWithoutResponse>>;
}
const AttendancesWithoutResponseTable = ({ rowsState, filtersState }: Props) => {
    return (
        <Table<AttendanceWithoutResponse>
            columns={columns}
            $fontSize={0.75}
            $modalFiltersWidth={40}
            $tableHeight="max-content"
            $tableWidth="calc(100% - 6px)"
            rowsState={rowsState}
            tableName="Atendimentos sem resposta"
            appliedFiltersState={filtersState}
        />
    );
}

export default AttendancesWithoutResponseTable;