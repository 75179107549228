import styled from "styled-components";
import cssVars from "../../../styles/cssVariables.vars";

export const StyledSupervisorHeader = styled.header`
    box-sizing: border-box;
    width: 100%;
    height: 4rem;

    background-color: ${cssVars.colorGrey[7]};
   
    display: flex;
    justify-content: space-between;

    >div:nth-child(1) {
        display: flex;
        align-items: center;
        width: calc(100% - 18rem);
        justify-content: space-between;

        >h1 {
            font-size: var(--font-title-3);
            color: ${cssVars.colorGrey[3]};
            font-weight: 700;
        }

        padding-left: 1rem;
        padding-right: 1rem;
    }

    >div:nth-child(2) {
        box-sizing: border-box;
        width: 18rem;

        padding: 0.5rem;
        color: ${cssVars.colorGrey[2]};

        cursor: default;

        display: flex;
        align-items: center;
        justify-content: flex-end;

        :hover {
            filter: brightness(1.1);
        }

        >div:nth-child(1) {
            display: flex;
            align-items: center;
            width: calc(100% - 3rem);
            display: flex;
            gap: 0.5rem;
            overflow: hidden;

            >.avatar {
                box-sizing: border-box;
                width: 2.5rem;
                height: 2.5rem;
                object-fit: cover;
                border-radius: 50%;
                padding: 0.5rem;
                color: ${cssVars.colorGrey["3"]};
                border: 2px solid ${cssVars.colorGrey["3"]};
                cursor: pointer;
            }

            >div {
                width: calc(100% - 3rem);
                display: flex;
                flex-direction: column;
                gap: 0.125rem;

                >span {
                    font-size: 0.875rem;
                }

                >h2 {
                    font-size: 0.875rem;
                    white-space: nowrap;
                }
            }
        }

        // Botão logout
        >:nth-child(2) {
            padding: 0.5625rem;
            padding-right: 1rem;
            width: 1.25rem;
            height: 1.25rem;
            margin-right: 0.25rem;
            cursor: pointer;

            filter: drop-shadow(1px 1px 0 rgba(0,0,0, 0.5));
        }
    }

`;