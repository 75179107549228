import { createGlobalStyle } from "styled-components";

export const ResetBrowserStyles = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        outline: none;
        border: none;
        background-color: transparent;
        list-style: none;
    }
`;