import styled from "styled-components";
import cssVars from "../../../../styles/cssVariables.vars";

export const StyledRegisterContactsModal = styled.div`
    background-color: ${cssVars.colorGrey[8]};
    border-radius: 0.25rem;
    height: max-content;
    width: 40rem;
    height: 40rem;
    max-height: 80vh;

    >header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
        padding-bottom: 0.5rem;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
        gap: 1rem;

        >h2 {
            font-size: 1rem;
            font-weight: 500;
        }

        >button {
            cursor: pointer;

            >svg {
                font-size: 1.5rem;
            }

            :hover {
                transform: scale(1.25);
            }
        }
    }

    >main {
        padding: 1rem;
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
        box-sizing: border-box;
        height: calc(100% - 3.25rem);
        overflow-y: auto;

        >h3 {
            font-weight: 500;
            font-size: 1rem;
        }

        >div.search {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            row-gap: 0.5rem;
            flex-wrap: wrap;

            >div:nth-child(1) {
                width: 22rem;
            }

            >div:nth-child(2) {
                width: 14rem;
            }

            >button {
                height: auto;
                font-size: 0.75rem;
                font-weight: 600;
                padding: 0.625rem 2rem;
                box-sizing: border-box;
            }
        }

        >ul {
            display: flex;
            flex-direction: column;
            gap: 0.25rem;
            overflow-y: auto;
            height: 100%;
            padding-right: 0.125rem;

            box-sizing: border-box;
            
            >li {
                display: flex;
                justify-content: space-between;
                cursor: pointer;
                border: 2px solid transparent;

                &:nth-child(odd) {
                    background-color: ${cssVars.colorGrey["7h"]};
                }

                &:nth-child(even) {
                    background-color: ${cssVars.colorGrey["6h"]};
                }

                &:hover {
                    border: 2px solid ${cssVars.colorGrey[4]};
                }

                border-radius: 0.25rem;
                padding: 0.5rem;
                transition: 250ms ease-in-out;
                
                >div {
                    display: flex;
                    flex-direction: column;
                    gap: 0.06125rem;

                    >h4 {
                        font-size: 0.875rem;
                        font-weight: 500;
                        color: ${cssVars.colorGrey[1]};
                    }

                    >h5 {
                        display: flex;
                        align-items: center;
                        gap: 0.5rem;
                        font-weight: 400;
                        font-size: 0.75rem;
                    }
                }
            }
        }

        >div.page {
            margin-bottom: 0 !important;
            margin-top: auto;
        }
    }
`;
