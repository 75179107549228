import { useContext, useEffect, useState } from "react";
import { FaEdit, FaSave } from "react-icons/fa";
import { TiCancel } from "react-icons/ti";
import { UsersRegistrationContext } from "../../../../../../contexts/admin/UsersRegistration.context";
import { NIVEL, UpdateUserRequestData, UserData } from "../../../../../../interfaces/Users.interfaces";
import { StyledTableItem } from "./style"
import { Option } from "../../../../../../components/CustomSelect";
import Select from "../../../../../../components/Select";
import Input from "../../../../../../components/Input";
import { defaultInput100, defaultSelect100 } from "../../../../../../components-variants/defaultInputs";
import { CustomState } from "../../../../../../Hooks/State.hook";
import api from "../../../../../../services/api";

interface TableItemProps {
    operador: UserData;
};

export const TableItem = ({ operador }: TableItemProps) => {
    const { updateOperator } = useContext(UsersRegistrationContext);

    const [disabled, setDisabled] = useState<boolean>(true);
    const [login, setLogin] = useState<string>(operador.LOGIN);
    const [nome, setNome] = useState<string>(operador.NOME);
    const [nomeExibicao, setNomeExibicao] = useState<string>(operador.NOME_EXIBICAO);
    const [nivel, setNivel] = useState<NIVEL>(operador.NIVEL);
    const [sector, setSector] = useState<number | null>(operador.SETOR);

    const sectorOptions = CustomState<Array<Option<number>>>([]);

    useEffect(() => {
        api.get<{ CODIGO: number, NOME: string }[]>(`/sectors`)
            .then(res => {
                const options = res.data.map(s => ({ name: s.NOME, value: s.CODIGO }))
                sectorOptions.set(options);
            })
            .catch(err => console.error(err));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function cancelEdit() {
        setLogin(operador.LOGIN);
        setNome(operador.NOME);
        setNomeExibicao(operador.NOME_EXIBICAO);
        setNivel(operador.NIVEL);
        setDisabled(true);
    };

    function saveEdit() {
        const data: UpdateUserRequestData = {
            NIVEL: nivel,
            NOME: nome,
            LOGIN: login,
            NOME_EXIBICAO: nomeExibicao || null,
            SETOR: sector
        };

        setDisabled(true);
        updateOperator(operador.CODIGO, data);
    };

    const options: Array<Option<NIVEL>> = [
        { name: "Supervisor", value: "ADMIN" },
        { name: "Atendente (Ativo)", value: "ATIVO" },
        { name: "Atendente (Recep)", value: "RECEP" },
        { name: "Atendente (Ambos)", value: "AMBOS" }
    ]

    return (
        <StyledTableItem>
            <div>
                {
                    disabled ?
                        <FaEdit onClick={() => { setDisabled(false) }} />
                        :
                        <>
                            <FaSave onClick={saveEdit} color="rgba(15, 215, 50, 80%)" />
                            <TiCancel onClick={cancelEdit} size="1.25rem" color="red" />
                        </>
                }
            </div>
            <div>
                {operador.CODIGO}
            </div>
            <div>
                {operador.ATIVO === "SIM" ? "SIM" : "NÃO"}
            </div>
            <div className="select">
                <Select
                    options={options}
                    onChange={(v) => setNivel(v)}
                    disabled={disabled}
                    defaultValue={options.find(o => o.value === nivel)}
                    placeholder={"Setor"}
                    {...defaultSelect100}
                    $focusColor=""
                    $disableBorder
                    $fontSize={0.75}
                />
            </div>
            <div>
                <Input
                    placeholder="Nome"
                    disabled={disabled}
                    value={nome}
                    onChange={(e) => setNome(e.target.value)}
                    {...defaultInput100}
                    $disableBorder
                    $fontSize={0.75}
                />
            </div>
            <div>
                <Input
                    placeholder="Login"
                    disabled={disabled}
                    value={login}
                    onChange={(e) => setLogin(e.target.value)}
                    {...defaultInput100}
                    $disableBorder
                    $fontSize={0.75}
                />
            </div>
            <div>
                <Input
                    placeholder="Nome de exibição"
                    disabled={disabled}
                    value={nomeExibicao || "N/D"}
                    onChange={(e) => setNomeExibicao(e.target.value)}
                    {...defaultInput100}
                    $disableBorder
                    $fontSize={0.75}
                />
            </div>
            <div className="setor">
                <Select
                    options={sectorOptions.value}
                    onChange={(v) => setSector(v)}
                    disabled={disabled}
                    defaultValue={{ name: operador.SETOR_NOME, value: operador.SETOR }}
                    placeholder={"Setor"}
                    {...defaultSelect100}
                    $focusColor=""
                    $disableBorder
                    $fontSize={0.75}
                />
            </div>
        </StyledTableItem>
    );
};