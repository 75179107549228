import { CustomState } from "../../../Hooks/State.hook";
import { OficialWhatsappMessageTemplate, RecoverTemplatesResponse } from "../../../interfaces/MessageTemplates.interfaces";
import { TableItem } from "./TableItem";
import { StyledTemplateMessages } from "./style";
import { useEffect } from "react";
import api from "../../../services/api";

export const TemplateMessages = () => {
    const templatesArr = CustomState<Array<OficialWhatsappMessageTemplate>>([]);

    useEffect(() => {
        api.get<RecoverTemplatesResponse>(`/whatsapp-cloud-api/templates/`)
            .then(res => {
                if (res.data.data) {
                    templatesArr.set(res.data.data);
                };
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <StyledTemplateMessages>
            <div className="templates_table">
                <header>
                    <div style={{ gridArea: "actions" }}>
                    </div>
                    <div style={{ gridArea: "id" }}>
                        ID
                    </div>
                    <div style={{ gridArea: "status" }}>
                        Status
                    </div>
                    <div style={{ gridArea: "lang" }}>
                        Idioma
                    </div>
                    <div style={{ gridArea: "category" }}>
                        Categoria
                    </div>
                    <div style={{ gridArea: "name" }}>
                        Nome do template
                    </div>
                </header>
                <ul>
                    {templatesArr.value.map(i => <TableItem key={i.id} messageTemplate={i} />)}
                </ul>
            </div>
        </StyledTemplateMessages>
    );
};